import moment from 'moment';
import { i18n } from 'shared/i18n';
import { setErrors } from 'shared/form/utils';
import { BreakingNews, SelectOption } from 'shared/types';
import { createSelectOption, FailliteStatus } from 'shared/models';
import { NewsletterDetail } from 'views/newsletter/types';
import { adToViewState } from 'views/newsletter/state/common';
import {
  SlugInput,
  ClubMemberNews,
  EnCeMomentDansLeMagazine,
  ExternalApiDefinition,
  Jobs,
  PaperjamClub,
  ParusChezMaisonModerne,
  FaillitesInput,
  NewsletterContent,
  NewsletterInput,
  NewsletterContentInput,
} from '../categories/types';
import { AdsContent } from '../categories/types';
import { appConfig } from 'shared/config/env';

const optionsToSlugs = (options?: SelectOption[]) => options?.map((option) =>
  (option ? option.value : '')
).filter((option) => option !== '') || [];

const isAdValid = (adsContent: AdsContent) => (
  adsContent.isActive && !!adsContent.link && !!adsContent.imageFilename
) || !adsContent.isActive;

const isExternalApiValid = (externalApi: ExternalApiDefinition) =>
  !externalApi.isActive || (
    externalApi.isActive &&
    !!externalApi.description &&
    !!externalApi.imageFilename &&
    !!externalApi.link &&
    !!externalApi.surtitle &&
    !!externalApi.title
  );

const isBreakingNewsValid = (breakingNews: BreakingNews) => !breakingNews.isActive || (
  breakingNews.isActive && !!breakingNews.title
);

const isSponsoredArticleValid = (article?: SlugInput) => article &&
  (!article.isActive || (article.isActive && !!article.slug));


const areDailyThingsValid = (delanoBreakfastInput: NewsletterContentInput) =>
  (!delanoBreakfastInput.chiffreDuJour?.isActive ||
    (delanoBreakfastInput.chiffreDuJour.isActive &&
      !!delanoBreakfastInput.chiffreDuJour.imageFilename &&
      !!delanoBreakfastInput.chiffreDuJour.title)) &&
  (!delanoBreakfastInput?.tweetDuJour?.isActive ||
    (delanoBreakfastInput.tweetDuJour.isActive &&
      !!delanoBreakfastInput.tweetDuJour.imageFilename &&
      !!delanoBreakfastInput.tweetDuJour.link)) &&
  (!delanoBreakfastInput?.photoDuJour?.isActive ||
    (delanoBreakfastInput.photoDuJour.isActive &&
      !!delanoBreakfastInput.photoDuJour.imageFilename &&
      !!delanoBreakfastInput.photoDuJour.title)) &&
  (!delanoBreakfastInput?.citationDuJour?.isActive ||
    (delanoBreakfastInput.citationDuJour.isActive &&
      !!delanoBreakfastInput.citationDuJour.author &&
      !!delanoBreakfastInput.citationDuJour.quote &&
      !!delanoBreakfastInput.citationDuJour.description)) &&
  (!delanoBreakfastInput?.enCeMomentDansLeMagazine?.isActive ||
    (delanoBreakfastInput.enCeMomentDansLeMagazine.isActive &&
      !!delanoBreakfastInput.enCeMomentDansLeMagazine.date &&
      !!delanoBreakfastInput.enCeMomentDansLeMagazine.title &&
      !!delanoBreakfastInput.enCeMomentDansLeMagazine.description &&
      !!delanoBreakfastInput.enCeMomentDansLeMagazine.link &&
      !!delanoBreakfastInput.enCeMomentDansLeMagazine.imageFilename
    ));

const isMagazineValid = (magazine: EnCeMomentDansLeMagazine) =>
  !magazine.isActive || (
    magazine.isActive &&
    !!magazine.date &&
    !!magazine.description &&
    !!magazine.imageFilename &&
    !!magazine.title &&
    !!magazine.link
  );

const isPJClubValid = (paperjamClub: PaperjamClub) => !paperjamClub.isActive || (
  paperjamClub.isActive && !!paperjamClub.title && !!paperjamClub.slug
);

const isClubMembersValid = (ClubMember: ClubMemberNews) => !ClubMember.isActive || (
  ClubMember.isActive &&
  !!ClubMember.description &&
  !!ClubMember.websiteLink &&
  !!ClubMember.websiteName
);

const isJobValid = (job: Jobs) =>
  !!job.lieu && !!job.link && !!job.logoFilename && !!job.societe && !!job.title && !!job.typeOffre;

const isParusChezValid = (parusChez?: ParusChezMaisonModerne) =>
  !!parusChez?.description &&
  !!parusChez?.imageFilename &&
  !!parusChez?.link &&
  !!parusChez?.title;

const isFailliteValid = (faillite: FaillitesInput) => !!faillite.nomSociete && !!faillite.status && !!faillite.typeSociete;

const failliteStatusToLabel = (status: string) => {
  switch (status) {
    case FailliteStatus.AnnulationFaillite:
      return i18n('newsletters.view.failStatus.bankrupt');
    case FailliteStatus.AnnulationLiquidation:
      return i18n('newsletters.view.failStatus.liquidation');
    case FailliteStatus.ClotureFaillite:
      return i18n('newsletters.view.failStatus.closing');
    case FailliteStatus.Reddition:
      return i18n('newsletters.view.failStatus.accountability');
    default:
      return status;
  }
};

export const initialDelanoBreakfastData = (value: NewsletterDetail) => {
  const content = value.content as NewsletterContent;

  return {
    title: value.title,
    publicationDate: moment(value.publicationDate).toDate(),
    content: {
      slugline: value.content.slugline,
      billboards: {
        adsBillboard1: adToViewState(content.billboards?.adsBillboard1),
        adsBillboard2: adToViewState(content.billboards?.adsBillboard2),
        adsBillboard3: adToViewState(content.billboards?.adsBillboard3),
        adsBillboard4: adToViewState(content.billboards?.adsBillboard4),
        adsBillboard5: adToViewState(content.billboards?.adsBillboard5),
        adsBillboard6: adToViewState(content.billboards?.adsBillboard6),
        adsBillboard7: adToViewState(content.billboards?.adsBillboard7),
      },
      externalApi: {
        myLifeLu: {
          isActive: !!content.externalApi?.myLifeLu,
          ...content.externalApi?.myLifeLu,
        },
        enjoy: {
          isActive: !!content.externalApi?.enjoy,
          ...content.externalApi?.enjoy,
        },
        businessInEnglish: {
          isActive: !!content.externalApi?.businessInEnglish,
          ...content.externalApi?.businessInEnglish,
        },
        vousMangezOuCeMidi: {
          isActive: !!content.externalApi?.vousMangezOuCeMidi,
          ...content.externalApi?.vousMangezOuCeMidi,
        },
        jobs: content.externalApi?.jobs,
        investLongTerm: {
          isActive: !!content.externalApi?.investLongTerm,
          slug: content.externalApi?.investLongTerm,
        },
      },
      breakingNews: {
        isActive: !!content.breakingNews,
        ...content.breakingNews,
      },
      adsImu: adToViewState(content.adsImu),
      aLaUne: content.aLaUne,
      inspiringWorkplace: {
        isActive: !!content.inspiringWorkplace,
        slug: content.inspiringWorkplace,
      },
      observatoireImmobilier: {
        isActive: !!content.observatoireImmobilier,
        slug: content.observatoireImmobilier,
      },
      aSuivre: content.aSuivre,
      sectors: {
        ...content.sectors
      },
      photoDuJour: {
        isActive: !!content.photoDuJour,
        ...content.photoDuJour
      },
      tweetDuJour: {
        isActive: !!content.tweetDuJour,
        ...content.tweetDuJour,
      },
      chiffreDuJour: {
        isActive: !!content.chiffreDuJour,
        ...content.chiffreDuJour
      },
      citationDuJour: {
        isActive: !!content.citationDuJour,
        ...content.citationDuJour,
      },
      expertises: content.expertises,
      carrieres: content.carrieres,
      faillites: content.faillites?.map((faillites) => ({
        nomSociete: faillites.nomSociete,
        typeSociete: faillites.typeSociete,
        status: { value: faillites.status, label: failliteStatusToLabel(faillites.status) },
      })),
      anniversaires: content.anniversaires?.map((a) => createSelectOption(a.slug, a.title)),
      topBonnesNouvelles: content.topBonnesNouvelles,
      clubPart: {
        agenda: content.clubPart?.agenda?.map((a) => createSelectOption(a.slug, a.title)) || [],
        clubAgenda: content.clubPart?.clubAgenda?.map((a) => createSelectOption(a.slug, a.title)) || [],
        paperjamClub: {
          isActive: !!content.clubPart?.paperjamClub,
          ...content.clubPart?.paperjamClub,
        },
        clubMemberNews: {
          isActive: !!content.clubPart?.clubMemberNews,
          ...content.clubPart?.clubMemberNews,
        },
      },
      enCeMomentDansLeMagazine: {
        isActive: !!content.enCeMomentDansLeMagazine,
        ...content.enCeMomentDansLeMagazine
      },
      parusChezMaisonModerne: value.content.parusChezMaisonModerne,
    },
  };
};

export const delanoBreakfastNoon = (value: NewsletterInput) => ({
  ...value,
  publicationDate: moment(value.publicationDate).format(appConfig.yearBasedDateFormat),
  content: {
    ...value.content,
    billboards: {
      adsBillboard1: value.content.billboards.adsBillboard1?.isActive
        ? {
          imageFilename: value.content.billboards.adsBillboard1.imageFilename,
          link: value.content.billboards.adsBillboard1.link,
        }
        : undefined,
      adsBillboard2: value.content.billboards.adsBillboard2?.isActive
        ? {
          imageFilename: value.content.billboards.adsBillboard2.imageFilename,
          link: value.content.billboards.adsBillboard2.link,
        }
        : undefined,
      adsBillboard3: value.content.billboards.adsBillboard3?.isActive
        ? {
          imageFilename: value.content.billboards.adsBillboard3.imageFilename,
          link: value.content.billboards.adsBillboard3.link,
        }
        : undefined,
      adsBillboard4: value.content.billboards.adsBillboard4?.isActive
        ? {
          imageFilename: value.content.billboards.adsBillboard4.imageFilename,
          link: value.content.billboards.adsBillboard4.link,
        }
        : undefined,
      adsBillboard5: value.content.billboards.adsBillboard5?.isActive
        ? {
          imageFilename: value.content.billboards.adsBillboard5.imageFilename,
          link: value.content.billboards.adsBillboard5.link,
        }
        : undefined,
      adsBillboard6: value.content.billboards.adsBillboard6?.isActive
        ? {
          imageFilename: value.content.billboards.adsBillboard6.imageFilename,
          link: value.content.billboards.adsBillboard6.link,
        }
        : undefined,
      adsBillboard7: value.content.billboards.adsBillboard7?.isActive
        ? {
          imageFilename: value.content.billboards.adsBillboard7.imageFilename,
          link: value.content.billboards.adsBillboard7.link,
        }
        : undefined,
    },
    aSuivre: value.content.aSuivre || [],
    carrieres: value.content.carrieres || [],
    expertises: value.content.expertises || [],
    externalApi: {
      ...value.content.externalApi,
      myLifeLu: value.content.externalApi.myLifeLu?.isActive
        ? {
          surtitle: value.content.externalApi.myLifeLu.surtitle,
          title: value.content.externalApi.myLifeLu.title,
          description: value.content.externalApi.myLifeLu.description,
          imageFilename: value.content.externalApi.myLifeLu.imageFilename,
          link: value.content.externalApi.myLifeLu.link,
        }
        : undefined,
      enjoy: value.content.externalApi.enjoy?.isActive
        ? {
          surtitle: value.content.externalApi.enjoy.surtitle,
          title: value.content.externalApi.enjoy.title,
          description: value.content.externalApi.enjoy.description,
          imageFilename: value.content.externalApi.enjoy.imageFilename,
          link: value.content.externalApi.enjoy.link,
        }
        : undefined,
      businessInEnglish: value.content.externalApi.businessInEnglish?.isActive
        ? {
          surtitle: value.content.externalApi.businessInEnglish.surtitle,
          title: value.content.externalApi.businessInEnglish.title,
          description: value.content.externalApi.businessInEnglish.description,
          imageFilename: value.content.externalApi.businessInEnglish.imageFilename,
          link: value.content.externalApi.businessInEnglish.link,
        }
        : undefined,
      vousMangezOuCeMidi: value.content.externalApi.vousMangezOuCeMidi?.isActive
        ? {
          surtitle: value.content.externalApi.vousMangezOuCeMidi.surtitle,
          title: value.content.externalApi.vousMangezOuCeMidi.title,
          description: value.content.externalApi.vousMangezOuCeMidi.description,
          imageFilename: value.content.externalApi.vousMangezOuCeMidi.imageFilename,
          link: value.content.externalApi.vousMangezOuCeMidi.link,
        }
        : undefined,
      investLongTerm: value.content.externalApi.investLongTerm?.isActive
        ? value.content.externalApi.investLongTerm.slug
        : undefined,
      jobs: value.content.externalApi.jobs || [],
    },
    adsImu: value.content.adsImu?.isActive
      ? {
        imageFilename: value.content.adsImu.imageFilename,
        link: value.content.adsImu.link,
      }
      : undefined,
    aLaUne: value.content.aLaUne || '',
    faillites: value.content.faillites?.map((faillites) => ({
      nomSociete: faillites.nomSociete,
      typeSociete: faillites.typeSociete,
      status: faillites.status.value,
    })) || [],
    inspiringWorkplace: value.content.inspiringWorkplace?.isActive ? value.content.inspiringWorkplace.slug : undefined,
    observatoireImmobilier: value.content.observatoireImmobilier?.isActive
      ? value.content.observatoireImmobilier.slug
      : undefined,
    photoDuJour:
      value.content.photoDuJour?.isActive
        ? {
          link: value.content.photoDuJour.link || undefined,
          title: value.content.photoDuJour.title,
          imageFilename: value.content.photoDuJour.imageFilename,
          description: value.content.photoDuJour.description || undefined,
        }
        : undefined,
    tweetDuJour: value.content.tweetDuJour?.isActive
      ? {
        link: value.content.tweetDuJour.link,
        imageFilename: value.content.tweetDuJour.imageFilename,
      }
      : undefined,
    chiffreDuJour: value.content.chiffreDuJour?.isActive
      ? {
        imageFilename: value.content.chiffreDuJour.imageFilename,
        title: value.content.chiffreDuJour.title,
        description: value.content.chiffreDuJour.description || undefined,
      }
      : undefined,
    citationDuJour: value.content.citationDuJour?.isActive
      ? {
        author: value.content.citationDuJour.author,
        description: value.content.citationDuJour.description,
        quote: value.content.citationDuJour.quote,
        slug: value.content.citationDuJour.slug,
      }
      : undefined,
    sectors: {
      entreprisesEtStrategies: value.content.sectors?.entreprisesEtStrategies || [],
      lifestyleEtViePrivee: value.content.sectors?.lifestyleEtViePrivee || [],
      placeFinanciereEtMarches: value.content.sectors?.placeFinanciereEtMarches || [],
      politiqueInstitutions: value.content.sectors?.politiqueInstitutions || [],
    },
    topBonnesNouvelles: value.content.topBonnesNouvelles || [],
    anniversaires: optionsToSlugs(value.content.anniversaires),
    breakingNews: value.content.breakingNews?.isActive ? {
      title: value.content.breakingNews.title,
      slug: value.content.breakingNews.slug,
    } : undefined,
    clubPart: {
      agenda: optionsToSlugs(value.content.clubPart.agenda),
      clubAgenda: optionsToSlugs(value.content.clubPart.clubAgenda),
      paperjamClub: value.content.clubPart.paperjamClub?.isActive
        ? {
          slug: value.content.clubPart.paperjamClub.slug || '',
          title: value.content.clubPart.paperjamClub.title,
        }
        : undefined,
      clubMemberNews: value.content.clubPart.clubMemberNews?.isActive
        ? {
          title: value.content.clubPart.clubMemberNews.title,
          description: value.content.clubPart.clubMemberNews.description,
          websiteName: value.content.clubPart.clubMemberNews.websiteName,
          websiteLink: value.content.clubPart.clubMemberNews.websiteLink,
        }
        : undefined,
    },
    enCeMomentDansLeMagazine: value.content.enCeMomentDansLeMagazine?.isActive
      ? {
        date: value.content.enCeMomentDansLeMagazine.date,
        title: value.content.enCeMomentDansLeMagazine.title,
        description: value.content.enCeMomentDansLeMagazine.description,
        link: value.content.enCeMomentDansLeMagazine.link,
        imageFilename: value.content.enCeMomentDansLeMagazine.imageFilename,
      }
      : undefined,
    parusChezMaisonModerne: value.content.parusChezMaisonModerne?.map((parusChezMaisonModern) => ({
      title: parusChezMaisonModern.title,
      description: parusChezMaisonModern.description,
      link: parusChezMaisonModern.link,
      imageFilename: parusChezMaisonModern.imageFilename,
    })) || [],
  }});


export const delanoBreakfastNoonValidate = (value: NewsletterInput) => {
  const errors = {};
  if (value) {
    if (!value.content.slugline) {
      setErrors(errors, 'content.slugline', i18n('forms.validation.required'));
    }
    if (!value.publicationDate) {
      setErrors(errors, 'publicationDate', i18n('forms.validation.required'));
    }
    if (!value.title) {
      setErrors(errors, 'title', i18n('forms.validation.required'));
    }
    if (!value.content.aLaUne) {
      setErrors(errors, 'content.aLaUne', i18n('forms.validation.required'));
    }
    if (value.content.inspiringWorkplace && !isSponsoredArticleValid(value.content.inspiringWorkplace)) {
      setErrors(errors, 'content.inspiringWorkplace', i18n('forms.validation.invalid'));
    }
    if (value.content.inspiringWorkplace && !isSponsoredArticleValid(value.content.observatoireImmobilier)) {
      setErrors(errors, 'content.observatoireImmobilier', i18n('forms.validation.invalid'));
    }
    if (value.content.billboards) {
      if (value.content.billboards.adsBillboard1 && !isAdValid(value.content.billboards.adsBillboard1)) {
        setErrors(errors, 'content.billboards.adsBillboard1', i18n('forms.validation.invalid'));
      }
      if (value.content.billboards.adsBillboard2 && !isAdValid(value.content.billboards.adsBillboard2)) {
        setErrors(errors, 'content.billboards.adsBillboard2', i18n('forms.validation.invalid'));
      }
      if (value.content.billboards.adsBillboard3 && !isAdValid(value.content.billboards.adsBillboard3)) {
        setErrors(errors, 'content.billboards.adsBillboard3', i18n('forms.validation.invalid'));
      }
      if (value.content.billboards.adsBillboard4 && !isAdValid(value.content.billboards.adsBillboard4)) {
        setErrors(errors, 'content.billboards.adsBillboard4', i18n('forms.validation.invalid'));
      }
      if (value.content.billboards.adsBillboard5 && !isAdValid(value.content.billboards.adsBillboard5)) {
        setErrors(errors, 'content.billboards.adsBillboard5', i18n('forms.validation.invalid'));
      }
      if (value.content.billboards.adsBillboard6 && !isAdValid(value.content.billboards.adsBillboard6)) {
        setErrors(errors, 'content.billboards.adsBillboard6', i18n('forms.validation.invalid'));
      }
      if (value.content.billboards.adsBillboard7 && !isAdValid(value.content.billboards.adsBillboard7)) {
        setErrors(errors, 'content.billboards.adsBillboard7', i18n('forms.validation.invalid'));
      }
    }
    if (value.content.adsImu && !isAdValid(value.content.adsImu)) {
      setErrors(errors, 'content.adsImu', i18n('forms.validation.invalid'));
    }
    if (value.content.externalApi) {
      if (value.content.externalApi.myLifeLu && !isExternalApiValid(value.content.externalApi.myLifeLu)) {
        setErrors(errors, 'content.externalApi.myLifeLu', i18n('forms.validation.invalid'));
      }
      if (value.content.externalApi.enjoy && !isExternalApiValid(value.content.externalApi.enjoy)) {
        setErrors(errors, 'content.externalApi.enjoy', i18n('forms.validation.invalid'));
      }
      if (
        value.content.externalApi.businessInEnglish &&
        !isExternalApiValid(value.content.externalApi.businessInEnglish)
      ) {
        setErrors(errors, 'content.businessInEnglish', i18n('forms.validation.invalid'));
      }
      if (
        value.content.externalApi.vousMangezOuCeMidi &&
        !isExternalApiValid(value.content.externalApi.vousMangezOuCeMidi)
      ) {
        setErrors(errors, 'content.externalApi.vousMangezOuCeMidi', i18n('forms.validation.invalid'));
      }
      if (value.content.externalApi.jobs && !value.content.externalApi.jobs.every((job) => isJobValid(job))) {
        setErrors(errors, 'content.externalApi.jobs', i18n('forms.validation.invalid'));
      }
    }
    if (
      value.content.breakingNews &&
      !isBreakingNewsValid(value.content.breakingNews)
    ) {
      setErrors(errors, 'content.breakingNews', i18n('forms.validation.invalid'));
    }
    if (!areDailyThingsValid(value.content)) {
      setErrors(errors, 'content', i18n('forms.validation.invalid'));
    }
    if (value.content.enCeMomentDansLeMagazine && !isMagazineValid(value.content.enCeMomentDansLeMagazine)) {
      setErrors(errors, 'content.enCeMomentDansLeMagazine', i18n('forms.validation.invalid'));
    }
    if (value.content.clubPart) {
      if (value.content.clubPart.paperjamClub && !isPJClubValid(value.content.clubPart.paperjamClub)) {
        setErrors(errors, 'content.clubPart.paperjamClub', i18n('forms.validation.invalid'));
      }
      if (value.content.clubPart.clubMemberNews && !isClubMembersValid(value.content.clubPart.clubMemberNews)) {
        setErrors(errors, 'content.clubPart.clubMemberNews', i18n('forms.validation.invalid'));
      }
    }
    if (value.content.parusChezMaisonModerne && !value.content.parusChezMaisonModerne.every(
      (parusChezMaisonModerne) => isParusChezValid(parusChezMaisonModerne)
    )) {
      setErrors(errors, 'content.parusChezMaisonModerne', i18n('forms.validation.invalid'));
    }
    if (value.content.faillites && !value.content.faillites.every((faillite) => isFailliteValid(faillite))) {
      setErrors(errors, 'content.faillites', i18n('forms.validation.invalid'));
    }
  }

  return errors;
};

export const delanoBreakfastNoonCopy = (value: NewsletterDetail) => {
  const content = value.content as NewsletterContent;
  return ({
    ...value,
    publicationDate: moment(value.publicationDate).format(appConfig.yearBasedDateFormat),
    content: {
      ...value.content,
      aSuivre: content.aSuivre || [],
      carrieres: content.carrieres || [],
      expertises: content.expertises || [],
      aLaUne: content.aLaUne || '',
      anniversaires: (content.anniversaires || []).map((anniversaires) => anniversaires.slug),
      clubPart: {
        ...content.clubPart,
        agenda: (content.clubPart.agenda || []).map((agenda) => agenda.slug),
        clubAgenda: (content.clubPart.clubAgenda || []).map((clubAgenda) => clubAgenda.slug),
      },
    }});
};

