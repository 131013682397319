import moment from 'moment';
import { appConfig, REST } from 'shared/config/env';
import { createSelectOption, getSubsectors, Sectors } from 'shared/models';
import { Image } from 'shared/types';
import { PodcastShowInput } from 'views/show/PodcastShowView';
import { PodcastShow } from 'views/shows/PodcastsShowsListView';

export const INITIAL_STATE = {
  slug: '',
  title: '',
  mainImage: {
    _type: 'lu.paperjam.common.models.ModernImageContentType',
    filename: '',
    caption: undefined,
    credits: undefined,
    focusPoint: undefined,
  },
  podcastEpisodes: [],
  creationDate: moment().toISOString(),
  updateDate: undefined,
  subscribeUrl: '',
  publication: {
    date: moment().toISOString(),
    isPublished: false,
    target: {
      news: true,
      club: true,
    },
  },
  isSponsored: false,
};

export const toPostCastShowUpdateDto = (model: PodcastShowInput, images: Image[]) => ({
  ...(appConfig.queryType === REST && model),
  isSponsored: model.isSponsored,
  mainImage: {filename: model.mainImage.filename},
  podcastEpisodes: model.podcastEpisodes,
  publication: model.publication,
  sector: model.sector?.value,
  subscribeUrl: model.subscribeUrl,
  subSector: model.subSector?.value,
  title: model.title,
  slug: model.slug,
  images
});

export const toPostCastShowDto = (model: PodcastShowInput, images: Image[]) => {
  const dto = {
  slug: appConfig.queryType === REST ? '' : undefined,
  creationDate: model.creationDate,
  isSponsored: model.isSponsored,
  mainImage: {
    _type: appConfig.queryType === REST ? 'lu.paperjam.common.models.ModernImageContentType' : undefined,
    filename: model.mainImage.filename,
  },
  podcastEpisodes: model.podcastEpisodes,
  publication: model.publication,
  sector: model.sector?.value,
  subscribeUrl: model.subscribeUrl,
  subSector: model.subSector?.value,
  title: model.title,
  images,
};

return dto;
};

export const toWorkshopDto = (model: PodcastShowInput) => ({
  ...model,
  sector: model.sector?.value,
  subSector: model.subSector?.value,
  subsubSector: model.subsubSector?.value,
  podcastEpisodes: model.podcastEpisodes,
});

export const toFormState = (podcastShow: PodcastShow) => {
  const { sector, subSector, ...rest } = podcastShow;
  return {
  ...rest,
  sector: sector && createSelectOption(sector, Sectors.display(sector)),
  subSector: sector && subSector && getSubsectors(sector).find(({ value }) => value === subSector),
  };
};
