import { useState } from 'react';
import { commonConstants } from 'shared/constants';
import { checkPasswordStrength, validateAuthentificationCode, verifyPassword } from 'shared/utils/utils';

interface UseResetPasswordProps {
    code: string;
    newPassword: string;
    showPassword: boolean;
    isDisabled: boolean;
    errorMessage: string[];
    strengthStyle: string;
    handleCodeChange: (value: unknown) => void;
    handleNewPasswordChange: (value: unknown) => void;
    handlePasswordChange: (value: unknown) => void;
    handleFormSubmit: () => void;
    togglePasswordVisibility: () => void;
}

type ConfirmPasswordCallback = (
    code: string,
    newPassword: string) => void;

export interface ResetFields {
    code: string;
    newPassword: string;
    password: string;
}

const useInteractionHandleResetPasswordInput = (onConfirmPassword: ConfirmPasswordCallback, loading: boolean): UseResetPasswordProps => {
    const [password, setPassword] = useState('');
    const [code, setCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string[]>([]);
    const [strengthStyle, setStrengthStyle] = useState('');
    const [errors, setErrors] = useState<Record<string, string>>({});

    const fieldsName = commonConstants.RESET_PASSWORD_FIELDS;
    const errorMessages = commonConstants.RESET_ERROR_MESSAGES;

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleCodeChange = (value: unknown) => {
        const currentCode = value as string;
        setCode(currentCode);
        handleResetPasswordErrors(fieldsName.code, currentCode, '');
    };

    const handleNewPasswordChange = (value: unknown) => {
        const currentPassword = value as string;
        setNewPassword(currentPassword);
        updatePasswordStrength(currentPassword);
        handleResetPasswordErrors(fieldsName.newPassword, currentPassword, '');
    };

    const handlePasswordChange = (value: unknown) => {
        const currentPassword = value as string;
        setPassword(currentPassword);
        handleResetPasswordErrors(fieldsName.password, currentPassword, newPassword);
    };

    const handleFormSubmit = async () => {
        const errorsList = getConcatenatedErrors() as string[];
        setErrorMessage(errorsList);
        const isValidFields = !!code && !!newPassword && !!password;

        if (!loading && errorsList.length === 0 && isValidFields) {
            onConfirmPassword(code, newPassword);
            setIsDisabled(!isValidFields);
        }
    };

    const updatePasswordStrength = (password: string) => {
        const strength = checkPasswordStrength(password);
        setStrengthStyle(`strength-${strength}`);
    };

    const handleResetPasswordErrors = (fieldName: string, fieldValue: string, newPassword?: string) => {
        setErrors((prevErrors) => {
            const updatedErrors = prevErrors;

            switch (fieldName) {
                case fieldsName.code:
                    const { status: codeStatus, message: codeMessage } = validateAuthentificationCode(fieldValue);
                    updatedErrors.code = codeStatus ? '' : codeMessage;
                    break;

                case fieldsName.newPassword:
                    const passwordStrength = checkPasswordStrength(fieldValue);
                    updatedErrors.newPassword = passwordStrength < 3 ? errorMessages.passwordStrength : '';
                    break;

                case fieldsName.password:
                    const initialPassword = newPassword || '';
                    const passwordValidation = verifyPassword(fieldValue, initialPassword);
                    updatedErrors.password = passwordValidation.status ? '' : passwordValidation.message;
                    break;

                default:
                    break;
            }

            return updatedErrors;
        });
    };

    const getConcatenatedErrors = () => {
        return Object.values(errors)
            .filter(error => error)
            .map(error => `* ${error}`);
    };

    return {
        code,
        newPassword,
        showPassword,
        isDisabled,
        errorMessage,
        strengthStyle,
        handleCodeChange,
        handleNewPasswordChange,
        handlePasswordChange,
        handleFormSubmit,
        togglePasswordVisibility,
    };
};

export default useInteractionHandleResetPasswordInput;
