import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { isDataError } from 'shared/utils/utils';
import { DataError } from 'shared/types';

interface UseResetPasswordReturn {
  loading: boolean;
  forgotPassword: <T>(username: string) => Promise<RequestSuccess<T | DataErrorResult | string>>;
  confirmNewPassword: <T>(username: string, code: string, newPassword: string) => Promise<RequestSuccess<T | DataErrorResult | string>>;
}

interface RequestSuccess<T> {
  status: boolean;
  data: T;
}

interface DataErrorResult {
  message: string;
  status: number;
}

const useResetPassword = (): UseResetPasswordReturn => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleAsyncOperation = async <T>(asyncFunc: () => Promise<T>): Promise<RequestSuccess<T | DataErrorResult>> => {
    try {
      setLoading(true);
      const data = await asyncFunc();
      setLoading(false);
      return { status: true, data };
    } catch (err) {
      setLoading(false);
      const error = err as DataError;
      return handleErrorNotification(error);
    }
  };

  const forgotPassword = <T>(username: string): Promise<RequestSuccess<T | DataErrorResult | string>> =>
    handleAsyncOperation(() => Auth.forgotPassword(username));

  const confirmNewPassword = <T>(username: string, code: string, newPassword: string): Promise<RequestSuccess<T | DataErrorResult | string>> => handleAsyncOperation(() => Auth.forgotPasswordSubmit(username, code, newPassword));

  const handleErrorNotification = (err: DataError) => {
    setLoading(false);
    if (isDataError(err)) {
      const error = err;
      return { status: false, data: { message: error.detail, status: error.status } };
    } else {
      return { status: false, data: { message: 'An unknown error occurred', status: 500 } };
    }
  };

  return { loading, forgotPassword, confirmNewPassword };
};

export default useResetPassword;
