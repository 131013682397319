import { DocumentNode, gql } from '@apollo/client';
import { GetListParams, GetOneParams, GetSearchParams } from 'shared/providers/dataProvider/types';
import { appConfig } from 'shared/config/env';
import { getSort } from 'shared/services/graphql/utils';
import { EventSortingCriteriaAlias } from 'shared/services/graphql/aliases';

const list = (params?: GetListParams): DocumentNode => {
  const offset =
    params?.page !== undefined
      ? params.page === 1
        ? appConfig.offset
        : (params.page - 1) * Number(appConfig.limit)
      : appConfig.offset;

  const [sort, asc] = getSort(EventSortingCriteriaAlias, params?.sort);

  const query = params?.titleQuery || '';

  return gql`
    query {
      events (
        limit: ${appConfig.limit},
        offset: ${offset},
        sort: "${sort}",
        asc: ${asc},
        language: ${appConfig.defaultLocale},
        fallback: ${appConfig.defaultLocale},
        query: "${query}"
      ) {
        ... on EventsResponse {
          items {
            slug
            publication {
              date
              isPublished
              target {
                news
                club
              }
              newsTicker
            }
            metadata {
              title
              format
              creationDate
            }
            content {
              mainImage {
                filename
                caption
                credits
                focusPoint {
                  x
                  y
                }
              }
            }
          }
          count
          limit
          offset
        }

        ... on ErrorPayload {
          traceId
          errorMessage
          validationErrors {
            code
            message
          }
        }

        ... on NoResult {
          message
        }
      }
    }
  `;
};

const retrieve = <T>(params?: GetOneParams<T>): DocumentNode =>
  gql`
    query {
      event (
        slug: "${params?.id}"
      ) {
          ...on EventOut {
            slug
            woodwingRef
            drupalRef
            metadata {
              format
              theme
              title
              subTitle
              updatedate
              tags
              linkedContent {
                articles {
                  slug
                  title
                }
                sponsoredContent {
                  slug
                  title
                }
                events {
                  slug
                  title
                }
              }
              creationDate
            }
            content {
              slugLine
              mainImage {
                filename
                caption
                credits
                focusPoint {
                  x
                  y
                }
              }
              wideImage {
                filename
                caption
                credits
                focusPoint {
                  x
                  y
                }
              }
              descriptions {
                lang
                contents {
                  introduction {
                    link {
                      name
                      type
                      value
                    }
                    rich {
                      link {
                        name
                        type
                        value
                      }
                      text
                      tags
                    }
                    text
                  }
                  paragraph {
                    link {
                      name
                      type
                      value
                    }
                    rich {
                      link {
                        name
                        type
                        value
                      }
                      text
                      tags
                    }
                    text
                  }
                  video
                  subtitle
                  drupalRich
                  drupalIntro
                  embed
                  image {
                    filename
                    caption
                    credits
                    focusPoint {
                      x
                      y
                    }
                  }
                  slideshow {
                    images {
                      filename
                      caption
                      credits
                      focusPoint {
                        x
                        y
                      }
                    }
                  }
                  readAlso {
                    text_1
                    link_1
                    text_2
                    link_2
                    text_3
                    link_3
                  }
                  quote {
                    imageFilename
                    text
                    author
                    company
                    role
                    authorLink
                    companyLink
                  }
                }
              }
              practicalInfo {
                beginDate
                endDate
                languages
                location {
                  name
                  street
                  zipCode
                  city
                  country
                }
              }
              speakers {
                slug
                title
              }
              sponsors {
                sponsorsGold {
                  slug
                  title
                }
                sponsorsSilver {
                  slug
                  title
                }
              }
              video {
                slug
                title
              }
              photo {
                slug
                title
              }
            }
            publication {
              date
              isPublished
              target {
                news
                club
              }
            }
            eventzillaEventId
          }
          ... on ErrorPayload {
            traceId
            errorMessage
            validationErrors {
              code
              message
            }
          }
          ... on NoResult {
            message
          }
        }
      }
  `;

const searchEventsAndWorkshopsDay = (params?: GetSearchParams): DocumentNode => {
  const queryStr = params?.q ? `query: "${params.q}"` : '';
  const filterStr = params?.filter ? `filter: "${params.filter}"` : '';
  const queryParams = [queryStr, filterStr].filter(Boolean).join(',');
  const finalParams = queryParams ? `(${queryParams})` : '';
  const gqlQuery = `
    query {
      searchEventAndWorkshops ${finalParams}{
        ... on SearchEventAndWorkshopOutput {
          items {
            kind
            slug
            title
          }
        }
        ... on ErrorPayload {
          traceId
          errorMessage
          validationErrors {
            code
            message
          }
        }
        ... on NoResult {
          message
        }
    }
  }`;
  return gql`
    ${gqlQuery}
  `;
};

const update = (): DocumentNode =>
  gql`
    mutation UpdateEvent($updateData: EventContentMetadataPublicationUpdateInput!) {
      updateEventContentMetadataPublication(updateData: $updateData) {
        ... on EventContentMetadataPublicationUpdateOutput {
          slug
          metadata {
            format
            theme
            updateDate
            tags
            linkedContent {
              articles
              sponsoredContent
              events
            }
          }
          content {
            speakers
            sponsors {
              sponsorsGold
              sponsorsSilver
            }
            video
            photo
          }
          publication {
            date
            isPublished
            target {
              news
              club
            }
          }
          eventzillaEventId
        }
        ... on ErrorPayload {
          traceId
          errorMessage
          validationErrors {
            code
            message
          }
        }
      }
    }
  `;

const eventsSearch = (params?: GetSearchParams): DocumentNode => {
  const queryStr = params?.q ? `query: "${params.q}"` : '';
  const filterStr = params?.filter ? `filter: "${params.filter}"` : '';
  const queryParams = [queryStr, filterStr].filter(Boolean).join(',');
  const finalParams = queryParams ? `(${queryParams})` : '';
  const gqlQuery = `
  query {
    eventsSearch ${finalParams} {
      ... on EventsSearchOutput {
        items {
          slug
          title
        }
      }
      ... on NoResult {
        message
      }
      ... on ErrorPayload {
        traceId
        errorMessage
        validationErrors {
          code
          message
        }
      }
    }
  }`;
  return gql`
    ${gqlQuery}
  `;
};

const eventsGraphqlServices = {
  list,
  retrieve,
  searchEventsAndWorkshopsDay,
  update,
  eventsSearch,
};

export default eventsGraphqlServices;
