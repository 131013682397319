import { resources, types } from 'shared/constants';

const getCreateNewsletterResource = (kind: string) => {
  switch (kind) {
    case types.CLUB:
      return resources.CREATE_NEWSLETTER_CLUB;
    case types.MORNING:
    case types.EVENING:
      return resources.CREATE_NEWSLETTER_DAILY;
    case types.DELANO_NOON:
    case types.DELANO_BREAKFAST:
      return resources.CREATE_NEWSLETTER_DELANO_DAILY;
    case types.GRAND_DOSSIER:
      return resources.CREATE_NEWSLETTER_DOSSIER;
    case types.TRENDIN:
      return resources.CREATE_NEWSLETTER_TRENDIN;
  }
};

const getUpdateNewsletterResource = (kind: string) => {
  switch (kind) {
    case types.CLUB:
      return resources.UPDATE_NEWSLETTER_CLUB;
    case types.MORNING:
    case types.EVENING:
      return resources.UPDATE_NEWSLETTER_DAILY;
    case types.DELANO_NOON:
    case types.DELANO_BREAKFAST:
      return resources.UPDATE_NEWSLETTER_DELANO_DAILY;
    case types.GRAND_DOSSIER:
      return resources.UPDATE_NEWSLETTER_DOSSIER;
    case types.TRENDIN:
      return resources.UPDATE_NEWSLETTER_TRENDIN;
  }
};

export default { getCreateNewsletterResource, getUpdateNewsletterResource };
