import { ChangeEvent, createRef, useEffect, useState } from 'react';
import { upload } from 'shared/services/httpService';
import { i18n } from 'shared/i18n';
import { CommonFieldProps, InputProps } from 'shared/form/types';
import {
  apiErrorToUiNotifier,
  SAVE_IMAGE_ERROR_MESSAGE,
  SAVE_IMAGE_SIZE_ERROR_MESSAGE,
  SAVE_IMAGE_TYPE_ERROR_MESSAGE,
} from 'shared/services';
import { ImageUpload } from 'shared/types';
import { appConfig, REST } from 'shared/config/env';
import './FileInput.scss';
import useInput from '../../useInput';

export interface FileInputProps extends InputProps<string>, CommonFieldProps {
  uploadUrl: string;
  onFileSelect?: (image: ImageUpload) => void;
  onRemoveFile?: (name: string) => void;
}

export interface ResponseFileData {
  filename: string;
}

const MAX_IMAGE_SIZE = 5242880;

const FileInput = ({
  uploadUrl,
  name,
  label,
  required,
  defaultValue,
  onFileSelect,
  onRemoveFile,
}: FileInputProps) => {
  const input = useInput({ name, defaultValue });
  const [filename, setFilename] =  useState<string>(input.value || '');
  const inputRef = createRef<HTMLInputElement>();

  const uploadFile = (formData: FormData) => {
    upload<ResponseFileData>(uploadUrl, formData)
      .then((data) => {
        setFilename(data.filename);
        input.onChange(data.filename);
      })
      .catch(apiErrorToUiNotifier(SAVE_IMAGE_ERROR_MESSAGE));
  };

  const handleUploadBtnClick = () => {
    if (inputRef.current === null) return;
    inputRef.current.click();
  };

  const handleResetBtnClick = () => {
    if (inputRef.current === null) return;
    inputRef.current.value = '';
    input.onChange('');
    setFilename('');
    if (onRemoveFile) {
      onRemoveFile(name);
    }
  };

  const showError = (file: File) => {
    if (['image/jpeg', 'image/jpg', 'image/png'].indexOf(file.type) === -1) {
      apiErrorToUiNotifier(SAVE_IMAGE_TYPE_ERROR_MESSAGE)();
      return true;
    }

    if(file.size > MAX_IMAGE_SIZE) {
      apiErrorToUiNotifier(SAVE_IMAGE_SIZE_ERROR_MESSAGE)();
      return true;
    }
    return false;
  };

  const handleChooseFile = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles === null) return;
    const file = selectedFiles[0];
    const formData = new FormData();
    formData.append('file', file);
    if (showError(file)) {
      return;
    }

    if (file) {
      if (appConfig.queryType !== REST) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          if (typeof reader.result === 'string') {
            if (onFileSelect) {
              onFileSelect({
                [name]: {
                  fileName: file.name,
                  base64: reader.result,
                }
              });
            }
            input.onChange(file.name);
          }
        };
        reader.onerror = () => {
          apiErrorToUiNotifier(SAVE_IMAGE_ERROR_MESSAGE)();
        };
        setFilename(file.name);
        return;
      }
      uploadFile(formData);
    }
  };

  useEffect(() => {
    if (filename) {
      const fileName = filename.substring(filename.lastIndexOf('/') + 1);
      if (fileName === 'temporary' || fileName === '') return;
      setFilename(fileName);
    }
  }, [filename]);

  useEffect(() => {
    if (input.value && !filename) {
      setFilename(input.value);
    }
  }, [filename, input]);

  return (
    <>
      {label !== undefined && (
        <span className='file-input-label'>
          {label}
          {required && <span className='file-input-required'>*</span>}
        </span>
      )}
      <div className='file-input-wrapper'>
        <input
          type="file"
          ref={inputRef}
          accept=".jpg,.jpeg,.png"
          onChange={handleChooseFile}
        />
        <input type="text" readOnly={true} className='file-input-name' value={filename} />
        <button type="button" className='file-input-reset-btn' onClick={handleResetBtnClick}>
          X
        </button>
        <button type="button" className='file-input-btn' onClick={handleUploadBtnClick}>
          {i18n('forms.fileInput.uploadLabel')}
        </button>
      </div>
      <span className='file-input-legend'>{i18n('forms.fileInput.info')}</span>
    </>
  );
};

export default FileInput;
