import moment from 'moment';
import { i18n } from 'shared/i18n';
import { useWatch } from 'shared/form';
import 'layout/header/header.scss';
import Header from 'layout/header/Header';

export interface ArticleDetailHeaderProps {
  title?: string;
}

const ArticleDetailHeader = ({ title = '' }: ArticleDetailHeaderProps) => {
  const date = useWatch('publication.date') as string | Date;

  return (
    <Header>
      <div className='header__inner'>
        <h1 className='header-title'>{i18n('articles.view.updateTitle')}</h1>
        <div className='header__section'>
          <span className='header__section-label'>{i18n('articles.view.articlesTitle')}</span>
          <span className='header__section-value'>{title}</span>
        </div>
        <div className='header__section'>
          <span className='header__section-label'>{i18n('articles.view.creationDate')}</span>
          <span className='header__section-value'>
          {date ? moment(date).format('DD/MM/YYYY') : ''}
        </span>
        </div>
      </div>
    </Header>
  );
};

export default ArticleDetailHeader;
