import {
  createSelectOption,
  getSubSubSectorByKey,
  getSubsectorByKey,
  Sectors,
  getSubsubSectorByName,
} from 'shared/models';
import { SelectLinkedContent, SelectOption } from 'shared/types';
import { Article, BaseArticle, Categorizations } from 'views/article/types';

export interface InputArticle extends BaseArticle {
  metadata: Metadata;
}

export interface Metadata {
  authorId?: number;
  author: string;
  audioId: string;
  categorizations: Partial<SelectCategorizations>[];
  creationDate: string;
  format: string;
  linkedContent: SelectLinkedContent;
  titleComplement?: string;
  showAudio?: boolean;
  tags: SelectOption[];
  title: string;
  topics: SelectOption[];
  updateDate?: string | Date;
}

export interface SelectCategorizations {
  sector: SelectOption;
  subSector: SelectOption;
  subsubSectorDto: {
    name: SelectOption;
    slug: string;
    subsector: string;
  }
}

const categorizationDtoFromModel = (categorization: Partial<Categorizations>): Partial<SelectCategorizations> => ({
  ...categorization,
  sector: categorization.sector ? {
    value: categorization.sector,
    label: Sectors.display(categorization.sector)
  } : undefined,
  subSector: categorization.subSector && categorization.sector ? {
    value: categorization.subSector,
    label: getSubsectorByKey(categorization.sector, categorization.subSector),
  } : undefined,
  subsubSectorDto: categorization.subsubSectorDto ? {
    slug: categorization.subsubSectorDto.slug || '',
    name: {
      value: categorization.subsubSectorDto.name,
      label: getSubsubSectorByName(categorization.subSector || '',
        categorization.subsubSectorDto.name || ''
      ),
    },
    subsector: categorization.subsubSectorDto.subsector || '',
  } : undefined,
});

export const articleToViewState = (article: Article): InputArticle => ({
  ...article,
  metadata: {
    ...article.metadata,
    updateDate: undefined,
    linkedContent: {
      ...article.metadata.linkedContent,
      articles: (article.metadata.linkedContent.articles &&
        article.metadata.linkedContent.articles.map((content) =>
        createSelectOption(content.slug, content.title))) || [],
      sponsoredContent: (article.metadata.linkedContent.sponsoredContent &&
        article.metadata.linkedContent.sponsoredContent.map((content) =>
          createSelectOption(content.slug || '', content.title || ''))) || [],
      events: (article.metadata.linkedContent.events &&
        article.metadata.linkedContent.events.map((content) =>
        createSelectOption(content.slug, content.title))) || [],
    },
    categorizations: article.metadata.categorizations &&
      article.metadata.categorizations.map(categorizationDtoFromModel),
    topics: article.metadata.topics &&
      article.metadata.topics.map((topic) => createSelectOption(topic, topic)),
    tags: article.metadata.tags &&
      article.metadata.tags.map((tag) => createSelectOption(tag, tag)),
  },
  publication: {
    ...article.publication,
    isPublished: article.publication.isPublished,
    date: getPublicationDate(article),
  },
});

export const viewStateToArticle = (article: InputArticle, showAudio: boolean): Article => ({
  ...article,
  metadata: {
    ...article.metadata,
    linkedContent: {
      ...article.metadata.linkedContent,
      articles: article.metadata.linkedContent.articles &&
        article.metadata.linkedContent.articles.map((content) => ({
        slug: content.value,
        title: content.label,
      })),
      sponsoredContent: article.metadata.linkedContent.sponsoredContent &&
        article.metadata.linkedContent.sponsoredContent.map((content) => ({
          slug: content.value,
          title: content.label,
        })),
      events: article.metadata.linkedContent.events &&
        article.metadata.linkedContent.events.map((content) => ({
        slug: content.value,
        title: content.label,
      })),
    },
    categorizations: article.metadata.categorizations &&
      article.metadata.categorizations.filter((categorization) =>
      categorization.sector && categorization.subSector
    ).map(
      (categorization) => ({
        sector: categorization.sector?.value,
        subSector: categorization.subSector?.value,
        subsubSectorDto: categorization.subsubSectorDto && categorization.subsubSectorDto.name ? {
          slug: categorization.subsubSectorDto.name.value,
          name: getSubSubSectorByKey(categorization.subsubSectorDto.name.value),
          subsector: categorization.subSector?.value,
        } : null,
      })),
    topics: article.metadata.topics && article.metadata.topics.map((topic) => topic.value),
    tags: article.metadata.tags && article.metadata.tags.map((tag) => tag.value),
    showAudio,
  },
  publication: {
    ...article.publication,
    isPublished: article.publication.isPublished,
  },
});

const getPublicationDate = (article: Article): Date | string => {
  if(!article.publication.date) {
    return new Date();
  }
  
  return article.publication.date;
};
