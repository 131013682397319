import { Content, FormSection } from 'layout/content';
import { Form } from 'shared/form';
import { ActionsBar, EventSelect } from 'shared/components';
import { ArrayInput, SlugInput } from 'shared/form/inputs';
import { resources } from 'shared/constants';
import ClubHomeModal from './ClubHomeModal';
import HeaderClubHome from './HeaderClubHome';
import './clubHomeDetails.scss';
import useInteractionClubHomeDetailsView from './useInteractionClubHomeDetailsView';

const ClubHomeDetailsView = () => {
  const {
    onSubmit,
    onPublish,
    onModalClose,
    setPublicationState,
    clubHomepage,
    publicationLoading,
    isPublishModalOpen,
    validate,
    loading,
    inputClubHomepageData,
  } = useInteractionClubHomeDetailsView();

  return (
    <div>
      <Form onSubmit={onSubmit} validate={validate} initialValues={clubHomepage}>
        <HeaderClubHome date={clubHomepage.at} />
        <Content loading={loading}>
          <div className='club-home-detail-content'>
            <FormSection title="" id="">
              {clubHomepage.events &&
                <ArrayInput<string>
                  required
                  numOfItems={3}
                  label="labels.event"
                  name="events"
                >
                  <SlugInput resource={resources.EVENTS} options={{ additionalResource: resources.EVENT }}>
                    <EventSelect
                      withWorkshop
                      clearable={false}
                      filter="isPublished:true"
                    />
                  </SlugInput>
                </ArrayInput>
              }
            </FormSection>
          </div>
          <ActionsBar
            canUpdate={!!clubHomepage.slug}
            canPublish={!clubHomepage.slug}
            publish={onPublish}
          />
        </Content>
      </Form>
      <ClubHomeModal
        clubHomepage={inputClubHomepageData}
        onModalClose={onModalClose}
        publicationLoading={publicationLoading}
        setPublicationState={setPublicationState}
        isPublishModalOpen={isPublishModalOpen}
      />
    </div>
  );
};

export default ClubHomeDetailsView;
