import { RouteComponentProps } from 'react-router';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import 'shared/components/modal/Modal.scss';
import { i18n } from 'shared/i18n';
import { appConfig } from 'shared/config/env';
import { DelanoPlatform } from 'shared/models';
import './createNewsletterModal.scss';
import { Form } from 'shared/form';
import { SelectOption } from 'shared/types';
import { SelectInput } from 'shared/form/inputs';
import { NewsletterOptions } from './constants';

const initKindValue = appConfig.platformName === DelanoPlatform ? 'delanobreakfast' : 'morning';

export interface NewsletterKindInput extends Record<string, unknown> {
  kind: SelectOption;
}

export interface CreateNewsletterModalProps extends RouteComponentProps {
  onModalClose: () => void;
}

const CreateNewsletterModal = ({ onModalClose, history }: CreateNewsletterModalProps) => {
  const onSubmit = (value: Record<string, unknown>) => {
    const newsLetterKindInput = value as NewsletterKindInput;
    history.push(`/newsletter/${newsLetterKindInput.kind.value}/create`);
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        kind: { label: i18n('newsletters.morningNewsletter'), value: initKindValue },
      }}
    >
      <div className='create-nl-modal'>
        <div className='modal-header'>
          <i onClick={() => onModalClose()} className={classNames('icon-delete', 'close-icon')} />
          <span className='modal-header-title-value'>{i18n('newsletters.createOne')}</span>
        </div>
        <div className='modal-content'>
          <div>
            <SelectInput
              name="kind"
              searchable={false}
              clearable={false}
              label={i18n('actionBar.publicationMode')}
              options={NewsletterOptions}
            />
          </div>
        </div>
        <button type="submit" className='create-nl-modal-create'>
          {i18n('actionBar.create')}
        </button>
      </div>
    </Form>
  );
};

export default withRouter(CreateNewsletterModal);
