import * as Http from 'shared/services/httpService';
import { PaperjamPlatform } from 'shared/models';
import { ResponseSlug } from 'shared/form/inputs/SlugInput';
import { Event } from 'views/events/types';
import {
  EventLinkedContent,
  Params,
  Publication,
  ResponseListBody,
  QueryParams,
  SlugAndTitle,
  Sponsors,
} from '../types';

const ENDPOINT_URI = '/api/event';

export interface Content {
  speakers: string[];
  sponsors: Sponsors;
  video: string | null;
  photo: string | null;
}

export interface Metadata {
  format: string;
  theme: string;
  updateDate: Date;
  tags: string[];
  linkedContent: EventLinkedContent;
}

export interface DataFormattedEvent extends Record<string, unknown> {
  metadata: Metadata;
  content: Content;
  publication: Publication
  eventzillaEventId?: number;
}

export interface EventParams {
  id: string;
  payload: DataFormattedEvent;
}

export const save = (params: EventParams) =>
  Http.put(`${ENDPOINT_URI}/${params.id}`,params.payload, {
    platformName: PaperjamPlatform,
  });

export interface GetOneParams {
  id: string;
  isWoodwing?: boolean;
}

export const getOne = <T extends ResponseSlug = ResponseSlug>(params: GetOneParams) => {
  const endpointUrl = params.isWoodwing ? `${ENDPOINT_URI}/woodwing/${params.id}` : `${ENDPOINT_URI}/${params.id}`;
  return Http.get<T>(endpointUrl, {
    platformName: PaperjamPlatform,
  });
};

export const getAll = (params: Params) =>
  Http.get<ResponseListBody<Event>>(`${ENDPOINT_URI}s`, {
    params,
    platformName: PaperjamPlatform,
  });

export function searchEvents(params: QueryParams) {
  return Http.get<SlugAndTitle[]>('api/events/search', {
    params,
    platformName: PaperjamPlatform,
  });
}

export function searchEventsAndWorkshopsDay(params: QueryParams) {
  return Http.get<SlugAndTitle[]>('api/events/searchEventAndWorkshopsDay', {
    params,
    platformName: PaperjamPlatform,
  });
}

const EventService = {
  save,
  getAll,
  getOne,
  searchEvents,
  searchEventsAndWorkshopsDay,
};

export default EventService;
