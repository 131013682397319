import { forwardRef } from 'react';
import { FormSection } from 'layout/content';
import useRemoveFile from 'shared/hooks/useRemoveFile';
import { i18n } from 'shared/i18n';
import { ImageUpload } from 'shared/types';
import { SimpleInput, ToggleInput } from 'shared/form/inputs';
import FileInput from 'shared/form/inputs/FileInput';

export interface TweetDuJourInputProps {
  onFileSelect: (image: ImageUpload) => void;
  onRemoveFile: (name: string) => void;
  id: string;
  title: string;
}

const TweetDuJourInput = forwardRef<HTMLElement, TweetDuJourInputProps>(({
  onFileSelect,
  onRemoveFile,
  id,
  title,
}, ref) => {
  const toggleName = 'content.tweetDuJour.isActive';
  const filename = 'content.tweetDuJour.imageFilename';

  useRemoveFile({ toggleName, filename, onRemoveFile });

  return (
    <FormSection
      ref={ref}
      id={id}
      title={title}
    >
      <ToggleInput id="toggler-tweetdujour" name={toggleName}>
        <FileInput
          required
          onRemoveFile={onRemoveFile}
          onFileSelect={onFileSelect}
          label={i18n('labels.image')}
          name={filename}
          uploadUrl="/api/newsletter/image"
        />
        <SimpleInput
          required
          width="100%"
          label={i18n('labels.link')}
          name="content.tweetDuJour.link"
        />
      </ToggleInput>
    </FormSection>
  );
});

export default TweetDuJourInput;
