import { useContext } from 'react';
import { DataProviderContext, DataProviderType } from 'shared/providers/dataProvider';

const useDataProvider = (): DataProviderType => {
  const dataProvider: DataProviderType | null = useContext(DataProviderContext);

  if (!dataProvider) {
    throw new Error('provider not found');
  }

  return dataProvider;
};

export default useDataProvider;
