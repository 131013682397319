import { FormSection } from 'layout/content';
import useGetSectionRef from 'layout/ScrollspyProvider/useGetSectionRef';
import { ImageUpload } from 'shared/types';
import AdInput from 'shared/form/inputs/AdInput';
import { GrandsDossiers } from 'views/newsletter/constants';
import './billboard.scss';

export interface BillboardInputProps {
  name?: string;
  index?: number;
  onFileSelect: (image: ImageUpload) => void;
  onRemoveFile: (name: string) => void;
}

const BillboardInput = ({ name, onFileSelect, onRemoveFile, index = 0 }: BillboardInputProps) => {
  const sectionRefs = useGetSectionRef();
  return (
    <FormSection
      ref={sectionRefs[`${GrandsDossiers.AdsBillboard.id}${index + 1}`]}
      className='billboard-section'
      id={`${GrandsDossiers.AdsBillboard.id}${index + 1}`}
      title={`${GrandsDossiers.AdsBillboard.label} ${index + 1}`}
    >
      <AdInput
        onRemoveFile={onRemoveFile}
        onFileSelect={onFileSelect}
        toggleInputName={`${name}.billboardsIsOpen`}
        nameLink={`${name}.link`}
        togglerId={`toggler-ad${index}-grandossier`}
        filename={`${name}.imageFilename`}
        uploadUrl="/api/newsletter/image"
      />
    </FormSection>
  );
};

export default BillboardInput;
