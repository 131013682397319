import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ListModel, Pagination, ResponseListBody } from 'shared/types';
import { DossierSortingCriteria } from 'shared/models';
import { useGetList } from 'shared/providers';
import { Dossier } from './types';
import { resources } from 'shared/constants';
import { FETCH_ERROR_MESSAGE, apiErrorToUiNotifier } from 'shared/services';

export interface UseInteractionDossierListViewReturn {
  isLoading: boolean;
  goToCreate: () => void;
  onPredicateChange: (predicate: string) => void;
  sortPredicate: string;
  dossiers: Dossier[];
  openDetailDossier: (item: Dossier) => void;
  pagination?: Pagination;
  onPageChange: (index: number) => void;
}

const useInteractionDossierListView = (): UseInteractionDossierListViewReturn => {
  const history = useHistory();
  const [dossier, setDossier] = useState<ListModel<Dossier>>({
    list: [],
    isLoading: true,
    perPage: 8,
    sortPredicate: DossierSortingCriteria.RevCreationDate,
  });
  const { data, pagination, loading, onPageChange } = useGetList<Dossier>(resources.DOSSIERS, {
    nbElementPerPage: dossier.perPage,
    sort: dossier.sortPredicate,
  });

  const openDetailDossier = (item: Dossier) => {
    history.push(`/dossier/${item.slug}`);
  };

  const handleLoadSuccess = (data: ResponseListBody<Dossier>) => {
    if (data.items.length > 0) {
      setDossier((prevState) => ({
        ...prevState,
        isLoading: false,
        list: data.items,
        pagination: data.pagination,
      }));
    }
  };

  useEffect(() => {
    if (data) {
      handleLoadSuccess({
        items: data,
        pagination,
      });
    }else {
      if(!loading && !data){
        apiErrorToUiNotifier(FETCH_ERROR_MESSAGE)();
      }
    }
  }, [data, loading, pagination]);

  const goToCreate = () => {
    history.push('/dossier/create');
  };

  const onPredicateChange = (predicate: string) => {
    setDossier((prevState) => ({ ...prevState, sortPredicate: predicate }));
    onPageChange(1);
  };

  return {
    isLoading: loading,
    goToCreate,
    onPredicateChange,
    sortPredicate: dossier.sortPredicate,
    dossiers: dossier.list,
    openDetailDossier,
    pagination: dossier.pagination,
    onPageChange,
  };
};

export default useInteractionDossierListView;
