import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { NewsletterSortingCriteria } from 'shared/models';
import { ListModel, Pagination } from 'shared/types';
import { useGetList } from 'shared/providers';
import { resources } from 'shared/constants';
import { Newsletter } from './NewsletterListView';
import { DELETE_SUCCESS_MESSAGE, uiNotifier } from 'shared/services';
import { apiErrorToUiNotifier } from 'shared/services';
import { DELETE_ERROR_MESSAGE } from 'shared/services';

export interface NewsletterListModel {
  isCreateModalOpen: boolean;
  perPage: ListModel<Newsletter>['perPage'];
  sortPredicate: ListModel<Newsletter>['sortPredicate'];
}
export interface UseInteractionNewsletterListReturn {
  newsletters: Newsletter[];
  pagination?: Pagination;
  isLoading: boolean;
  sortPredicate: string;
  isCreateModalOpen: boolean;
  openModal: () => void;
  openNewsletterDetail: (item: Newsletter) => void;
  onPredicateChange: (predicate: string) => void;
  onItemDeleted: (item: Newsletter) => void;
  onPageChange: (index: number) => void;
  closeModal: () => void;
  copySuccess: () => void;
}

const useInteractionNewsletterList = (): UseInteractionNewsletterListReturn => {
  const history = useHistory();
  const [newsletterState, setNewsletterState] = useState<NewsletterListModel>({
      isCreateModalOpen: false,
      perPage: 8,
      sortPredicate: NewsletterSortingCriteria.RevCreationDate,
    },
  );
  const { data, pagination, loading, onPageChange, doRefetch } = useGetList<Newsletter>(
    resources.NEWSLETTERS,
    {
      nbElementPerPage: newsletterState.perPage,
      sort: newsletterState.sortPredicate,
    }
  );
  
  const onPredicateChange = (predicate: string) => {
    setNewsletterState((prevState) => ({
      ...prevState,
      sortPredicate: predicate
    }));
    onPageChange(1);
  };

  const onItemDeleted = () => {
    doRefetch({throwOnError: true}).then(()=> {
      uiNotifier('success',DELETE_SUCCESS_MESSAGE);
    }).catch((error)=> {
      apiErrorToUiNotifier(DELETE_ERROR_MESSAGE)(error);
    });
  };

  const openModal = () => {
    setNewsletterState((prevState) => ({
      ...prevState,
      isCreateModalOpen: true,
    }));
  };

  const openNewsletterDetail = (item: Newsletter) => {
    const enSuffix = item.title.indexOf('_en_') >= 0 ? '_en' : '';
    history.push(`/newsletter/${item.kind.toLowerCase()}${enSuffix}/${item.id}`);
  };

  const closeModal = () => {
    setNewsletterState((prevState) => ({
      ...prevState,
      isCreateModalOpen: false,
    }));
  };

  const copySuccess = () => {
    if (pagination?.page === 1) {
      setNewsletterState((prevState) => ({
        ...prevState,
      }));
      return;
    }
    onPageChange(1);
  };

  return {
    newsletters: data,
    pagination: pagination,
    isLoading: loading,
    sortPredicate: newsletterState.sortPredicate,
    isCreateModalOpen: newsletterState.isCreateModalOpen,
    openModal,
    openNewsletterDetail,
    onPredicateChange,
    onItemDeleted,
    onPageChange,
    closeModal,
    copySuccess,
  };
};

export default useInteractionNewsletterList;
