import { useCallback } from 'react';
import ReactSelect from 'react-select';
import { useInput } from 'shared/form';
import { SelectOption } from 'shared/types';
import { CommonFieldPropsWithoutChange, InputProps } from 'shared/form/types';
import 'shared/components/form/select/Select.scss';

export interface SelectInputProps extends InputProps<SelectOption>, CommonFieldPropsWithoutChange {
  options: SelectOption[];
  onChange?: (value: SelectOption) => void;
  searchable?: boolean;
  clearable?: boolean;
  placeholder?: string;
}

const SelectInput = ({
  options,
  label,
  name,
  onChange,
  defaultValue,
  placeholder = '',
  searchable = true,
  clearable = true,
  disabled = false,
  required = false,
}: SelectInputProps) => {
  const input = useInput({ name, defaultValue });

  const onSelectChange = useCallback((value) => {
    const active = document.activeElement;
    input.onChange(value);
    if (onChange) {
      onChange(value);
    }
    if (active) {
      (active as HTMLElement).blur();
    }
  }, [input, onChange]);

  return (
    <div className='select'>
      <span className='select-label'>
        {label}
        {required && <span className='select-required'>*</span>}
      </span>
      <ReactSelect
        {...input}
        isDisabled={disabled}
        isClearable={clearable}
        isSearchable={searchable}
        options={options} // react-select use mutable array
        defaultValue={null}
        placeholder={placeholder}
        onChange={onSelectChange}
        styles={{
          menu: (styles) => Object.assign(styles, { zIndex: 999999 }),
        }}
      />
    </div>
  );
};

export default SelectInput;
