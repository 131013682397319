import classNames from 'classnames';
import { getEventFormatByKey } from 'shared/models';
import { woodwingLink } from 'shared/stores/woodwingStore';
import { i18n } from 'shared/i18n';
import {
  ActionsCell,
  DataTableRow,
  DateFieldCell,
  ImageFieldCell,
  PublicationFieldCell,
  TextFieldCell,
} from 'shared/components/dataTableRow';
import './eventRow.scss';
import { Event } from '../types';

export interface EventRowProps {
  item: Event;
}

const EventRow = ({ item }: EventRowProps) => (
  <DataTableRow<Event> record={item}>
    <ImageFieldCell source="slug" alt="sdsd" viewName="events" sourceMainImage="content.mainImage" />
    <TextFieldCell source="metadata.title" />
    <TextFieldCell source="metadata.format" format={getEventFormatByKey} />
    <DateFieldCell source="metadata.creationDate" />
    <PublicationFieldCell
      label={i18n('labels.at')}
      source="publication.date"
      published={item.publication.isPublished}
    />
    <ActionsCell viewName="event" preview={`/event/${item.slug}/preview`}>
      <a className='event-row-action' target="_blank" rel="noreferrer" href={woodwingLink(item.woodwingRef)}>
        <i className={classNames('icon-woodwing', 'event-row-icon', 'event-row-icon-woodwing')} />
      </a>
    </ActionsCell>
  </DataTableRow>
);

export default EventRow;
