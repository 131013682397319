import moment from 'moment';
import { setErrors } from 'shared/form/utils';
import { i18n } from 'shared/i18n';
import { NewsletterTrendinContent } from 'shared/types';
import { NewsletterDetail } from 'views/newsletter/types';
import { adToViewState } from 'views/newsletter/state/common';
import { Trendin, TrendinInput } from 'views/newsletter/categories/TrendinNewsletterForm/types';
import { appConfig } from 'shared/config/env';

export const initialStateTrendin = (value: NewsletterDetail) => {
  const content = value.content as NewsletterTrendinContent;
  return {
    title: value.title,
    publicationDate: moment(value.publicationDate).toDate(),
    content: {
      slugline: content.slugline,
      adsBillboard1: adToViewState(content.adsBillboard1),
      adsBillboard2: adToViewState(content.adsBillboard2),
      adsBillboard3: adToViewState(content.adsBillboard3),
      decodeurs: content.decodeurs,
      troisQuestionsA: content.troisQuestionsA,
      focus: content.focus,
      expertises: content.expertises,
      laMinuteLuxInnovation: content.laMinuteLuxInnovation,
      lInstantT: content.lInstantT,
      enjeux: content.enjeux,
      startuppers: content.startuppers,
      brandVoice: content.brandVoice,
      enBref: content.enBref,
      agenda: content.agenda,
      laPresseEnParle: content.laPresseEnParle,
    },
  };
};

export const trendinData = (value: TrendinInput) => ({
  ...value,
  publicationDate: moment(value.publicationDate).format(appConfig.yearBasedDateFormat),
  content: {
    ...value.content,
    adsBillboard1: value.content.adsBillboard1.isActive
      ? {
        imageFilename: value.content.adsBillboard1.imageFilename,
        link: value.content.adsBillboard1.link,
      }
      : undefined,
    adsBillboard2: value.content.adsBillboard2.isActive
      ? {
        imageFilename: value.content.adsBillboard2.imageFilename,
        link: value.content.adsBillboard2.link,
      }
      : undefined,
    adsBillboard3: value.content.adsBillboard3.isActive
      ? {
        imageFilename: value.content.adsBillboard3.imageFilename,
        link: value.content.adsBillboard3.link,
      }
      : undefined,
    decodeurs: value.content.decodeurs,
    troisQuestionsA: value.content.troisQuestionsA,
    focus: value.content.focus,
    expertises: value.content.expertises?.filter((expertises) => expertises !== '') || [],
    laMinuteLuxInnovation: value.content.laMinuteLuxInnovation,
    lInstantT: value.content.lInstantT,
    enjeux: value.content.enjeux?.filter((enjeux) => enjeux !== '') || [],
    startuppers: value.content.startuppers?.filter((startupper) => startupper !== '') || [],
    enBref: value.content.enBref?.filter((enBref) => enBref !== '') || [],
    brandVoice: value.content.brandVoice,
    agenda: value.content.agenda?.filter((agenda) => agenda !== '') || [],
    laPresseEnParle: value.content.laPresseEnParle?.filter((laPresseEnParle) => laPresseEnParle !== '') || [],
  },
});

export const trendinValidate = (value: TrendinInput) => {
  const errors = {};
  if (value) {
    if (!value.publicationDate) {
      setErrors(errors, 'publicationDate', i18n('forms.validation.required'));
    }
    if (value.content && !value.content.slugline) {
      setErrors(errors, 'content.slugline', i18n('forms.validation.required'));
    }
  }

  return errors;
};

export const trendinDataCopy = (value: Trendin) => ({
  ...value,
  publicationDate: moment(value.publicationDate).format(appConfig.yearBasedDateFormat),
});