import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ArticleSortingCriteria } from 'shared/models';
import { useGetList } from 'shared/providers';
import { Pagination } from 'shared/types';
import { ArticleListModel } from './types';


export interface UseInteractionArticleListViewReturn {
  query: string;
  pagination?: Pagination;
  loading: boolean;
  sortPredicate: string;
  data: ArticleListModel[];
  openArticle: (article: ArticleListModel) => void;
  onSearchbarChange: (value: string) => void;
  onPredicateChange: (predicate: string) => void;
  onSearch: (query: string) => void;
  onPageChange: (page: number) => void;
}

const useInteractionArticleListView = (): UseInteractionArticleListViewReturn => {
  const history = useHistory();
  const [articleParams, setArticleParams] = useState({
    titleQuery: '',
    sort: ArticleSortingCriteria.RevCreationDate,
    nbElementPerPage: 8,
  });

  const [query, setQuery] = useState<string>('');

  const { titleQuery, ...params } = articleParams;

  const { data, pagination, loading, onPageChange } = useGetList<ArticleListModel>(
    'articles',
    titleQuery
      ? { ...articleParams }
      : { ...params }
  );

  const onSearch = (query: string) => {
    onPageChange();
    setArticleParams((prevState) => ({ ...prevState, titleQuery: query }));
  };

  const onPredicateChange = (predicate: string) => {
    onPageChange(query ? pagination?.page : 1);
    setArticleParams((prevState) => ({
      ...prevState,
      sort: predicate
    }));
  };

  const onSearchbarChange = (value: string) => {
    setQuery(value);
    if (!value) {
      setArticleParams((prevState) => ({ ...prevState, titleQuery: '' }));
    }
  };

  const openArticle = (article: ArticleListModel) => {
    history.push(`/article/${article.slug}`);
  };

  return {
    sortPredicate: articleParams.sort,
    query,
    loading,
    pagination,
    data,
    openArticle,
    onSearchbarChange,
    onPredicateChange,
    onSearch,
    onPageChange,
  };
};

export default useInteractionArticleListView;
