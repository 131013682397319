import { DocumentNode, gql } from '@apollo/client';
import { GetSearchParams } from 'shared/providers/dataProvider/types';

const search =  (params?: GetSearchParams): DocumentNode => {
  let query = `
    query {
      biograhiesSearch(
        query: "${params?.q || ''}"
        filter: "${params?.filter || ''}"
      ) {
        ... on BiographiesSearchOutput {
          items {
            slug
            title
          }
        }
        ... on NoResult {
          message
        }
        ... on ErrorPayload {
          traceId
          errorMessage
          validationErrors {
            code
            message
          }
        }
      }
    }`;
    
  if (!params?.q) {
    query = query.replace('filter: ""', '');
  }
  
  return  gql`${query}`;
};

const biographiesGraphqlServices = {
  search,
};

export default biographiesGraphqlServices;
