import { forwardRef } from 'react';
import { SimpleInput, ToggleInput } from 'shared/form/inputs';
import { FormSection } from 'layout/content';
import { i18n } from 'shared/i18n';
import { FormSection as FormSectionProps } from 'shared/types';

const ClubMemberNewsInput = forwardRef<HTMLElement, FormSectionProps>(({ id, title }, ref) => (
  <FormSection ref={ref} id={id} title={title}>
    <ToggleInput id="toggler-ClubMembers" name="content.clubPart.clubMemberNews.isActive">
      <SimpleInput
        required
        width="100%"
        label={i18n('labels.title')}
        name="content.clubPart.clubMemberNews.title"
      />
      <SimpleInput
        required
        width="100%"
        label={i18n('newsletters.view.description')}
        name="content.clubPart.clubMemberNews.description"
      />
      <SimpleInput
        required
        width="100%"
        label={i18n('newsletters.view.nameOfSite')}
        name="content.clubPart.clubMemberNews.websiteName"
      />
      <SimpleInput
        required
        width="100%"
        label={i18n('labels.link')}
        name="content.clubPart.clubMemberNews.websiteLink"
      />
    </ToggleInput>
  </FormSection>
));

export default ClubMemberNewsInput;
