import { forwardRef } from 'react';
import { i18n } from 'shared/i18n';
import { FormSection } from 'layout/content';
import { ImageUpload } from 'shared/types';
import { useRemoveFile } from 'shared/hooks';
import { FileInput, SimpleInput, ToggleInput } from 'shared/form/inputs';
import { GrandsDossiers } from 'views/newsletter/constants';

export interface MagazineInputProps {
  onFileSelect: (image: ImageUpload) => void;
  onRemoveFile: (name: string) => void;
}

const MagazineInput = forwardRef<HTMLElement, MagazineInputProps>(({ onFileSelect, onRemoveFile }, ref) => {
  const toggleName = 'content.magazine.isMagazinePresent';
  const filename = 'content.magazine.imageFilename';

  useRemoveFile({ toggleName, filename, onRemoveFile });

  return (
    <FormSection ref={ref} id={GrandsDossiers.Magazine.id} title={GrandsDossiers.Magazine.label}>
      <ToggleInput id="toggler-magazine-grandossier" name={toggleName} defaultValue={true}>
        <SimpleInput required label={i18n('generalSection.publicationDate')} name="content.magazine.date" />
        <SimpleInput required width="100%" label={i18n('labels.title')} name="content.magazine.titre" />
        <SimpleInput
          required
          width="100%"
          label={i18n('newsletters.view.description')}
          name="content.magazine.description"
        />
        <SimpleInput required width="100%" label={i18n('labels.link')} name="content.magazine.link" />
        <FileInput
          required
          onRemoveFile={onRemoveFile}
          onFileSelect={onFileSelect}
          label={i18n('labels.image')}
          name="content.magazine.imageFilename"
          uploadUrl="/api/newsletter/image"
        />
      </ToggleInput>
    </FormSection>
  );
});

export default MagazineInput;
