import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { Content } from 'layout/content';
import Header from 'layout/header/Header';
import 'layout/header/header.scss';
import { i18n } from 'shared/i18n';
import { DataTable, Pagination } from 'shared/components';
import { DossierSortingCriteria } from 'shared/models';
import './dossiersListView.scss';
import DossierRow from './row/DossierRow';
import { Dossier } from './types';
import useInteractionDossierListView from './useInteractionDossierListView';
import { RowRenderData } from 'shared/components/datatable/DataTable';

const DossierListView = () => {
  const {
    isLoading,
    goToCreate,
    openDetailDossier,
    onPredicateChange,
    sortPredicate,
    dossiers,
    pagination,
    onPageChange,
  } = useInteractionDossierListView();

  const rowRender = ({ item }: RowRenderData<Dossier>) => <DossierRow item={item} key={item.slug}/>;

  return (
    <div className='dossier-list-root'>
      <Header>
        <h1 className={classNames('header-title', 'header-title-dark')}>
          <i className={classNames('header-title-icon')} />
          <div className='header-title-text'>{i18n('dossiers.createNew')}</div>
        </h1>
      </Header>
      <Content loading={isLoading}>
        <div className='dossier-list-create-btn-container'>
          <button type="button" className='dossier-list-create-btn' onClick={goToCreate}>
            {i18n('dossiers.createNew')}
          </button>
        </div>
        <DataTable<Dossier>
          sortable
          sortByColumn={onPredicateChange}
          sortPredicate={sortPredicate}
          columns={[
            {
              label: i18n('table.columns.title'),
              predicate: DossierSortingCriteria.Title,
            },
            {
              label: i18n('table.columns.author'),
            },
            {
              label: i18n('table.columns.creationDate'),
              predicate: DossierSortingCriteria.CreationDate,
            },
            {
              label: i18n('table.columns.publicationDate'),
              predicate: DossierSortingCriteria.PublicationDate,
            },
            {
              label: '',
            },
          ]}
          data={dossiers}
          isLoading={isLoading}
          selectItem={openDetailDossier}
          rowRenderer={rowRender}
        />
        {pagination && (
          <Pagination
            buttonsRange={3}
            onPageChange={onPageChange}
            currentPage={pagination?.page}
            lastPage={pagination?.lastPage}
          />
        )}
      </Content>
    </div>
  );
};

export default withRouter(DossierListView);
