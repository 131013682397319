import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Content, FormSection } from 'layout/content';
import useGetSectionRef from 'layout/ScrollspyProvider/useGetSectionRef';
import { i18n } from 'shared/i18n';
import { FormMode } from 'shared/models';
import FormHeader from 'shared/components/header/FormHeader';
import { BasePodcastShow, Image, SelectOption } from 'shared/types';
import {
  DnDArrayInput,
  FileInput,
  GeneralInput,
  PodcastSelectInput,
  SimpleInput,
  SlugInput,
  ToggleInput,
} from 'shared/form/inputs';
import { Form } from 'shared/form';
import { ActionsBar } from 'shared/components';
import { MenuItems } from 'views/show/constants';
import '../workshop/WorkshopView.scss';
import PodcastShowArticleSectionInput from './input/PodcastShowArticleSectionInput';
import useInteractionPodcastShowView from './useInteractionPodcastShowView';
import { resources } from 'shared/constants';

export interface PodcastShowInput extends BasePodcastShow {
  podcastEpisodes: string[];
  sector?: SelectOption;
  subSector?: SelectOption;
  subsubSector?: SelectOption;
  images?: Image[];
}

const PodcastShowView = ({ match }: RouteComponentProps<{ slug: string }>) => {
  const sectionRefs = useGetSectionRef();

  const {
    onSubmit,
    validate,
    publish,
    openPreview,
    podcastShowDetail,
    loading,
    mode,
    onFileSelect,
    onRemoveFile,
  } = useInteractionPodcastShowView(match);

  return (
    <Form onSubmit={onSubmit} validate={validate} initialValues={podcastShowDetail}>
      <FormHeader
        label={
          match.path === '/podcast-show/create'
            ? i18n('podcastShows.view.createPodcastShow')
            : i18n('podcastShows.view.updatePodcastShow')
        }
      />

      <Content loading={loading}>
        <div className='workshop-content'>
          <div className='workshop-sections'>
            <GeneralInput
              isoFormat
              ref={sectionRefs['general-section']}
              nameTitle="title"
              label={i18n('podcastShows.view.title')}
              title={podcastShowDetail.title}
            />

            <PodcastShowArticleSectionInput ref={sectionRefs[MenuItems.sector.id]} />
      
            <FormSection
              ref={sectionRefs[MenuItems.mainImage.id]}
              id={MenuItems.mainImage.id}
              title={MenuItems.mainImage.label}
            >
              <FileInput
                required
                onFileSelect={onFileSelect}
                onRemoveFile={onRemoveFile}
                label={i18n('labels.image')}
                name="mainImage.filename"
                uploadUrl="/api/podcastShow/image"
              />
            </FormSection>

            <FormSection
              ref={sectionRefs[MenuItems.episodes.id]}
              id={MenuItems.episodes.id}
              title={MenuItems.episodes.label}
            >
              <DnDArrayInput<SelectOption>
                required
                name="podcastEpisodes"
                label="podcastShows.view.podcastEpisode"
                >
                  <SlugInput resource={resources.ARTICLES} options={{additionalResource: resources.ARTICLE_WITH_TITLES}}>
                      <PodcastSelectInput  />
                  </SlugInput>
              </DnDArrayInput>
            </FormSection>

            <FormSection
              ref={sectionRefs[MenuItems.subscribe.id]}
              id={MenuItems.subscribe.id}
              title={MenuItems.subscribe.label}
            >
              <SimpleInput
                width="100%"
                name="subscribeUrl"
                label={i18n('labels.link')}
              />
            </FormSection>

            <FormSection
              ref={sectionRefs[MenuItems.sponsored.id]}
              id={MenuItems.sponsored.id}
              title={MenuItems.sponsored.label}
            >
              <ToggleInput id="toggler-sponsored-content" name="isSponsored" />
            </FormSection>
          </div>
      
          <ActionsBar
            canUpdate={mode === FormMode.EDIT}
            canPublish={mode === FormMode.CREATE}
            canPreview={mode === FormMode.EDIT}
            publish={publish}
            preview={openPreview}
          />
        </div>
      </Content>
    </Form>
  );
};

export default withRouter(PodcastShowView);
