import { MouseEventHandler, ReactNode, useCallback } from 'react';
import { RowRenderData } from 'shared/components/datatable/DataTable';
import './Datatable.scss';

export interface DataTableItemProps<T> {
  index: number;
  item: T;
  onItemDeleted?: (item: T) => void;
  selectItemOnDirectClicks: (event: MouseEventHandler<HTMLAnchorElement>, item: T) => void;
  rowRenderer: (item: RowRenderData<T>) => ReactNode;
}

const DataTableItem = <T extends Record<string, unknown>>({
  index,
  item,
  onItemDeleted,
  selectItemOnDirectClicks,
  rowRenderer,
}: DataTableItemProps<T>) => {
  const selectItem = useCallback((event) => {
    selectItemOnDirectClicks(event, item);
  }, [item, selectItemOnDirectClicks]);

  return (
    <tr className='data-table-row' onClick={selectItem}>
      {rowRenderer({ item, onItemDeleted,  key: index })}
    </tr>
  );
};

export default DataTableItem;
