import { Modal } from 'shared/components';
import { useGetValues } from 'shared/form';
import { InputArticle } from 'views/article/utils';
import PublishArticleModal from './PublishArticleModal';

export interface ArticleModalProps {
  onModalClose: () => void;
  isPublishModalOpen: boolean;
  showAudio: boolean;
  putArticleMetadata: (value: InputArticle) => void;
}

const ArticleModal = ({ isPublishModalOpen, showAudio, putArticleMetadata, onModalClose }: ArticleModalProps) => {
  const articleData = useGetValues();

  return (
    <Modal onModalClose={onModalClose} isOpen={isPublishModalOpen}>
      <PublishArticleModal
        publicationChange
        articleData={articleData as InputArticle}
        showAudio={showAudio}
        onModalClose={onModalClose}
        onSubmit={putArticleMetadata}
      />
    </Modal>
  );
};

export default ArticleModal;
