import { CommonFieldProps, InputProps } from 'shared/form/types';
import './styles/SimpleInput.scss';
import useInput from '../useInput';

export interface TextAreaInputProps extends InputProps<string>, CommonFieldProps {
  width?: string;
  height?: string;
  rows?: number;
}

const TextAreaInput = ({
  rows,
  name,
  label,
  defaultValue = '',
  disabled = false,
  required = false,
  width = 'auto',
  height = '38px',
}: TextAreaInputProps) => {
  const input = useInput({ name, defaultValue });

  return (
    <div>
      {label && (
        <span className='simple-input-label'>
          {label}
          {required && <span className='simple-input-required'>*</span>}
        </span>
      )}
      <textarea
        {...input}
        style={{ width, height }}
        rows={rows}
        className='simple-input'
        disabled={disabled}
        required={required}
      />
    </div>
  );
};

export default TextAreaInput;
