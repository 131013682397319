import * as Http from 'shared/services/httpService';
import { Article, MetaData } from 'views/article/types';
import { ArticleListModel } from 'views/articles/types';
import { Params, Publication, QueryParams, ResponseListBody, SlugAndTitle, SlugParams, Topic } from 'shared/types';

const formatLinkedContent = (linkedContent?: Partial<SlugAndTitle>[]) =>
  linkedContent?.filter((x) => x).map((x) => x.slug);

export function updateMetadataDtoMapper(metadata: MetaData,  publication: Publication) {
  return {
    titleComplement:
      !!metadata.titleComplement && !!metadata.titleComplement.trim() ? metadata.titleComplement : undefined,
    categorizationsDto: metadata.categorizations,
    linkedContent: {
      articles: formatLinkedContent(metadata.linkedContent.articles),
      events: formatLinkedContent(metadata.linkedContent.events),
      sponsoredContent: formatLinkedContent(metadata.linkedContent.sponsoredContent),
    },
    topics: metadata.topics,
    tags: metadata.tags,
    publicationDate: publication.date,
    updateDate: metadata.updateDate,
    isPublished: publication.isPublished,
    showAudio: metadata.showAudio,
    audioId: metadata.audioId,
    newsTicker: publication.newsTicker,
  };
}

export function getArticles(params: Params) {
  return Http.get<ResponseListBody<ArticleListModel>>('api/articles', {
    params,
  });
}

export function searchArticles(params: QueryParams) {
  return Http.get<SlugAndTitle[]>('api/articles/search', { params });
}

export const searchArticlesByPlatform = (params: QueryParams) =>
  Http.get<SlugAndTitle[]>('api/articles/search', {
    params,
    platformName: params.platformName,
  });

export const articleNormalizer = (data: Article) => ({
  ...data,
  publication: {
    ...data.publication,
    date: data.publication && typeof data.publication.date === 'string'
      ? new Date(data.publication.date)
      : new Date(),
  },
});

export interface GetParams extends SlugParams {
  IsWoodwingRef?: boolean;
}

export function getArticle(params: GetParams) {
  const articleId = params && params.id ? params.id : params;
  const articleUrl = params.IsWoodwingRef ? `api/article/woodwing/${articleId}` : `api/article/${articleId}`;
  return Http.getWith<Article>(articleUrl, articleNormalizer);
}

export interface GetByPlatformParams extends Record<string, unknown> {
  id: string;
  platformName: string;

}
export function getArticleByPlatform({ id: slug, platformName }: GetByPlatformParams) {
  return Http.getWith(`api/article/${slug}`, articleNormalizer, { platformName });
}

export interface PutParams extends Record<string, unknown> {
  id: string;
  payload: Article;
}

export function putArticleMetadata(params: PutParams) {
  const article = params.payload;
  return Http.put(
    `api/article/${params.id}/metadata`,
    updateMetadataDtoMapper(article.metadata, article.publication),
  );
}

export function putArticlePublished(params: PutParams) {
  const article = params.payload;
  return Http.put(`api/article/${params.id}/publish`, article.publication);
}

export function searchTopics(params: QueryParams) {
  return Http.get<Topic[]>('api/articles/searchTopics', { params });
}

const ArticleService = {
  getArticle,
  getArticleByPlatform,
  getArticles,
  putArticleMetadata,
  putArticlePublished,
  searchArticles,
  searchArticlesByPlatform,
  searchTopics
};

export default ArticleService;
