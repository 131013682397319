import { toast, ToastOptions } from 'react-toastify';
import { DataError } from 'shared/types';

export const toastOptions: ToastOptions = {
  position: 'bottom-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  draggablePercent: 1,
};

export const apiErrorToUiNotifier = (message: string) => (error?: Error | DataError) => {
let errorMessage = message;

  if (error instanceof Error) {
    errorMessage += ` : ${error.message}`;
  } else if (error) {
    errorMessage += ` : ${error.detail}`;
  }

   toast.error(errorMessage, toastOptions);
 };

export type ToastType = 'info' | 'error' | 'success' | 'warning' | string;

export const uiNotifier = (type: ToastType, message: string) => {
  switch (type) {
    case 'info':
      toast.info(message, toastOptions);
      break;
    case 'error':
      toast.error(message, toastOptions);
      break;
    case 'success':
      toast.success(message, toastOptions);
      break;
    case 'warning':
      toast.warn(message, toastOptions);
      break;
    default:
      toast(message, toastOptions);
      break;
  }
};
