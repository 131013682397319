import { Link } from 'react-router-dom';
import classNames from 'classnames';
import 'layout/aside/Aside.scss';
import { RouteStore } from 'shared/stores/routeStore';
import { i18n } from 'shared/i18n';

const computeStyles = (displayButton: boolean) =>
  classNames({
    ['aside-prev-btn']: true,
    ['aside-prev-btn-hidden']: displayButton,
  });


export interface GoBackLinkProps {
  routeStore: RouteStore;
}

const GoBackLink = ({ routeStore }: GoBackLinkProps) => {
  const { isItemRoute } = routeStore;
  return (
    <Link to={routeStore.getPreviousRoute()} className={computeStyles(!isItemRoute)}>
      <i className={classNames('icon-arrow', 'aside-prev-btn-icon')} />
      <span className={'aside-prev-btn-text'}>{i18n('nav.returnBack')}</span>
    </Link>
  );
};

export default GoBackLink;
