import { forwardRef } from 'react';
import { FormSection } from 'layout/content';
import { ImageUpload } from 'shared/types';
import { ArrayInput } from 'shared/form/inputs';
import JobFormset from 'views/newsletter/categories/daily/JobFormset';
import { Jobs } from '../types';

const EMPTY_JOB_VALUE = {
  title: '',
  societe: '',
  logoFilename: '',
  typeOffre: '',
  lieu: '',
  link: '',
};

export interface JobsSectionInput {
  id: string;
  label: string;
  onFileSelect: (image: ImageUpload) => void;
  onRemoveFile: (name: string) => void;
}

const JobsSection = forwardRef<HTMLElement, JobsSectionInput>(({
  id,
  label,
  onFileSelect,
  onRemoveFile,
}, ref) => {
  const removeNameItem = (name: string) => {
    onRemoveFile(`${name}.logoFilename`);
  };

  const getEmptyValue = () => {
    return { ...EMPTY_JOB_VALUE };
  };

  return (
    <FormSection ref={ref} id={id} title={label}>
      <ArrayInput<Jobs>
        onRemoveNameItem={removeNameItem}
        getEmptyValue={getEmptyValue}
        name="content.externalApi.jobs"
      >
        <JobFormset onRemoveFile={onRemoveFile} onFileSelect={onFileSelect} />
      </ArrayInput>
    </FormSection>
  );
});

export default JobsSection;
