import { useState } from 'react';
import useResetPassword from 'shared/providers/authProvider/useResetPassword';
import { PASSWORD_DAILY_LIMIT_REACHED, RESET_ERROR_MESSAGE, RESET_SUCCESS_EMAIL_MESSAGE, RESET_SUCCESS_MESSAGE, apiErrorToUiNotifier, uiNotifier } from 'shared/services';
import { getPlatformLogo } from 'shared/utils/utils';

const useInteractionResetPasswordView = () => {
    const [isNotify, setIsNotify] = useState(false);
    const [currentEmail, setCurrentEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);

    const { loading, forgotPassword, confirmNewPassword } = useResetPassword();
    const logoUrl = getPlatformLogo();

    const handleForgotPassword = async (email: string) => {
        const { status } = await forgotPassword(email);
        setCurrentEmail(email);
        if (status) {
            setIsEmailValid(true);
            uiNotifier('info', RESET_SUCCESS_EMAIL_MESSAGE);
        } else {
            apiErrorToUiNotifier(PASSWORD_DAILY_LIMIT_REACHED)();
        }
    };

    const handleConfirmNewPassword = async (code: string, newPassword: string) => {
        const { status } = await confirmNewPassword(currentEmail, code, newPassword);
        if (status && !isNotify) {
            setIsNotify(true);
            uiNotifier('success', RESET_SUCCESS_MESSAGE);
        } else {
            if (!status) {
                apiErrorToUiNotifier(RESET_ERROR_MESSAGE)();
            }
        }

        setTimeout(() => {
            window.location.href = './articles';
        }, 3000);
    };

    return {
        logoUrl,
        loading,
        isEmailValid,
        handleForgotPassword,
        handleConfirmNewPassword,
    };
};

export default useInteractionResetPasswordView;
