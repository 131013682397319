import {
  ActionsCell,
  DataTableRow,
  DateFieldCell,
  PublicationFieldCell,
  TextFieldCell,
} from 'shared/components/dataTableRow';
import './dossierRow.scss';
import { Dossier } from '../types';

export interface DossierRowProps {
  item: Dossier;
}

const DossierRow = ({ item }: DossierRowProps) => {
  return (
    <DataTableRow<Dossier> record={item}>
      <TextFieldCell className='dossier-row-first-cell' source="title" />
      <TextFieldCell source="creator" />
      <DateFieldCell source="creationDate" />
      <PublicationFieldCell source="publication.date" published={item.publication.isPublished} />
      <ActionsCell
        viewName="dossier"
        preview={`/dossier/${item.slug}/preview`}
      />
    </DataTableRow>
  );
};

export default DossierRow;
