import classNames from 'classnames';
import 'shared/components/form/tagSelect/TagSelect.scss';
import { SelectOption } from 'shared/types';
import { ReactNode } from 'react';

export interface TagItemProps {
  data: SelectOption;
  children?: ReactNode;
  onRemoveTag: (label: string) => void;
  isDisabled?: boolean;
}

const TagItem = ({ data, children, onRemoveTag, isDisabled = false }: TagItemProps) => (
  <div className='tag-select-value' title={data.label}>
    <span id={data.value} className='tag-select-value-label'>
      <span className='tag-select-value-label-inner'>{children}</span>
      <i
        onMouseDown={(e) => {
          if (!isDisabled && data.label) {
            onRemoveTag(data.label);
          }
          e.stopPropagation();
        }}
        className={classNames('icon-delete', 'tag-select-value-icon')}
        aria-hidden="true"
      />
    </span>
  </div>
);

export default TagItem;
