import {
  BiographyService,
  EventService,
  OrganisationService,
  ArticleService,
  NewsletterService,
  HomepageService,
  WorkshopService,
  DossierService,
  PodcastShowService,
  MagazineService,
  GuideHomepageService,
} from 'shared/services';
import { providerType, resources } from 'shared/constants';
import { createResource } from 'shared/services/RestResource';

export type ServiceFn<T> = (...args: never[]) => Promise<T>;

export const clubHomepageServices = createResource('/api/club/homepage');
export const dossierCreateServices = createResource('/api/dossier');

const services = (type: string, resource: string): ServiceFn<unknown> | undefined => {
  switch (resource) {
    case resources.ARTICLES:
      return {
        [providerType.GET_LIST]: ArticleService.getArticles,
        [providerType.GET_ONE]: ArticleService.getArticle,
        [providerType.GET_SEARCH]: ArticleService.searchArticles,
        [providerType.UPDATE]: ArticleService.putArticleMetadata,
      }[type];
    case resources.ARTICLE_PUBLISHED:
      return {
        [providerType.UPDATE]: ArticleService.putArticlePublished,
      }[type];
    case resources.ARTICLE_BY_PLATFORM:
      return {
        [providerType.GET_ONE]: ArticleService.getArticleByPlatform,
        [providerType.GET_SEARCH]: ArticleService.searchArticlesByPlatform,
      }[type];
    case resources.TOPICS:
      return {
        [providerType.GET_SEARCH]: ArticleService.searchTopics,
      }[type];
    case resources.EVENTS_WITH_WORKSHOP:
    case resources.EVENTS:
      return {
        [providerType.GET_LIST]: EventService.getAll,
        [providerType.GET_ONE]: EventService.getOne,
        [providerType.GET_SEARCH]: EventService.searchEventsAndWorkshopsDay,
        [providerType.UPDATE]: EventService.save,
      }[type];
    case resources.SPONSORS_SEARCH:
    case resources.SPONSOR:
      return {
        [providerType.GET_SEARCH]: OrganisationService.searchSponsors,
        [providerType.GET_ONE]: OrganisationService.getSponsor,
      }[type];
    case resources.BIOGRAPHIES_SEARCH:
      return {
        [providerType.GET_SEARCH]: BiographyService.searchBiographies,
      }[type];
    case resources.CLUB_HOMEPAGE:
      return {
        [providerType.GET_ONE]: clubHomepageServices.get,
        [providerType.UPDATE]: clubHomepageServices.save,
        [providerType.CREATE]: clubHomepageServices.create,
      }[type];
    case resources.NEWSLETTERS:
      return {
        [providerType.GET_LIST]: NewsletterService.getNewsletters,
        [providerType.GET_ONE]: NewsletterService.getNewsletter,
        [providerType.UPDATE]: NewsletterService.updateNewsletter,
        [providerType.CREATE]: NewsletterService.postNewsletter,
        [providerType.DELETE_ONE]: NewsletterService.deleteNewsletter,
      }[type];
    case resources.NEWS_HOMEPAGES:
      return {
        [providerType.GET_LIST]: HomepageService.getHomepages,
      }[type];
    case resources.NEWS_HOMEPAGE:
      return {
        [providerType.GET_ONE]: HomepageService.getHomepage,
        [providerType.CREATE]: HomepageService.createHomepage,
        [providerType.UPDATE]: HomepageService.updateHomepage,
        [providerType.DELETE_ONE]: HomepageService.deleteHomepage,
      }[type];
    case resources.WORKSHOPS:
      return {
        [providerType.GET_LIST]: WorkshopService.getAll,
        [providerType.GET_ONE]: WorkshopService.getOne,
        [providerType.CREATE]: WorkshopService.create,
        [providerType.UPDATE]: WorkshopService.save,
      }[type];
    case resources.DOSSIERS, resources.SEARCH_DOSSIER:
      return {
        [providerType.CREATE]: dossierCreateServices.create,
        [providerType.UPDATE]: dossierCreateServices.save,
        [providerType.GET_LIST]: DossierService.getAll,
        [providerType.GET_ONE]: DossierService.getOne,
        [providerType.GET_SEARCH]: DossierService.searchDossiers,
      }[type];
    case resources.PODCAST_SHOWS:
      return {
        [providerType.GET_LIST]: PodcastShowService.getAll,
        [providerType.GET_ONE]: PodcastShowService.getOne,
        [providerType.GET_SEARCH]: PodcastShowService.searchPodcasts,
        [providerType.UPDATE]: PodcastShowService.save,
        [providerType.CREATE]: PodcastShowService.create,
      }[type];
    case resources.MAGAZINES:
      return {
        [providerType.UPDATE]: MagazineService.save,
        [providerType.GET_ONE]: MagazineService.getOne,
      }[type];
    case resources.GUIDES:
      return {
        [providerType.GET_ONE]: GuideHomepageService.featuredProfiles,
        [providerType.UPDATE]: GuideHomepageService.updatefeaturedProfiles,
      }[type];
  }
  return () => Promise.reject('Service not found');
};

export default services;
