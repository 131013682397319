import * as Http from 'shared/services/httpService';
import { QueryParams, SlugAndTitle, SlugParams } from 'shared/types';

export function searchSponsors(params: QueryParams) {
  return Http.get<SlugAndTitle[]>('api/guide/sponsor/search', { params });
}

interface Sponsor {
  name: string;
}

export function getSponsor(params: SlugParams) {
  const slug = params.id;
  return Http.get<Sponsor>(`api/guide/organisation/${encodeURIComponent(slug as string)}`);
}

const OrganisationService = {
  searchSponsors,
  getSponsor,
};

export default OrganisationService;
