import { DocumentNode, gql } from '@apollo/client';
import { GetOneParams, GetListParams } from 'shared/providers/dataProvider/types';
import { appConfig } from 'shared/config/env';
import { NewsletterSortingCriteriaAlias } from 'shared/services/graphql/aliases';
import { getSort } from 'shared/services/graphql/utils';
import { CreateParams, UpdateParams } from 'shared/providers';
import { types } from 'shared/constants';
import { Kind } from 'shared/types';

const retrieve = <T>(params?: GetOneParams<T>): DocumentNode => {
  const kind = params?.kind && Kind[params.kind];
  return gql`
    query {
      newsletter(id: "${params?.id}", kind: ${kind}, platformName: ${appConfig.platformName}) {
        ... on Newsletter {
          id
          platformName
          kind
          title
          creationDate
          updateDate
          publicationDate
          content {
            ... on NewsletterDailyContent {
              slugline
              dailyBillboards: billboards {
                adsBillboard1 {
                  link
                  imageFilename
                }
                adsBillboard2 {
                  link
                  imageFilename
                }
                adsBillboard3 {
                  link
                  imageFilename
                }
                adsBillboard4 {
                  link
                  imageFilename
                }
                adsBillboard5 {
                  link
                  imageFilename
                }
                adsBillboard6 {
                  link
                  imageFilename
                }
                adsBillboard7 {
                  link
                  imageFilename
                }
              }
              externalApi {
                myLifeLu {
                  surtitle
                  title
                  description
                  imageFilename
                  link
                }
                enjoy {
                  surtitle
                  title
                  description
                  imageFilename
                  link
                }
                businessInEnglish {
                  surtitle
                  title
                  description
                  imageFilename
                  link
                }
                vousMangezOuCeMidi {
                  surtitle
                  title
                  description
                  imageFilename
                  link
                }
                jobs {
                  title
                  societe
                  logoFilename
                  typeOffre
                  lieu
                  link
                }
                investLongTerm
              }
              breakingNews {
                title
                slug
              }
              aSuivre
              dailyALaUne: aLaUne
              inspiringWorkplace
              adsImu {
                link
                imageFilename
              }
              sectors {
                politiqueInstitutions
                entreprisesEtStrategies
                placeFinanciereEtMarches
                lifestyleEtViePrivee
              }
              photoDuJour {
                title
                imageFilename
                link
                description
              }
              tweetDuJour {
                link
                imageFilename
              }
              chiffreDuJour {
                title
                imageFilename
                link
                description
              }
              citationDuJour {
                quote
                author
                description
                slug
              }
              dailyExpertises: expertises
              carrieres
              faillites {
                nomSociete
                typeSociete
                status
              }
              anniversaires {
                title
                slug
              }
              topBonnesNouvelles
               clubPart {
            agenda {
              title
              slug
              __typename
            }

                paperjamClub {
                  title
                  slug
                }
                clubMemberNews {
                  title
                  description
                  websiteName
                  websiteLink
                }
              }
              enCeMomentDansLeMagazine {
                date
                title
                description
                link
                imageFilename
              }
              dailyParusChezMaisonModerne: parusChezMaisonModerne {
                title
                imageFilename
                link
                description
              }
              observatoireImmobilier
            }
            ... on NewsletterDelanoDailyContent {
              slugline
              delanoBillboards: billboards {
                adsBillboard1 {
                  link
                  imageFilename
                }
                adsBillboard2 {
                  link
                  imageFilename
                }
                adsBillboard3 {
                  link
                  imageFilename
                }
                adsBillboard4 {
                  link
                  imageFilename
                }
                adsBillboard5 {
                  link
                  imageFilename
                }
                adsBillboard6 {
                  link
                  imageFilename
                }
                adsBillboard7 {
                  link
                  imageFilename
                }
              }
              externalApi {
                myLifeLu {
                  surtitle
                  title
                  description
                  imageFilename
                  link
                }
                enjoy {
                  surtitle
                  title
                  description
                  imageFilename
                  link
                }
                businessInEnglish {
                  surtitle
                  title
                  description
                  imageFilename
                  link
                }
                vousMangezOuCeMidi {
                  surtitle
                  title
                  description
                  imageFilename
                  link
                }
                jobs {
                  title
                  societe
                  logoFilename
                  typeOffre
                  lieu
                  link
                }
                investLongTerm
              }
              breakingNews {
                title
                slug
              }
              aSuivre
              delanoALaUne: aLaUne
              inspiringWorkplace
              adsImu {
                link
                imageFilename
              }
              sectors {
                politiqueInstitutions
                entreprisesEtStrategies
                placeFinanciereEtMarches
                lifestyleEtViePrivee
              }
              photoDuJour {
                title
                imageFilename
                link
                description
              }
              tweetDuJour {
                link
                imageFilename
              }
              chiffreDuJour {
                title
                imageFilename
                link
                description
              }
              citationDuJour {
                quote
                author
                description
                slug
              }
              delanoExpertises: expertises
              carrieres
              faillites {
                nomSociete
                typeSociete
                status
              }
              anniversaires {
                title
                slug
              }
              topBonnesNouvelles
              clubPart {
                agenda {
                  title
                  slug
                }
                           clubAgenda {
              title
              slug
            }
                paperjamClub {
                  title
                  slug
                }
                clubMemberNews {
                  title
                  description
                  websiteName
                  websiteLink
                }
              }
              enCeMomentDansLeMagazine {
                date
                title
                description
                link
                imageFilename
              }
              delanoParusChezMaisonModerne: parusChezMaisonModerne {
                title
                imageFilename
                link
                description
              }
              observatoireImmobilier
            }
            ... on NewsletterClubContent {
              slugline
              clubALaUne: aLaUne {
                title
                slug
              }
              prochainement {
                title
                slug
              }
            }
            ... on NewsletterDossierContent {
              slugline
              dossier {
                title
                slug
              }
              dossierBillboards: billboards {
                link
                imageFilename
              }
              magazine {
                date
                titre
                description
                link
                imageFilename
              }
            }
            ... on NewsletterTrendinContent {
              slugline
              adsBillboard1 {
                link
                imageFilename
              }
              decodeurs
              troisQuestionsA
              focus
              trendinExpertises: expertises
              laMinuteLuxInnovation
              lInstantT
              enjeux
              adsBillboard2 {
                link
                imageFilename
              }
              startuppers
              brandVoice
              enBref
              agenda
              adsBillboard3 {
                link
                imageFilename
              }
              laPresseEnParle
            }
          }
        }
        ... on ErrorPayload {
            traceId
            errorMessage
            validationErrors {
              code
              message
            }
        }
          ... on NoResult {
            message
        }
      }
    }
  `;
};

const list = (params?: GetListParams): DocumentNode => {
  const offset =
    params?.page !== undefined
      ? params.page === 1
        ? appConfig.offset
        : (params.page - 1) * Number(appConfig.limit)
      : appConfig.offset;

  const [sort, asc] = getSort(NewsletterSortingCriteriaAlias, params?.sort);

  const query = params?.titleQuery || '';

  return gql`
    query {
      newsletters (
        limit: ${appConfig.limit},
        offset: ${offset},
        platformName: ${appConfig.platformName},
        sort: "${sort}",
        asc: ${asc},
        query: "${query}"
      ) {
        ... on NewslettersResponse {
          items {
            id
            platformName
            kind
            title
            creationDate
            publicationDate
          }
          count
          limit
          offset
        }
        ... on ErrorPayload {
          traceId
          errorMessage
          validationErrors {
            code
            message
          }
        }
        ... on NoResult {
          message
        }
      }
    }
  `;
};

const updateNewsletterClub = (): DocumentNode => gql`
  mutation UpdateNewsletterClub($updateData: NewsletterClubInput!) {
    updateNewsletterClub(updateData: $updateData) {
      ... on ErrorPayload {
        traceId
        errorMessage
        validationErrors {
          code
          message
        }
      }
      ... on Newsletter {
        id
        platformName
        kind
        title
        creationDate
        updateDate
        publicationDate
        content {
          ... on NewsletterClubContent {
            slugline
            aLaUne {
              title
              slug
            }
            prochainement {
              title
              slug
            }
          }
        }
      }
    }
  }
`;

const updateNewsletterDossier = (): DocumentNode => gql`
  mutation UpdateNewsletterDossier($updateData: NewsletterDossierInput!) {
    updateNewsletterDossier(updateData: $updateData) {
      ... on ErrorPayload {
        traceId
        errorMessage
        validationErrors {
          code
          message
        }
      }
      ... on Newsletter {
        id
        platformName
        kind
        title
        creationDate
        updateDate
        publicationDate
        content {
          ... on NewsletterDossierContent {
            slugline
            dossier {
              title
              slug
            }
            billboards {
              link
              imageFilename
            }
            magazine {
              date
              titre
              description
              link
              imageFilename
            }
          }
        }
      }
    }
  }
`;

const updateNewsletterMorningEvening = (): DocumentNode => gql`
  mutation UpdateNewsletterMorningEvening($updateData: NewsletterDailyInput!) {
    updateNewsletterDaily(updateData: $updateData) {
      ... on ErrorPayload {
        traceId
        errorMessage
        validationErrors {
          code
          message
        }
      }
      ... on Newsletter {
        id
        platformName
        kind
        title
        creationDate
        updateDate
        publicationDate
        content {
          ... on NewsletterDailyContent {
            slugline
            billboards {
              adsBillboard1 {
                link
                imageFilename
              }
              adsBillboard2 {
                link
                imageFilename
              }
              adsBillboard3 {
                link
                imageFilename
              }
              adsBillboard4 {
                link
                imageFilename
              }
              adsBillboard5 {
                link
                imageFilename
              }
              adsBillboard6 {
                link
                imageFilename
              }
              adsBillboard7 {
                link
                imageFilename
              }
            }
            externalApi {
              myLifeLu {
                surtitle
                title
                description
                imageFilename
                link
              }
              enjoy {
                surtitle
                title
                description
                imageFilename
                link
              }
              businessInEnglish {
                surtitle
                title
                description
                imageFilename
                link
              }
              vousMangezOuCeMidi {
                surtitle
                title
                description
                imageFilename
                link
              }
              jobs {
                title
                societe
                logoFilename
                typeOffre
                lieu
                link
              }
              investLongTerm
            }
            breakingNews {
              title
              slug
            }
            aSuivre
            aLaUne
            inspiringWorkplace
            adsImu {
              link
              imageFilename
            }
            sectors {
              politiqueInstitutions
              entreprisesEtStrategies
              placeFinanciereEtMarches
              lifestyleEtViePrivee
            }
            photoDuJour {
              title
              imageFilename
              link
              description
            }
            tweetDuJour {
              link
              imageFilename
            }
            chiffreDuJour {
              title
              imageFilename
              link
              description
            }
            citationDuJour {
              quote
              author
              description
              slug
            }
            expertises
            carrieres
            faillites {
              nomSociete
              typeSociete
              status
            }
            anniversaires {
              title
              slug
            }
            topBonnesNouvelles
            clubPart {
              agenda {
                title
                slug
              }
              paperjamClub {
                title
                slug
              }
              clubMemberNews {
                title
                description
                websiteName
                websiteLink
              }
            }
            enCeMomentDansLeMagazine {
              date
              title
              description
              link
              imageFilename
            }
            parusChezMaisonModerne {
              title
              imageFilename
              link
              description
            }
            observatoireImmobilier
          }
        }
      }
    }
  }
`;

const updateNewsletterDelanoNoonBreakfast = (): DocumentNode => gql`
  mutation UpdateNewsletterDelanoNoonBreakfast($updateData: NewsletterDelanoDailyInput!) {
    updateNewsletterDelanoDaily(updateData: $updateData) {
      ... on ErrorPayload {
        traceId
        errorMessage
        validationErrors {
          code
          message
        }
      }
      ... on Newsletter {
        id
        platformName
        kind
        title
        creationDate
        updateDate
        publicationDate
        content {
          ... on NewsletterDelanoDailyContent {
            slugline
            billboards {
              adsBillboard1 {
                link
                imageFilename
              }
              adsBillboard2 {
                link
                imageFilename
              }
              adsBillboard3 {
                link
                imageFilename
              }
              adsBillboard4 {
                link
                imageFilename
              }
              adsBillboard5 {
                link
                imageFilename
              }
              adsBillboard6 {
                link
                imageFilename
              }
              adsBillboard7 {
                link
                imageFilename
              }
            }
            externalApi {
              myLifeLu {
                surtitle
                title
                description
                imageFilename
                link
              }
              enjoy {
                surtitle
                title
                description
                imageFilename
                link
              }
              businessInEnglish {
                surtitle
                title
                description
                imageFilename
                link
              }
              vousMangezOuCeMidi {
                surtitle
                title
                description
                imageFilename
                link
              }
              jobs {
                title
                societe
                logoFilename
                typeOffre
                lieu
                link
              }
              investLongTerm
            }
            breakingNews {
              title
              slug
            }
            aSuivre
            aLaUne
            inspiringWorkplace
            adsImu {
              link
              imageFilename
            }
            sectors {
              politiqueInstitutions
              entreprisesEtStrategies
              placeFinanciereEtMarches
              lifestyleEtViePrivee
            }
            photoDuJour {
              title
              imageFilename
              link
              description
            }
            tweetDuJour {
              link
              imageFilename
            }
            chiffreDuJour {
              title
              imageFilename
              link
              description
            }
            citationDuJour {
              quote
              author
              description
              slug
            }
            expertises
            carrieres
            faillites {
              nomSociete
              typeSociete
              status
            }
            anniversaires {
              title
              slug
            }
            topBonnesNouvelles
            clubPart {
              agenda {
                title
                slug
              }
              clubAgenda {
                title
                slug
              }
              paperjamClub {
                title
                slug
              }
              clubMemberNews {
                title
                description
                websiteName
                websiteLink
              }
            }
            enCeMomentDansLeMagazine {
              date
              title
              description
              link
              imageFilename
            }
            parusChezMaisonModerne {
              title
              imageFilename
              link
              description
            }
            observatoireImmobilier
          }
        }
      }
    }
  }
`;

const updateNewsletterTrendin = (): DocumentNode => gql`
  mutation UpdateNewsletterTrendin($updateData: NewsletterTrendinInput!) {
    updateNewsletterTrendin(updateData: $updateData) {
      ... on ErrorPayload {
        traceId
        errorMessage
        validationErrors {
          code
          message
        }
      }
      ... on Newsletter {
        id
        platformName
        kind
        title
        creationDate
        updateDate
        publicationDate
        content {
          ... on NewsletterTrendinContent {
            slugline
            adsBillboard1 {
              link
              imageFilename
            }
            decodeurs
            troisQuestionsA
            focus
            expertises
            laMinuteLuxInnovation
            lInstantT
            enjeux
            adsBillboard2 {
              link
              imageFilename
            }
            startuppers
            brandVoice
            enBref
            agenda
            adsBillboard3 {
              link
              imageFilename
            }
            laPresseEnParle
          }
        }
      }
    }
  }
`;

const createNewsletterClub = (): DocumentNode => gql`
  mutation CreateNewsletterClub($createData: NewsletterClubCreateInput!) {
    createNewsletterClub(createData: $createData) {
      ... on ErrorPayload {
        traceId
        errorMessage
        validationErrors {
          code
          message
        }
      }
      ... on Newsletter {
        id
        platformName
        kind
        title
        creationDate
        updateDate
        publicationDate
        content {
          ... on NewsletterClubContent {
            slugline
            aLaUne {
              title
              slug
            }
            prochainement {
              title
              slug
            }
          }
        }
      }
    }
  }
`;

const createNewsletterDossier = (): DocumentNode => gql`
  mutation CreateNewsletterDossier($createData: NewsletterDossierCreateInput!) {
    createNewsletterDossier(createData: $createData) {
      ... on ErrorPayload {
        traceId
        errorMessage
        validationErrors {
          code
          message
        }
      }
      ... on Newsletter {
        id
        platformName
        kind
        title
        creationDate
        updateDate
        publicationDate
        content {
          ... on NewsletterDossierContent {
            slugline
            dossier {
              title
              slug
            }
            billboards {
              link
              imageFilename
            }
            magazine {
              date
              titre
              description
              link
              imageFilename
            }
          }
        }
      }
    }
  }
`;

const createNewsletterMorningEvening = (): DocumentNode => gql`
  mutation CreateNewsletterDaily($createData: NewsletterDailyCreateInput!) {
    createNewsletterDaily(createData: $createData) {
      ... on ErrorPayload {
        traceId
        errorMessage
        validationErrors {
          code
          message
        }
      }
      ... on Newsletter {
        id
        platformName
        kind
        title
        creationDate
        updateDate
        publicationDate
        content {
          ... on NewsletterDailyContent {
            slugline
            billboards {
              adsBillboard1 {
                link
                imageFilename
              }
              adsBillboard2 {
                link
                imageFilename
              }
              adsBillboard3 {
                link
                imageFilename
              }
              adsBillboard4 {
                link
                imageFilename
              }
              adsBillboard5 {
                link
                imageFilename
              }
              adsBillboard6 {
                link
                imageFilename
              }
              adsBillboard7 {
                link
                imageFilename
              }
            }
            externalApi {
              myLifeLu {
                surtitle
                title
                description
                imageFilename
                link
              }
              enjoy {
                surtitle
                title
                description
                imageFilename
                link
              }
              businessInEnglish {
                surtitle
                title
                description
                imageFilename
                link
              }
              vousMangezOuCeMidi {
                surtitle
                title
                description
                imageFilename
                link
              }
              jobs {
                title
                societe
                logoFilename
                typeOffre
                lieu
                link
              }
              investLongTerm
            }
            breakingNews {
              title
              slug
            }
            aSuivre
            aLaUne
            inspiringWorkplace
            adsImu {
              link
              imageFilename
            }
            sectors {
              politiqueInstitutions
              entreprisesEtStrategies
              placeFinanciereEtMarches
              lifestyleEtViePrivee
            }
            photoDuJour {
              title
              imageFilename
              link
              description
            }
            tweetDuJour {
              link
              imageFilename
            }
            chiffreDuJour {
              title
              imageFilename
              link
              description
            }
            citationDuJour {
              quote
              author
              description
              slug
            }
            expertises
            carrieres
            faillites {
              nomSociete
              typeSociete
              status
            }
            anniversaires {
              title
              slug
            }
            topBonnesNouvelles
            clubPart {
              agenda {
                title
                slug
              }
              paperjamClub {
                title
                slug
              }
              clubMemberNews {
                title
                description
                websiteName
                websiteLink
              }
            }
            enCeMomentDansLeMagazine {
              date
              title
              description
              link
              imageFilename
            }
            parusChezMaisonModerne {
              title
              imageFilename
              link
              description
            }
            observatoireImmobilier
          }
        }
      }
    }
  }
`;

const createNewsletterDelanoNoonBreakfast = (): DocumentNode => gql`
  mutation CreateNewsletterDelanoDaily($createData: NewsletterDelanoDailyCreateInput!) {
    createNewsletterDelanoDaily(createData: $createData) {
      ... on ErrorPayload {
        traceId
        errorMessage
        validationErrors {
          code
          message
        }
      }
      ... on Newsletter {
        id
        platformName
        kind
        title
        creationDate
        updateDate
        publicationDate
        content {
          ... on NewsletterDelanoDailyContent {
            slugline
            billboards {
              adsBillboard1 {
                link
                imageFilename
              }
              adsBillboard2 {
                link
                imageFilename
              }
              adsBillboard3 {
                link
                imageFilename
              }
              adsBillboard4 {
                link
                imageFilename
              }
              adsBillboard5 {
                link
                imageFilename
              }
              adsBillboard6 {
                link
                imageFilename
              }
              adsBillboard7 {
                link
                imageFilename
              }
            }
            externalApi {
              myLifeLu {
                surtitle
                title
                description
                imageFilename
                link
              }
              enjoy {
                surtitle
                title
                description
                imageFilename
                link
              }
              businessInEnglish {
                surtitle
                title
                description
                imageFilename
                link
              }
              vousMangezOuCeMidi {
                surtitle
                title
                description
                imageFilename
                link
              }
              jobs {
                title
                societe
                logoFilename
                typeOffre
                lieu
                link
              }
              investLongTerm
            }
            breakingNews {
              title
              slug
            }
            aSuivre
            aLaUne
            inspiringWorkplace
            adsImu {
              link
              imageFilename
            }
            sectors {
              politiqueInstitutions
              entreprisesEtStrategies
              placeFinanciereEtMarches
              lifestyleEtViePrivee
            }
            photoDuJour {
              title
              imageFilename
              link
              description
            }
            tweetDuJour {
              link
              imageFilename
            }
            chiffreDuJour {
              title
              imageFilename
              link
              description
            }
            citationDuJour {
              quote
              author
              description
              slug
            }
            expertises
            carrieres
            faillites {
              nomSociete
              typeSociete
              status
            }
            anniversaires {
              title
              slug
            }
            topBonnesNouvelles
            clubPart {
              agenda {
                title
                slug
              }
              clubAgenda {
                title
                slug
              }
              paperjamClub {
                title
                slug
              }
              clubMemberNews {
                title
                description
                websiteName
                websiteLink
              }
            }
            enCeMomentDansLeMagazine {
              date
              title
              description
              link
              imageFilename
            }
            parusChezMaisonModerne {
              title
              imageFilename
              link
              description
            }
            observatoireImmobilier
          }
        }
      }
    }
  }
`;

const createNewsletterTrendin = (): DocumentNode => gql`
  mutation CreateNewsletterTrendin($createData: NewsletterTrendinCreateInput!) {
    createNewsletterTrendin(createData: $createData) {
      ... on ErrorPayload {
        traceId
        errorMessage
        validationErrors {
          code
          message
        }
      }
      ... on Newsletter {
        id
        platformName
        kind
        title
        creationDate
        updateDate
        publicationDate
        content {
          ... on NewsletterTrendinContent {
            slugline
            adsBillboard1 {
              link
              imageFilename
            }
            decodeurs
            troisQuestionsA
            focus
            expertises
            laMinuteLuxInnovation
            lInstantT
            enjeux
            adsBillboard2 {
              link
              imageFilename
            }
            startuppers
            brandVoice
            enBref
            agenda
            adsBillboard3 {
              link
              imageFilename
            }
            laPresseEnParle
          }
        }
      }
    }
  }
`;

const update = (params?: UpdateParams): DocumentNode | undefined => {
  switch (params?.kind) {
    case types.CLUB:
      return updateNewsletterClub();
    case types.GRAND_DOSSIER:
      return updateNewsletterDossier();
    case types.DELANO_BREAKFAST:
    case types.DELANO_NOON:
      return updateNewsletterDelanoNoonBreakfast();
    case types.MORNING:
    case types.EVENING:
      return updateNewsletterMorningEvening();
    case types.TRENDIN:
      return updateNewsletterTrendin();
  }
};

const create = (params?: CreateParams): DocumentNode | undefined => {
  switch (params?.kind) {
    case types.CLUB:
      return createNewsletterClub();
    case types.GRAND_DOSSIER:
      return createNewsletterDossier();
    case types.DELANO_BREAKFAST:
    case types.DELANO_NOON:
      return createNewsletterDelanoNoonBreakfast();
    case types.MORNING:
    case types.EVENING:
      return createNewsletterMorningEvening();
    case types.TRENDIN:
      return createNewsletterTrendin();
  }
};

const newslettersGraphqlServices = {
  retrieve,
  list,
  update,
  create,
};

export default newslettersGraphqlServices;
