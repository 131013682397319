import classNames from 'classnames';
import Header from 'layout/header/Header';
import 'layout/header/header.scss';
import { i18n } from 'shared/i18n';

const MagazineHeader =  () => (
  <Header>
    <h1 className={classNames('header-title', 'header-title-dark')}>
      <i className={classNames('header-title-icon', 'icon-big', 'icon-read')} />
      <div className='header-title-text'>{i18n('magazine.pageTitle')}</div>
    </h1>
  </Header>
);

export default MagazineHeader;
