import { useCallback } from 'react';
import moment from 'moment';
import { i18n } from 'shared/i18n';
import { CommonFieldPropsWithoutChange, InputProps } from 'shared/form/types';
import { DatePicker } from 'shared/components';
import useInput from '../useInput';

export interface DateInputProps extends InputProps<Date>, CommonFieldPropsWithoutChange {
  onChange?: (newDate: Date) => void;
  isoFormat?: boolean;
}

const DateInput = ({
  name,
  defaultValue,
  onChange,
  label = '',
  isoFormat = false,
  required = false,
  disabled = false,
}: DateInputProps) => {
  const input = useInput({ name, defaultValue });

  const onDateChange = useCallback((newDate) => {
    if (onChange) {
      onChange(newDate);
    }

    input.onChange(isoFormat ? moment(newDate).toISOString() : newDate);
  }, [input, isoFormat, onChange]);

  return (
    <DatePicker
      disabled={disabled}
      required={required}
      label={i18n(label)}
      value={input.value || new Date()}
      onChange={onDateChange}
    />
  );
};

export default DateInput;
