import { ReactNode, useCallback } from 'react';
import TableColumn from './TableColumn';
import DataTableItem from './DataTableItem';
import './Datatable.scss';

export interface ColumnTable {
  label: string;
  predicate?: string;
}

export interface RowRenderData<T> {
  item: T;
  key: number;
  onItemDeleted?: (item: T) => void;
}

export interface DataTableProps<T> {
  sortPredicate: string;
  selectItem: (item: T) => void;
  sortByColumn?: (value: string) => void;
  columns: ColumnTable[];
  data: T[];
  rowRenderer: (item: RowRenderData<T>) => ReactNode;
  deleteItem?: (item: T) => void;
  isLoading: boolean;
  sortable?: boolean;
}

const invertPredicate = (predicate: string) => (predicate[0] === '-' ? predicate.substr(1) : `-${predicate}`);

const DataTable = <T extends Record<string, unknown>>({
 sortPredicate,
 selectItem,
 sortByColumn,
 columns,
 data,
 deleteItem,
 rowRenderer,
 isLoading,
 sortable = false,
}: DataTableProps<T>) => {
  const selectItemOnDirectClicks = useCallback((event, item) => {
    // if we didnt click on an icon
    if (event.target.nodeName !== 'I') {
      selectItem(item);
    }
  }, [selectItem]);

  const sortClickHandler = useCallback((column) => {
    if (column.predicate && sortByColumn) {
      sortByColumn(sortPredicate === column.predicate ? invertPredicate(column.predicate) : column.predicate);
    }
  }, [sortByColumn, sortPredicate]);

  return  isLoading ? (
    <div className='data-table-loading' />
  ) : (
      <table className='data-table'>
        <thead className='data-table-header'>
          <tr className='data-table-header-row'>
        {sortable
          ? columns.map((column, index) => (
            <TableColumn
              key={index}
              column={column}
              lastColumn={index === columns.length - 1}
              sortByColumn={sortByColumn}
              sortPredicate={sortPredicate}
              sortClickHandler={sortClickHandler}
            />
          ))
          : columns.map((column, index) => (
            <th className={index === columns.length - 1 ? 'data-table-header-last' : undefined} key={index}>
              {column}
            </th>
          ))}
      </tr>
      </thead>
        <tbody className={isLoading ? 'data-table-tbody-loading' : ''}>
      {data.map((item, index) => (
        <DataTableItem
          key={index}
          index={index}
          item={item}
          onItemDeleted={deleteItem}
          selectItemOnDirectClicks={selectItemOnDirectClicks}
          rowRenderer={rowRenderer}
        />
      ))}
      </tbody>
    </table>
  );
};

export default DataTable;
