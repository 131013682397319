import { createContext } from 'react';
import { Subject } from 'shared/form/createSubject';

export interface FormState {
  isValid: boolean;
  isDirty: boolean;
}

export interface FormContextProps {
  initialValues: Record<string, unknown>;
  formState: FormState;
  formValues: Record<string, unknown>;
  setValue: (name: string, value: unknown) => void;
  getValues: (name?: string) => unknown;
  handleChange: (name: string, value: unknown) => void;
  subject: Subject<unknown>;
}

const FormContext = createContext<FormContextProps | null>(null);

export default FormContext;
