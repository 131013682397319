import { i18n } from 'shared/i18n';

export const SAVE_SUCCESS_MESSAGE = i18n('messages.successMessage'); //  'Succès de la sauvegarde';
export const SAVE_ERROR_MESSAGE = i18n('messages.errorMessage'); // 'Erreur lors de la sauvegarde';
export const LOAD_ERROR_MESSAGE = i18n('messages.loadErrorMessage'); // 'Erreur lors du chargement';
export const DEFAULT_ERROR_MESSAGE = i18n('messages.defaultErrorMessage'); // 'Une erreur est survenue';
export const FETCH_ERROR_MESSAGE = i18n('messages.fetchErrorMessage');  // 'Erreur lors de la récupération des données';
export const SAVE_IMAGE_ERROR_MESSAGE = i18n('messages.loadErrorMessage'); //  "Erreur de chargement de l'image";
export const INCOMPLETE_FORM_ERROR_MESSAGE = `${SAVE_ERROR_MESSAGE} ${i18n('messages.incompleteFormError')}`;
// `${SAVE_ERROR_MESSAGE}: formulaire invalide`;
export const SIGN_IN_ERROR_MESSAGE = i18n('messages.signInErrorMessage');
export const LOGOUT_ERROR_MESSAGE = i18n('messages.logoutErrorMessage');
export const SAVE_IMAGE_TYPE_ERROR_MESSAGE = i18n('messages.imageTypeErrorMessage');
export const SAVE_IMAGE_SIZE_ERROR_MESSAGE = i18n('messages.imageSizeErrorMessage');

export const COPY_SUCCESS_MESSAGE = i18n('messages.successCopyMessage');
export const COPY_ERROR_MESSAGE = i18n('messages.errorCopyMessage');

export const DELETE_SUCCESS_MESSAGE = i18n('messages.successDeleteMessage');
export const DELETE_ERROR_MESSAGE = i18n('messages.errorDeleteMessage');

export const DUPLICATE_ENTRIES_ERROR_MESSAGE = i18n('messages.errorDuplicateEntriesMessage');

export const RESET_SUCCESS_EMAIL_MESSAGE = i18n('messages.resetEmailSuccessMessage');
export const RESET_ERROR_EMAIL_MESSAGE = i18n('messages.resetEmailErrorMessage');
export const RESET_SUCCESS_MESSAGE = i18n('messages.resetSuccessMessage');
export const RESET_ERROR_MESSAGE = i18n('messages.resetErrorMessage');
export const RESET_ERROR_CHECK_EMAIL_MESSAGE = i18n('messages.checkEmailMessage');
export const PASSWORD_DAILY_LIMIT_REACHED = i18n('messages.dailyLimitReached');
