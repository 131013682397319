import { appConfig } from 'shared/config/env';
import * as homeConstants from 'views/home/constants';
import * as articleConstants from 'views/article/constants';
import * as workshopConstants from 'views/workshop/constants';
import * as eventConstants from 'views/event/constants';
import * as dossierConstants from 'views/dossier/constants';
import { MenuItems as podcastShowConstants } from 'views/show/constants';
import {
  DelanoBreakfastNoon,
  MorningEvening,
  Club,
  Trendin,
  GrandsDossiers,
} from 'views/newsletter/constants';

export interface MenuItem {
  elementId: string;
  label: string;
}

const HomepageMenu: MenuItem[] = [
  {
    elementId: homeConstants.HomeGeneralId,
    label: homeConstants.HomeGeneralLabel,
  },
  {
    elementId: homeConstants.HomeBreakingNewsId,
    label: homeConstants.HomeBreakingNewsLabel,
  },
  {
    elementId: homeConstants.HomeHotTopicsId,
    label: homeConstants.HomeHotTopicsLabel,
  },
  {
    elementId: homeConstants.HomeFocusAreaId,
    label: homeConstants.HomeFocusAreaLabel,
  },
  {
    elementId: homeConstants.HomeAlaUneId,
    label: homeConstants.HomeAlaUneLabel,
  },
  {
    elementId: homeConstants.HomeTopRecommandesId,
    label: homeConstants.HomeTopRecommandesLabel,
  },
];

const ArticleMenu: MenuItem[] = [
  {
    elementId: articleConstants.ArticleCategoriePrincipaleId,
    label: articleConstants.ArticleCategoriePrincipaleLabel,
  },
  {
    elementId: articleConstants.ArticleCategorieSecondaireId,
    label: articleConstants.ArticleCategorieSecondaireLabel,
  },
  {
    elementId: articleConstants.ArticleTopicsId,
    label: articleConstants.ArticleTopicsLabel,
  },
  {
    elementId: articleConstants.ArticleArticleAssociesId,
    label: articleConstants.ArticleArticleAssociesLabel,
  },
  {
    elementId: articleConstants.ArticleTagsId,
    label: articleConstants.ArticleTagsLabel,
  },
  {
    elementId: articleConstants.ArticleContenusSponsoId,
    label: articleConstants.ArticleContenusSponsoLabel,
  },
  {
    elementId: articleConstants.ArticleEvenementsAssociesId,
    label: articleConstants.ArticleEvenementsAssociesLabel,
  },
  {
    elementId: articleConstants.ArticleAudioId,
    label: articleConstants.ArticleAudio,
  },
];

export interface NewslettersMenu {
  [newsletterKey: string]: MenuItem[];
}

const NewslettersMenu: NewslettersMenu = {
  delanoBreakfastNoon: [
    {
      elementId: DelanoBreakfastNoon.General.id,
      label: DelanoBreakfastNoon.General.label,
    },
    {
      elementId: DelanoBreakfastNoon.AdsBillboard1.id,
      label: DelanoBreakfastNoon.AdsBillboard1.label,
    },
    {
      elementId: DelanoBreakfastNoon.BreakingNews.id,
      label: DelanoBreakfastNoon.BreakingNews.label,
    },
    {
      elementId: DelanoBreakfastNoon.ASuivre.id,
      label: DelanoBreakfastNoon.ASuivre.label,
    },
    {
      elementId: DelanoBreakfastNoon.ALaUne.id,
      label: DelanoBreakfastNoon.ALaUne.label,
    },
    {
      elementId: DelanoBreakfastNoon.AdsImu.id,
      label: DelanoBreakfastNoon.AdsImu.label,
    },
    {
      elementId: DelanoBreakfastNoon.PolitiqueInstitutions.id,
      label: DelanoBreakfastNoon.PolitiqueInstitutions.label,
    },
    {
      elementId: DelanoBreakfastNoon.AdsBillboard2.id,
      label: DelanoBreakfastNoon.AdsBillboard2.label,
    },
    {
      elementId: DelanoBreakfastNoon.EntreprisesStratégies.id,
      label: DelanoBreakfastNoon.EntreprisesStratégies.label,
    },
    {
      elementId: DelanoBreakfastNoon.AdsBillboard3.id,
      label: DelanoBreakfastNoon.AdsBillboard3.label,
    },
    {
      elementId: DelanoBreakfastNoon.PlaceFinancière.id,
      label: DelanoBreakfastNoon.PlaceFinancière.label,
    },
    {
      elementId: DelanoBreakfastNoon.AdsBillboard4.id,
      label: DelanoBreakfastNoon.AdsBillboard4.label,
    },
    {
      elementId: DelanoBreakfastNoon.LifestyleViePratique.id,
      label: DelanoBreakfastNoon.LifestyleViePratique.label,
    },
    {
      elementId: DelanoBreakfastNoon.AdsBillboard5.id,
      label: DelanoBreakfastNoon.AdsBillboard5.label,
    },

    {
      elementId: DelanoBreakfastNoon.PhotoDuJour.id,
      label: DelanoBreakfastNoon.PhotoDuJour.label,
    },
    {
      elementId: DelanoBreakfastNoon.TweetDuJour.id,
      label: DelanoBreakfastNoon.TweetDuJour.label,
    },
    {
      elementId: DelanoBreakfastNoon.ChiffreDuJour.id,
      label: DelanoBreakfastNoon.ChiffreDuJour.label,
    },
    {
      elementId: DelanoBreakfastNoon.CitationDuJour.id,
      label: DelanoBreakfastNoon.CitationDuJour.label,
    },
    {
      elementId: appConfig.platformName === 'delano'
        ? DelanoBreakfastNoon.Knowledge.id
        : DelanoBreakfastNoon.Expertises.id,
      label: appConfig.platformName === 'delano'
        ? DelanoBreakfastNoon.Knowledge.label
        : DelanoBreakfastNoon.Expertises.label,
    },
    {
      elementId: DelanoBreakfastNoon.InvestLongTerm.id,
      label: DelanoBreakfastNoon.InvestLongTerm.label,
    },
    {
      elementId: DelanoBreakfastNoon.AdsBillboard6.id,
      label: DelanoBreakfastNoon.AdsBillboard6.label,
    },
    {
      elementId: DelanoBreakfastNoon.Agenda.id,
      label: DelanoBreakfastNoon.Agenda.label,
    },

    {
      elementId: DelanoBreakfastNoon.Explorator.id,
      label: DelanoBreakfastNoon.Explorator.label,
    },
    {
      elementId: DelanoBreakfastNoon.Anniversaires.id,
      label: DelanoBreakfastNoon.Anniversaires.label,
    },
    {
      elementId: DelanoBreakfastNoon.Carrieres.id,
      label: DelanoBreakfastNoon.Carrieres.label,
    },
    {
      elementId: DelanoBreakfastNoon.Delano.id,
      label: DelanoBreakfastNoon.Delano.label,
    },
    {
      elementId: DelanoBreakfastNoon.Club.id,
      label: DelanoBreakfastNoon.Club.label,
    },
    {
      elementId: DelanoBreakfastNoon.ClubMembers.id,
      label: DelanoBreakfastNoon.ClubMembers.label,
    },
    {
      elementId: DelanoBreakfastNoon.ClubAgenda.id,
      label: DelanoBreakfastNoon.ClubAgenda.label,
    },
    {
      elementId: DelanoBreakfastNoon.Magazine.id,
      label: DelanoBreakfastNoon.Magazine.label,
    },
    {
      elementId: DelanoBreakfastNoon.ParusChez.id,
      label: DelanoBreakfastNoon.ParusChez.label,
    },
    {
      elementId: DelanoBreakfastNoon.AdsBillboard7.id,
      label: DelanoBreakfastNoon.AdsBillboard7.label,
    },
  ],
  morningEvening: [
    {
      elementId: MorningEvening.General.id,
      label: MorningEvening.General.label,
    },
    {
      elementId: MorningEvening.BreakingNews.id,
      label: MorningEvening.BreakingNews.label,
    },
    {
      elementId: MorningEvening.AdsBillboard1.id,
      label: MorningEvening.AdsBillboard1.label,
    },
    {
      elementId: MorningEvening.ASuivre.id,
      label: MorningEvening.ASuivre.label,
    },
    {
      elementId: MorningEvening.ALaUne.id,
      label: MorningEvening.ALaUne.label,
    },
    {
      elementId: MorningEvening.AdsImu.id,
      label: MorningEvening.AdsImu.label,
    },
    {
      elementId: MorningEvening.PolitiqueInstitutions.id,
      label: MorningEvening.PolitiqueInstitutions.label,
    },
    {
      elementId: MorningEvening.AdsBillboard2.id,
      label: MorningEvening.AdsBillboard2.label,
    },
    {
      elementId: MorningEvening.MyLifeLu.id,
      label: MorningEvening.MyLifeLu.label,
    },
    {
      elementId: MorningEvening.EntreprisesStratégies.id,
      label: MorningEvening.EntreprisesStratégies.label,
    },
    {
      elementId: MorningEvening.AdsBillboard3.id,
      label: MorningEvening.AdsBillboard3.label,
    },
    {
      elementId: MorningEvening.Enjoy.id,
      label: MorningEvening.Enjoy.label,
    },
    {
      elementId: MorningEvening.InspiringWorkplace.id,
      label: MorningEvening.InspiringWorkplace.label,
    },
    {
      elementId: MorningEvening.ObservatoireImmobilier.id,
      label: MorningEvening.ObservatoireImmobilier.label,
    },
    {
      elementId: MorningEvening.PlaceFinancière.id,
      label: MorningEvening.PlaceFinancière.label,
    },
    {
      elementId: MorningEvening.AdsBillboard4.id,
      label: MorningEvening.AdsBillboard4.label,
    },
    {
      elementId: MorningEvening.Delano.id,
      label: MorningEvening.Delano.label,
    },
    {
      elementId: MorningEvening.AdsBillboard5.id,
      label: MorningEvening.AdsBillboard5.label,
    },
    {
      elementId: MorningEvening.PhotoDuJour.id,
      label: MorningEvening.PhotoDuJour.label,
    },
    {
      elementId: MorningEvening.TweetDuJour.id,
      label: MorningEvening.TweetDuJour.label,
    },
    {
      elementId: MorningEvening.ChiffreDuJour.id,
      label: MorningEvening.ChiffreDuJour.label,
    },
    {
      elementId: MorningEvening.CitationDuJour.id,
      label: MorningEvening.CitationDuJour.label,
    },
    {
      elementId: appConfig.platformName === 'delano'
        ? MorningEvening.Knowledge.id
        : MorningEvening.Expertises.id,
      label: appConfig.platformName === 'delano'
        ? MorningEvening.Knowledge.label
        : MorningEvening.Expertises.label,
    },
    {
      elementId: MorningEvening.InvestLongTerm.id,
      label: MorningEvening.InvestLongTerm.label,
    },
    {
      elementId: MorningEvening.AdsBillboard6.id,
      label: MorningEvening.AdsBillboard6.label,
    },
    {
      elementId: MorningEvening.LifestyleViePratique.id,
      label: MorningEvening.LifestyleViePratique.label,
    },
    {
      elementId: MorningEvening.AdsBillboard7.id,
      label: MorningEvening.AdsBillboard7.label,
    },
    {
      elementId: MorningEvening.Explorator.id,
      label: MorningEvening.Explorator.label,
    },
    {
      elementId: MorningEvening.Carrieres.id,
      label: MorningEvening.Carrieres.label,
    },
    {
      elementId: MorningEvening.Faillites.id,
      label: MorningEvening.Faillites.label,
    },
    {
      elementId: MorningEvening.Anniversaires.id,
      label: MorningEvening.Anniversaires.label,
    },
    {
      elementId: MorningEvening.BonnesNouvelles.id,
      label: MorningEvening.BonnesNouvelles.label,
    },
    {
      elementId: MorningEvening.Jobs.id,
      label: MorningEvening.Jobs.label,
    },
    {
      elementId: MorningEvening.Agenda.id,
      label: MorningEvening.Agenda.label,
    },
    {
      elementId: MorningEvening.Club.id,
      label: MorningEvening.Club.label,
    },
    {
      elementId: MorningEvening.ClubMembers.id,
      label: MorningEvening.ClubMembers.label,
    },
    {
      elementId: MorningEvening.Magazine.id,
      label: MorningEvening.Magazine.label,
    },
    {
      elementId: MorningEvening.ParusChez.id,
      label: MorningEvening.ParusChez.label,
    },
  ],
  club: [
    {
      elementId: Club.Header.id,
      label: Club.Header.label,
    },
    {
      elementId: Club.ALaUne.id,
      label: Club.ALaUne.label,
    },
    {
      elementId: Club.Prochainement.id,
      label: Club.Prochainement.label,
    },
  ],
  grandDossiers: [
    {
      elementId: GrandsDossiers.GrandsDossiers.id,
      label: GrandsDossiers.GrandsDossiers.label,
    },
    ...Array(GrandsDossiers.BillboardsQuantity)
      .fill(0)
      .map((_, i) => ({
        elementId: `${GrandsDossiers.AdsBillboard.id}${i + 1}`,
        label: `${GrandsDossiers.AdsBillboard.label} ${i + 1}`,
      })),
    {
      elementId: GrandsDossiers.Magazine.id,
      label: GrandsDossiers.Magazine.label,
    },
  ],
  trendin: [
    {
      elementId: Trendin.Header.id,
      label: Trendin.Header.label,
    },
    {
      elementId: Trendin.AdsBillboard1.id,
      label: Trendin.AdsBillboard1.label,
    },
    {
      elementId: Trendin.Decodeurs.id,
      label: Trendin.Decodeurs.label,
    },
    {
      elementId: Trendin.TroisQuestionsA.id,
      label: Trendin.TroisQuestionsA.label,
    },
    {
      elementId: Trendin.Focus.id,
      label: Trendin.Focus.label,
    },
    {
      elementId: Trendin.Expertises.id,
      label: Trendin.Expertises.label,
    },
    {
      elementId: Trendin.LaMinuteLuxInnovation.id,
      label: Trendin.LaMinuteLuxInnovation.label,
    },
    {
      elementId: Trendin.Enjeux.id,
      label: Trendin.Enjeux.label,
    },
    {
      elementId: Trendin.LInstantT.id,
      label: Trendin.LInstantT.label,
    },
    {
      elementId: Trendin.AdsBillboard2.id,
      label: Trendin.AdsBillboard2.label,
    },
    {
      elementId: Trendin.Startuppers.id,
      label: Trendin.Startuppers.label,
    },
    {
      elementId: Trendin.BrandVoice.id,
      label: Trendin.BrandVoice.label,
    },
    {
      elementId: Trendin.EnBref.id,
      label: Trendin.EnBref.label,
    },
    {
      elementId: Trendin.Agenda.id,
      label: Trendin.Agenda.label,
    },
    {
      elementId: Trendin.AdsBillboard3.id,
      label: Trendin.AdsBillboard3.label,
    },
    {
      elementId: Trendin.LaPresseEnParle.id,
      label: Trendin.LaPresseEnParle.label,
    },
  ],
};

const WorkshopsMenu: MenuItem[] = [
  {
    elementId: workshopConstants.workshopFormatMenuItem.id,
    label: workshopConstants.workshopFormatMenuItem.label,
  },
  {
    elementId: workshopConstants.workshopIntroMenuItem.id,
    label: workshopConstants.workshopIntroMenuItem.label,
  },
  {
    elementId: workshopConstants.workshopSponsorsMenuItem.id,
    label: workshopConstants.workshopSponsorsMenuItem.label,
  },
  {
    elementId: workshopConstants.workshopProgrammeMenuItem.id,
    label: workshopConstants.workshopProgrammeMenuItem.label,
  },
  {
    elementId: workshopConstants.workshopArticlesMenuItem.id,
    label: workshopConstants.workshopArticlesMenuItem.label,
  },
  {
    elementId: workshopConstants.workshopEvenementsMenuItem.id,
    label: workshopConstants.workshopEvenementsMenuItem.label,
  },
];

const EventMenu: MenuItem[] = [
  {
    elementId: eventConstants.eventFormatMenuItem.id,
    label: eventConstants.eventFormatMenuItem.label,
  },
  {
    elementId: eventConstants.eventOratorsMenuItem.id,
    label: eventConstants.eventOratorsMenuItem.label,
  },
  {
    elementId: eventConstants.eventTagsMenuItem.id,
    label: eventConstants.eventTagsMenuItem.label,
  },
  {
    elementId: eventConstants.eventSponsorsGoldMenuItem.id,
    label: eventConstants.eventSponsorsGoldMenuItem.label,
  },
  {
    elementId: eventConstants.eventSponsorsSilverMenuItem.id,
    label: eventConstants.eventSponsorsSilverMenuItem.label,
  },
  {
    elementId: eventConstants.eventArticlesMenuItem.id,
    label: eventConstants.eventArticlesMenuItem.label,
  },
  {
    elementId: eventConstants.eventEventsMenuItem.id,
    label: eventConstants.eventEventsMenuItem.label,
  },
  {
    elementId: eventConstants.eventPhotosMenuItem.id,
    label: eventConstants.eventPhotosMenuItem.label,
  },
  {
    elementId: eventConstants.eventVideoMenuItem.id,
    label: eventConstants.eventVideoMenuItem.label,
  },
  {
    elementId: eventConstants.eventIdMenuItem.id,
    label: eventConstants.eventIdMenuItem.label,
  },
];

const DossierMenu: MenuItem[] = [
  {
    elementId: dossierConstants.dossierGeneralMenuItem.id,
    label: dossierConstants.dossierGeneralMenuItem.label,
  },
  {
    elementId: dossierConstants.dossierSecteurMenuItem.id,
    label: dossierConstants.dossierSecteurMenuItem.label,
  },
  {
    elementId: dossierConstants.dossierTableRondeMenuItem.id,
    label: dossierConstants.dossierTableRondeMenuItem.label,
  },
  {
    elementId: dossierConstants.dossierQuestionsMenuItem.id,
    label: dossierConstants.dossierQuestionsMenuItem.label,
  },
  {
    elementId: dossierConstants.dossierCarteBlancheMenuItem.id,
    label: dossierConstants.dossierCarteBlancheMenuItem.label,
  },
];

export const scrollMenus = {
  home: HomepageMenu,
  article: ArticleMenu,
  newsletter: NewslettersMenu,
  workshop: WorkshopsMenu,
  event: EventMenu,
  dossier: DossierMenu,
  podcastShow: Object.values(podcastShowConstants).map((menuItem) => {
    const { id: elementId, label } = menuItem;
    return { elementId, label };
  }),
};
