import { ReactNode } from 'react';
import './Content.scss';

export interface ContentProps {
  loading?: boolean;
  children: ReactNode;
}

const Content = ({ children, loading = false }: ContentProps) => (
  <div className="content">{loading ? <div className="data-table-loading" /> : children}</div>
);

export default Content;
