import {
  ActionsCell,
  DataTableRow,
  DateFieldCell,
  ImageFieldCell,
  PublicationFieldCell,
  TextFieldCell,
} from 'shared/components/dataTableRow';
import { PodcastShow } from 'views/shows/PodcastsShowsListView';
import './podcastShowRow.scss';

export interface PodcastShowRowProps {
  item: PodcastShow;
}

const PodcastShowRow = ({ item }: PodcastShowRowProps) => (
  <DataTableRow<PodcastShow> record={item}>
    <ImageFieldCell source="slug" alt="podcast_show" viewName="podcastShow" />
    <TextFieldCell source="title" className='workshop-row-first-cell' />
    <DateFieldCell source="creationDate" />
    <PublicationFieldCell published={item.publication.isPublished} source="publication.date" />
    <ActionsCell viewName="podcast-show" preview={`/podcast-show/${item.slug}/preview`} />
  </DataTableRow>
);

export default PodcastShowRow;
