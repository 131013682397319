import { FormSection } from 'layout/content';
import useGetSectionRef from 'layout/ScrollspyProvider/useGetSectionRef';
import { resources } from 'shared/constants';
import { i18n } from 'shared/i18n';
import { ArrayInput, SlugInput } from 'shared/form/inputs';
import { ImageUpload } from 'shared/types';
import { ArticleSelect, EventSelect } from 'shared/components';
import AdInput from 'shared/form/inputs/AdInput';
import { Trendin } from 'views/newsletter/constants';
import GeneralInput from 'views/newsletter/categories/inputs/GeneralInput';

export interface TrendinNewsletterFormProps {
  onFileSelect: (image: ImageUpload) => void;
  onRemoveFile: (name: string) => void;
}

const TrendinNewsletterForm = ({ onFileSelect, onRemoveFile }: TrendinNewsletterFormProps) => {
  const sectionRefs = useGetSectionRef();

  return(
    <>
      <FormSection ref={sectionRefs[Trendin.Header.id]} id={Trendin.Header.id} title={Trendin.Header.label}>
        <GeneralInput
          type="trendin"
          labelTitle="newsletters.view.referenceNewsletter"
          labelSlugLine="newsletters.view.preHeaderMail"
        />
      </FormSection>
      <FormSection
        ref={sectionRefs[Trendin.AdsBillboard1.id]}
        id={Trendin.AdsBillboard1.id}
        title={Trendin.AdsBillboard1.label}
      >
        <AdInput
          onRemoveFile={onRemoveFile}
          onFileSelect={onFileSelect}
          togglerId="AdsBillboard1-id"
          toggleInputName="content.adsBillboard1.isActive"
          uploadUrl="/api/newsletter/image"
          filename="content.adsBillboard1.imageFilename"
          nameLink="content.adsBillboard1.link"
        />
      </FormSection>
      <FormSection
        ref={sectionRefs[Trendin['Decodeurs'].id]}
        id={Trendin['Decodeurs'].id}
        title={Trendin['Decodeurs'].label}
      >
        <SlugInput
          resource={resources.ARTICLES}
          name="content.decodeurs"
          label={`${i18n('labels.article')}`}
          options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}
        >
          <ArticleSelect />
        </SlugInput>
      </FormSection>
      <FormSection
        ref={sectionRefs[Trendin['TroisQuestionsA'].id]}
        id={Trendin['TroisQuestionsA'].id}
        title={Trendin['TroisQuestionsA'].label}
      >
        <SlugInput
          resource={resources.ARTICLES}
          name="content.troisQuestionsA"
          label={`${i18n('labels.article')}`}
          options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}
        >
          <ArticleSelect />
        </SlugInput>
      </FormSection>
      <FormSection ref={sectionRefs[Trendin['Focus'].id]} id={Trendin['Focus'].id} title={Trendin['Focus'].label}>
        <SlugInput
          resource={resources.ARTICLES}
          name="content.focus"
          label={`${i18n('labels.article')}`}
          options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}
        >
          <ArticleSelect />
        </SlugInput>
      </FormSection>
      <FormSection
        ref={sectionRefs[Trendin['Expertises'].id]}
        id={Trendin['Expertises'].id}
        title={Trendin['Expertises'].label}
      >
        <ArrayInput<string> name="content.expertises" maxItems={2} label="labels.article">
          <SlugInput resource={resources.ARTICLES} options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}>
            <ArticleSelect />
          </SlugInput>
        </ArrayInput>
      </FormSection>
      <FormSection
        ref={sectionRefs[Trendin['LaMinuteLuxInnovation'].id]}
        id={Trendin['LaMinuteLuxInnovation'].id}
        title={Trendin['LaMinuteLuxInnovation'].label}
      >
        <SlugInput
          resource={resources.ARTICLES}
          name="content.laMinuteLuxInnovation"
          label={`${i18n('labels.article')}`}
          options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}
        >
          <ArticleSelect />
        </SlugInput>
      </FormSection>
      <FormSection ref={sectionRefs[Trendin['Enjeux'].id]} id={Trendin['Enjeux'].id} title={Trendin['Enjeux'].label}>
        <ArrayInput<string> name="content.enjeux" maxItems={2} label="labels.article">
          <SlugInput resource={resources.ARTICLES} options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}>
            <ArticleSelect />
          </SlugInput>
        </ArrayInput>
      </FormSection>
      <FormSection
        ref={sectionRefs[Trendin['LInstantT'].id]}
        id={Trendin['LInstantT'].id}
        title={Trendin['LInstantT'].label}
      >
        <SlugInput
          resource={resources.ARTICLES}
          name="content.lInstantT"
          label={`${i18n('labels.article')}`}
          options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}
        >
          <ArticleSelect />
        </SlugInput>
      </FormSection>
      <FormSection
        ref={sectionRefs[Trendin.AdsBillboard2.id]}
        id={Trendin.AdsBillboard2.id}
        title={Trendin.AdsBillboard2.label}
      >
        <AdInput
          onRemoveFile={onRemoveFile}
          onFileSelect={onFileSelect}
          togglerId="AdsBillboard2-id"
          toggleInputName="content.adsBillboard2.isActive"
          filename="content.adsBillboard2.imageFilename"
          uploadUrl="/api/newsletter/image"
          nameLink="content.adsBillboard2.link"
        />
      </FormSection>
      <FormSection
        ref={sectionRefs[Trendin['Startuppers'].id]}
        id={Trendin['Startuppers'].id}
        title={Trendin['Startuppers'].label}
      >
        <ArrayInput<string> name="content.startuppers" maxItems={2} label="labels.article">
          <SlugInput resource={resources.ARTICLES} options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}>
            <ArticleSelect />
          </SlugInput>
        </ArrayInput>
      </FormSection>
      <FormSection
        ref={sectionRefs[Trendin['BrandVoice'].id]}
        id={Trendin['BrandVoice'].id}
        title={Trendin['BrandVoice'].label}
      >
        <SlugInput
          resource={resources.ARTICLES}
          name="content.brandVoice"
          label={`${i18n('labels.article')}`}
          options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}
        >
          <ArticleSelect />
        </SlugInput>
      </FormSection>

      <FormSection ref={sectionRefs[Trendin['EnBref'].id]} id={Trendin['EnBref'].id} title={Trendin['EnBref'].label}>
        <ArrayInput<string> name="content.enBref" maxItems={2} label="labels.article">
          <SlugInput resource={resources.ARTICLES} options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}>
            <ArticleSelect />
          </SlugInput>
        </ArrayInput>
      </FormSection>
      <FormSection ref={sectionRefs[Trendin.Agenda.id]} id={Trendin.Agenda.id} title={Trendin.Agenda.label}>
        <ArrayInput<string> name="content.agenda" label="labels.event">
          <SlugInput resource={resources.EVENTS} options={{ additionalResource: resources.EVENT }}>
            <EventSelect withWorkshop />
          </SlugInput>
        </ArrayInput>
      </FormSection>
      <FormSection
        ref={sectionRefs[Trendin.AdsBillboard3.id]}
        id={Trendin.AdsBillboard3.id}
        title={Trendin.AdsBillboard3.label}
      >
        <AdInput
          onRemoveFile={onRemoveFile}
          onFileSelect={onFileSelect}
          togglerId="AdsBillboard3-id"
          toggleInputName="content.adsBillboard3.isActive"
          filename="content.adsBillboard3.imageFilename"
          uploadUrl="/api/newsletter/image"
          nameLink="content.adsBillboard3.link"
        />
      </FormSection>
      <FormSection
        ref={sectionRefs[Trendin['LaPresseEnParle'].id]}
        id={Trendin['LaPresseEnParle'].id}
        title={Trendin['LaPresseEnParle'].label}
      >
        <ArrayInput<string> name="content.laPresseEnParle" maxItems={3} label="labels.article">
          <SlugInput resource={resources.ARTICLES} options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}>
            <ArticleSelect />
          </SlugInput>
        </ArrayInput>
      </FormSection>
    </>
  );
};

export default TrendinNewsletterForm;
