import { Modal } from 'shared/components';
import { ClubHomepage } from 'views/clubHome/types';
import PublishClubHomeModal from './PublishClubHomeModal';
import { PublishData } from '../useInteractionClubHomeDetailsView';

export interface ClubHomeModalProps {
  onModalClose: () => void;
  publicationLoading: boolean;
  setPublicationState: (data: PublishData) => void;
  isPublishModalOpen: boolean;
  clubHomepage?: ClubHomepage;
}

const ClubHomeModal = ({
  publicationLoading,
  isPublishModalOpen,
  onModalClose,
  setPublicationState,
  clubHomepage,
}: ClubHomeModalProps) => {
  return (
    <Modal onModalClose={onModalClose} isOpen={isPublishModalOpen}>
        <PublishClubHomeModal
          clubHomepage={clubHomepage}
          onModalClose={onModalClose}
          isLoading={publicationLoading}
          setPublicationState={setPublicationState}
        />
    </Modal>
  );
};

export default ClubHomeModal;
