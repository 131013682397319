import * as Http from 'shared/services/httpService';
import { MagazineHomepageData } from 'views/magazine/MagazineHomepageView';

export const getOne = () => {
  return Http.get<MagazineHomepageData>('api/magazine/configuration');
};

export const save = (data: MagazineHomepageData) => {
  const {payload} = data;
  return Http.put('api/magazine/configuration', payload);
};

const MagazineService = {
  getOne,
  save,
};

export default MagazineService;
