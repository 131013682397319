import { useCallback } from 'react';
import classNames from 'classnames';
import './Datatable.scss';
import { ColumnTable } from 'shared/components/datatable/DataTable';

export interface TableColumn {
  column: ColumnTable;
  lastColumn: boolean;
  sortByColumn?: (value: string) => void;
  sortPredicate: string;
  sortClickHandler: (column: ColumnTable) => void;
}

const TableColumn = ({
  lastColumn,
  column,
  sortPredicate,
  sortByColumn,
  sortClickHandler,
}: TableColumn) => {
  const isCurrentPredicate = useCallback((predicate) =>
      (predicate && sortPredicate && predicate === sortPredicate) || predicate === sortPredicate.substr(1),
    [sortPredicate]
  );

  const getSortIconFromPredicate = useCallback((predicate) => {
    if (sortPredicate && isCurrentPredicate(predicate)) {
      return sortPredicate[0] === '-' ? 'icon-sort-desc' : 'icon-sort-asc';
    }
    return '';
  }, [isCurrentPredicate, sortPredicate]);

  const sort = useCallback(() => {
    sortClickHandler(column);
  }, [column, sortClickHandler]);

  return (
    <th className={lastColumn ? 'data-table-header-last' : undefined}>
      <a
        className={classNames({
          ['cursor-initial']: !(column.predicate && sortByColumn),
          ['data-table-header-active']: isCurrentPredicate(column.predicate),
        })}
        onClick={sort}
      >
        {column.label}
        {column.predicate && (
          <i
            className={classNames(
              getSortIconFromPredicate(column.predicate),
              'data-table-header-sort-icon',
            )}
          />
        )}
      </a>
    </th>
  );
};

export default TableColumn;
