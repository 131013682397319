import { selectSearchHitLabel, selectSearchHitValue } from 'shared/models/response/search';
import { useSearch } from 'shared/providers';
import { resources } from 'shared/constants';
import { SelectOption, SlugAndTitle } from 'shared/types';
import AsyncSelect from './AsyncSelect';

export interface SponsorSelectProps {
  label?: string;
  onChange?: (value: SelectOption) => void;
  value?: SelectOption;
  filter?: string;
  required?: boolean;
  platformName?: string;
  clearable?: boolean;
  resource?: string;
}

const SponsorSelect = ({
  label,
  onChange,
  value,
  filter,
  platformName,
  resource = resources.SPONSORS_SEARCH,
  clearable = true,
  required = false,
}: SponsorSelectProps) => {
  const { refetch } = useSearch<SlugAndTitle[]>(resource);

  const getOptions = (inputValue: string) => {
    const baseParams = { filter };
    const params = inputValue !== '' ? { ...baseParams, q: inputValue } : baseParams;
    return refetch<SlugAndTitle[]>(
      { ...params, platformName, additionalResource: resources.SPONSORS_SEARCH }
    );
  };

  return (
    <AsyncSelect
      searchable
      clearable={clearable}
      onChange={onChange}
      label={label}
      value={value}
      getOptions={getOptions}
      baseParams={{ platformName }}
      valueAccessor={selectSearchHitValue}
      labelAccessor={selectSearchHitLabel}
      filter={filter}
      required={required}
    />
  );
};

export default SponsorSelect;
