import { useCallback, useEffect, useState } from 'react';
import useForm from 'shared/form/useForm';
import useSubscribe from 'shared/form/useSubscribe';
import { InputProps as UseInputProps } from './types';

type Event = { target:  { value: unknown, type: string, checked: boolean } }

const useInput = <T>({ name, defaultValue }: UseInputProps<T>) => {
  const [value, setInputValue] = useState<T | undefined>(defaultValue);
  const { formState, setValue, getValues, handleChange } = useForm();

  useSubscribe<T>({
    name,
    update: (fieldValue) => {
      if (fieldValue !== value) {
        setInputValue(fieldValue as T);
      }
    }
  });

  useEffect(() => {
    const fieldValue = getValues(name) as T;
    if (fieldValue !== undefined) {
      setInputValue(fieldValue);
    } else if (defaultValue !== undefined) {
      setValue(name, defaultValue);
      setInputValue(defaultValue);
    }
  }, [formState, setValue, getValues, name, defaultValue]);

  const getValue = useCallback((event: unknown): unknown => {
    if (event && typeof event === 'object' && (event as Event).target) {
      const target = (event as Event).target;
      if (target.type === 'checkbox') {
        return target.checked;
      }
      return target.value;
    }
    return event;
  }, []);

  const onChange = useCallback((event: unknown) => {
    const fieldValue = getValue(event);
    handleChange(name, fieldValue);
  }, [getValue, handleChange, name]);

  return {
    name,
    value,
    onChange,
  };
};

export default useInput;
