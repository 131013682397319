import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { match } from 'react-router';
import { i18n } from 'shared/i18n';
import { useGetOne, useUpdate } from 'shared/providers';
import { resources } from 'shared/constants';
import { setErrors } from 'shared/form/utils';
import { isSelectOptionNonEmpty } from 'shared/utils/utils';
import { eventFormModel, toEventDto, toEventFormModel } from './model/EventFormModel';
import { EventDetail, InputEvent } from './types';

const SaveErrorMessage = i18n('events.messages.saveError');
const SaveSuccessMessage = i18n('events.messages.saveSuccess');

export interface UseInteractionEventView {
  isWoodwing: boolean;
  match: match<{ slug: string }>;
}

export interface UseInteractionEventViewReturn {
  event: InputEvent;
  fetchError: boolean;
  isLoading: boolean;
  onSubmit: (value: Record<string, unknown>) => void;
  validate: (value: Record<string, unknown>) => Record<string, unknown>;
  openPreview: () => void;
}

const useInteractionEventView = ({ isWoodwing, match }: UseInteractionEventView): UseInteractionEventViewReturn => {
  const history = useHistory();
  const [event, setEvent] = useState<InputEvent>(eventFormModel);
  const { update } = useUpdate<EventDetail>(
    resources.EVENTS,
    {
      additionalResource: resources.UPDATE_EVENT_CONTENT_METADATA_PUBLICATION,
    },
    SaveSuccessMessage,
    SaveErrorMessage,
  );

  const onSuccess = (data: unknown) => {
    setEvent(toEventFormModel(data as EventDetail));
  };

  const { error, loading: isLoading } = useGetOne<EventDetail>(resources.EVENTS, {
    id: match.params.slug,
    isWoodwing,
    additionalResource: resources.EVENT,
    onSuccess,
  });

  const openPreview = () => {
    history.push(`/event/${event.slug}/preview`);
  };

  const onSubmit = (value: Record<string, unknown>) => {
    const eventDto = toEventDto(value as InputEvent);
    update({
      payload: { ...eventDto, slug: value.slug },
      id: value.slug,
    });
  };

  const validate = (value: Record<string, unknown>): Record<string, unknown> => {
    const errors = {};
    if (value) {
      const inputEvent = value as InputEvent;
      if (!isSelectOptionNonEmpty(inputEvent.metadata.format)) {
        setErrors(errors, 'metadata.format', i18n('forms.validation.required'));
      }
      if (!isSelectOptionNonEmpty(inputEvent.metadata.theme)) {
        setErrors(errors, 'metadata.theme', i18n('forms.validation.required'));
      }
    }

    return errors;
  };

  return {
    event,
    isLoading,
    fetchError: !!error,
    onSubmit,
    validate,
    openPreview,
  };
};

export default useInteractionEventView;
