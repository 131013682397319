import { useState } from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';
import { i18n } from 'shared/i18n';
import 'shared/style/common/preview.scss';
import { RouteComponentProps } from 'react-router';
import { appConfig } from 'shared/config/env';
import { useGetOne } from 'shared/providers';
import { resources } from 'shared/constants';
import { NewsletterDetail } from 'views/newsletter/types';

const NewsletterPreview = ({
  match,
  history,
}: RouteComponentProps<{ kind: string, id: string, previewUrl: string }>) => {
  const { data } = useGetOne<NewsletterDetail>(resources.NEWSLETTERS, {
    id: match.params.id,
    kind: match.params.kind === 'club_en' ? 'club' : match.params.kind,
    additionalResource: 'newsletter',
  });

  const [loaded, setLoaded] = useState<boolean>( false);

  const editArticle = () => {
    history.push(`/newsletter/${match.params.kind}/${match.params.id}`);
  };

  const goBack = () => {
    history.goBack();
  };

  const onLoad = () => {
    setLoaded(true );
  };

  return (
    <div className='preview'>
      <div className='preview-header'>
        <div className='preview-prev-btn-container'>
          <button type="button" className='preview-prev-btn' onClick={goBack}>
            <i className={classNames('icon-arrow', 'preview-prev-btn-icon')} />
            <span className='preview-prev-btn-text'>{i18n('labels.back')}</span>
          </button>
        </div>
        <div className='preview__section'>
          <span className='preview__section-label'>{i18n('labels.title')}</span>
          <span className='preview__section-value'>
            {data ? data.title : ''}
          </span>
        </div>
        <div className='preview__section'>
          <span className='preview__section-label'>{i18n('generalSection.publicationDate')}</span>
          <span className='preview__section-value'>
            {data ? moment(data.publicationDate).format(appConfig.dateFormat) : ''}
          </span>
        </div>
        <div className='preview_buttons'>
          <button
            type="button"
            className={classNames('preview-edit-btn', 'preview-edit-wide-btn')}
            onClick={editArticle}
          >
            <i className={classNames('icon-edit', 'preview-edit-btn-icon')} />
            {i18n('actionBar.edit')}
          </button>
        </div>
      </div>
      <div className='preview-iframe-container'>
        {!loaded && <div className='preview-iframe-loading' />}
        <iframe
          title="article-preview"
          onLoad={onLoad}
          className='preview-iframe'
          src={decodeURIComponent(match.params.previewUrl)}
        />
      </div>
    </div>
  );
};

export default withRouter(NewsletterPreview);
