import { forwardRef, useCallback } from 'react';
import Creatable from 'react-select/creatable';
import { FormSection } from 'layout/content';
import useInput from 'shared/form/useInput';
import { SelectOption } from 'shared/types';
import { CommonFieldProps, InputProps } from 'shared/form/types';
import 'shared/components/form/tagSelect/TagSelect.scss';
import TagItem from 'shared/components/form/tagSelect/item/TagItem';

export interface TagSelectInputProps extends
  InputProps<SelectOption[]>, CommonFieldProps {
  options?: SelectOption[];
}

const TagSelectInput = forwardRef<HTMLElement, TagSelectInputProps>(({
  optionsNumberLimit,
  label,
  options,
  name,
  title,
  id,
  defaultValue = [],
}, ref) => {
  const input = useInput({ name, defaultValue });

  const handleSelectChange = useCallback((items) => {
    const isFull = optionsNumberLimit && items.length >= optionsNumberLimit;
    if (!isFull) {
      input.onChange(items);
    }
  }, [input, optionsNumberLimit]);

  const removeTag = useCallback((tag) => {
    handleSelectChange(input.value?.filter((value) => value.value !== tag));
  }, [handleSelectChange, input]);

  return (
    <FormSection ref={ref} title={title} id={id}>
      <div className='tag-select'>
        <span className='tag-select-title'>{label}</span>
        <Creatable
          isMulti
          name={label}
          options={options || []}
          onChange={handleSelectChange}
          value={input.value}
          components={{
            MultiValue: (props) => <TagItem {...props} onRemoveTag={removeTag} />,
          }}
        />
      </div>
    </FormSection>
  );
});

export default TagSelectInput;
