import { Content, FormSection } from 'layout/content';
import { i18n } from 'shared/i18n';
import { ActionsBar } from 'shared/components';
import { Form } from 'shared/form';
import { SimpleInput } from 'shared/form/inputs';
import './magazineConfigPage.scss';
import Header from './MagazineHeader';
import useInteractionMagazineHomepageView from './useInteractionMagazineHomepageView';
import useGetSectionRef from 'layout/ScrollspyProvider/useGetSectionRef';

export interface Supplement {
  title: string;
  uri: string;
}

export interface MagazineHomepageData extends Record<string, unknown> {
  magazine: Supplement;
  supplement1?: Supplement;
  supplement2?: Supplement;
}

const MagazineHomepageView = () => {
  const { onSubmit, magazineHomepageData, loading } = useInteractionMagazineHomepageView();
  const sectionRefs = useGetSectionRef();

  return (
    <Form onSubmit={onSubmit} initialValues={magazineHomepageData}>
      <Header />
      <Content loading={loading}>
        <div className="magazine-config-page-content">
          <FormSection title={i18n('magazine.magazineConfig')} id="magazine-config">
            <SimpleInput required name="magazine.title" width="100%" label={i18n('magazine.title')} />
            <SimpleInput
              required
              name="magazine.uri"
              width="100%"
              label={i18n('magazine.contentLink')} // "Lien vers le contenu du magazine"
            />
          </FormSection>
          <FormSection
            title={i18n('magazine.supplement1')}
            id="magazine1"
            ref={sectionRefs['magazine1']}
          >
            <SimpleInput name="supplement1.title" width="100%" label={i18n('magazine.supplement1Title')} />
            <SimpleInput name="supplement1.uri" width="100%" label={i18n('magazine.supplement1Link')} />
          </FormSection>
          <FormSection
            title={i18n('magazine.supplement2')}
            id="magazine2"
            ref={sectionRefs['magazine2']}
          >
            <SimpleInput name="supplement2.title" width="100%" label={i18n('magazine.supplement2Title')} />
            <SimpleInput name="supplement2.uri" width="100%" label={i18n('magazine.supplement2Link')} />
          </FormSection>
        </div>
        {!loading && <ActionsBar canUpdate />}
      </Content>
    </Form>
  );
};

export default MagazineHomepageView;
