import classNames from 'classnames';
import moment from 'moment';
import 'shared/components/modal/Modal.scss';
import { i18n } from 'shared/i18n';
import { HomepageData } from 'views/home/homepageService';
import './publishHomeModal.scss';

export interface PublishHomeModalProps {
  isLoading: boolean;
  onClose: () => void;
  homepage: HomepageData;
  onSubmit: () => void;
}

const PublishHomeModal =  ({ isLoading, onClose, homepage, onSubmit }: PublishHomeModalProps) => (
  <div className='publish-modal'>
    <div className='modal-header'>
      <i onClick={onClose} className={classNames('icon-delete', 'close-icon')} />
      <span className='modal-header-title-label'>{i18n('homes.view.confirmPublication')}</span>
      <span className='modal-header-title-value'>{homepage.name}</span>
    </div>
    <div className='modal-content'>
      <div className='publish-modal-pickers'>
        <div className='publish-modal-picker'>
          <span className='publish-Modal-picker-label'>{i18n('generalSection.publicationDate')}</span>
          <span>{moment(homepage.scheduledTime).format('DD/MM/YYYY')}</span>
        </div>
        <div className='publish-modal-picker'>
          <span className='publish-modal-picker-label'>{i18n('generalSection.publicationHour')}</span>
          <span>{moment(homepage.scheduledTime).format('HH:mm')}</span>
        </div>
      </div>
      <button type="button" onClick={onSubmit} className='publish-modal-confirm'>
        {isLoading && <div className='loader' />}
        {i18n('actionBar.confirm')}
      </button>
    </div>
  </div>
);

export default PublishHomeModal;
