import { createRef, ReactNode, useMemo } from 'react';
import { RouteStore } from 'shared/stores/routeStore';
import toScrollMenuForRoute from '../aside/nav/Nav/toScrollMenuForRoute';
import ScrollspyContext, { SectionRefs } from './ScrollspyContext';

export interface ScrollspyProviderProps {
  routeStore: RouteStore;
  children: ReactNode;
}

const ScrollspyProvider = ({ routeStore, children }: ScrollspyProviderProps) => {
  const sectionRefs: SectionRefs = useMemo(() =>
    toScrollMenuForRoute(routeStore.currentRoute).reduce((sectionRef, key) => ({
      ...sectionRef,
      [key.elementId]: createRef<HTMLElement>()}),
      {}
    ),
    [routeStore.currentRoute]);

  return (
    <ScrollspyContext.Provider value={{ sectionRefs }}>
      {children}
    </ScrollspyContext.Provider>
  );
};

export default ScrollspyProvider;
