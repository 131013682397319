import { Query } from 'shared/services/graphql/aliases';
import { getProp } from 'shared/utils';
import { Kind } from 'shared/types';
import { NewsletterDetailInput } from 'shared/services/graphql/transform';
import { NewsletterContent } from 'views/newsletter/categories/types';

export interface ImageContentType {
  filename: string;
}

export interface LinkImage {
  link: string;
  image: ImageContentType;
}

export interface ObjLinkImageType {
  link?: string;
  imageFilename?: string;
}

export interface TitleDescriptionContent {
  title: string;
  description: string;
  surtitle?: string;
}

export const getSort = (alias: Record<string, Query>, sort?: string): [string, boolean] => {
  if (!sort || (sort && !alias[sort])) return ['', false];
  
  return [alias[sort].sort, alias[sort].asc];
};

export const getImageFilename = (
  obj: unknown,
  propName: string,
) => {
  const result = getProp(obj, propName) as ObjLinkImageType | undefined;

  if (!result) return undefined;

  return  {
    image: {
      filename: result.imageFilename,
    }
  };
};

export const getLinkImageFilename = (
  obj: unknown,
  propName: string,
): LinkImage | undefined => {
  const result = getProp(obj, propName) as ObjLinkImageType | undefined;

  if (!result) return undefined;

  return {
    link: result.link,
    ...getImageFilename(obj, propName),
  } as LinkImage;
};


export const getTitleDescriptionSurtitle = (
  obj: unknown,
  propName: string,
  withLink = true,
  surtitle = false
): TitleDescriptionContent | undefined => {
  const result = getProp(obj, propName) as TitleDescriptionContent | undefined;

  if (!result) return undefined;

  const content = {
    title: result.title,
    description: result.description,
    ...(
      withLink
        ? getLinkImageFilename(obj, propName)
        : getImageFilename(obj, propName)
    ),
  };

  return surtitle ? {
    ...content,
    surtitle: result.surtitle,
  } : content;
};

export const getBaseNewsletterContent = (newsletter: NewsletterDetailInput) => {
  const newsletterContent = newsletter.content as NewsletterContent;
  
  return {
    ...newsletter,
    kind: newsletter.kind && Kind[newsletter.kind],
    content: {
      ...newsletter.content,
      billboards: {
        ...[
          'adsBillboard1',
          'adsBillboard2',
          'adsBillboard3',
          'adsBillboard4',
          'adsBillboard5',
          'adsBillboard6',
          'adsBillboard7',
        ].reduce((result, propName) => (
          { ...result, [propName]: getLinkImageFilename(newsletterContent.billboards, propName)}
        ), {})
      },
      adsImu: getLinkImageFilename(newsletterContent, 'adsImu'),
      photoDuJour: getTitleDescriptionSurtitle(newsletterContent, 'photoDuJour'),
      tweetDuJour: getLinkImageFilename(newsletterContent, 'tweetDuJour'),
      chiffreDuJour: getTitleDescriptionSurtitle(newsletterContent, 'chiffreDuJour', false),
      enCeMomentDansLeMagazine: newsletterContent.enCeMomentDansLeMagazine
        ? {
          date: newsletterContent.enCeMomentDansLeMagazine.date,
          ...getTitleDescriptionSurtitle(newsletterContent, 'enCeMomentDansLeMagazine'),
        }
        : undefined,
      externalApi: {
        ...newsletterContent.externalApi,
        myLifeLu: getTitleDescriptionSurtitle(
          newsletterContent.externalApi,
          'myLifeLu',
          true,
          true,
        ),
        enjoy: getTitleDescriptionSurtitle(
          newsletterContent.externalApi,
          'enjoy',
          true,
          true,
        ),
        businessInEnglish: getTitleDescriptionSurtitle(
          newsletterContent.externalApi,
          'businessInEnglish',
          true,
          true,
        ),
        vousMangezOuCeMidi: getTitleDescriptionSurtitle(
          newsletterContent.externalApi,
          'vousMangezOuCeMidi',
          true,
          true,
        ),
        jobs: (newsletterContent.externalApi.jobs || []).map((jobs) => ({
          title: jobs.title,
          societe: jobs.societe,
          logo: {
            filename: jobs.logoFilename,
          },
          typeOffre: jobs.typeOffre,
          lieu: jobs.lieu,
          link: jobs.link,
        })),
      },
      parusChezMaisonModerne: newsletterContent.parusChezMaisonModerne?.map(
        (parusChezMaisonModern, index) => ({
          ...getTitleDescriptionSurtitle(newsletterContent, `parusChezMaisonModerne[${index}]`),
        })) || [],
    },
  };
};
