import { DELANO_BREAKFAST, DELANO_NOON, GRAND_DOSSIER, TRENDIN } from 'views/newsletter/state';

const getNewsletterType = (type: string) => {
  switch (type) {
    case GRAND_DOSSIER:
      return 'dossier';
    case TRENDIN:
      return 'tech';
    case DELANO_BREAKFAST:
      return 'breakfast';
    case DELANO_NOON:
      return 'noon';
    default: return type;
  }
};

export default getNewsletterType;
