import { i18n } from 'shared/i18n';

export const MenuItems = {
  general: {
    id: 'general-section',
    label: i18n('podcastShows.nav.general'),
  },
  sector: {
    id: 'sector-subsector',
    label: i18n('podcastShows.nav.subSector'), // 'Catégorie Principale',
  },
  mainImage: {
    id: 'main-image',
    label: i18n('podcastShows.nav.mainImage'), // 'IMAGE',
  },
  episodes: {
    id: 'podcast-episodes',
    label: i18n('podcastShows.nav.episodes'), // 'ÉPISODES DE PODCAST',
  },
  subscribe: {
    id: 'podcast-subscribe-link',
    label: i18n('podcastShows.nav.subscribe'), // "S'ABONNER LIEN",
  },
  sponsored: {
    id: 'sponsored-content',
    label: i18n('podcastShows.nav.sponsored'), // 'CONTENU SPONSORISÉ',
  },
};
