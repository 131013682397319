import { DocumentNode, gql } from '@apollo/client';

const retrieve = (): DocumentNode => gql`
  query {
    featuredProfiles {
      __typename
      ... on FeaturedProfilesResponse {
        monthlyProfiles {
          slug
          title
        }
      }
      ... on ErrorPayload {
        traceId
        errorMessage
        validationErrors {
          code
          message
        }
      }
    }
  }
`;

const update = (): DocumentNode => gql`
  mutation saveFeaturedProfiles($updateData: SaveFeaturedProfilesInput!) {
    saveFeaturedProfiles(updateData: $updateData) {
      __typename
      ... on FeaturedProfilesResponse {
        monthlyProfiles {
          slug
          title
        }
      }
      ... on ErrorPayload {
        traceId
        errorMessage
        validationErrors {
          code
          message
        }
      }
    }
  }
`;

const guideGraphqlServices = {
  retrieve,
  update,
};

export default guideGraphqlServices;
