import { useEffect } from 'react';
import { Subject } from 'shared/form/createSubject';
import useForm from 'shared/form/useForm';
import { getProp } from 'shared/utils/getProp';

export interface UseSubscribe<T> {
  name: string | string[];
  update: (value: T, name?: string) => void;
}

const useSubscribe = <T>({ update, name }: UseSubscribe<T>) => {
  const { subject } = useForm();

  useEffect(() => {
    const subscription = (subject as Subject<T>).subscribe({
      name,
      update: (values, fieldName) => {
        if (fieldName) {
          update(values);
        } else {
          if (!Array.isArray(name)) {
            const value = getProp(values, name) as T;
            update(value);
          } else {
            update(values);
          }
        }
      },
    });

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [name, subject, update]);
};

export default useSubscribe;
