import './OutputText.scss';

export interface OutputTextProps {
  label: string;
  value: string;
  width?: string;
  wrapperClassName: string;
}

const OutputText = ({ label, value, wrapperClassName, width = 'auto', }: OutputTextProps) => (
  <div className={wrapperClassName}>
    <span className='output-text-label'>{label}</span>
    <span style={{ width }} className='output-text'>
      {value}
    </span>
  </div>
);

export default OutputText;
