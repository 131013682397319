import 'shared/style/common/preview.scss';

export interface SectionProps {
  label: string;
  value: string;
}

const Section = ({ label, value }: SectionProps) => (
  <div className='preview__section'>
    <span className='preview__section-label'>{label}</span>
    <span className='preview__section-value'>{value}</span>
  </div>
);

export default Section;
