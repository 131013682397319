import { selectSearchHitLabel, selectSearchHitValue } from 'shared/models/response/search';
import {  Refetch, SelectOption, SlugAndTitle } from 'shared/types';
import { useInput } from 'shared/form';
import { useSearch } from 'shared/providers';
import { resources } from 'shared/constants';
import { SelectInputProps } from 'shared/form/types';
import AsyncSelect from 'shared/components/form/select/AsyncSelect';

const SponsorSelectInput = ({
  label,
  onChange: customChange,
  defaultValue,
  name = '',
  required = false,
}: SelectInputProps) => {
  const input = useInput({ name, defaultValue });
  const { refetch } = useSearch<SlugAndTitle[]>(resources.SPONSORS_SEARCH);

  const onChange = (value: SelectOption) => {
    if (customChange) {
      customChange(value);
    }
    input.onChange(value);
  };

  return (
    <AsyncSelect
      searchable
      clearable
      {...input}
      onChange={onChange}
      label={label}
      loadOptions={refetch as Refetch}
      valueAccessor={selectSearchHitValue}
      labelAccessor={selectSearchHitLabel}
      required={required}
    />
  );
};

export default SponsorSelectInput;
