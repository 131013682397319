import { SlugParams } from 'shared/types';
import { CreateParams, UpdateParams } from 'shared/providers';
import * as Http from './httpService';

export const createResource = (endpoint: string) => ({
  create<T, >(params: CreateParams): Promise<T> {
    return Http.post<T>(endpoint, params.payload);
  },
  save(params: UpdateParams) {
    return Http.put(`${endpoint}/${params.id}`, params.payload);
  },
  get<T, >(params: SlugParams): Promise<T> {
    return Http.get<T>(`${endpoint}/${params.id}`);
  },
});
