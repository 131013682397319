import { resources, types } from 'shared/constants';
import { appConfig } from 'shared/config/env';
import { Content, Metadata } from 'shared/services/eventService';
import {
  Magazine,
  NewsletterClubContent,
  NewsletterGrandDossierContent,
  NewsletterTrendinContent,
  SlugAndTitle,
} from 'shared/types';
import { Article } from 'views/article/types';
import { EventDetail } from 'views/event/types';
import { ClubHomepage } from 'views/clubHome/types';
import { Homepage } from 'views/home/types';
import { NewsletterDetail } from 'views/newsletter/types';
import { NewsletterContent } from 'views/newsletter/categories/types';
import { WorkshopFormatted } from 'views/workshop/model/WorkshopFormModel';
import { getBaseNewsletterContent, getLinkImageFilename } from './utils';
import { DossierDetail } from 'views/dossier/types';
import { PodcastShow } from 'views/shows/PodcastsShowsListView';
import { PodcastShowInput } from 'views/show/PodcastShowView';
import { GuideHomepageData } from 'views/guide/GuideHomepageView';

export type EventWithoutContent = Omit<EventDetail, 'content'>;

export interface Event extends EventWithoutContent {
  content: Content;
  metadata: Metadata;
}

export interface NewsletterDetailInput extends NewsletterDetail {
  id?: string;
  kind: string;
}

export const transformNewsletterData = (payload: unknown): Record<string, unknown> | undefined => {
  const newsletter = payload as NewsletterDetailInput;
  const { kind, ...otherDataNewsletter } = newsletter;
  
  switch (kind) {
    case types.CLUB: {
      const newsletterClubContent = newsletter.content as NewsletterClubContent;
      
      return {
        ...otherDataNewsletter,
        platformName: appConfig.platformName,
        content: {
          ...newsletterClubContent,
          aLaUne: newsletterClubContent.aLaUne,
          prochainement: newsletterClubContent.prochainement,
        },
      };
    }
    case types.DELANO_BREAKFAST:
    case types.DELANO_NOON: {
      const content: Record<string, unknown> = getBaseNewsletterContent(newsletter);
      if (content.platformName) {
        delete content['platformName'];
      }
      return content;
    }
    case types.MORNING:
    case types.EVENING: {
      const newsletterContent = newsletter.content as NewsletterContent;
      const baseContent = getBaseNewsletterContent(newsletter);
      
      return {
        ...baseContent,
        platformName: appConfig.platformName,
        content: {
          ...baseContent.content,
          clubPart: {
            agenda: newsletterContent.clubPart.agenda,
            paperjamClub: newsletterContent.clubPart.paperjamClub,
            clubMemberNews: newsletterContent.clubPart.clubMemberNews,
          },
        }
      };
    }
    case types.TRENDIN: {
      const newsletterTrendinContent = newsletter.content as NewsletterTrendinContent;
      return {
        ...otherDataNewsletter,
        platformName: appConfig.platformName,
        content: {
          ...newsletterTrendinContent,
          ...['adsBillboard1', 'adsBillboard2', 'adsBillboard3'].reduce((result, propName) => (
            { ...result, [propName]: getLinkImageFilename(newsletterTrendinContent, propName)}
          ), {})
        },
      };
    }
    case types.GRAND_DOSSIER: {
      const newsletterGrandDossierContent = newsletter.content as NewsletterGrandDossierContent;
      
      return {
        ...otherDataNewsletter,
        platformName: appConfig.platformName,
        content: {
          ...newsletterGrandDossierContent,
          billboards: newsletterGrandDossierContent.billboards.map((billboard, index) =>
            getLinkImageFilename(
              newsletterGrandDossierContent,
              `billboards[${index}]`
            )),
          magazine: newsletterGrandDossierContent.magazine
            ? {
              date: newsletterGrandDossierContent.magazine.date,
              description: newsletterGrandDossierContent.magazine.description,
              ...getLinkImageFilename(newsletterGrandDossierContent, 'magazine'),
              titre: newsletterGrandDossierContent.magazine.titre,
            }
            : undefined,
        },
      };
    }
  }
};

export const transformUpdateData = (resource: string, payload: unknown): Record<string, unknown> | undefined => {
  switch (resource) {
    case resources.ARTICLES:
    case resources.ARTICLE_PUBLISHED:
      const article = payload as Article;
      return {
        updateData: {
          slug: article.slug,
          platformName: appConfig.platformName,
          titleComplement: article.metadata.title,
          topics: article.metadata.topics || [],
          categorizationsDto: article.metadata.categorizations.map((category) => ({
            sector: category.sector,
            subSector: category.subSector,
            subSubSector: category.subsubSectorDto
              ? {
                slug: category.subsubSectorDto.slug,
                name: category.subsubSectorDto.name,
                subSector: category.subsubSectorDto?.subsector
              }
              : null,
          })),
          linkedContent: {
            articles: article.metadata.linkedContent.articles.filter((article: SlugAndTitle | null) => article).map(
              (article) => article.slug
            ),
            sponsoredContent: article.metadata.linkedContent.sponsoredContent.filter(
              (sponsoredContent: Partial<SlugAndTitle> | null) => sponsoredContent
            ).map(
              (sponsoredContent) => sponsoredContent.slug
            ),
            events: article.metadata.linkedContent.events.filter(
              (event: SlugAndTitle | null) => event
            ).map((event) => event.slug),
          },
          tags: article.metadata.tags || [],
          publicationDate: article.publication.date,
          isPublished: article.publication.isPublished,
          showAudio: article.metadata.showAudio,
          newsTicker: article.publication.newsTicker,
        }
      };
    case resources.EVENTS:
      const event = payload as Event;

      return {
        updateData: {
          slug: event.slug,
          metadata: {
            format: event.metadata.format,
            theme: event.metadata.theme,
            tags: event.metadata.tags || [],
            linkedContent: {
              articles: event.metadata.linkedContent?.articles || [],
              sponsoredContent: event.metadata.linkedContent?.sponsoredContent || [],
              events: event.metadata.linkedContent?.events || [],
            },
          },
          content: {
            speakers: event.content.speakers || [],
            sponsors: {
              sponsorsGold: event.content.sponsors?.sponsorsGold || [],
              sponsorsSilver: event.content.sponsors?.sponsorsSilver || [],
            },
            video: event.content.video,
            photo: event.content.photo,
          },
          publication: event.publication,
          eventzillaEventId: event.eventzillaEventId,
        }
      };
    case resources.CLUB_HOMEPAGE:
      const clubHomepage = payload as ClubHomepage;

      return {
        updateData: {
          slug: clubHomepage.slug,
          events: clubHomepage.events,
          platformName: clubHomepage.platformName,
          at: clubHomepage.at,
        }
      };
    case resources.NEWSLETTERS:
      return { updateData: transformNewsletterData(payload) };
    case resources.NEWS_HOMEPAGE:
      const homepage = payload as Homepage;
  
      return {
        updateData: {
          platformName: appConfig.platformName,
          ...homepage,
        }
      };
    case resources.WORKSHOPS:
        return { updateData: payload };
    case resources.DOSSIERS:
        const dossier = payload as DossierDetail;
        return { updateData: { platformName: appConfig.platformName, ...dossier } };
    case resources.MAGAZINES:
        const magazine = payload as Magazine;
        return { updateData: { platformName: appConfig.platformName, magazineInput: {...magazine }} };
    case resources.PODCAST_SHOWS:
        const podcast = payload as PodcastShow;
        return { updateData: { platformName: appConfig.platformName, ...podcast } };
    case resources.GUIDES:
        const guides = payload as GuideHomepageData;
        return { updateData: { monthlyProfiles: guides.monthlyProfiles } };
  }
};

export const transformCreateData = (resource: string, payload: unknown): Record<string, unknown> | undefined => {
  switch (resource) {
    case resources.CLUB_HOMEPAGE:
      const clubHomepage = payload as ClubHomepage;

      return {
        createData: {
          events: clubHomepage.events,
          platformName: clubHomepage.platformName,
          at: clubHomepage.at,
        }
      };
    case resources.NEWSLETTERS:
      return { createData: transformNewsletterData(payload) };
    case resources.NEWS_HOMEPAGE:
      const homepage = payload as Homepage;

      return {
        createData: {
          platformName: appConfig.platformName,
          ...homepage,
        },
      };
    case resources.WORKSHOPS:
      const workshop = payload as WorkshopFormatted;

      return {
        createData: {
          ...workshop,
          mainImage: Object.fromEntries(
            Object
              .entries(workshop.mainImage)
              .filter(([name, _]) => name !== '_type')
          )
        }
    };
    case resources.DOSSIERS:
      const dossier = payload as DossierDetail;
      return { createData: {platformName: appConfig.platformName ,...dossier } 
    };
    case resources.PODCAST_SHOWS:
      const podcast = payload as PodcastShowInput;
      return { createData: {platformName: appConfig.platformName ,...podcast } 
    };
    };
};
