import { ClubHomepage } from 'views/clubHome/types';
import { SelectOption } from 'shared/types';

export const formattedClubHomeValue = (clubHomeValue: ClubHomepage) => ({
  ...clubHomeValue,
  events: clubHomeValue.events.map(
    (eventClubHome: SelectOption | string | null) => eventClubHome &&
      (typeof eventClubHome === 'string' ? eventClubHome :eventClubHome.value)
  ),
});
