import { DocumentNode, gql } from '@apollo/client';
import { appConfig } from 'shared/config/env';
import { GetListParams } from 'shared/providers/dataProvider/types';
import { getSort } from 'shared/services/graphql/utils';
import { DossierSortingCriteriaAlias } from 'shared/services/graphql/aliases';

import { GetOneParams, GetSearchParams } from 'shared/providers/dataProvider/types';

const create = (): DocumentNode => gql`
  mutation createDossier($createData: DossierCreateInput!) {
    createDossier(createData: $createData) {
      ... on Dossier {
        creationDate
        creator
        publication {
          date
          isPublished
          target {
            news
            club
          }
        }
        questions {
          question
          slugs
        }
        slug
        slugline
        subSector
        tableRonde
        title
      }
      ... on ErrorPayload {
        traceId
        errorMessage
        validationErrors {
          code
          message
        }
      }
    }
  }
`;

const update = (): DocumentNode => gql`
  mutation updateDossier($updateData: DossierUpdateInput!) {
    updateDossier(updateData: $updateData) {
      ... on Dossier {
        creationDate
        creator
        publication {
          date
          isPublished
          target {
            news
            club
          }
        }
        questions {
          question
          slugs
        }
        slug
        slugline
        subSector
        tableRonde
        title
      }
      ... on ErrorPayload {
      traceId
      errorMessage
      validationErrors {
          code
          message
        }
      }
    }
  }
`;

const retrieve = <T>(params?: GetOneParams<T>): DocumentNode => gql`
query {
    dossier(slug: "${params?.id}" , platformName: ${appConfig.platformName}) {
    ... on Dossier{
        creationDate
        creator
        publication {
          date
          isPublished
          target {
            news
            club
          }
        }
        questions {
          question
          slugs
        }
        slug
        slugline
        subSector
        tableRonde
        title
    }
    ... on ErrorPayload {
      traceId
      errorMessage
      validationErrors {
          code
          message
        }
    }
    ... on NoResult{
    message
    }
  }
}`;

const list = (params?: GetListParams): DocumentNode => {
  const offset = params?.page !== undefined
  ? params.page === 1
    ? appConfig.offset
    : (params.page - 1) * Number(appConfig.limit)
  : appConfig.offset;

  const [sort, asc] = getSort(DossierSortingCriteriaAlias, params?.sort);

  return gql`
    query {
      dossiers (
          limit: ${appConfig.limit},
          offset: ${offset},
          platformName: ${appConfig.platformName},
          sort: "${sort}",
          asc: ${asc},
      ) { 
        ... on DossiersResponse {
          items {
            slug
            subSector
            title
            slugline
            publication {
              date
              isPublished
              target {
                news
                club
              }
              newsTicker
            }
            tableRonde
            questions {
              question
              slugs
            }
            creator
            creationDate
            updateDate
            platformName
            updatedBy
          }
          count
          limit
          offset
        }

        ... on ErrorPayload {
          traceId
          errorMessage
          validationErrors {
            code
            message
          }
        }

        ... on NoResult {
          message
        }
      }
    }
  `;
};

const search =  (params?: GetSearchParams): DocumentNode => {
  const query = `
    query {
      dossiersSearch(
        query: "${params?.q || ''}"
        platformName: ${appConfig.platformName}
      ) {
        ... on DossiersSearchOutput {
          items {
            slug
            title
          }
        }
        ... on ErrorPayload {
          traceId
          errorMessage
          validationErrors {
            code
            message
          }
        }
        ... on NoResult {
          message
        }
    }
  }`;
  
  return  gql`${query}`;
};

const dossiersGraphqlServices = {
  create,
  update,
  retrieve,
  list,
  search
};

export default dossiersGraphqlServices;
