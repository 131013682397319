import Nav from 'layout/aside/nav/Nav';
import Logo from 'shared/components/logo/Logo';
import 'layout/aside/Aside.scss';
import { RouteStore } from 'shared/stores/routeStore';
import GoBackLink from './GoBackLink';

export interface AsideProps {
  routeStore: RouteStore;
}

export const Aside = ({ routeStore }: AsideProps) => {
  const { isMenuRoute } = routeStore;

  return (
    <aside hidden={!isMenuRoute} className='aside'>
      <GoBackLink routeStore={routeStore} />
      <Logo subTitleAlign="right" />
      <Nav routeStore={routeStore} />
    </aside>
  );
};
