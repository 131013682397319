import { Fragment } from 'react';
import { useSingIn } from 'shared/providers';
import { Form } from 'shared/form';
import { SimpleInput } from 'shared/form/inputs';
import './LoginView.scss';
import { getPlatformLogo } from 'shared/utils/utils';
import { i18n } from 'shared/i18n';
export interface SingInViewProps {
  loadingUser: boolean;
}

const SingInView = ({ loadingUser }: SingInViewProps) => {
  const { singIn, loading } = useSingIn();
  const logoUrl = getPlatformLogo();

  return (
    <div className="login">
      <div className='login-wrapper'>
        {loadingUser ? <div className='login-loading' /> : (
          <Fragment>
            <div className='login-logo-wrapper'>
              <img width="175" height="23" src={logoUrl} alt="xxx" />
              <div style={{ textAlign: 'center' }} className='login-logo-sub-logo'>
                <span>BackOffice</span>
              </div>
            </div>
            <div className='login-box'>
              <h1 className='login-title'>Identification</h1>
              <Form onSubmit={singIn}>
                <label className='login-label' htmlFor="email">
                  E-Mail
                </label>
                <SimpleInput className='login-input' name="email" placeholder="@maisonmoderne.com" />
                <label className='login-label' htmlFor="password">
                {i18n('actionBar.password')}
                </label>
                <SimpleInput className='login-input' type="password" name="password" placeholder="••••••••" />
                {loading ? (
                  <div className='login-submit-loading' />
                ) : (
                    <button className='login-submit' type="submit">
                     {i18n('actionBar.signin')}
                  </button>
                )}
                <p>
                  <a href='./reset-password' className='login-forgotten-pwd'>
                    {i18n('resetPassword.forgottenPassword')}
                  </a>
                </p>
              </Form>
            </div>
          </Fragment>
          )}
      </div>
    </div>
  );
};

export default SingInView;
