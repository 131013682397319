import * as Http from 'shared/services/httpService';
import { Params, QueryParams, ResponseListBody, SlugAndTitle } from 'shared/types';
import { Dossier } from 'views/dossiers/types';
import { GetParams } from './articleService';

const ENDPOINT_URI = '/api/dossier';

export const getAll = (params: Params) =>
  Http.get<ResponseListBody<Dossier>>(`${ENDPOINT_URI}s`, {
    params,
  });

export const getOne = (params: GetParams): Promise<Dossier> => {
  const {id} = params;
  return Http.get<Dossier>(`${ENDPOINT_URI}/${id}`);

};

export function searchDossiers(params: QueryParams): Promise<SlugAndTitle[]> {
  return Http.get<SlugAndTitle[]>('api/dossiers/search', { params });
}

const DossierService = {
  getAll,
  getOne,
  searchDossiers,
};

export default DossierService;
