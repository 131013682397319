import { ReactNode, useState, useEffect, useMemo } from 'react';
import { whoami } from 'shared/services/userService';
import { UserSession } from 'shared/types';
import { REST, appConfig, featureFlags } from 'shared/config/env';
import UserSessionContext from './store';
import { trimSlashes } from '../../services/httpService';
import { AuthProviderType } from 'shared/providers';
import { toUserSessionDto } from 'shared/stores/user/userModel';
import { SIGN_IN_ERROR_MESSAGE, apiErrorToUiNotifier } from 'shared/services';
import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';

interface UserSessionState {
  userSession?: UserSession;
}
export interface UserSessionProviderProps {
  children: ReactNode;
  authProvider?: AuthProviderType;
}

const UserSessionProvider = ({ children }: UserSessionProviderProps) => {
  const [state, setState] = useState<UserSessionState>({});

  useEffect(() => {
    if (appConfig.queryType === REST) {
      whoami()
        .then((userSession: UserSession) => {
          setState({ userSession });
        })
        .catch(() => {
          const { enableNoRedirectMode } = featureFlags;
          if (enableNoRedirectMode) {
            // Do not redirect to backoffice login page automatically
            return;
          }

          const { loginUrl } = appConfig;
          const { pathname } = window.location;
          const redirectPath = trimSlashes(pathname);

          const redirectUrl = `${loginUrl}?redirectPath=${redirectPath}`;
          window.location.replace(redirectUrl);
        });
    } else {
      Auth.currentAuthenticatedUser()
        .then((response: CognitoUser) => {
          return toUserSessionDto(response); // Return Promise<UserSession>
        })
        .then((userSession) => {
          setState({ userSession }); // Use the resolved UserSession
        })
        .catch(() => {
          apiErrorToUiNotifier(SIGN_IN_ERROR_MESSAGE);
        });
    }
  }, [setState]);

  const isAuthenticated = useMemo(() => Boolean(state.userSession || true), [state]);
  return (
    <UserSessionContext.Provider value={state.userSession}>
      {isAuthenticated ? children : null}
    </UserSessionContext.Provider>
  );
};

export default UserSessionProvider;
