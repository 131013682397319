import { i18n } from 'shared/i18n';

const DEFAULT_WOODWING = 'woodwing';
const DEFAULT_DOMAIN = 'maisonmoderne.com';

const RESET_PASSWORD_FIELDS = {
  code: 'code',
  newPassword: 'newPassword',
  password: 'password',
};

const RESET_ERROR_MESSAGES = {
  codeError: i18n('resetPassword.codeError'),
  passwordMatchError: i18n('resetPassword.passwordMatchError'),
  passwordStrength: i18n('resetPassword.passwordStrength')
};

const DEFAULT_EMAIL_RESPONSE = {
  status: false,
  isDomain: false
};

const DEFAULT_VALIDATION_SUCCESS = {
  status: true,
  message: 'success'
};

const commonConstants = {
  DEFAULT_WOODWING,
  DEFAULT_DOMAIN,
  RESET_PASSWORD_FIELDS,
  RESET_ERROR_MESSAGES,
  DEFAULT_EMAIL_RESPONSE,
  DEFAULT_VALIDATION_SUCCESS
};


export default commonConstants;
