import { useEffect, useState } from 'react';
import { apiErrorToUiNotifier, LOAD_ERROR_MESSAGE, SAVE_ERROR_MESSAGE } from 'shared/services';
import { i18n } from 'shared/i18n';
import { Form } from 'shared/form';
import { SelectOption } from 'shared/types';
import { ArrayInput, BiographySelectInput } from 'shared/form/inputs';
import { ActionsBar } from 'shared/components';
import { Content, FormSection } from 'layout/content';
import Header from './HeaderGuideHomepage';
import './backofficeDetailPage.scss';
import { useGetOne, useUpdate } from 'shared/providers';
import { resources } from 'shared/constants';

export interface GuideHomepageData extends Record<string, unknown> {
  monthlyProfiles: SelectOption[];
}

const GuideHomepageView = () => {
  const [guideHomepageDate, setGuideHomepageData] = useState<GuideHomepageData>({ monthlyProfiles: [] });
  const [isUpdated, setIsUpdated] = useState<boolean>(false);

  const {
    data,
    error,
    loading: isLoading,
  } = useGetOne<GuideHomepageData>(resources.GUIDES, {
    additionalResource: resources.GUIDE_FEATURED_PROFILES,
    allowedRequest: !isUpdated,
  });

  const { update } = useUpdate<GuideHomepageData>(resources.GUIDES, {
    additionalResource: resources.GUIDE_UPDATE_FEATURED_PROFILES,
  });

  const onSubmit = (value: Record<string, unknown>) => {
    update({
      payload: {
        monthlyProfiles: (value as GuideHomepageData).monthlyProfiles
          .filter((value: SelectOption) => value)
          .map((monthlyProfile: SelectOption) => monthlyProfile.value),
      },
      onFailure: () => {
        apiErrorToUiNotifier(SAVE_ERROR_MESSAGE);
      },
    });
  };

  useEffect(() => {
    if (data && data.monthlyProfiles && !isUpdated) {
      const { monthlyProfiles } = data;
      setGuideHomepageData({
        monthlyProfiles: monthlyProfiles.map(
          (monthlyProfiles: SelectOption) =>
            monthlyProfiles && { value: monthlyProfiles.slug, label: monthlyProfiles.title },
        ) as SelectOption[],
      });
      setIsUpdated(isUpdated);
    } else {
      if (!!error) {
        apiErrorToUiNotifier(LOAD_ERROR_MESSAGE)(error as Error);
        setGuideHomepageData({ monthlyProfiles: [] });
      }
    }
  }, [data, error, isUpdated]);

  return (
    <Form onSubmit={onSubmit} initialValues={guideHomepageDate}>
      <Header />
      <Content loading={isLoading}>
        <div className="backoffice-detail-page-content">
          <FormSection title={i18n('guide.monthlyProfiles')} id="monthly-profiles">
            <ArrayInput<SelectOption> name="monthlyProfiles" label="guide.profileOfMonth">
              <BiographySelectInput />
            </ArrayInput>
          </FormSection>
        </div>
        {!isLoading && <ActionsBar canUpdate />}
      </Content>
    </Form>
  );
};

export default GuideHomepageView;
