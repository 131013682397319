import * as Http from 'shared/services/httpService';
import { Params, ResponseListBody, SlugParams } from 'shared/types';
import { CreateParams, DeleteParams, UpdateParams } from 'shared/providers';
import { HomepageListModel } from 'views/homes/types';
import { Homepage } from 'views/home/types';

export function getHomepages(params: Partial<Params>) {
  return Http.get<ResponseListBody<HomepageListModel>>('api/homepages', { params });
}

export function deleteHomepage({ payload }: DeleteParams) {
  return Http.del(`api/homepage/${(payload as Record<string, unknown>).slug}`);
}

const getHomepage = (params: SlugParams) =>
  Http.get<Homepage>(`api/homepage/${params.id}`);

const createHomepage = ({ payload }: CreateParams) =>
  Http.post('api/homepage', payload);

const updateHomepage = ({ payload, id }: UpdateParams) =>
  Http.put(`api/homepage/${id}`,payload);

const HomepageService = {
  getHomepages,
  deleteHomepage,
  getHomepage,
  createHomepage,
  updateHomepage,
};

export default HomepageService;
