import * as Http from 'shared/services/httpService';
import { QueryParams, SlugAndTitle } from 'shared/types';

export function searchBiographies(params: QueryParams): Promise<SlugAndTitle[]> {
  return Http.get<SlugAndTitle[]>('api/guide/biography/search', { params });
}

const BiographyService = {
  searchBiographies,
};

export default BiographyService;
