import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { Content } from 'layout/content';
import Header from 'layout/header/Header';
import 'layout/header/header.scss';
import { DataTable, Pagination, Searchbar } from 'shared/components';
import { EventSortingCriteria } from 'shared/models';
import { i18n } from 'shared/i18n';
import { RowRenderData } from 'shared/components/datatable/DataTable';
import EventRow from 'views/events/row/EventRow';
import { Event } from './types';
import './eventList.scss';
import useInteractionEventListView from './useInteractionEventListView';

const EventListView = () => {
  const {
    events,
    isLoading,
    query,
    sortPredicate,
    pagination,
    handlePageChange,
    onChangeSearchBar,
    onPredicateChange,
    openDetailEvent,
    handleSearch,
  } = useInteractionEventListView();

  const rowRenderer = ({ item, key }: RowRenderData<Event>) => <EventRow item={item} key={key} />;

  return (
    <div className="event-list-root">
      <Header>
        <h1 className={classNames('header-title', 'header-title-dark')}>
          <i className={classNames('header-title-icon', 'icon-big', 'icon-event')} />
          <div className="header-title-text">{i18n('events.pageTitle')}</div>
        </h1>
      </Header>
      <Content loading={isLoading}>
        <Searchbar
          value={query}
          onChange={onChangeSearchBar}
          onSearch={handleSearch}
          placeholder={i18n('events.searchEvents')}
        />
        <DataTable<Event>
          sortable
          sortByColumn={onPredicateChange}
          sortPredicate={sortPredicate}
          columns={[
            {
              label: '',
            },
            {
              label: i18n('table.columns.title'),
              predicate: EventSortingCriteria.Title,
            },
            {
              label: i18n('table.columns.type'),
            },
            {
              label: i18n('table.columns.creationDate'),
              predicate: EventSortingCriteria.CreationDate,
            },
            {
              label: i18n('table.columns.publicationDate'),
              predicate: EventSortingCriteria.PublicationDate,
            },
            {
              label: '',
            },
          ]}
          data={events}
          isLoading={isLoading}
          selectItem={openDetailEvent}
          rowRenderer={rowRenderer}
        />
        {pagination && (
          <Pagination
            buttonsRange={3}
            onPageChange={handlePageChange}
            currentPage={pagination.page}
            lastPage={pagination.lastPage}
          />
        )}
      </Content>
    </div>
  );
};

export default withRouter(EventListView);
