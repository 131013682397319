import { ReactElement } from 'react';
import './TwoColumnsRow.scss';

export interface TwoColumnsRowProps {
  children: ReactElement[];
}

const TwoColumnsRow = ({ children: [firstChild, secondChild] }: TwoColumnsRowProps) => (
  <div className='twoColumnRow'>
    <div className='column'>{firstChild}</div>
    <div className='column'>{secondChild}</div>
  </div>
);

export default TwoColumnsRow;
