import * as Http from 'shared/services/httpService';
import { Params, QueryParams, SlugAndTitle, ResponseListBody, Slug, SlugParams } from 'shared/types';
import { PodcastShow } from 'views/shows/PodcastsShowsListView';

const ENDPOINT_URI = 'api/podcast-show';

export const create = (dto: PodcastShow) =>{
  const {payload} = dto;
  return Http.post<Slug>(ENDPOINT_URI, payload);
};

export const save = (dto: PodcastShow) => {
  const {payload} = dto;
  const {slug} = payload as PodcastShow;
  return Http.put(`${ENDPOINT_URI}/${slug}`, payload);
};

export const getAll = (params: Params) => {
  const updatedParams = {
    page: params.page,
    nbElementPerPage: params.nbElementPerPage,
    sort: params.sortPredicate
  };
  return Http.get<ResponseListBody<PodcastShow>>(`${ENDPOINT_URI}s`, {params: updatedParams});
};

export const getOne = (params: SlugParams) => {
  return Http.get<PodcastShow>(`${ENDPOINT_URI}/${params.id}`);
};

export function searchPodcasts(params: QueryParams) {
  return Http.get<SlugAndTitle[]>('api/podcasts/search', { params });
}

const PodcastShowService = {
  create,
  save,
  getAll,
  getOne,
  searchPodcasts,
};

export default PodcastShowService;
