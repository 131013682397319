import { useEffect } from 'react';
import { useWatch } from 'shared/form';

export interface UseRemoveFile {
  toggleName: string;
  filename: string;
  onRemoveFile: (name: string) => void;
}

const useRemoveFile = ({ toggleName, filename, onRemoveFile }: UseRemoveFile) => {
  const toggleValue = useWatch(toggleName);
  
  useEffect(() => {
    if (!toggleValue) {
      onRemoveFile(filename);
    }
  }, [filename, onRemoveFile, toggleValue]);
};

export default useRemoveFile;
