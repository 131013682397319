import classNames from 'classnames';
import 'shared/components/modal/Modal.scss';
import { CopyField } from 'shared/components';
import { i18n } from 'shared/i18n';

export interface GetUrlModalProps {
  onModalClose: () => void;
  newsletterTitle: string;
  newsletterUrl: string;
  platform: string;
  newNewsletterEndpoint: boolean;
}

const GetUrlModal = ({
  onModalClose,
  newsletterTitle,
  newsletterUrl,
  platform,
  newNewsletterEndpoint,
}: GetUrlModalProps) => {
  const url = `${newsletterUrl}${newNewsletterEndpoint
    ? `${encodeURIComponent(`/${new Date().getTime()}${platform}`)}`
    : `/${new Date().getTime()}${platform}`}`;

  return (
    <div>
      <div className='modal-header'>
        <i onClick={() => onModalClose()} className={classNames('icon-delete', 'close-icon')} />
        <span className='modal-header-title-value'>{newsletterTitle}</span>
      </div>
      <div className='modal-content'>
        <CopyField
          value={url}
          btnLabel={i18n('labels.copy')}
        />
      </div>
    </div>
  );
};

export default GetUrlModal;
