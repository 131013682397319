import { ForwardedRef, forwardRef } from 'react';
import { ArticleSectionInput } from 'shared/form/inputs';
import { MenuItems } from 'views/show/constants';
import { SelectOption } from 'shared/types';
import { useSetValue } from 'shared/form';
import { getSubsectors } from 'shared/models';

const PodcastShowArticleSectionInput = forwardRef((_, ref: ForwardedRef<HTMLElement>) => {
  const setValue = useSetValue();

  const handleSectorChange = (value: SelectOption | null) => {
    if (value) {
      setValue('subSector', getSubsectors(value.value)[0]);
      return;
    }
    setValue('subSector', null);
  };

  return (
    <ArticleSectionInput
      required
      ref={ref}
      id={MenuItems.sector.id}
      title={MenuItems.sector.label}
      onChangeSector={handleSectorChange}
      nameSector="sector"
      nameSubSector="subSector"
      nameSubSubSector="subsubSector"
    />
  );
});

export default PodcastShowArticleSectionInput;
