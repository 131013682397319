import { MouseEvent, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { appConfig } from 'shared/config/env';
import './ActionCell.scss';

export interface ActionsCellProps {
  viewName: string;
  preview: string;
  record?: Record<string, unknown>;
  children?: ReactElement;
  editUrl?: string;
  targetDeletedAction?: string;
  targetPreviewAction?: string;
  canDelete?: boolean;
  deleteAction?: (event: MouseEvent<HTMLAnchorElement>) => void;
  copyItem?: () => void;
}

const ActionsCell = ({
  preview,
  viewName,
  record,
  deleteAction,
  children,
  targetDeletedAction,
  targetPreviewAction,
  editUrl,
  copyItem,
  canDelete = true,
}: ActionsCellProps) => (
  <td className="action-cell-root">
    {children}
    <Link className="action-cell-action" to={editUrl || `/${viewName}/${record?.slug}`}>
      <i className={classNames('icon-edit', 'action-cell-icon')} />
    </Link>
    <Link className="action-cell-action" to={preview} target={targetPreviewAction}>
      <i className={classNames('icon-eye', 'action-cell-icon')} />
    </Link>
    {appConfig.duplicate && copyItem && (
      <a className="action-cell-action" onClick={copyItem}>
        <i className={classNames('icon-articles', 'action-cell-icon')} />
      </a>
    )}
    {deleteAction && (
      <a
        className="newsletter-row-action"
        target={targetDeletedAction}
        onClick={canDelete ? deleteAction : (e) => e.preventDefault()}
      >
        <i
          className={classNames('icon-trash', 'action-cell-icon', {
            ['action-cell-icon-disabled']: !canDelete,
          })}
        />
      </a>
    )}
  </td>
);

export default ActionsCell;
