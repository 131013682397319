import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { WorkshopSortingCriteria } from 'shared/models';
import { Pagination } from 'shared/types';
import { useGetList } from 'shared/providers';
import { resources } from 'shared/constants';
import { Workshop } from './types';

export interface UseInteractionWorkshopListViewReturn {
  workshops: Workshop[];
  pagination?: Pagination;
  isLoading: boolean;
  sortPredicate: string;
  openWorkshopDetail: (item: Workshop) => void;
  handlePageChange: (index: number) => void;
  onPredicateChange: (predicate: string) => void;
  goToCreate: () => void;
}

const useInteractionWorkshopListView = (): UseInteractionWorkshopListViewReturn => {
  const history = useHistory();
  const [workshopSort, setWorkshopSort] = useState<string>(WorkshopSortingCriteria.RevCreationDate);

  const { data, pagination, loading, onPageChange } = useGetList<Workshop>(resources.WORKSHOPS, {
    nbElementPerPage: 8,
    sort: workshopSort,
  });

  const goToCreate = () => {
    history.push('/workshop/create');
  };

  const onPredicateChange = (predicate: string) => {
    setWorkshopSort(predicate);
  };

  const openWorkshopDetail = (item: Workshop) => {
    history.push(`/workshop/${item.slug}`);
  };

  return {
    workshops: data,
    pagination,
    isLoading: loading,
    sortPredicate: workshopSort,
    openWorkshopDetail,
    handlePageChange: onPageChange,
    onPredicateChange,
    goToCreate,
  };
};

export default useInteractionWorkshopListView;
