import { forwardRef } from 'react';
import { ArticleSectionInput } from 'shared/form/inputs';
import { CommonFieldProps } from 'shared/form/types';
import useArticleCategoryInput from './useArticleCategoryInput';

export interface ArticleCategoryInputProps extends CommonFieldProps {
  index: number;
}

const ArticleCategoryInput = forwardRef<HTMLElement, ArticleCategoryInputProps>(({
  id,
  title,
  index,
  required = false,
}, ref) => {
  const {
    onChangeSubSector,
    onChangeSector,
    onChangeSubSubSector,
  } = useArticleCategoryInput({ index });

  return (
    <ArticleSectionInput
      id={id}
      title={title}
      required={required}
      ref={ref}
      onChangeSubSector={onChangeSubSector}
      onChangeSector={onChangeSector}
      onChangeSubsubSector={onChangeSubSubSector}
      nameSector={`metadata.categorizations[${index}].sector`}
      nameSubSector={`metadata.categorizations[${index}].subSector`}
      nameSubSubSector={`metadata.categorizations[${index}].subsubSectorDto.name`}
    />
  );
});

export default ArticleCategoryInput;
