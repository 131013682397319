import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { Content } from 'layout/content';
import Header from 'layout/header/Header';
import { DataTable } from 'shared/components';
import { i18n } from 'shared/i18n';
import { HomePageSortingCriteria } from 'shared/models';
import { RowRenderData } from 'shared/components/datatable/DataTable';
import 'layout/header/header.scss';
import './homeListView.scss';
import { HomepageListModel } from './types';
import HomeRow from './row/HomeRow';
import useInteractionHomepageListView from './useInteractionHomepageListView';

const HomeListView = () => {
  const {
    data,
    isLoading,
    sortPredicate,
    deleteHomepage,
    openCreateHomepage,
    openDetailHomepage,
  } = useInteractionHomepageListView();

  const rowRenderer = ({ key, item, onItemDeleted }: RowRenderData<HomepageListModel>) =>
      <HomeRow
        current={item.current}
        key={key}
        item={item}
        onItemDeleted={onItemDeleted}
      />;

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        verticalAlign: 'center',
        textAlign: 'center',
      }}
    >
      <Header>
        <h1 className={classNames('header-title', 'header-title-dark')}>
          <i className={classNames('header-title-icon', 'icon-big', 'icon-articles')} />
          <div className='header-title-text'>{i18n('homes.pageTitle')}</div>
        </h1>
      </Header>
      <Content loading={false}>
        <div className='home-list-create-btn-container'>
          <button type="button" className='home-list-create-btn' onClick={openCreateHomepage}>
            {i18n('homes.createNew')}
          </button>
        </div>
        <DataTable<HomepageListModel>
          sortable
          isLoading={isLoading}
          columns={[
            {
              label: i18n('table.columns.title'),
            },
            {
              label: i18n('table.columns.author'),
            },
            {
              label: i18n('table.columns.creationDate'),
              predicate: HomePageSortingCriteria.CreationDate,
            },
            {
              label: i18n('table.columns.state'),
            },
            {
              label: '',
            },
          ]}
          data={data}
          rowRenderer={rowRenderer}
          selectItem={openDetailHomepage}
          deleteItem={deleteHomepage}
          sortPredicate={sortPredicate}
        />
      </Content>
    </div>
  );
};

export default withRouter(HomeListView);
