import moment from 'moment';
import rosetta from 'rosetta';
import 'moment/locale/fr';
import 'moment/locale/en-gb';

import { appConfig } from '../config/env';
import enTranslations from './en.json';
import frTranslations from './fr.json';

// convert 'en' to 'en-gb'
const locale = appConfig.defaultLocale === 'en' ? 'en-gb' : appConfig.defaultLocale;
const translations = appConfig.defaultLocale === 'en' ? enTranslations : frTranslations;

// use locale data side effect
moment.locale(locale);

const rosettaIntl = rosetta();

rosettaIntl.locale(appConfig.defaultLocale);
rosettaIntl.set(appConfig.defaultLocale, translations);

const notFound = (key: string) => {
  // eslint-disable-next-line no-console
  console.warn(`Translation not found for path '${key}'`);
  return key;
};

export const i18n = <T extends Record<string, unknown> = Record<string, unknown>>(
  key: string,
  params?: T,
  lang?: string,
) => {
  const value = rosettaIntl.t(key, params, lang);
  return value || notFound(key);
};
