import { useCallback, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';
import { i18n } from 'shared/i18n';
import { useGetOne } from 'shared/providers';
import { resources } from 'shared/constants';
import 'shared/style/common/preview.scss';
import { Homepage } from 'views/home/types';
import Section from './Section';

export interface HomePreviewState {
  name: string;
  datetime: string;
}

const HomePreview = ({ history, match }: RouteComponentProps<{ previewUrl: string, slug: string }>) => {
  const [homePreviewState, setHomePreviewState] = useState<HomePreviewState>({
    name: '',
    datetime: '',
  });
  const onSuccess = (data?: unknown) => {
    const homepage = data as Homepage;
    if (homepage) {
      setHomePreviewState({
        name: homepage.name,
        datetime: homepage.scheduledTime.toString(), // FIXME
      });
    }
  };

  useGetOne<Homepage>(resources.NEWS_HOMEPAGE,
    {
      id: match.params.slug,
      onSuccess,
    }
  );

  const openDetailHomepage = useCallback(() => {
    history.push(`/home/${match.params.slug}`);
  }, [history, match]);

  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const { datetime, name } = homePreviewState;

  return (
    <div className='preview'>
      <div className='preview-header'>
        <div className='preview-prev-btn-container'>
          <button type="button" className='preview-prev-btn' onClick={goBack}>
            <i className={classNames('icon-arrow', 'preview-prev-btn-icon')} />
            <span className='preview-prev-btn-text'>{i18n('labels.back')}</span>
          </button>
        </div>
        
        <Section label={i18n('homes.view.titleLabel')} value={name} />
        <Section
          label={i18n('generalSection.publicationDate')}
          value={datetime ? moment(datetime).format('DD/MM/YYYY') : ''}
        />
        <Section
          label={i18n('generalSection.publicationHour')}
          value={datetime ? moment(datetime).format('HH:mm') : ''}
        />
        
        <div className='preview-buttons'>
          <button type="button" className='preview-edit-btn' onClick={openDetailHomepage}>
            <i className={classNames('icon-edit', 'preview-edit-btn-icon')} />
            &nbsp;
          </button>
        </div>
      </div>
      
      <div className='preview-iframe-container'>
        <iframe
          title="preview-homepage"
          className='preview-iframe'
          src={decodeURIComponent(match.params.previewUrl)}
        />
      </div>
    </div>
  );
};

export default withRouter(HomePreview);
