import { i18n } from 'shared/i18n';

export const EventTheme = {
  CommunicationMarketingVente: i18n('events.theme.CommunicationMarketingVente'),
  FinanceComptabilite: i18n('events.theme.FinanceComptabilite'),
  Droit: i18n('events.theme.Droit'),
  RessourcesHumaines: i18n('events.theme.RessourcesHumaines'),
  StrategieOperations: i18n('events.theme.StrategieOperations'),
  DeveloppementPersonnel: i18n('events.theme.DeveloppementPersonnel'),
  ManagementLeadership: i18n('events.theme.ManagementLeadership'),
  WelcomeToLetzerbuerg: i18n('events.theme.WelcomeToLetzerbuerg'),
  SocialEvent: i18n('events.theme.SocialEvent'),
  Actualite: i18n('events.theme.Actualite'),
  InnovationEntrepreneurship: i18n('events.theme.InnovationEntrepreneurship'),
  Art: i18n('events.theme.Art'),
  Famille: i18n('events.theme.Famille'),
};

export const eventThemesAsSelectOptions = Object.entries(EventTheme).map(([key, value]) => ({
  value: key,
  label: value,
}));

export const getEventThemeAsSelectOptionBy = (key: string) =>
  eventThemesAsSelectOptions.find((option) => option.value === key);

export const EventFormat: Record<string, string> = {
  Autre: i18n('events.format.Autre'),
  // CLUB
  DixFoisSix: i18n('events.format.DixFoisSix'),
  DiscoveryDay: i18n('events.format.DiscoveryDay'),
  DelanoLive: i18n('events.format.DelanoLive'),
  BehindTheScene: i18n('events.format.BehindTheScene'),
  Lunch: i18n('events.format.Lunch'),
  Workshop: i18n('events.format.Workshop'),
  Tasting: i18n('events.format.Tasting'),
  NetworkingCircle: i18n('events.format.NetworkingCircle'),
  ClubTalk: i18n('events.format.ClubTalk'),
  Shows: i18n('events.format.Shows'),
  Dejeuners: i18n('events.format.Dejeuners'),
  Webinar: i18n('events.format.Webinar'),
  CEOCocktail: i18n('events.format.CEOCocktail'),
  // NEWS
  Conference: i18n('events.format.Conference'),
  Formation: i18n('events.format.Formation'),
  Inauguration: i18n('events.format.Inauguration'),
  KeynoteSpeech: i18n('events.format.KeynoteSpeech'),
  ThisWeekend: i18n('events.format.ThisWeekend'),
  Seminaire: i18n('events.format.Seminaire'),
  Sorties: i18n('events.format.Sorties'),
  Spectacles: i18n('events.format.Spectacles'),
  RoundTable: i18n('events.format.RoundTable'),
  Awards: i18n('events.format.Awards'),
  VisiteGuidee: i18n('events.format.VisiteGuidee'),
  NewsWorkshop: i18n('events.format.NewsWorkshop'),
};

export const getEventFormatByKey = (key: string): string => EventFormat[key];

export const eventFormatsAsSelectOptions = Object.entries(EventFormat).map(([key, value]) => ({
  value: key,
  label: value,
}));

export const getEventFormatAsSelectOptionBy = (key: string) =>
  eventFormatsAsSelectOptions.find((option) => option.value === key);
