import { cloneElement, ReactElement } from 'react';
import { useInput } from 'shared/form';
import { SelectOption } from 'shared/types';
import WithSlugAndTitle from 'shared/components/form/select/WithSlugAndTitle';

export interface ResponseSlug {
  slug: string;
  metadata: {
    title: string;
  };
}

export interface SlugInputProps {
  name?: string;
  label?: string;
  children: ReactElement;
  required?: boolean;
  resource: string;
  options?: Record<string, unknown>;
}

const SlugInput = ({ resource, children, options = {}, name = '', label = '', required = false }: SlugInputProps) => {
  const input = useInput<string>({ name, defaultValue: '' });

  const onChange = (options: SelectOption) => {
    let slug = '';
    if (options) {
      slug = options.value;
    }

    input.onChange(slug);
  };

  return (
    <WithSlugAndTitle
      {...input}
      slug={input.value || ''}
      resource={resource}
      options={options}
      render={({ value }) =>
        cloneElement(children, {
          ...children.props,
          value,
          label,
          onChange,
          required,
        })}
    />
  );
};

export default SlugInput;
