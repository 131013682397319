import { i18n } from 'shared/i18n';

export const dossierGeneralMenuItem = {
  id: 'general-section',
  label: i18n('dossiers.nav.general'), // 'GENERAL',
};

export const dossierSecteurMenuItem = {
  id: 'dossier-secteur',
  label: i18n('dossiers.nav.sector'), // 'SECTEUR',
};

export const dossierTableRondeMenuItem = {
  id: 'dossier-table',
  label: i18n('dossiers.nav.table'), // 'INTERVIEW',
};

export const dossierQuestionsMenuItem = {
  id: 'dossier-questions',
  label: i18n('dossiers.nav.questions'), // 'QUESTIONS',
};

export const dossierCarteBlancheMenuItem = {
  id: 'dossier-carte',
  label: i18n('dossiers.nav.cart'), // 'CARTES BLANCHES',
};
