import { i18n } from 'shared/i18n';

export const eventFormatMenuItem = {
  id: 'event-format',
  label: i18n('events.nav.eventFormat'),
};

export const eventOratorsMenuItem = {
  id: 'event-orators',
  label: i18n('events.nav.orators'), // 'Orateurs',
};

export const eventTagsMenuItem = {
  id: 'event-tags',
  label: i18n('events.nav.tags'), // 'Tags',
};

export const eventSponsorsGoldMenuItem = {
  id: 'event-gold-sponsors',
  label: i18n('events.nav.goldSponsors'), // 'Gold sponsors',
};

export const eventSponsorsSilverMenuItem = {
  id: 'event-silver-sponsor',
  label: i18n('events.nav.silverSponsor'), // 'Silver Sponsors',
};
export const eventArticlesMenuItem = {
  id: 'event-articles',
  label: i18n('events.nav.associatedArticles'), // 'Articles associés',
};

export const eventEventsMenuItem = {
  id: 'event-events',
  label: i18n('events.nav.associatedEvents'), // 'Événements associés',
};

export const eventPhotosMenuItem = {
  id: 'event-photo',
  label: i18n('events.nav.photo'), // 'Galerie photo',
};

export const eventVideoMenuItem = {
  id: 'event-video',
  label: i18n('events.nav.video'), // 'Vidéo',
};

export const eventIdMenuItem = {
  id: 'event-id',
  label: i18n('events.nav.eventzillaEventId'), // 'Eventzilla event id',
};
