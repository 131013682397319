import { selectSearchHitLabel, selectSearchHitValue } from 'shared/models/response/search';
import { Refetch, SelectOption, SlugAndTitle } from 'shared/types';
import { useInput } from 'shared/form';
import { SelectInputProps } from 'shared/form/types';
import AsyncSelect from 'shared/components/form/select/AsyncSelect';
import { resources } from 'shared/constants';
import { useSearch } from 'shared/providers';

const DossierSelectInput = ({
  label,
  onChange: customChange,
  filter,
  name = '',
  defaultValue,
  required = false,
}: SelectInputProps) => {
  const input = useInput({ name, defaultValue });
  const { refetch } = useSearch<SlugAndTitle[]>(resources.SEARCH_DOSSIER);

  const onChange = (value: SelectOption) => {
    if (customChange) {
      customChange(value);
    }
    input.onChange(value);
  };

  return (
    <AsyncSelect
      searchable
      clearable
      {...input}
      onChange={onChange}
      label={label}
      loadOptions={refetch as Refetch}
      valueAccessor={selectSearchHitValue}
      labelAccessor={selectSearchHitLabel}
      filter={filter}
      required={required}
    />
  );
};

export default DossierSelectInput;
