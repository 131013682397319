import { ActionsCell, DataTableRow, DateFieldCell, TextFieldCell } from 'shared/components/dataTableRow';
import { apiErrorToUiNotifier, COPY_ERROR_MESSAGE, COPY_SUCCESS_MESSAGE, NewsletterService } from 'shared/services';
import { newsletterKindFromString } from 'shared/models';
import { i18n } from 'shared/i18n';
import { useCreate, useGetOne } from 'shared/providers';
import { resources } from 'shared/constants';
import { getNewsletterType, resourceNewsletters } from 'shared/utils';
import { apiConfig, appConfig, REST } from 'shared/config/env';
import transform from 'views/newsletter/transform';
import { NewsletterDetail } from 'views/newsletter/types';
import { reformattingCopyValue } from 'views/newsletter/state';
import { Newsletter } from 'views/newsletters/NewsletterListView';
import './newsletterRow.scss';

export interface NewsletterRowProps {
  item: Newsletter;
  onItemDeleted?: (item: Newsletter) => void;
  onCopySuccess: () => void;
}

const NewsletterRow = ({ item, onItemDeleted, onCopySuccess }: NewsletterRowProps) => {
  const enSuffix = item.title.indexOf('_en_') >= 0 ? '_en' : '';
  const kind = item.kind.toLowerCase();
  const typeWithoutClubEn = kind === 'club_en' ? 'club' : kind;
  const newsletterType = getNewsletterType(typeWithoutClubEn);

  const encodedUrl = encodeURIComponent(
    `${apiConfig.newsletterEndpointUrl}/api/${newsletterType}/${item.id}?ts=${new Date().getTime()}`,
  );

  const newUrl = `/newsletter/preview/${kind}${enSuffix}/${item.id}/${encodedUrl}`;

  const deleteNewsletter = () => {
    if (confirm(i18n('newsletters.messages.confirmDelete'))) {
      NewsletterService.deleteNewsletter(kind, item.id).then(() => {
        if (onItemDeleted && typeof onItemDeleted === 'function') {
          onItemDeleted(item);
        }
      });
    }
  };
  const { refetch } = useGetOne(resources.NEWSLETTERS, {
    allowedRequest: false,
  });
  const { create } = useCreate(resources.NEWSLETTERS, {}, COPY_SUCCESS_MESSAGE, COPY_ERROR_MESSAGE);

  const copyNewsletter = () => {
    const type = kind;
    refetch({
      id: item.id,
      additionalResource: 'newsletter',
      kind: typeWithoutClubEn,
    })
      .then((data: unknown) => {
        if (data) {
          const newsletter = data as NewsletterDetail;
          const payload = {
            ...(appConfig.queryType === REST ? newsletter : transform(newsletter, type)),
            kind: type.toLowerCase(),
          };
          const { content, platformName, publicationDate, title } = (reformattingCopyValue(payload, type) ||
            {}) as Record<string, unknown>;
          const resource = resourceNewsletters.getCreateNewsletterResource(typeWithoutClubEn);
          return create({
            kind: typeWithoutClubEn,
            additionalResource: resource,
            payload: { content, platformName, publicationDate, title, kind: typeWithoutClubEn },
            onSuccess: onCopySuccess,
          });
        }
        apiErrorToUiNotifier(COPY_ERROR_MESSAGE)();
      })
      .catch(apiErrorToUiNotifier(COPY_ERROR_MESSAGE));
  };

  return (
    <DataTableRow<Newsletter> record={item}>
      <TextFieldCell className="newsletter-row-title" source="title" />
      <DateFieldCell source="publicationDate" />
      <TextFieldCell source="kind" format={newsletterKindFromString} />
      <DateFieldCell source="creationDate" />
      <ActionsCell
        copyItem={copyNewsletter}
        deleteAction={deleteNewsletter}
        targetDeletedAction="_blank"
        viewName="newsletter"
        preview={newUrl}
        editUrl={`/newsletter/${kind}${enSuffix}/${item.id}`}
      />
    </DataTableRow>
  );
};

export default NewsletterRow;
