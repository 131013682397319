import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { NavScrollMenu } from 'layout/aside/nav/scrollMenu';
import { RouteStore } from 'shared/stores/routeStore';
import './Nav.scss';
import GlobalNav from './GlobalNav';
import toScrollMenuForRoute from './toScrollMenuForRoute';

export interface NavProps extends RouteComponentProps {
  routeStore: RouteStore;
}

const Nav = ({ routeStore }: NavProps) => {
  const { currentRoute, isItemRoute } = routeStore;

  return (
    <nav className='nav'>
      {isItemRoute ? (
        <NavScrollMenu items={toScrollMenuForRoute(currentRoute)} />
      ) : (
        <GlobalNav routeStore={routeStore} />
      )}
    </nav>
  );
};

export default withRouter(Nav);
