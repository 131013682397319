import { useCallback, useMemo } from 'react';
import ReactAsyncSelect from 'react-select/async';
import ReactAsyncCreatableSelect  from 'react-select/async-creatable';
import { useSearch } from 'shared/providers';
import { resources } from 'shared/constants';
import { SelectOption, Topic } from 'shared/types';
import 'shared/components/form/tagSelect/TagSelect.scss';
import TagItem from 'shared/components/form/tagSelect/item/TagItem';

export interface TopicSelectProps {
  values: SelectOption[];
  onChange: (value: SelectOption[]) => void;
  disabled?: boolean;
  creatable?: boolean;
  optionsNumberLimit?: number;
  label?: string;
}

export const TopicSelect = ({
  values,
  onChange,
  disabled,
  creatable,
  optionsNumberLimit,
  label,
}: TopicSelectProps) => {
  const { refetch } = useSearch<Topic[]>(resources.TOPICS);
  const loadOptions = useCallback((input) => {
    const params = input !== '' ? { q: input } : {};
    return refetch<Topic[]>({
      ...params,
      additionalResource: resources.SEARCH_TOPICS,
    }).then((response) =>
      response.map((topic: Topic) => ({
        value: topic.name,
        label: topic.name,
      })),
    );
  }, [refetch]);

  const removeTag = useCallback((tag) => {
    onChange(values.filter((selectValue) => selectValue.label !== tag));
  }, [onChange, values]);

  const onChangeComp = useCallback((value) => {
    const active = document.activeElement;
    const isFull = optionsNumberLimit && values.length >= optionsNumberLimit;
    if (!isFull) {
      onChange(value);
    }
    if (active) {
      (active as HTMLElement).blur();
    }
  }, [onChange, optionsNumberLimit, values]);

  const ReactSelectComp = creatable ? ReactAsyncCreatableSelect : ReactAsyncSelect;
  const noOptionsMessage = useMemo(() => () => 'Aucun résultat', []);

  return (
    <div className='tag-select'>
      <span className='tag-select-title'>
        {(label || 'topics') + (optionsNumberLimit ? ` (maximum ${optionsNumberLimit})` : '')}
      </span>
      <ReactSelectComp
        loadOptions={loadOptions}
        value={values}
        onChange={onChangeComp}
        components={{
          MultiValue: (props) => <TagItem {...props} onRemoveTag={removeTag} />,
        }}
        noOptionsMessage={noOptionsMessage}
        styles={{ menu: (styles) => Object.assign(styles, { zIndex: 999999 }) }}
        defaultOptions
        cacheOptions={false}
        isDisabled={disabled}
        isMulti
      />
    </div>
  );
};
