import { i18n } from 'shared/i18n';
import moment from 'moment';

const config = {
  previousMonth: i18n('datePicker.previousMonth'),
  nextMonth: i18n('datePicker.nextMonth'),
  months: moment.months(),
  weekdays: moment.weekdays(),
  weekdaysShort: moment.weekdaysShort(),
};

export default config;
