import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Form } from 'shared/form';
import { ArrayInput, FileInput, GeneralInput, SlugInput, TextAreaInput } from 'shared/form/inputs';
import DateInput from 'shared/form/inputs/DateInput';
import { ActionsBar, ArticleSelect, EventSelect } from 'shared/components';
import { SelectOption } from 'shared/types';
import { i18n } from 'shared/i18n';
import { FormMode } from 'shared/models';
import FormHeader from 'shared/components/header/FormHeader';
import { Content, FormSection } from 'layout/content';
import useGetSectionRef from 'layout/ScrollspyProvider/useGetSectionRef';
import './WorkshopView.scss';
import * as workshopConstants from './constants';
import useInteractionWorkshopView from './useInteractionWorkshopView';
import { resources } from 'shared/constants';
import SponsorSelect from 'shared/components/form/select/SponsorSelect';
import { appConfig, REST } from 'shared/config/env';

const WORKSHOP_DAY_FILTER = 'format:workshop';

const WorkshopView = ({ match }: RouteComponentProps<{ slug: string }>) => {
  const sectionRefs = useGetSectionRef();

  const { onSubmit, validate, workshopView, loading, mode, publish, openPreview, onFileSelect, onRemoveFile } =
    useInteractionWorkshopView(match);

  const isREST = appConfig.queryType === REST;
  const sponsorAdditionalResource = isREST ? resources.SPONSORS_SEARCH : resources.ORGANISATION_BY_SLUG;

  return (
    <Form onSubmit={onSubmit} validate={validate} initialValues={workshopView}>
      <FormHeader
        label={
          match.path === '/workshop/create' ? i18n('workshops.view.createTitle') : i18n('workshops.view.updateTitle')
        }
      />

      <Content loading={loading}>
        <div className="workshop-content">
          <div className="workshop-sections">
            <GeneralInput isoFormat ref={sectionRefs['general-section']} title={workshopView.title} nameTitle="title" />
            <FormSection
              ref={sectionRefs[workshopConstants.workshopIntroMenuItem.id]}
              title={workshopConstants.workshopIntroMenuItem.label}
              id={workshopConstants.workshopIntroMenuItem.id}
            >
              <FileInput
                onFileSelect={onFileSelect}
                onRemoveFile={onRemoveFile}
                name="mainImage.filename"
                uploadUrl="/api/workshop/image"
                required
                label={i18n('labels.image')}
              />

              <TextAreaInput
                required
                width="100%"
                rows={10}
                height="70px"
                name="description.chapeau"
                label={i18n('workshops.view.header')}
              />

              <TextAreaInput
                required
                width="100%"
                label={i18n('labels.text')}
                rows={20}
                height="140px"
                name="description.slugline"
              />

              <DateInput required isoFormat label="workshops.view.date" name="practicalInfo.beginDate" />
            </FormSection>

            <FormSection
              ref={sectionRefs[workshopConstants.workshopSponsorsMenuItem.id]}
              title={i18n('workshops.view.sponsorsGoldTitle')}
              id={workshopConstants.workshopSponsorsMenuItem.id}
            >
              <ArrayInput<SelectOption> name="eventSponsors.sponsorsGold" label="workshops.view.sponsorsGold">
                <SlugInput resource={resources.SPONSOR} options={{ additionalResource: sponsorAdditionalResource }}>
                  <SponsorSelect />
                </SlugInput>
              </ArrayInput>
            </FormSection>

            <FormSection
              title={i18n('workshops.view.sponsorsSilverTitle')}
              id={workshopConstants.workshopSponsorsMenuItem.id}
            >
              <ArrayInput<SelectOption> name="eventSponsors.sponsorsSilver" label="workshops.view.sponsorSilver">
                <SlugInput resource={resources.SPONSOR} options={{ additionalResource: sponsorAdditionalResource }}>
                  <SponsorSelect />
                </SlugInput>
              </ArrayInput>
            </FormSection>

            <FormSection
              ref={sectionRefs[workshopConstants.workshopProgrammeMenuItem.id]}
              title={i18n('workshops.view.morningProgram')}
              id={workshopConstants.workshopProgrammeMenuItem.id}
            >
              <ArrayInput<SelectOption> label="workshops.view.workshop" name="morning" required>
                <SlugInput resource={resources.EVENTS} options={{ additionalResource: resources.EVENT }}>
                  <EventSelect filter={WORKSHOP_DAY_FILTER} />
                </SlugInput>
              </ArrayInput>
            </FormSection>

            <FormSection title={i18n('workshops.view.afternoonProgram')} id="workshop-pm">
              <ArrayInput<SelectOption> label="workshops.view.workshop" name="afternoon" required>
                <SlugInput resource={resources.EVENTS} options={{ additionalResource: resources.EVENT }}>
                  <EventSelect filter={WORKSHOP_DAY_FILTER} required />
                </SlugInput>
              </ArrayInput>
            </FormSection>

            <FormSection
              ref={sectionRefs[workshopConstants.workshopArticlesMenuItem.id]}
              title={workshopConstants.workshopArticlesMenuItem.label}
              id={workshopConstants.workshopArticlesMenuItem.id}
            >
              <ArrayInput<SelectOption> label="workshops.view.linkedArticle" name="linkedContent.articles">
                <SlugInput
                  resource={resources.ARTICLES}
                  options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}
                >
                  <ArticleSelect platformName={appConfig.platformName} />
                </SlugInput>
              </ArrayInput>
            </FormSection>

            <FormSection
              ref={sectionRefs[workshopConstants.workshopEvenementsMenuItem.id]}
              title={workshopConstants.workshopEvenementsMenuItem.label}
              id={workshopConstants.workshopEvenementsMenuItem.id}
            >
              <ArrayInput<SelectOption> label="workshops.view.linkedEvent" name="linkedContent.events">
                <SlugInput resource={resources.EVENTS} options={{ additionalResource: resources.EVENT }}>
                  <EventSelect filter={WORKSHOP_DAY_FILTER} />
                </SlugInput>
              </ArrayInput>
            </FormSection>
          </div>

          <ActionsBar
            canUpdate={mode === FormMode.EDIT}
            canPublish={mode === FormMode.CREATE}
            canPreview={mode === FormMode.EDIT}
            publish={publish}
            preview={openPreview}
          />
        </div>
      </Content>
    </Form>
  );
};

export default withRouter(WorkshopView);
