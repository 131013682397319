import * as Http from 'shared/services/httpService';
import { Params, ResponseListBody, Slug } from 'shared/types';
import { CreateParams, UpdateParams } from 'shared/providers';
import { NewsletterDetail } from 'views/newsletter/types';
import { Newsletter } from 'views/newsletters/NewsletterListView';
import { GetOneParams } from 'shared/providers/dataProvider/types';

export function getNewsletters(params: Params) {
  return Http.get<ResponseListBody<Newsletter>>('api/newsletters', { params });
}

export function getNewsletter(payload: GetOneParams<Newsletter>) {
  return Http.get<NewsletterDetail>(`api/newsletter/${payload.kind}/${payload.id}`);
}

export function deleteNewsletter(kind: string, id: string) {
  return Http.del(`api/newsletter/${kind}/${id}`);
}

export function postNewsletter(params: CreateParams) {
  return Http.post<Slug>(`api/newsletter/${params.kind}`, params.payload);
}

export function updateNewsletter (params: UpdateParams) {
  return Http.put(`api/newsletter/${params.kind}/${params.id}`, params.payload);
}

const NewsletterService = {
  getNewsletter,
  getNewsletters,
  deleteNewsletter,
  postNewsletter,
  updateNewsletter,
};

export default NewsletterService;
