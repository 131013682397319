import { ChangeEvent, useMemo } from 'react';
import { CommonFieldPropsWithoutChange } from 'shared/form/types';
import './styles/RadioInput.scss';

export interface RadioInputProps extends CommonFieldPropsWithoutChange {
  notChecked?: boolean;
  value: string;
  isActive: boolean;
  onChange: (value: ChangeEvent<HTMLInputElement>) => void;
}

const RadioInput = ({ label, isActive, onChange, value, notChecked = false }: RadioInputProps) => {
  const renderClassLabel = useMemo(() =>
    isActive && !notChecked
      ? 'radio-input_label radio-input_label-checked'
      : 'radio-input_label',
    [isActive, notChecked],
  );

  return (
    <label className={renderClassLabel}>
      {label}
      <input
        onChange={onChange}
        type="radio"
        value={value}
        className='radio-input_item'
        checked={isActive}
      />
    </label>
  );
};

export default RadioInput;
