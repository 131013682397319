import { useEffect, useState } from 'react';
import { apiErrorToUiNotifier, FETCH_ERROR_MESSAGE } from 'shared/services';
import { MagazineHomepageData, Supplement } from './MagazineHomepageView';
import { useUpdate } from 'shared/providers';
import { useGetOne } from 'shared/providers';
import { resources } from 'shared/constants';

const EMPTY_SUPPLEMENT: Supplement = {
  title: '',
  uri: '',
};

export interface UseInteractionMagazineHomepageViewReturn {
  onSubmit: (value: Record<string, unknown>) => void;
  magazineHomepageData: MagazineHomepageData;
  loading: boolean;
}

const useInteractionMagazineHomepageView = (): UseInteractionMagazineHomepageViewReturn => {
  const [magazineHomepageData, setMagazineHomepageData] = useState<MagazineHomepageData>({
    magazine: EMPTY_SUPPLEMENT,
    supplement1: undefined,
    supplement2: undefined,
  });
  const { update } = useUpdate<MagazineHomepageData>(resources.MAGAZINES, {
    additionalResource: resources.UPDATE_MAGAZINE,
  });

  const { data, error, loading } = useGetOne<MagazineHomepageData>(resources.MAGAZINES, {
    additionalResource: resources.MAGAZINE,
  });

  const onSubmit = (value: Record<string, unknown>) => {
    const magazineHomepageInput = value as MagazineHomepageData;
    const payload: MagazineHomepageData = {
      magazine: magazineHomepageInput.magazine,
      supplement1: magazineHomepageInput.supplement1 || EMPTY_SUPPLEMENT,
      supplement2: magazineHomepageInput.supplement2 || EMPTY_SUPPLEMENT,
    };

    update({
      payload,
    });
  };

  useEffect(() => {
    if (data) {
      setMagazineHomepageData({
        ...data,
        supplement1: data.supplement1 ?? undefined,
        supplement2: data.supplement2 ?? undefined,
      });
    } else if (error) {
      apiErrorToUiNotifier(FETCH_ERROR_MESSAGE);
    }
  }, [data, error]);

  return {
    onSubmit,
    magazineHomepageData,
    loading,
  };
};

export default useInteractionMagazineHomepageView;
