import { useCallback } from 'react';
import { getSubsectorByKey, getSubsectors, getSubsubSectors } from 'shared/models';
import { SelectOption } from 'shared/types';
import { useSetValue } from 'shared/form';

export interface UseArticleCategoryInputReturn {
  onChangeSector: (value: SelectOption) => void;
  onChangeSubSector: (value: SelectOption) => void;
  onChangeSubSubSector: (value: SelectOption, subsector?: string) => void;
}

export interface UseArticleCategoryInputProps {
  index: number;
}

const useArticleCategoryInput = ({
  index,
}: UseArticleCategoryInputProps): UseArticleCategoryInputReturn => {
  const setValue = useSetValue();

  const onChangeSector = useCallback((sector) => {
    const subSector = sector ? getSubsectors(sector.value)[0].value : undefined;
    setValue(`metadata.categorizations[${index}].subSector`, subSector
        ? { value: subSector, label: getSubsectorByKey(sector.value, subSector)}
        : null
    );
  }, [index, setValue]);

  const onChangeSubSector = useCallback((subSector) => {
    const subsubSectorDto = subSector
      ? getSubsubSectors(subSector.value).length
        ? getSubsubSectors(subSector.value)[0]
        : null
      : null;

    setValue(`metadata.categorizations[${index}].subsubSectorDto.name`,
      subsubSectorDto ? subsubSectorDto : null,
    );
  }, [index, setValue]);

  const onChangeSubSubSector = useCallback((subSubSector, subsector) => {
    setValue(`metadata.categorizations[${index}].subsubSectorDto`, {
      slug: subSubSector.value,
      name: subSubSector,
      subsector,
    });
  }, [index, setValue]);

  return {
    onChangeSector,
    onChangeSubSector,
    onChangeSubSubSector,
  };
};

export default useArticleCategoryInput;
