import { DocumentNode, gql } from '@apollo/client';
import { appConfig } from 'shared/config/env';

const retrieve = (): DocumentNode =>
  gql`
    query {
      scheduledClubHomepage (
        platformName: ${appConfig.platformName}
      ) {
          ...on ScheduledClubHomepage {
            slug
            events
            at
            createdAt
            platformName
          }
        }
      }
  `;

const create = (): DocumentNode =>
  gql`
     mutation CreateClubHomepage(
      $createData: ScheduledClubHomepageUpdateInput!
     ) {
      updateScheduledClubHomepage(updateData: $createData) {
        ... on ScheduledClubHomepage {
          slug
          events
          at
          createdAt
          platformName
        }
        ... on ErrorPayload {
          traceId
          errorMessage
          validationErrors {
            code
            message
          }
        }
      }
    }
  `;

const update = (): DocumentNode =>
  gql`
     mutation UpdateClubHomepage(
      $updateData: ScheduledClubHomepageUpdateInput!
     ) {
      updateScheduledClubHomepage(updateData: $updateData) {
        ... on ScheduledClubHomepage {
          slug
          events
          at
          createdAt
          platformName
        }
        ... on ErrorPayload {
          traceId
          errorMessage
          validationErrors {
            code
            message
          }
        }
      }
    }
  `;



const clubHomeGraphqlServices = {
  retrieve,
  update,
  create,
};

export default clubHomeGraphqlServices;