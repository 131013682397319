import { ForwardedRef, forwardRef } from 'react';
import { i18n } from 'shared/i18n';
import {
  TopicSelectInput,
  SimpleInput,
  ToggleInput,
  DnDArrayInput,
  ArticleSelectInput,
} from 'shared/form/inputs';
import { FormSection } from 'layout/content';
import 'views/home/homeDetail.scss';
import * as constants from 'views/home/constants';
import { SelectOption } from 'shared/types';

const FocusAreaSectionInput = forwardRef((_, ref: ForwardedRef<HTMLElement>) => {
  const required = (index: number) => index < 2;

  return (
    <FormSection ref={ref} title={constants.HomeFocusAreaLabel} id={constants.HomeFocusAreaId}>
      <ToggleInput id="toggler-focus-area" name="focusArea.isActive">
        <div className='home-detail-general'>
          <SimpleInput required width="100%" label="Titre" name="focusArea.title" />
          <DnDArrayInput<SelectOption>
            numOfItems={5}
            required={required}
            name="focusArea.articles"
            label="labels.article"
          >
            <ArticleSelectInput />
          </DnDArrayInput>
          <TopicSelectInput
            name="focusArea.lirePlus"
            label={i18n('homes.view.readMore')}
            optionsNumberLimit={1}
          />
        </div>
      </ToggleInput>
    </FormSection>
  );
});

export default FocusAreaSectionInput;
