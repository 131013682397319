import { MouseEvent, useRef, useState } from 'react';
import {
  uiNotifier,
  INCOMPLETE_FORM_ERROR_MESSAGE,
} from 'shared/services';
import { setErrors } from 'shared/form/utils';
import { useGetOne, useUpdate } from 'shared/providers';
import { resources } from 'shared/constants';
import { formattedClubHomeValue } from 'views/clubHome/utils';
import { ClubHomepage, ClubHomepageState } from './types';

const QUANTITIES_EVENT = 3;

export interface UseInteractionClubHomeDetailsViewReturn {
  clubHomepage: Partial<ClubHomepage>;
  validate: (value: Record<string, unknown>) => Record<string, unknown>;
  onModalClose: () => void;
  onPublish: (event: MouseEvent<HTMLElement>, value?: unknown) => void;
  onSubmit: (value: Record<string, unknown>) => void;
  loading: boolean;
  publicationLoading: boolean;
  isPublishModalOpen: boolean;
  setPublicationState: (data: PublishData) => void;
  inputClubHomepageData?: ClubHomepage;
}

export interface PublishData {
  at?: Date | string;
  slug?: string;
}

const useInteractionClubHomeDetailsView = (): UseInteractionClubHomeDetailsViewReturn => {
  const { update } = useUpdate<ClubHomepage>(resources.CLUB_HOMEPAGE);
  const { data, loading } = useGetOne<Partial<ClubHomepage>>(resources.CLUB_HOMEPAGE, {
    id: 'temporaryFakeSlug',
  });

  const publishData = useRef<PublishData>({});
  const inputClubHomepageData = useRef<ClubHomepage>();
  const [clubHomepageState, setClubHomepageState] = useState<ClubHomepageState>({
    publicationLoading: false,
    isPublishModalOpen: false,
  });

  const onModalClose = () => {
    setClubHomepageState({
      publicationLoading: false,
      isPublishModalOpen: false,
    });
  };
  
  const onPublish = (event: MouseEvent<HTMLElement>, value?: unknown) => {
    inputClubHomepageData.current = formattedClubHomeValue(value as ClubHomepage);
    setClubHomepageState({
      publicationLoading: false,
      isPublishModalOpen: true,
    });
  };

  const onSubmit = (value: Record<string, unknown>) => {
    const inputClubHomepage = value as ClubHomepage;
    if (inputClubHomepage.slug) {
      const clubHomeValue = formattedClubHomeValue(inputClubHomepage);
      update({
        additionalResource: resources.UPDATE_SCHEDULED_CLUB_HOMEPAGE,
        id: clubHomeValue.slug,
        payload: clubHomeValue,
      });
    } else {
      uiNotifier('error', INCOMPLETE_FORM_ERROR_MESSAGE);
    }
  };

  const setPublicationState = (data: PublishData) => {
    publishData.current = data;
    setClubHomepageState((prevState) => ({
      ...prevState,
      isPublishModalOpen: false,
      publicationLoading: false,
    }));
  };

  const validate = (value: Record<string, unknown>) => {
    const errors = {};
    const inputClubHome = value as ClubHomepage;
    if (inputClubHome.events.every((event) => event === null)) {
      setErrors(errors, 'events', 'the event cannot be empty');
    }

    if (inputClubHome.events.length !== QUANTITIES_EVENT) {
      setErrors(errors, 'events', `the size of the events must be ${QUANTITIES_EVENT}`);
    }
    return errors;
  };

  return {
    clubHomepage: data && Object.keys(data).length ? { ...data, ...publishData.current } : { events: [] },
    validate,
    onModalClose,
    onPublish,
    onSubmit,
    loading,
    publicationLoading: clubHomepageState.publicationLoading,
    isPublishModalOpen: clubHomepageState.isPublishModalOpen,
    setPublicationState,
    inputClubHomepageData: inputClubHomepageData.current,
  };
};

export default useInteractionClubHomeDetailsView;
