import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { Content } from 'layout/content';
import Header from 'layout/header/Header';
import 'layout/header/header.scss';
import { i18n } from 'shared/i18n';
import { DataTable, Pagination } from 'shared/components';
import { PodcastShowSortingCriteria } from 'shared/models';
import { BasePodcastShow } from 'shared/types';
import { RowRenderData } from 'shared/components/datatable/DataTable';
import '../workshops/workshopListView.scss';
import Row from './row/PodcastShowRow';
import './podcastShowList.scss';
import useInteractionPodcastShowsListView from './useInteractionPodcastShowsListView';

export interface PodcastShow extends BasePodcastShow {
  podcastEpisodes: string[];
  sector?: string;
  subSector?: string;
}

const PodcastShowsListView = () => {
  const {
    list,
    pagination,
    isLoading,
    sortPredicate,
    goToCreate,
    onPredicateChange,
    openPodcastShowDetail,
    handlePageChange,
  } = useInteractionPodcastShowsListView();

  const rowRenderer = ({ item }: RowRenderData<PodcastShow>) => <Row item={item} key={item.slug} />;

  return (
    <div className='podcast-shows-list-root'>
      <Header>
        <h1 className={classNames('header-title', 'header-title-dark')}>
          <i className={classNames('header-title-icon', 'icon-big', 'icon-articles')} />
          <div className='header-title-text'>{i18n('podcastShows.title')}</div>
        </h1>
      </Header>
      <Content loading={isLoading}>
        <div className='workshop-list-create-btn-container'>
          <button type="button" className='workshop-list-create-btn' onClick={goToCreate}>
            {i18n('podcastShows.createNew')}
          </button>
        </div>
        <DataTable<PodcastShow>
          sortable
          sortByColumn={onPredicateChange}
          sortPredicate={sortPredicate}
          columns={[
            { label: '' },
            {
              label: i18n('table.columns.title'),
              predicate: PodcastShowSortingCriteria.Title,
            },
            {
              label: i18n('table.columns.creationDate'),
              predicate: PodcastShowSortingCriteria.CreationDate,
            },
            {
              label: i18n('table.columns.publicationDate'),
              predicate: PodcastShowSortingCriteria.PublicationDate,
            },
            { label: '' },
          ]}
          data={list}
          isLoading={isLoading}
          selectItem={openPodcastShowDetail}
          rowRenderer={rowRenderer}
        />
        {pagination && (
          <Pagination
            buttonsRange={3}
            onPageChange={handlePageChange}
            currentPage={pagination.page}
            lastPage={pagination.lastPage}
          />
        )}
      </Content>
    </div>
  );
};

export default withRouter(PodcastShowsListView);
