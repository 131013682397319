import { memo, MouseEvent } from 'react';
import classNames from 'classnames';
import { useGetValues, useFormValid, useFormDirty } from 'shared/form';
import { i18n } from 'shared/i18n';
import { Show } from 'shared/components/utils/Displays';
import './ActionBar.scss';

export interface ActionsBarProps {
  canPreview?: boolean;
  canPublish?: boolean;
  canUpdate?: boolean;
  customPublishLabel?: string;
  customPublishIcon?: string;
  preview?: (value?: unknown) => void;
  publish?: (event: MouseEvent<HTMLElement>, value?: unknown) => void;
  formStateDependent?: boolean;
}

const ActionsBar = memo(({
  customPublishLabel,
  customPublishIcon,
  preview: onPreview,
  publish: onPublish,
  canPreview = false,
  canPublish = false,
  canUpdate = false,
  formStateDependent = false,
}: ActionsBarProps) => {
  const isFormValid  = useFormValid();
  const isFormDirty = useFormDirty();
  const valueForm = useGetValues();

  const publish = (event: MouseEvent<HTMLElement>) => {
    if (onPublish) {
      onPublish(event, valueForm);
    }
  };

  const preview = () => {
    if (onPreview) {
      onPreview(valueForm);
    }
  };

  return (
    <div className='actions-bar'>
      <Show when={canUpdate}>
        <button
          disabled={!isFormValid}
          className='actions-bar-button'
          type="submit"
        >
          <i className={classNames('icon-reload', 'actions-bar-button-ico')} />
          1. {i18n('actionBar.save')}
        </button>
      </Show>
      <Show when={canPreview && formStateDependent ? !isFormDirty : canPreview}>
        <button
          type="button"
          disabled={!isFormValid}
          title={i18n('actionBar.previewInfo')}
          onClick={preview}
          className='actions-bar-button'
        >
          <i className={classNames('icon-eye', 'actions-bar-button-ico')} />
          2. {i18n('actionBar.preview')}
        </button>
      </Show>
      <Show when={canPublish}>
        <button
          type="button"
          disabled={!isFormValid}
          className={classNames('actions-bar-button', 'actions-bar-button-validate')}
          onClick={publish}
        >
          <i className={classNames(customPublishIcon || 'icon-paper-plane', 'actions-bar-button-ico')} />
          {customPublishLabel || `3. ${i18n('actionBar.publish')}`}
        </button>
      </Show>
    </div>
  );
});

export default ActionsBar;
