import { ReactNode } from 'react';
import classnames from 'classnames';
import { useInput } from 'shared/form/index';
import { CommonFieldProps, InputProps } from 'shared/form/types';
import { appConfig } from 'shared/config/env';
import './Toggler.scss';

export interface ToggleInputProps extends InputProps<boolean>, CommonFieldProps {
  children?: ReactNode;
}

export const ToggleInput = ({ id, name, defaultValue = false, children }: ToggleInputProps) => {
  const input = useInput({ name, defaultValue });
  const lang = appConfig.defaultLocale;

  return (
    <div className='toggle-wrapper'>
      <input
        id={id}
        name={input.name}
        type="checkbox"
        checked={!!input.value}
        className='toggle-input'
        onChange={input.onChange}
      />
      <label className={classnames(['toggle-label', { ['toggle-english']: lang === 'en' }])} htmlFor={id}>
        <span className='toggle-span' />
      </label>
      {input.value && children && <div className='toggle-content'>{children}</div>}
    </div>
  );
};

export default ToggleInput;
