import { i18n } from 'shared/i18n';
import { PlatformNameService } from 'shared/services';

export const SubsectorPolitiqueInstitutions = {
  Politique: i18n('subSector.politique'),
  Institutions: i18n('subSector.institutions'),
  Justice: i18n('subSector.justice'),
  Education: i18n('subSector.education'),
  Europe: i18n('subSector.europe'),
  Monde: i18n('subSector.world'),
  Economie: i18n('subSector.economy'),
};

export const SubsectorEntreprisesStrategies = {
  FinanceLegal: i18n('subSector.financeLegal'),
  ServicesConseils: i18n('subSector.servicesAdvice'),
  Technologies: i18n('subSector.technologies'),
  Industrie: i18n('subSector.industry'),
  Immobilier: i18n('subSector.immovable'),
  Artisanat: i18n('subSector.arts'),
  Commerce: i18n('subSector.trade'),
};

export const SubsectorPlaceFinanciereMarches = {
  Banques: i18n('subSector.banks'),
  Fonds: i18n('subSector.funds'),
  Assurances: i18n('subSector.assurances'),
  WealthManagement: i18n('subSector.wealthManagement'),
  PrivateEquity: i18n('subSector.privateEquity'),
  Fintech: i18n('subSector.fintech'),
  MarchesFinanciers: i18n('subSector.financialMarkets'),
};

export const SubsectorCommunautesExpertisesPaperjam = {
  PlaceFinanciere: i18n('subSector.placeFinanciere'),
  RealEstate: i18n('subSector.realEstate'),
  TransformationDigitale: i18n('subSector.technologies'),
  Legal: i18n('subSector.legal'),
  AdministrationFinance: i18n('subSector.administrationFinance'),
  Management: i18n('subSector.management'),
  Communication: i18n('subSector.communication'),
  Mouvements: i18n('subSector.movements'),
  RessourcesHumaines: i18n('subSector.hr'),
  Durabilite: i18n('subSector.durabilite'),
  OutilsCompetences: i18n('subSector.outilsCompetences'),
  CommuniquesDePresse: i18n('subSector.communiquesdepresse'),
  Expertises: i18n('subSector.expertises'),
};

export const SubsectorCommunautesExpertisesDelano = {
  PlaceFinanciere: i18n('subSector.placeFinanciere'),
  RealEstate: i18n('subSector.realEstate'),
  TransformationDigitale: i18n('subSector.technologies'),
  Legal: i18n('subSector.legal'),
  AdministrationFinance: i18n('subSector.administrationFinance'),
  Management: i18n('subSector.management'),
  Communication: i18n('subSector.communication'),
  Mouvements: i18n('subSector.movements'),
  RessourcesHumaines: i18n('subSector.hr'),
  Sustainability: i18n('subSector.sustainability'),
  ToolsSkills: i18n('subSector.toolsSkills'),
  CommuniquesDePresse: i18n('subSector.communiquesdepresse'),
  Knowledge: i18n('subSector.knowledge'),
};

export const SubsectorLifestyleViePratiquePaperjam = {
  Foodzilla: i18n('subSector.foodzilla'),
  FoodzillaGuide: i18n('subSector.foodzillaGuide'),
  Sorties: i18n('subSector.culture'),
  BienEtre: i18n('subSector.wellBeing'),
  Style: i18n('subSector.style'),
  Habitat: i18n('subSector.habitat'),
  Voyages: i18n('subSector.travels'),
  Techno: i18n('subSector.tech'),
  Drive: i18n('subSector.drive'),
  Argent: i18n('subSector.argent'),
  Carriere: i18n('subSector.career'),
  Mobilité: i18n('subSector.mobility'),
  Concours: i18n('subSector.contest'),
};
export const SubsectorLifestyleViePratiqueDelano = {
  Foodzilla: i18n('subSector.foodzilla'),
  FoodzillaGuide: i18n('subSector.foodzillaGuide'),
  Sorties: i18n('subSector.culture'),
  BienEtre: i18n('subSector.wellBeing'),
  Style: i18n('subSector.style'),
  Habitat: i18n('subSector.habitat'),
  Voyages: i18n('subSector.travels'),
  Techno: i18n('subSector.tech'),
  Drive: i18n('subSector.drive'),
  Argent: i18n('subSector.argent'),
  Carriere: i18n('subSector.career'),
  Mobilité: i18n('subSector.mobility'),
  Concours: i18n('subSector.contest'),
  ExpatGuide: i18n('subSector.expatGuide'),
};

export type SectorType =
  | 'PolitiqueInstitutions'
  | 'EntreprisesStrategies'
  | 'PlaceFinanciereMarches'
  | 'CommunautesExpertises'
  | 'LifestyleViePratique'
  | string;

const toSubsectors = (sector: SectorType) => {
  switch (sector) {
    case 'PolitiqueInstitutions':
      return Object.entries(SubsectorPolitiqueInstitutions).map(([key, value]) => ({
        name: key,
        value,
      }));

    case 'EntreprisesStrategies':
      return Object.entries(SubsectorEntreprisesStrategies).map(([key, value]) => ({
        name: key,
        value,
      }));

    case 'PlaceFinanciereMarches':
      return Object.entries(SubsectorPlaceFinanciereMarches).map(([key, value]) => ({
        name: key,
        value,
      }));

    case 'CommunautesExpertises': {
      const entries = PlatformNameService.isDelanoPlatform()
        ? SubsectorCommunautesExpertisesDelano
        : SubsectorCommunautesExpertisesPaperjam;
      return Object.entries(entries).map(([key, value]) => ({
        name: key,
        value,
      }));
    }

    case 'LifestyleViePratique':
      const entries = PlatformNameService.isDelanoPlatform()
        ? SubsectorLifestyleViePratiqueDelano
        : SubsectorLifestyleViePratiquePaperjam;
      return Object.entries(entries).map(([key, value]) => ({
        name: key,
        value,
      }));

    default:
      return [];
  }
};

export const getSubsectors = (sector: string) => {
  const namesAndValues = toSubsectors(sector);
  return namesAndValues.map((s) => ({ value: s.name, label: s.value }));
};

export const getSubsectorByKey = (sector: string, subsector: string) => {
  const entry = getSubsectors(sector).find((s) => s.value === subsector);
  return entry ? entry.label : '';
};
