import { createContext } from 'react';
import { History, Location } from 'history';

const itemRoutes = [
  '/article',
  '/home',
  '/workshop',
  '/event',
  '/dossier',
  '/newsletter/club',
  '/newsletter/morning',
  '/newsletter/evening',
  '/newsletter/delanobreakfast',
  '/newsletter/delanonoon',
  '/newsletter/trendin',
  '/newsletter/granddossier',
  '/podcast-show',
];
const noMenuRoutes = [
  '/signin',
  '/article/preview',
  '/home/preview',
  '/article/woodwing',
  '/newsletter/preview',
  '/event/woodwing',
];
const forcedTransitions: Record<string, string> = {
  '/article': '/articles',
  '/home': '/homes',
  '/newsletter/club': '/newsletters',
  '/newsletter/morning': '/newsletters',
  '/newsletter/evening': '/newsletters',
  '/newsletter/delanobreakfast': '/newsletters',
  '/newsletter/delanonoon': '/newsletters',
  '/newsletter/trendin': '/newsletters',
  '/newsletter/granddossier': '/newsletters',
  '/workshop': '/workshops',
  '/event': '/events',
  '/dossier': '/dossiers',
  '/podcast-show': '/podcast-shows',
};
/*
  this is a simplistic approach for matching a route such as /article/article-id
  to a typed route (/article)
  if there is only one level, it does not remove anything.
  If more complicated cases appear, we will consider another way to store currentRoute
*/
export const trimRouteParameter = (route: string) => {
  // split creates empty string before first /
  const arr = route.split('/').filter((folder) => folder !== '');
  if (arr.length > 1) {
    arr.pop();
  }
  return arr.length === 1 ? `/${arr[0]}` : `/${arr.join('/')}`;
};

/**
 * extract rest action from a rest like route: e.g /resource/:id/action
 */
export const extractRouteAction = (route: string) => {
  if (route.split('/').length > 1) {
    return route.substr(route.lastIndexOf('/') + 1);
  }
  return '';
};

const initialRouteState = {
  action: '',
  currentRoute: '/homes',
  isItemRoute: false,
};

export interface RouteState {
  action: string;
  currentRoute: string;
  isItemRoute: boolean;
}

export const createStore = (state: RouteState) => ({
  initialized: false,
  init(history: History) {
    if (!this.initialized) {
      history.listen((location) => {
        this.storeRoute(location as unknown as Location);
      });
      this.initialized = true;
    }
  },
  storeRoute: (location: Location) => {
    if (!location.pathname) {
      return;
    }
    state.currentRoute = trimRouteParameter(location.pathname);
    state.action = extractRouteAction(location.pathname);
    state.isItemRoute = itemRoutes.some((r) => r === state.currentRoute);
  },
  currentRouteIs: (route: string) => route === state.currentRoute,
  get isItemRoute() {
    return state.isItemRoute;
  },
  currentRouteIsOneOf: (routes: string[]) => routes.some((route) => route === state.currentRoute),
  get currentRoute() {
    return state.currentRoute;
  },
  get isMenuRoute() {
    return !noMenuRoutes.some((r) => state.currentRoute.includes(r)) && state.action !== 'preview';
  },
  getPreviousRoute() {
    const targetRoute = forcedTransitions[state.currentRoute] ? forcedTransitions[state.currentRoute] : '/homes';
    return targetRoute;
  },
});

export interface RouteStore {
  initialized: boolean;
  init: (history: History) => void;
  storeRoute: (location: Location) => void;
  currentRouteIs: (route: string) => boolean;
  isItemRoute: boolean
  currentRouteIsOneOf: (routes: string[]) => boolean;
  currentRoute: string
  isMenuRoute: boolean;
  getPreviousRoute: () => string;
}

export const routeStore: RouteStore = createStore(initialRouteState);

const Routing = createContext<RouteStore>(routeStore);

export default Routing;
