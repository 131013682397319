import moment from 'moment';
import { i18n } from 'shared/i18n';
import useWatch from 'shared/form/useWatch';
import Header from 'layout/header/Header';
import './FormHeader.scss';
import { Show } from '../utils/Displays';

export interface FormHeaderProps {
  label: string;
  titleName?: string;
  showTime?: boolean;
}

const FormHeader = ({ label, titleName = 'title', showTime = true }: FormHeaderProps) => {
  const [title, publicationDate] = useWatch(
    [titleName, 'publication.date'],
  ) as [string, string | Date];

  return (
    <Header>
      <div className='header-inner'>
        <h1 className='header-title'>{label}</h1>
        <div className='header-section'>
          <span className='header-section-label'>{i18n('labels.title')}</span>
          <span className='header-section-value'>{title}</span>
        </div>
        <Show when={showTime}>
          <div className='header-section'>
            <span className='header-section-label'>{i18n('generalSection.publicationHour')}</span>
            <span className='header-section-value'>{moment(publicationDate).format('HH:mm')}</span>
          </div>
        </Show>
      </div>
    </Header>
  );
};

export default FormHeader;
