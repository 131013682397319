export interface DataRecord<T> {
  [key: string]: T;
}

export const getProp = <T,>(
  obj: T,
  path?: string,
  defaultValue?: unknown,
) => {
  const result = path ? path.split(/[,[\].]+?/).filter((key) => key.length !== 0).reduce(
    (result, key: string) => result ? (result as unknown as DataRecord<T>)[key] : result,
    obj,
  ) : obj;
  return result === undefined ? defaultValue : result;
};
