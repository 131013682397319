import { SelectInput, SimpleInput } from 'shared/form/inputs';
import { FailliteStatus } from 'shared/models';
import { i18n } from 'shared/i18n';
import './faillites.scss';

export interface FaillitesItemInputProps {
  name?: string;
  index?: number;
}

const FaillitesItemInput = ({ name, index = 0, }: FaillitesItemInputProps) => (
  <div style={{ width: '100%', marginTop: '-6px' }}>
    <div className='faillites-root'>
      <SimpleInput
        required
        label={`${i18n('newsletters.view.nameOfSociety')} ${index + 1}`}
        name={`${name}.nomSociete`}
      />
    </div>
    <div className='faillites-item'>
      <SimpleInput
        required
        label={`${i18n('newsletters.view.statusOfSociety')} ${index + 1}`}
        name={`${name}.typeSociete`}
      />
    </div>
    <div className='faillites-item'>
      <SelectInput
        required
        label="Motif"
        name={`${name}.status`}
        options={[
          {
            label: FailliteStatus.Liquidation,
            value: FailliteStatus.Liquidation,
          },
          {
            label: FailliteStatus.Faillite,
            value: FailliteStatus.Faillite,
          },
          {
            label: i18n('newsletters.view.failStatus.accountability'),
            value: FailliteStatus.Reddition,
          },
          {
            label: i18n('newsletters.view.failStatus.closing'),
            value: FailliteStatus.ClotureFaillite,
          },
          {
            label: i18n('newsletters.view.failStatus.bankrupt'),
            value: FailliteStatus.AnnulationFaillite,
          },
          {
            label: i18n('newsletters.view.failStatus.liquidation'),
            value: FailliteStatus.AnnulationLiquidation,
          },
        ]}
      />
    </div>
  </div>
);

export default FaillitesItemInput;
