import { useCallback } from 'react';
import useInput from 'shared/form/useInput';
import { CommonFieldProps, InputDefaultValue } from 'shared/form/types';
import './styles/SimpleInput.scss';

export interface SimpleInputProps extends InputDefaultValue<string>, CommonFieldProps<string> {
  width?: string;
  name?: string;
  className?: string;
  placeholder?: string;
  type?: string;
}

const SimpleInput = ({
  label,
  defaultValue,
  onChange,
  className,
  placeholder,
  type = 'text',
  name = '',
  width = 'auto',
  disabled = false,
  required = false
}: SimpleInputProps) => {
  const input = useInput({ name, defaultValue });

  const onChangeValue = useCallback((event) => {
    if (onChange) {
      onChange(event.target.value);
    }
    input.onChange(event);
  }, [input, onChange]);

  return (
    <div style={{ width: width || 'auto' }}>
      {label && (
        <span className='simple-input-label'>
          {label}
          {required && <span className='simple-input-required'>*</span>}
        </span>
      )}
      <input
        {...input}
        onChange={onChangeValue}
        value={input.value || ''}
        className={className || 'simple-input'}
        disabled={disabled}
        type={type}
        required={required}
        placeholder={placeholder}
      />
    </div>
  );
};

export default SimpleInput;
