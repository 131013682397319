import classNames from 'classnames';
import { i18n } from 'shared/i18n';
import { Form } from 'shared/form';
import 'shared/components/modal/Modal.scss';
import { InputArticle } from 'views/article/utils';
import './publishArticleModal.scss';
import FormContent from './FormContent';
import usePublishArticleModal from 'views/article/modal/usePublishArticleModal';

export interface PublishArticleModalProps {
  showAudio?: boolean;
  articleData: InputArticle;
  publicationChange?: boolean;
  onModalClose: () => void;
  onSubmit?: (value: InputArticle) => void;
}

const PublishArticleModal = ({
  articleData,
  onModalClose,
  onSubmit,
  showAudio = false,
  publicationChange = false,
}: PublishArticleModalProps) => {
  const {
    initialValues,
    publishArticleState,
    mode,
    setPublishMode,
    onPublish,
  } = usePublishArticleModal({ showAudio, publicationChange, onModalClose, onSubmit, articleData });

  return (
    <Form onSubmit={onPublish} initialValues={initialValues}>
      <div className='publish-modal'>
        <div className='modal-header'>
          <i onClick={() => onModalClose()} className={classNames('icon-delete', 'close-icon')} />
          <span className='modal-header-title-label'>{i18n('articles.view.confirmPublication')} :</span>
          <span className='modal-header-title-value'>
            {articleData.metadata.title}
          </span>
        </div>
        <div className='modal-content'>
          <FormContent setPublishMode={setPublishMode} mode={mode} />
          <button type="submit" className='publish-modal-confirm'>
            {publishArticleState.isLoading && <div className='loader' />}
            {i18n('actionBar.confirm')}
          </button>
        </div>
      </div>
    </Form>
  );
};

export default PublishArticleModal;
