import { DocumentNode, gql } from '@apollo/client';
import { appConfig } from 'shared/config/env';

const retrieve = (): DocumentNode => gql`
  query {
    magazine(platformName: ${appConfig.platformName}) {
      ... on MagazineResponse {
        magazine {
          title
          uri
        }
        supplement1 {
          title
          uri
        }
        supplement2 {
          title
          uri
        }
      }

      ... on ErrorPayload {
        traceId
        errorMessage
        validationErrors {
          code
          message
        }
      }

      ... on NoResult {
        message
      }
    }
  }`;

const update = (): DocumentNode => gql`
mutation saveMagazine($updateData: SaveMagazineInput!) {
    saveMagazine(updateData: $updateData) {
      __typename
        ... on MagazineResponse{
      magazine{
        title
        uri
      }
      supplement1 {
        title
        uri
      }
      supplement2 {
        title
        uri
      }
    }
    ... on ErrorPayload {
      traceId
      errorMessage
      validationErrors {
        code
        message
      }
    }
  }
}`;


const magazinesGraphqlServices = {
    retrieve,
    update
  };
  
export default magazinesGraphqlServices;
