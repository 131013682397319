import { SelectOption } from 'shared/types';
import { useInput } from 'shared/form';
import { SelectInputProps } from 'shared/form/types';
import { EventSelect } from '../../components';

export interface EventSelectInputProps extends SelectInputProps {
  withWorkshop?: boolean;
}

const EventSelectInput = ({
  label,
  onChange: customChange,
  filter,
  name = '',
  defaultValue,
  required = false,
  withWorkshop = false,
  clearable = true,
}: EventSelectInputProps) => {
  const input = useInput({ name, defaultValue });

  const onChange = (value: SelectOption) => {
    if (customChange) {
      customChange(value);
    }
    input.onChange(value);
  };

  return (
    <EventSelect
      {...input}
      withWorkshop={withWorkshop}
      onChange={onChange}
      clearable={clearable}
      label={label}
      filter={filter}
      required={required}
    />
  );
};

export default EventSelectInput;
