import { forwardRef } from 'react';
import { i18n } from 'shared/i18n';
import { Hide, Show } from 'shared/components/utils/Displays';
import OutputText from 'shared/components/form/output/OutputText';
import { DateTimeInput, SimpleInput, CheckboxInput } from 'shared/form/inputs/index';
import { CommonFieldProps } from 'shared/form/types';
import PublishInput from 'shared/form/inputs/PublishInput';
import { FormSection } from 'layout/content';
import './GeneralSection.scss';

export interface GeneralInputProps extends CommonFieldProps {
  isoFormat?: boolean;
  isTitleEditable?: boolean;
  publishLabel?: string;
  nameTitle: string;
  onPublicationChange?: () => void;
  labelCheckbox?:string;
  nameCheckbox?: string;
}

const GeneralInput = forwardRef<HTMLElement, GeneralInputProps>(({
  onPublicationChange,
  nameTitle,
  nameCheckbox,
  labelCheckbox,
  title = '',
  label = i18n('labels.title'),
  isoFormat = false,
  isTitleEditable = true,
  publishLabel = i18n('publication.published'),
}, ref) => (
  <FormSection ref={ref} title={i18n('generalSection.general')} id="general-section">
    <div className='container'>
      <Show when={isTitleEditable}>
        <SimpleInput required width="240px" label={label} name={nameTitle}  />
      </Show>
      <Hide when={isTitleEditable}>
        <OutputText label={label} value={title} wrapperClassName='titleOut' />
      </Hide>
      <DateTimeInput
        required
        isoFormat={isoFormat}
        onChange={onPublicationChange}
        labelDate="generalSection.publicationDate"
        labelTime="generalSection.publicationHour"
        name="publication.date"
      />
    </div>
    <div className='action-container'>
      <PublishInput className='action-item' label={publishLabel} name="publication.isPublished" />
      {nameCheckbox && labelCheckbox && <CheckboxInput label={labelCheckbox} name={nameCheckbox} />}
    </div>
  </FormSection>
));

export default GeneralInput;
