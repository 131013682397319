import { DocumentNode, gql } from '@apollo/client';
import { GetSearchParams } from 'shared/providers/dataProvider/types';

const search =  (params?: GetSearchParams): DocumentNode => {
  const query = `
    query {
      sponsorsSearch(
        query: "${params?.q || ''}"
      ) {
        ... on SponsorsSearchOutput {
          items {
            slug
            title
          }
        }
        ... on NoResult {
          message
        }
        ... on ErrorPayload {
          traceId
          errorMessage
          validationErrors {
            code
            message
          }
        }
    }
  }`;

  return  gql`${query}`;
};

const retrieve =  (params?: GetSearchParams): DocumentNode => {
  const query = `
    query {
        organisationBySlug(slug: "${params?.id || ''}") {
          ... on Organisation {
            slug
            hash
            slugifiedName
            salesforceRef
            lastModifiedAt
            isPublished
            buildingImage {
              uri
              caption
              credits
              crop {
                x
                y
                width
                height
              }
            }
            logoImage {
              uri
              caption
              credits
              crop {
                x
                y
                width
                height
              }
            }
            sponsorImage {
              uri
              caption
              credits
              crop {
                x
                y
                width
                height
              }
            }
            name
            description
            administrativeData {
              dateOfCreation
              yearOfCreation
              monthOfCreation
              dayOfCreation
              legalForm
              tradeRegister
              naceCode
              internationalVAT
              euVAT
              iban
              bic
              certification
              email
              fax
              phone
              mobile
              poBox {
                number
                postalCode
                city
                country
              }
              openingHours
              services
              localizedService
            }
            financialEconomicData {
              employees {
                salesforceRef
                listPosition
                year
                nbLuxembourg
                nbWorld
              }
              shareholders {
                salesforceRef
                salesforceOwnerRef
                listPosition
                ownerTitle
                ownerLogo {
                  caption
                  credits
                  uri
                  crop {
                    height
                    width
                    x
                    y
                  }
                }
                showLink
                percent
              }
              history {
                salesforceRef
                listPosition
                year
                turnover
                profits
              }
              brands {
                salesforceRef
                listPosition
                url
                title
                image {
                  caption
                  credits
                  uri
                  crop {
                    height
                    width
                    x
                    y
                  }
                }
              }
              products {
                salesforceRef
                listPosition
                url
                title
                image {
                  caption
                  credits
                  uri
                  crop {
                    height
                    width
                    x
                    y
                  }
                }
              }
              awards {
                salesforceRef
                listPosition
                url
                title
                image {
                  caption
                  credits
                  uri
                  crop {
                    height
                    width
                    x
                    y
                  }
                }
              }
              memberships {
                salesforceRef
                listPosition
                url
                title
                image {
                  caption
                  credits
                  uri
                  crop {
                    height
                    width
                    x
                    y
                  }
                }
              }
              partners {
                salesforceRef
                listPosition
                url
                title
                image {
                  caption
                  credits
                  uri
                  crop {
                    height
                    width
                    x
                    y
                  }
                }
              }
              shareCapital
            }
            spokenLanguages {
              salesforceRef
              listPosition
              language
              level
            }
            addresses {
              salesforceRef
              listPosition
              street
              postalCode
              city
              country
              title
            }
            websites {
              salesforceRef
              listPosition
              url
              title
            }
            socialMedias {
              salesforceRef
              listPosition
              socialMedia
              url
            }
            executiveDirectors {
              salesforceRef
              biographyRef
              firstName
              lastName
              email
              phone
              mobilePhone
              function
              listPosition
              showLink
              image {
                uri
                caption
                credits
                crop {
                  height
                  width
                  x
                  y
                }
              }
            }
            executiveAdministrators {
              salesforceRef
              biographyRef
              firstName
              lastName
              email
              phone
              mobilePhone
              function
              listPosition
              showLink
              image {
                uri
                caption
                credits
                crop {
                  height
                  width
                  x
                  y
                }
              }
            }
            categories {
              id
              salesforceRef
              organisationRef
              label
              node
              parentId
            }
            selectedCategories {
              id
              salesforceRef
              organisationRef
              label
              node
              parentId
            }
            mainTag
            searchSuffixes
          }
          ... on ErrorPayload {
            traceId
            errorMessage
            validationErrors {
              code
              message
            }
          }
          ... on NoResult {
            message
          }
    }
  }`;

  return  gql`${query}`;
};

const sponsorsGraphqlServices = {
  search,
  retrieve
};

export default sponsorsGraphqlServices;
