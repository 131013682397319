import { useContext } from 'react';
import { apiErrorToUiNotifier, LOGOUT_ERROR_MESSAGE } from 'shared/services';
import AuthContext from './AuthContext';

const useLogout = () => {
  const auth = useContext(AuthContext);

  const logout = async () => {
    try {
      await auth?.logout();
      auth?.setUser(null);
      window.location.href= '/';
    } catch (error) {
      apiErrorToUiNotifier(LOGOUT_ERROR_MESSAGE)();
    }

  };

  return logout;
};

export default useLogout;
