import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import AuthContext from './AuthContext';

const useAuthorized = () => {
  const auth = useContext(AuthContext);
  const unLogin = useRef<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const checkUser = useCallback(() => {
    setLoading(true);
    auth?.checkAuth().then((currentUser) => {
      auth?.setUser(currentUser);
      unLogin.current = false;
      setLoading(false);
    }).catch(() => {
      unLogin.current = true;
      setLoading(false);
    });
  }, [auth]);

  useEffect(() => {
    if (!auth?.user && !loading && !unLogin.current) {
      checkUser();
    }
  }, [auth, checkUser, loading]);

  return { user: auth?.user, loading };
};

export default useAuthorized;
