import { FormSection } from 'layout/content';
import useGetSectionRef from 'layout/ScrollspyProvider/useGetSectionRef';
import { i18n } from 'shared/i18n';
import SimpleInput from 'shared/form/inputs/SimpleInput';
import DateInput from 'shared/form/inputs/DateInput';
import TextAreaInput from 'shared/form/inputs/TextAreaInput';
import { ArrayInput, EventSelectInput } from 'shared/form/inputs';
import { useSetValue } from 'shared/form';
import { SelectOption } from 'shared/types';
import { Club } from 'views/newsletter/constants';
import { computeNewsletterTitle } from 'views/newsletter/state/common';

export interface ClubNewsletterFormProps {
  type: string;
}

const ClubNewsletterForm = ({ type }: ClubNewsletterFormProps) => {
  const setTitleValue = useSetValue();
  const sectionRefs = useGetSectionRef();

  const onChange = (value: Date) => {
    setTitleValue('title', computeNewsletterTitle(type, value));
  };

  return (
    <>
      <FormSection ref={sectionRefs[Club.Header.id]} id={Club.Header.id} title={Club.Header.label}>
        <SimpleInput
          disabled
          width="100%"
          label={i18n('labels.title')}
          name="title"
        />
        <DateInput
          required
          label="generalSection.publicationDate"
          name="publicationDate"
          onChange={onChange}
        />
        <TextAreaInput
          required
          height="80px"
          width="100%"
          name="content.slugline"
          label={i18n('newsletters.view.textPreview')}
        />
      </FormSection>
      <FormSection ref={sectionRefs[Club.ALaUne.id]} id={Club.ALaUne.id} title={Club.ALaUne.label}>
        <ArrayInput<SelectOption> name="content.aLaUne" label="labels.event">
          <EventSelectInput withWorkshop />
        </ArrayInput>
      </FormSection>
      <FormSection
        ref={sectionRefs[Club.Prochainement.id]}
        id={Club.Prochainement.id}
        title={Club.Prochainement.label}
      >
        <ArrayInput<SelectOption> name="content.prochainement" label="labels.event">
          <EventSelectInput withWorkshop />
        </ArrayInput>
      </FormSection>
    </>
  );
};

export default ClubNewsletterForm;
