import { useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { uiNotifier, INCOMPLETE_FORM_ERROR_MESSAGE } from 'shared/services';
import { useGetValues } from 'shared/form';
import { useCreate } from 'shared/providers';
import { resources } from 'shared/constants';
import { useTimeout } from 'shared/hooks';
import { Modal } from 'shared/components';
import PublishHomeModal from 'views/home/modal/PublishHomeModal';
import { HomepageInput } from 'views/home/types';
import { formState2ScheduledHomepageDto } from 'views/home/translators';

export interface HomepageState {
  isPublishModalOpen?: boolean;
}

export interface HomeModalProps extends RouteComponentProps {
  isPublishModalOpen: boolean;
  onModalClose: () => void;
}

const HomeModal = ({ isPublishModalOpen, onModalClose, history }: HomeModalProps) => {
  const formValues = useGetValues();
  const { create, loading } = useCreate(resources.NEWS_HOMEPAGE);
  const homepageDto = formState2ScheduledHomepageDto({
    ...(formValues as Partial<HomepageInput>),
  });

  const { run: successfulPostHomepage } = useTimeout({
    callback: (value: unknown) => {
      history.push(`/home/${(value as Record<string, unknown>).slug}`);
    },
    ms: 300,
  });

  const onSubmit = useCallback(() => {
    if (homepageDto) {
      create({
        payload: homepageDto,
        additionalResource: resources.CREATE_NEWS_HOMEPAGE,
        onSuccess: (data) => {
          onModalClose();
          successfulPostHomepage(data);
        },
      });
    } else {
      uiNotifier('error', INCOMPLETE_FORM_ERROR_MESSAGE);
    }
  }, [create, homepageDto, onModalClose, successfulPostHomepage]);

  return (
    <Modal onModalClose={onModalClose} isOpen={isPublishModalOpen && !!homepageDto}>
      {homepageDto && (
        <PublishHomeModal onClose={onModalClose} isLoading={loading} onSubmit={onSubmit} homepage={homepageDto} />
      )}
    </Modal>
  );
};

export default withRouter(HomeModal);
