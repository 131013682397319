import { useEffect, useState } from 'react';
import useForm from 'shared/form/useForm';

export interface UseArrayInputReturn<T> {
  items: Field[];
  append: (value?: T) => void;
  deleteItem: (index: number) => void;
  move: (to: number, from: number) => void;
  moveValueItems: () => void;
}

export interface UseArrayInputProps {
  name: string;
  numOfItems?: number;
  movedValueItems?: boolean;
}

interface Field {
  key?: string;
}

// generate key in UUID format
const generateKeyId = (): string => {
  // get count milliseconds
  const milliseconds =
    typeof performance === 'undefined' ? Date.now() : performance.now() * 1000;

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (item) => {
    // get random hex value
    const hexValue = (Math.random() * 16 + milliseconds) % 16 | 0;

    // returns a string representing in hex system
    return (item == 'x' ? hexValue : (hexValue & 0x3) | 0x8).toString(16);
  });
};

const transform = <T>(value?: T[]) => value ? value.map(() => ({
    key: generateKeyId(),
  })
) : [];

const useArrayInput = <T>({
  name,
  numOfItems,
  movedValueItems = false,
}: UseArrayInputProps): UseArrayInputReturn<T> => {
  const { formState, setValue, getValues } = useForm();
  const [inputs, setInput] = useState<Field[]>(transform(
    numOfItems ? [...new Array(numOfItems)] : getValues(name) as T[]
  ));

  const append = (value?: T) => {
    const inputValue = getValues(name) as T[];
    setInput((prevState) => [ ...prevState, { key: generateKeyId() }]);
    setValue(name, inputValue ? [...inputValue, value] : [value]);
  };

  const deleteItem = (index: number) => {
    const inputValue = getValues(name) as T[];
    setInput((prevState) => [...prevState.slice(0, index), ...prevState.slice(index + 1)]);
    setValue(name, [ ...inputValue.slice(0, index), ...inputValue.slice(index + 1)]);
  };

  const moveArrayAt = <T>(data: (T | null)[], to: number, from: number) => {
    if (data[to] === undefined) {
      data[to] = null;
    }
    data.splice(to, 0, data.splice(from, 1)[0]);
  };

  const move = (to: number, from: number) => {
    const inputValue = getValues(name) as T[];
    moveArrayAt<T>(inputValue, to, from);
    moveArrayAt<Field>(inputs, to, from);
    setInput(inputs);
    setValue(name, inputValue);
  };

  useEffect(() => {
    const inputValue = getValues(name) as T[];
    if (numOfItems === undefined && inputValue && inputValue.length !== inputs.length) {
      setInput(transform(inputValue));
    }
  }, [getValues, inputs, name, numOfItems, formState]);

  const moveInputValue = (data: (T | null)[], nullIndex: number) => {
    data?.splice(nullIndex, 1);
  };

  const moveKeysInput = (nullIndex: number) => {
    if (nullIndex === inputs.length - 1) {
      inputs.splice(nullIndex, 1, { key: generateKeyId() });
      setInput([...inputs]);
    } else {
      const lastIndex = inputs.length - 1;
      const newInputs: Field[] = inputs.reduce((newKeys: Field[], current, index) => {
        if (index < nullIndex) {
          newKeys.push(current);
        }
        if (index >= nullIndex && index <= lastIndex) {
          newKeys.push({ key: generateKeyId() });
        }
        return newKeys;
      }, []);
      setInput(newInputs);
    }
  };

  const moveValueItems = () => {
    const inputValues = getValues(name) as (T | null)[] | undefined;
    if (movedValueItems && inputValues?.some((inputValue) => inputValue === null)) {
      const nullIndex = inputValues?.indexOf(null);

      moveInputValue(inputValues, nullIndex);
      setValue(name, inputValues);

      moveKeysInput(nullIndex);
    }
  };

  return {
    items: inputs,
    moveValueItems,
    append,
    deleteItem,
    move,
  };
};

export default useArrayInput;
