import { useContext } from 'react';
import ScrollspyContext from './ScrollspyContext';

const useGetSectionRef = () => {
  const scrollspyContext = useContext(ScrollspyContext);

  return scrollspyContext ? scrollspyContext.sectionRefs : {};
};

export default useGetSectionRef;
