export interface Query {
  asc: boolean;
  sort: string;
}

export const ArticleSortingCriteriaAlias: Record<string, Query> = {
  Title: {
    asc: true,
    sort: 'metadata.title'
  },
  Author: {
    asc: true,
    sort: 'metadata.author'
  },
  CreationDate: {
    asc: true,
    sort: 'metadata.creationDate'
  },
  PublicationDate: {
    asc: true,
    sort: 'metadata.publicationDate'
  },
  '-Title': {
    asc: false,
    sort: 'metadata.title'
  },
  '-Author': {
    asc: false,
    sort: 'metadata.author'
  },
  '-CreationDate': {
    asc: false,
    sort: 'metadata.creationDate'
  },
  '-PublicationDate': {
    asc: false,
    sort: 'metadata.publicationDate'
  },
};

export const EventSortingCriteriaAlias: Record<string, Query> = {
  Title: {
    asc: true,
    sort: 'metadata.title'
  },
  CreationDate: {
    asc: true,
    sort: 'metadata.creationDate'
  },
  PublicationDate: {
    asc: true,
    sort: 'metadata.publicationDate'
  },
  '-Title': {
    asc: false,
    sort: 'metadata.title'
  },
  '-CreationDate': {
    asc: false,
    sort: 'metadata.creationDate'
  },
  '-PublicationDate': {
    asc: false,
    sort: 'publication.date'
  },
};

export const NewsletterSortingCriteriaAlias: Record<string, Query> = {
  CreationDate: {
    asc: true,
    sort: 'creationDate'
  },
  PublicationDate: {
    asc: true,
    sort: 'publicationDate'
  },
  '-CreationDate': {
    asc: false,
    sort: 'creationDate'
  },
  '-PublicationDate': {
    asc: false,
    sort: 'publicationDate'
  },
};

export const HomepageSortingCriteriaAlias: Record<string, Query> = {
  CreationDate: {
    asc: true,
    sort: 'creationDate'
  },
  '-CreationDate': {
    asc: false,
    sort: 'creationDate'
  },
};

export const WorkshopSortingCriteriaAlias: Record<string, Query> = {
  Title: {
    asc: true,
    sort: 'title'
  },
  CreationDate: {
    asc: true,
    sort: 'creationDate'
  },
  PublicationDate: {
    asc: true,
    sort: 'publication.date'
  },
  '-CreationDate': {
    asc: false,
    sort: 'creationDate'
  },
  '-PublicationDate': {
    asc: false,
    sort: 'publication.date'
  },
  '-Title': {
    asc: false,
    sort: 'title'
  },
};

export const PodcastSortingCriteriaAlias: Record<string, Query> = {
  Title: {
    asc: true,
    sort: 'metadata.title'
  },
  CreationDate: {
    asc: true,
    sort: 'creationDate'
  },
  PublicationDate: {
    asc: true,
    sort: 'metadata.publicationDate'
  },
  '-Title': {
    asc: false,
    sort: 'title'
  },
  '-CreationDate': {
    asc: false,
    sort: 'creationDate'
  },
  '-PublicationDate': {
    asc: false,
    sort: 'publication.date'
  },
};

export const DossierSortingCriteriaAlias: Record<string, Query> = {
  Title: {
    asc: true,
    sort: 'title'
  },
  CreationDate: {
    asc: true,
    sort: 'creationDate'
  },
  PublicationDate: {
    asc: true,
    sort: 'publication.date'
  },
  '-CreationDate': {
    asc: false,
    sort: 'creationDate'
  },
  '-PublicationDate': {
    asc: false,
    sort: 'publication.date'
  },
  '-Title': {
    asc: false,
    sort: 'title'
  },
};