import { cloneElement, ReactElement, useEffect } from 'react';
import { i18n } from 'shared/i18n';
import { useWatch } from 'shared/form';
import { CommonFieldPropsWithoutRequired, InputName } from 'shared/form/types';
import './ListForms.scss';

export interface ArrayItemInputProps extends InputName, CommonFieldPropsWithoutRequired {
  deleteItem?: (index: number) => void;
  index: number;
  children: ReactElement;
  staticArray?: boolean;
  required: ((index: number) => void) | boolean;
  moveValueItems: () => void;
  translateLabel?: boolean;
}

const ArrayItemInput = ({
  index,
  name,
  label,
  deleteItem,
  children,
  required,
  moveValueItems,
  translateLabel = true,
  staticArray = false,
}: ArrayItemInputProps) => {
  const itemValue = useWatch(name);

  useEffect(() => {
    if (itemValue !== undefined) {
      moveValueItems();
    }
  }, [itemValue, moveValueItems, name]);

  const removeItem = () => {
    if (deleteItem) {
      deleteItem(index);
    }
  };

  return (
    <div className='list-form-item'>
      {cloneElement(children, {
        ...children.props,
        name,
        index,
        required:
          typeof required === 'function'
            ? required(index)
            : required,
        label: label
          ? translateLabel
            ? `${i18n(label)} ${index + 1}`
            : label
          : '',
      })}
      {!staticArray &&
        <button type='button' className='list-form-remove-button' onClick={removeItem}>
          <i className='icon icon-trash' />
        </button>
      }
    </div>
  );
};

export default ArrayItemInput;
