import { ReactElement } from 'react';
import { getProp } from 'shared/utils';
import { Kind } from 'shared/models/enum/newsletterKind';

export interface TextFieldCellProps {
  className?: string;
  record?: Record<string, unknown>;
  source: string;
  children?: ReactElement | null;
  format?: (value: Kind) => string;
}

const TextFieldCell = ({ className, source, record, children, format }: TextFieldCellProps) => {
  const cellValue = getProp(record, source, '') as Kind;

  return (
    <td className={className}>
      {children}
      {format ? format(cellValue) : cellValue}
    </td>
  );
};

export default TextFieldCell;
