import { i18n } from 'shared/i18n';

export const Sector: Record<string, string> = {
  PolitiqueInstitutions: i18n('sector.PolitiqueInstitutions'),
  EntreprisesStrategies: i18n('sector.EntreprisesStrategies'),
  PlaceFinanciereMarches: i18n('sector.PlaceFinanciereMarches'),
  CommunautesExpertises: i18n('sector.CommunautesExpertises'),
  LifestyleViePratique: i18n('sector.LifestyleViePratique'),
};

const sectorValues = Object.entries(Sector).map(([key, value]) => ({
  value: key,
  label: value,
}));

export const Sectors = {
  display: (sectorName: string) => Sector[sectorName],
  values: sectorValues,
};
