import { useCallback, useEffect, useRef } from 'react';

export interface UseTimeout {
  callback: (props?: unknown) => void;
  ms?: number;
}

export interface UseTimeoutReturn {
  run: (props?: unknown) => void
  cancel: () => void
}

const useTimeout = ({ callback: fn, ms= 0 }: UseTimeout): UseTimeoutReturn => {
  const timeout = useRef<ReturnType<typeof setTimeout>>();
  const callback = useRef(fn);

  const run = useCallback((props?: unknown) => {
    timeout.current && clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      callback.current(props);
    }, ms);

  }, [ms]);

  const cancel = useCallback(() => {
    timeout.current && clearTimeout(timeout.current);
  }, []);

  useEffect(() => {
    callback.current = fn;
  }, [fn]);

  return { run, cancel };
};

export default useTimeout;
