import moment from 'moment';
import { WorkshopFormatted } from 'views/workshop/model/WorkshopFormModel';
import { WorkshopInputAsString } from 'views/workshops/types';

export const toWorkshopDto = (model: WorkshopInputAsString): WorkshopFormatted => ({
  title: model.title,
  description: model.description,
  mainImage: model.mainImage,
  instantDebut: moment(model.practicalInfo.beginDate).toDate(),
  instantFin: moment(model.practicalInfo.endDate).toDate(),
  location: model.practicalInfo.location,
  morning: model.morning,
  afternoon: model.afternoon,
  sponsorsGold: model.eventSponsors.sponsorsGold,
  sponsorsSilver: model.eventSponsors.sponsorsSilver,
  linkedContent: {
    events: model.linkedContent.events,
    articles: model.linkedContent.articles,
    sponsoredContent: [],
  },
  publication: model.publication,
});

export default toWorkshopDto;