import { ReactNode, useState } from 'react';
import { AuthProviderType } from './auth';
import AuthContext from './AuthContext';

export interface AuthProviderProps {
  children: ReactNode;
  provided: AuthProviderType;
}

const AuthProvider = ({ children, provided }: AuthProviderProps) => {
  const [user, setUser] = useState<unknown>(null);
  
  return (
    <AuthContext.Provider value={{ ...provided, setUser, user, }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
