import { ReactNode, useContext, useMemo } from 'react';
import { Route, Switch, Redirect, withRouter, RouteProps } from 'react-router-dom';
import { RouteComponentProps, useHistory } from 'react-router';
import classNames from 'classnames';
import { Auth } from 'aws-amplify';

import { RouteStore } from 'shared/stores/routeStore';
import { Role } from 'shared/types';
import { useAuthorized } from 'shared/providers';
import 'views/views.scss';
import { appConfig } from 'shared/config/env';
import { i18n } from 'shared/i18n';
import { PaperjamPlatform, DelanoPlatform } from 'shared/models';

import UnknownView from 'views/UnknownView';

import HomeListView from 'views/homes/HomeListView';
import HomeDetailView from 'views/home/HomeDetailView';
import HomePreview from 'views/home/preview/HomePreview';

import ArticleListView from 'views/articles/ArticleListView';
import ArticleDetailView from 'views/article/ArticleDetailView';
import ArticlePreview from 'views/article/preview/ArticlePreview';

import NewsLetterListView from 'views/newsletters/NewsletterListView';
import NewsletterDetailView from 'views/newsletter/NewsletterDetailView';
import NewsletterPreview from 'views/newsletter/preview/NewsletterPreview';

import GuideHomepageView from 'views/guide/GuideHomepageView';

import MagazineHomepageView from 'views/magazine/MagazineHomepageView';

import PodcastShowsListView from 'views/shows/PodcastsShowsListView';
import PodcastShowView from 'views/show/PodcastShowView';
import PodcastShowPreview from './show/preview/PodcastShowPreview';

import EventListView from './events/EventListView';
import EventView from './event/EventView';
import EventPreview from './event/preview/EventPreview';

import ClubHomeDetailsView from './clubHome/ClubHomeDetailsView';

import DossiersListView from './dossiers/DossiersListView';
import DossierView from './dossier/DossierView';
import DossierPreview from './dossier/preview/DossierPreview';

import WorkshopListView from './workshops/WorkshopListView';
import WorkshopView from './workshop/WorkshopView';
import WorkshopPreview from './workshop/preview/WorkshopPreview';
import SingInView from './login/SignInView';
import ResetPasswordView from './resetPassword/ResetPasswordView';
import UserSessionContext, { ROLE_IT_DIGITAL } from 'shared/stores/user/store';
export interface SubSection {
  route: string;
  iconClass: string;
  label: string;
  roles: Role[];
  platforms: string[];
  routes: ReactNode;
}

export interface Sections {
  [sectionKey: string]: SubSection;
}

export interface PrivateRouterProps extends RouteProps {
  children: ReactNode;
  sectionKey: keyof Sections;
}

const PUBLIC_ROUTES = ['/reset-password'];

const PrivateRouter = ({ children, sectionKey, ...other }: PrivateRouterProps) => {
  const { user, loading } = useAuthorized();
  const userSession = useContext(UserSessionContext);
  const sectionRoles = Sections[sectionKey].roles;
  const userRoles = userSession?.roleNames;
  const hasAccess = useMemo(() => {
    if (!userRoles) return false;
    return userRoles.includes(ROLE_IT_DIGITAL) || sectionRoles.some((role) => userRoles.includes(role));
  }, [sectionRoles, userRoles]);

  const isPublicRoute = PUBLIC_ROUTES.includes(other.path as string);

  if (userSession?.roleNames === undefined) {
    return <SingInView loadingUser={loading} />;
  }

  // If the user is not signed in and the route is public, show ResetPasswordView
  if (!user && isPublicRoute) {
    return <ResetPasswordView loadingUser={loading} />;
  }

  // If the user is signed in, check if they have access to the section
  return (
    <Route {...other}>
      {user ? hasAccess ? children : <Redirect to="/articles" /> : <SingInView loadingUser={loading} />}
    </Route>
  );
};

export const Sections: Sections = {
  homes: {
    route: '/homes',
    iconClass: 'icon-homepage',
    label: i18n('nav.homes'),
    roles: ['News', 'Sales', 'Agence', 'Club'],
    platforms: [PaperjamPlatform, DelanoPlatform],
    routes: [
      <PrivateRouter exact key="homes" path="/homes" sectionKey="homes">
        <HomeListView />
      </PrivateRouter>,
      <PrivateRouter key="homes-preview" path="/home/preview/:slug/:previewUrl" sectionKey="homes">
        <HomePreview />
      </PrivateRouter>,
      <PrivateRouter key="homes-view" path="/home/:id" sectionKey="homes">
        <HomeDetailView />
      </PrivateRouter>,
    ],
  },
  articles: {
    route: '/articles',
    iconClass: 'icon-articles',
    label: i18n('nav.articles'),
    roles: ['News', 'Sales', 'Agence', 'Club'],
    platforms: [PaperjamPlatform, DelanoPlatform],
    routes: [
      <PrivateRouter exact key="articles" path="/articles" sectionKey="articles">
        <ArticleListView />
      </PrivateRouter>,
      <PrivateRouter key="articles-woodwing" path="/article/woodwing/embed/:slug" sectionKey="articles">
        <ArticleDetailView isWoodwing />
      </PrivateRouter>,
      <PrivateRouter key="articles-preview" path="/article/preview/:slug/:previewUrl" sectionKey="articles">
        <ArticlePreview />
      </PrivateRouter>,
      <PrivateRouter key="articles-view" path="/article/:slug" sectionKey="articles">
        <ArticleDetailView />
      </PrivateRouter>,
    ],
  },
  newsletters: {
    route: '/newsletters',
    iconClass: 'icon-email',
    label: i18n('nav.newsletters'),
    roles: ['News', 'Sales', 'Agence', 'Club'],
    platforms: [PaperjamPlatform, DelanoPlatform],
    routes: [
      <PrivateRouter exact key="newsletters" path="/newsletters" sectionKey="newsletters">
        <NewsLetterListView />
      </PrivateRouter>,
      <PrivateRouter
        key="newsletters-preview"
        path="/newsletter/preview/:kind/:id/:previewUrl"
        sectionKey="newsletters"
      >
        <NewsletterPreview />
      </PrivateRouter>,
      <PrivateRouter key="newsletters-view" path="/newsletter/:type/:id" sectionKey="newsletters">
        <NewsletterDetailView />
      </PrivateRouter>,
    ],
  },
  clubHome: {
    route: '/clubHome',
    iconClass: 'icon-homepage-club',
    label: i18n('nav.clubHome'),
    roles: ['Club', 'IT_Digital'],
    platforms: [PaperjamPlatform, DelanoPlatform],
    routes: [
      <PrivateRouter key="club-home" path="/clubHome" sectionKey="clubHome">
        <ClubHomeDetailsView />
      </PrivateRouter>,
    ],
  },
  events: {
    route: '/events',
    iconClass: 'icon-event',
    label: i18n('nav.events'),
    roles: ['News', 'Sales', 'Agence', 'Club', 'IT_Digital'],
    platforms: [PaperjamPlatform, DelanoPlatform],
    routes: [
      <PrivateRouter key="events" path="/events" sectionKey="events">
        <EventListView />
      </PrivateRouter>,
      <Route key="events-create" path="/event/create" component={EventView} />,
      <PrivateRouter key="events-woodwing" path="/event/woodwing/embed/:slug" sectionKey="events">
        <EventView isWoodwing={true} />
      </PrivateRouter>,
      <Route key="events-preview" path="/event/:slug/preview" component={EventPreview} />,
      <PrivateRouter key="events-view" path="/event/:slug" sectionKey="events">
        <EventView />
      </PrivateRouter>,
    ],
  },
  workshops: {
    route: '/workshops',
    iconClass: 'icon-workshop',
    label: i18n('nav.workshops'),
    roles: ['Club', 'IT_Digital'],
    platforms: [PaperjamPlatform, DelanoPlatform],
    routes: [
      <PrivateRouter exact key="workshops" path="/workshops" sectionKey="workshops">
        <WorkshopListView />
      </PrivateRouter>,
      <PrivateRouter key="workshop-preview" path="/workshop/:slug/preview" sectionKey="workshops">
        <WorkshopPreview />
      </PrivateRouter>,
      <PrivateRouter exact key="workshop-create" path="/workshop/create" sectionKey="workshops">
        <WorkshopView />
      </PrivateRouter>,
      <PrivateRouter key="workshop-view" path="/workshop/:slug" sectionKey="workshops">
        <WorkshopView />
      </PrivateRouter>,
    ],
  },
  dossiers: {
    route: '/dossiers',
    iconClass: 'icon-dossier',
    label: i18n('nav.dossiers'),
    roles: ['News', 'Sales', 'Agence', 'Club', 'IT_Digital'],
    platforms: [PaperjamPlatform, DelanoPlatform],
    routes: [
      <PrivateRouter key="dossiers" path="/dossiers" sectionKey="dossiers">
        <DossiersListView />
      </PrivateRouter>,
      <PrivateRouter key="dossier-create" path="/dossier/create" sectionKey="dossiers" component={DossierView}>
        <Route component={DossierView} />
      </PrivateRouter>,
      <PrivateRouter key="dossier-preview" path="/dossier/:slug/preview" sectionKey="dossiers">
        <DossierPreview />
      </PrivateRouter>,
      <PrivateRouter key="dossier-view" path="/dossier/:slug" sectionKey="dossiers">
        <Route component={DossierView} />
      </PrivateRouter>,
    ],
  },
  guide: {
    route: '/guide',
    iconClass: 'icon-profiles',
    label: i18n('nav.guide'),
    roles: ['Guide', 'IT_Digital'],
    platforms: [PaperjamPlatform, DelanoPlatform],
    routes: [
      <PrivateRouter key="guide" path="/guide" sectionKey="guide">
        <GuideHomepageView />
      </PrivateRouter>,
    ],
  },
  magazine: {
    route: '/magazine',
    iconClass: 'icon-read',
    label: i18n('nav.magazine'),
    roles: ['News', 'IT_Digital'],
    platforms: [PaperjamPlatform, DelanoPlatform],
    routes: [
      <PrivateRouter key="magazine" exact path="/magazine" component={MagazineHomepageView} sectionKey="magazine">
        <MagazineHomepageView />
      </PrivateRouter>,
    ],
  },
  podcastShows: {
    route: '/podcast-shows',
    iconClass: 'icon-articles',
    label: i18n('nav.podcastShows'),
    roles: ['News', 'Sales', 'Agence', 'IT_Digital'],
    platforms: [PaperjamPlatform, DelanoPlatform],
    routes: [
      <PrivateRouter key="podcast-shows" path="/podcast-shows" sectionKey="podcastShows">
        <PodcastShowsListView />
      </PrivateRouter>,
      <PrivateRouter key="podcast-shows-create" path="/podcast-show/create" sectionKey="podcastShows">
        <PodcastShowView />
      </PrivateRouter>,
      <PrivateRouter key="podcast-shows-preview" path="/podcast-show/:slug/preview" sectionKey="podcastShows">
        <PodcastShowPreview />
      </PrivateRouter>,
      <PrivateRouter key="podcast-shows-view" path="/podcast-show/:slug" sectionKey="podcastShows">
        <PodcastShowView />
      </PrivateRouter>,
    ],
  },
};

export interface ViewsProps extends RouteComponentProps {
  routeStore: RouteStore;
}

const Views = ({ routeStore }: ViewsProps) => {
  const { user } = useAuthorized();
  const history = useHistory();
  const DEFAULT_AUTHENTICATION_ROUTE = '/articles';
  const DEFAULT_PUBLIC_ROUTES = ['/reset-password'];

  if (
    !user &&
    routeStore.currentRoute !== DEFAULT_AUTHENTICATION_ROUTE &&
    DEFAULT_PUBLIC_ROUTES.indexOf(routeStore.currentRoute) < 0
  ) {
    Auth.currentSession()
      .then((response) => {
        if (!response.isValid()) {
          history.push(DEFAULT_AUTHENTICATION_ROUTE);
        }
      })
      .catch(() => {
        history.push(DEFAULT_AUTHENTICATION_ROUTE);
      });
  }

  const routes = useMemo(
    () =>
      Object.values(Sections)
        .filter((section) => section.platforms.includes(appConfig.platformName))
        .map((section) => section.routes)
        .flat(),
    [],
  );

  return (
    <section
      className={classNames('view-stack-container', {
        ['view-stack-container-no-menu']: !routeStore.isMenuRoute,
      })}
    >
      <Switch>
        <Route exact path="/">
          <Redirect to="/articles" />
        </Route>

        <Route path="/reset-password" component={ResetPasswordView} />

        {routes}

        <Route component={UnknownView} />
      </Switch>
    </section>
  );
};

export default withRouter(Views);
