import { Amplify, Auth } from 'aws-amplify';
import { AuthProviderType } from 'shared/providers';
import { appConfig } from '../config/env';

Amplify.configure({
  userPoolId: appConfig.userPoolId,
  userPoolWebClientId: appConfig.userPoolWebClientId,
});

const auth: AuthProviderType = {
  login: (email: string, password: string) => Auth.signIn(email, password),
  logout: () => Auth.signOut({ global: true }),
  checkAuth: () => Auth.currentAuthenticatedUser(),
};

export default auth;
