import { i18n } from 'shared/i18n';

const FailliteStatus = {
  Faillite: i18n('newsletters.failliteStatus.Faillite'), //
  Liquidation: i18n('newsletters.failliteStatus.Liquidation'), //  'Liquidation',
  Reddition: i18n('newsletters.failliteStatus.Reddition'), //  'Reddition',
  ClotureFaillite: i18n('newsletters.failliteStatus.ClotureFaillite'), // 'ClotureFaillite',
  AnnulationFaillite: i18n('newsletters.failliteStatus.AnnulationFaillite'), //  'AnnulationFaillite',
  AnnulationLiquidation: i18n('newsletters.failliteStatus.AnnulationLiquidation'), // 'AnnulationLiquidation',
};

export default FailliteStatus;
