import { forwardRef } from 'react';
import { i18n } from 'shared/i18n';
import { resources } from 'shared/constants';
import { ArticleSelect } from 'shared/components';
import { SimpleInput, SlugInput, DnDArrayInput } from 'shared/form/inputs';
import { CommonFieldProps } from 'shared/form/types';
import { FormSection } from 'layout/content';
import '../dossierView.scss';

export interface DossierQuestionInputProps extends CommonFieldProps {
  showLabelInput?: boolean;
  name?: string;
}

const DossierQuestionInput = forwardRef<HTMLElement, DossierQuestionInputProps>(({
  label,
  id,
  name = '',
  showLabelInput = true,
}, ref) => (
  <FormSection ref={ref} className='dossier-list-item' title={label} id={id}>
    <div>
      {showLabelInput && (
        <SimpleInput label={i18n('labels.question')} name={`${name}.question`} />
      )}
      <DnDArrayInput<string>
        name={`${name}.slugs`}
        label="labels.question"
      >
        <SlugInput resource={resources.ARTICLES} options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}>
          <ArticleSelect filter="editorialFormat:Contribution" />
        </SlugInput>
      </DnDArrayInput>
    </div>
  </FormSection>
));

export default DossierQuestionInput;
