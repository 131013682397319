export const REST = 'REST';

export const appConfig = {
  nodeEnv: process.env.NODE_ENV,
  platformName: process.env.REACT_APP_PLATFORM_NAME || 'delano',

  platformHost: process.env.REACT_APP_PLATFORM_HOST,

  defaultLocale: process.env.REACT_APP_DEFAULT_LOCALE || 'en',

  woodwingBaseUrl: process.env.REACT_APP_WOODWING_BASE_URL,

  assetsCdnHost: process.env.REACT_APP_ASSETS_CDN_HOST,
  imagesCdnHost: process.env.REACT_APP_IMAGES_CDN_HOST,

  loginUrl: process.env.REACT_APP_LOGIN_URL,
  dateFormat: process.env.REACT_APP_DATE_FORMAT || 'DD/MM/YYYY',
  timeFormat: process.env.REACT_APP_TIME_FORMAT || 'HH:mm',
  yearBasedDateFormat: process.env.REACT_APP_YEAR_BASED_DATE_FORMAT || 'YYYY-MM-DD',
  dateFormatWithDashDelimiter: process.env.REACT_APP_DATE_FORMAT_DASH_DELIMITER || 'DD-MM-YYYY',
  dateTimeFormat: process.env.REACT_APP_DATE_TIME_FORMAT || 'DD/MM/YYYY à HH:mm',
  queryType: process.env.REACT_APP_QUERY_TYPE || REST,
  offset: process.env.REACT_APP_QUERY_OFFSET || 0,
  limit: process.env.REACT_APP_QUERY_LIMIT || 8,
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
  duplicate: process.env.REACT_APP_DUPLICATE === 'true',
  newsletterEndpointEnable: process.env.REACT_APP_NEWSLETTER_ENDPOINT_ENABLE,
};

export const apiConfig = {
  endpointUrl: process.env.REACT_APP_API_ENDPOINT_URL,
  endpointGraphqlUrl: process.env.REACT_APP_API_ENDPOINT_GRAPHQL_URL || '',
  newsletterEndpointUrl: process.env.REACT_APP_NEWSLETTER_ENDPOINT_URL,
};

export const featureFlags = {
  enableNoRedirectMode: process.env.REACT_APP_ENABLE_NO_REDIRECT_MODE === 'true',
};

export const isDelanoPlatform = appConfig.platformName === 'delano';

export const BASE_URL = 'api';
