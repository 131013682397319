import { useCallback, useEffect, useState } from 'react';
import { match } from 'react-router';
import { useHistory } from 'react-router';
import { i18n } from 'shared/i18n';
import { useGetOne, useUpdate } from 'shared/providers';
import { resources } from 'shared/constants';
import { articleNormalizer } from 'shared/services/articleService';
import { toArticleEncodedUrl } from 'shared/utils/preview';
import { setErrors } from 'shared/form/utils';
import { articleToViewState, InputArticle, viewStateToArticle } from 'views/article/utils';
import { Article } from 'views/article/types';
import { commonConstants } from 'shared/constants';

export interface ArticleState {
  isPublishModalOpen: boolean;
  publicationChanged: boolean;
  isPublished: boolean;
  showAudio: boolean;
  hasUnsavedData: boolean;
}

export interface UseInteractionArticleViewProps {
  isWoodwing?: boolean;
  match: match<{ slug: string }>;
}

export interface UseInteractionArticleViewReturn {
  audioId?: string;
  fetchError: boolean;
  hasUnsavedData: boolean;
  showAudio: boolean;
  isPublishModalOpen: boolean;
  putArticleMetadata: (value: InputArticle) => void;
  article?: InputArticle;
  labelArticles: (index: number) => void;
  labelEvent: (index: number) => void;
  labelSponsoredContent: (index: number) => void;
  onModalClose: () => void;
  onPublicationChange: () => void;
  onSubmit: (value: Record<string, unknown>) => void;
  onPublish: () => void;
  validate: (value: unknown) => Record<string, unknown>;
  openPreview: () => void;
  loading: boolean;
}

const MAIN_CATEGORY = 0;

const useInteractionArticleView = ({
  isWoodwing,
  match,
}: UseInteractionArticleViewProps): UseInteractionArticleViewReturn => {
  const { update } = useUpdate<Article>(resources.ARTICLES, {
    additionalResource: resources.UPDATE_ARTICLE_METADATA_PUBLICATION,
  });

  const history = useHistory();

  const { data, error, loading } = useGetOne<Article>(resources.ARTICLES, {
    id: match.params.slug,
    kind: isWoodwing ? commonConstants.DEFAULT_WOODWING : '',
    normalize: articleNormalizer,
    additionalResource: resources.ARTICLE_WITH_TITLES,
  });

  const [article, setArticle] = useState<InputArticle>();
  const [articleState, setArticleState] = useState<ArticleState>({
    hasUnsavedData: false,
    isPublishModalOpen: false,
    publicationChanged: false,
    isPublished: true,
    showAudio: false,
  });

  const openPreview = () => {
    if (article) {
      const { slug } = article;
      const encodedUrl = toArticleEncodedUrl(slug as string);
      const newUrl = `/article/preview/${slug}/${encodedUrl}`;
      history.push(newUrl);
    }
  };

  const onPublicationChange = () => {
    setArticleState((prevState) => ({
      ...prevState,
      publicationChanged: true,
    }));
  };

  const putArticleMetadata = useCallback(
    (value) => {
      if (!value) {
        return;
      }

      const newArticle = viewStateToArticle(value, articleState.showAudio);
      update({
        payload: newArticle,
        id: article?.slug,
        onSuccess: () => {
          setArticleState((prevState) => ({
            ...prevState,
            hasUnsavedData: false,
            publicationChanged: false,
          }));
        },
        onFailure: () => {
          setArticleState((prevState) => ({ ...prevState, hasUnsavedData: true }));
        },
      });
    },
    [article, articleState, update],
  );

  const onSubmit = useCallback(
    (value) => {
      if (articleState.publicationChanged) {
        setArticleState((prevState) => ({ ...prevState, isPublishModalOpen: true }));
      } else {
        putArticleMetadata(value);
      }
    },
    [putArticleMetadata, articleState],
  );

  const validate = (value: unknown) => {
    const errors = {};
    if (value) {
      const inputArticle = value as InputArticle;
      if (inputArticle.metadata.categorizations && inputArticle.metadata.categorizations.length >= 1) {
        if (
          !inputArticle.metadata.categorizations[MAIN_CATEGORY].sector &&
          !inputArticle.metadata.categorizations[MAIN_CATEGORY].subSector
        ) {
          setErrors(errors, 'article.metadata.categorizations', 'main category required');
        }
        if (
          inputArticle.metadata.categorizations.every(
            (articleCategorization) => !articleCategorization.sector || !articleCategorization.subSector,
          )
        ) {
          setErrors(errors, 'article.metadata.categorizations', 'min length categorizations must be 1');
        }
      } else {
        setErrors(errors, 'article.metadata.categorizations', 'min length categorizations must be 1');
      }
    } else {
      setErrors(errors, 'article', i18n('forms.validation.required'));
    }
    return errors;
  };

  useEffect(() => {
    if (data && Object.keys(data).length) {
      const article: InputArticle = articleToViewState(data);
      const { showAudio, audioId } = article.metadata;
      setArticleState((prevState) => ({
        ...prevState,
        showAudio: !showAudio,
        publicationChanged: !data.publication.isPublished || !data.publication.date,
      }));
      setArticle(article);
      if (audioId) {
        // Append script to load speechkit player
        const tag = document.createElement('script');
        tag.src =
          'https://cdn.jsdelivr.net/npm/@speechkit/speechkit-audio-player@latest/dist/speechkit-iframe-helper.js';
        const body = document.getElementsByTagName('body')[0];
        body.appendChild(tag);
      }
    }
  }, [data]);

  const onPublish = () => {
    setArticleState((prevState) => ({ ...prevState, isPublishModalOpen: true }));
  };

  const onModalClose = () => {
    setArticleState((prevState) => ({ ...prevState, isPublishModalOpen: false }));
  };

  const audioId = article && article.metadata.audioId;
  const labelSponsoredContent = (index: number) => `${i18n('labels.sponsored')} ${index + 1}`;
  const labelEvent = (index: number) => `${i18n('labels.event')} ${index + 1}`;
  const labelArticles = (index: number) => `${i18n('labels.article')} ${index + 1}`;

  return {
    audioId,
    hasUnsavedData: articleState.hasUnsavedData,
    fetchError: !!error,
    showAudio: articleState.showAudio,
    isPublishModalOpen: articleState.isPublishModalOpen,
    putArticleMetadata,
    article,
    labelArticles,
    labelEvent,
    labelSponsoredContent,
    onModalClose,
    onPublicationChange,
    onSubmit,
    onPublish,
    validate,
    openPreview,
    loading,
  };
};

export default useInteractionArticleView;
