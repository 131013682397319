import { SimpleInput, TextAreaInput } from 'shared/form/inputs';
import DateInput from 'shared/form/inputs/DateInput';
import { i18n } from 'shared/i18n';
import { useSetValue } from 'shared/form';
import { computeNewsletterTitle } from 'views/newsletter/state/common';

export interface GeneralInputProps {
  type: string;
  labelTitle: string;
  labelSlugLine: string;
}

const GeneralInput = ({ type, labelSlugLine, labelTitle }: GeneralInputProps) => {
  const setValue = useSetValue();

  const onTitleChange = (value: Date) => {
    setValue('title', computeNewsletterTitle(type, value));
  };

  return (
    <>
      <SimpleInput
        disabled
        width="100%"
        name="title"
        label={i18n(labelTitle)}
      />
      <DateInput
        required
        label="generalSection.publicationDate"
        name="publicationDate"
        onChange={onTitleChange}
      />
      <TextAreaInput
        required
        width="100%"
        name="content.slugline"
        label={i18n(labelSlugLine)}
        height="80px"
      />
    </>
  );
};

export default GeneralInput;
