import { i18n } from 'shared/i18n';
import {
  ActionsCell,
  DataTableRow,
  DateFieldCell,
  ImageFieldCell,
  PublicationFieldCell,
  TextFieldCell,
} from 'shared/components/dataTableRow';
import './workshopRow.scss';
import { Workshop } from '../types';

export interface WorkshopRowProps {
  item: Workshop;
}

const WorkshopRow = ({ item }: WorkshopRowProps) => (
  <DataTableRow<Workshop> record={item}>
    <ImageFieldCell alt="text" viewName="workshop" source="slug" />
    <TextFieldCell className='workshop-row-first-cell' source="title" />
    <DateFieldCell source="creationDate" />
    <PublicationFieldCell
      published={item.publication.isPublished}
      source="publication.date"
      label={i18n('labels.at')}
    />
    <ActionsCell preview={`/workshop/${item.slug}/preview`} viewName="workshop" />
  </DataTableRow>
);

export default WorkshopRow;
