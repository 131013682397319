import { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { DataProviderContext, DataProviderType } from 'shared/providers/dataProvider';

export interface DataProviderProps {
  children: ReactNode;
  provider: DataProviderType;
}

const client = new QueryClient();

const DataProvider = ({ children, provider }: DataProviderProps) => (
  <DataProviderContext.Provider value={provider}>
    <QueryClientProvider client={client}>
      {children}
    </QueryClientProvider>
  </DataProviderContext.Provider>
);

export default DataProvider;
