import { ReactNode } from 'react';
import ReactModal from 'react-modal';
import 'shared/components/modal/Modal.scss';
import { useRunOnce } from 'shared/hooks';

export interface ModalProps {
  onModalClose: () => void;
  isOpen: boolean;
  children: ReactNode;
}

export const Modal = ({ isOpen, onModalClose, children }: ModalProps) => {
  useRunOnce(() => ReactModal.setAppElement(document.getElementById('root') || ''));

  return (
    <ReactModal
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      role="dialog"
      isOpen={isOpen}
      onRequestClose={onModalClose}
      overlayClassName='overlay'
      className='modal'
      bodyOpenClassName='body-open-modal'
    >
      {children}
    </ReactModal>
  );
};
