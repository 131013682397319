import { ForwardedRef, forwardRef } from 'react';
import { i18n } from 'shared/i18n';
import { resources } from 'shared/constants';
import { ArticleSelect } from 'shared/components';
import { SimpleInput, ToggleInput, SlugInput } from 'shared/form/inputs';
import { FormSection } from 'layout/content';
import 'views/home/homeDetail.scss';
import * as constants from 'views/home/constants';

const BreakingNewsSectionInput = forwardRef((_, ref: ForwardedRef<HTMLElement>) => (
  <FormSection ref={ref} title={constants.HomeBreakingNewsLabel} id={constants.HomeBreakingNewsId}>
    <ToggleInput id="toggler-breakingnews" name="breakingNews.isActive">
      <div className='home-detail-general'>
        <SimpleInput
          required
          width="100%"
          name="breakingNews.title"
          label={i18n('labels.title')}
        />
        <SlugInput
          resource={resources.ARTICLES}
          options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}
          name="breakingNews.slug"
          label={i18n('labels.article')}
        >
          <ArticleSelect />
        </SlugInput>
      </div>
    </ToggleInput>
  </FormSection>
));

export default BreakingNewsSectionInput;
