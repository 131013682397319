import axios from 'axios';
import { PlatformNameService } from 'shared/services';
import { Error } from 'shared/types';
import { apiConfig } from '../config/env';

export const trimSlashes = (assetPath: string) => assetPath.trim().replace(/^\/|\/$/g, '');

export const toUrl = (url: string) => `${apiConfig.endpointUrl}/${trimSlashes(url)}`;

export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  return parts.length === 2 ? parts[1].split(';').shift() : undefined;
};

const axiosInstance = axios.create({
  baseURL: window.location.origin,
  withCredentials: true,
  headers: {},
});

function toApiErrors(url: string, err: Error) {
  if (!err || !err.response || !err.response.data) {
    return {
      status: 500,
      title: 'Unknown error',
      source: url,
      detail: err.toString,
      otherErrors: [],
    };
  }
  if (!err.response.data.otherErrors) {
    err.response.data.otherErrors = [];
    return err.response.data;
  }
  return err.response.data;
}

// Deprecated: use `get` with a validator instead 👇
export function get<T>(url: string, config = {}): Promise<T> {
  return new Promise((resolve, reject) => {
    axiosInstance.get(toUrl(url), PlatformNameService.withPlatformName(config)).then(
      (response) => resolve(response.data),
      (err) => {
        reject(toApiErrors(url, err));
      },
    );
  });
}

export type Normalize<T> = (data: T) => T;

export function getWith<T, >(
  url: string,
  normalize: Normalize<T>,
  config?: Record<string, unknown>
): Promise<T> {
  return new Promise((resolve, reject) => {
    axiosInstance.get(toUrl(url), PlatformNameService.withPlatformName(config)).then(
      (response) => resolve(normalize(response.data)),
      (err) => {
        reject(toApiErrors(url, err));
      },
    );
  });
}

export function del<T>(url: string, config = {}): Promise<T> {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(toUrl(url), {
        params: {
          // Always add CSRF param for authenticated DELETE request
          csrfToken: getCookie('PLAY_CSRF'),
          platformName: PlatformNameService.getPlatform(config),
        },
      })
      .then(
        (response) => resolve(response.data),
        (err) => {
          reject(toApiErrors(url, err));
        },
      );
  });
}

export function post<T, >(url: string, data: unknown, config = {}): Promise<T> {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(toUrl(url), data, {
        params: {
          // Always add CSRF param for authenticated POST request
          csrfToken: getCookie('PLAY_CSRF'),
          platformName: PlatformNameService.getPlatform(config),
        },
      })
      .then(
        (response) => resolve(response.data),
        (err) => {
          reject(toApiErrors(url, err));
        },
      );
  });
}

export function put<T>(url: string, data: unknown, config = {}): Promise<T> {
  return new Promise((resolve, reject) => {
    axiosInstance
      .put(toUrl(url), data, {
        params: {
          // Always add CSRF param for authenticated PUT request
          csrfToken: getCookie('PLAY_CSRF'),
          platformName: PlatformNameService.getPlatform(config),
        },
      })
      .then(
        (response) => resolve(response.data),
        (err) => {
          reject(toApiErrors(url, err));
        },
      );
  });
}

export type UploadProgress = (progressEvent: unknown) => void;

export function upload<T, >(
  url: string,
  data: unknown,
  onProgress?: UploadProgress,
): Promise<T> {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(toUrl(url), data, {
        params: {
          // Always add CSRF param for authenticated POST request
          csrfToken: getCookie('PLAY_CSRF'),
        },
        onUploadProgress: onProgress,
      })
      .then(
        (response) => resolve(response.data),
        (err) => reject(err),
      );
  });
}
