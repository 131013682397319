import { useCallback, useRef } from 'react';
import { useMutation as useMainMutation } from 'react-query';
import { DataError } from 'shared/types';
import {
  SAVE_ERROR_MESSAGE,
  SAVE_SUCCESS_MESSAGE,
  apiErrorToUiNotifier,
  uiNotifier,
} from 'shared/services';
import { DataProviderType, Payload, useDataProvider } from 'shared/providers/dataProvider/index';

export type OnSuccess<T> = (data?: T) => void;

export type OnFailure = (error?: DataError) => void;

export interface MutationParams<T> extends Payload {
  onSuccess?: OnSuccess<T>;
  onFailure?: OnFailure;
  [key: string]: unknown;
}

export interface StateMutate<T> {
  data?: T;
}

export type MutationDataProviderType = 'update' | 'deleteOne' | 'create';

export interface UseMutationReturn<T> {
  mutate: (params?: MutationParams<T>) => void;
  data?: T;
  error: unknown;
  loading: boolean;
}

const useMutation = <T extends Record<string, unknown>>(
  resource: string,
  type: MutationDataProviderType,
  params?: MutationParams<T>,
  messageSuccess = SAVE_SUCCESS_MESSAGE,
  messageFailure = SAVE_ERROR_MESSAGE,
): UseMutationReturn<T> => {
  const dataProvider = useDataProvider();
  const mutationParams = useRef<MutationParams<T>>(params ? {...params} : {});

  const onSuccess = useCallback((data) => {
    uiNotifier('success', messageSuccess);
    mutationParams.current?.onSuccess?.(data);
  }, [messageSuccess]);

  const onFailure = useCallback((error) => {
    apiErrorToUiNotifier(messageFailure)(error);
    mutationParams.current?.onFailure?.(error);
  }, [messageFailure]);

  const { mutate, data, error, isLoading } = useMainMutation((mutateParams?: MutationParams<T>) => {
    let mainParams: MutationParams<T> = {};

    if (params) {
      mainParams = { ...params };
    }
    if (mutateParams) {
      mainParams = { ...mainParams, ...mutateParams };
    }
    mutationParams.current = mainParams;

    return (dataProvider as DataProviderType)[type]<T>(resource, mainParams);
  }, { onSuccess, onError: onFailure });

  return { mutate, data, error, loading: isLoading };
};

export default useMutation;
