import classNames from 'classnames';
import moment from 'moment';
import { i18n } from 'shared/i18n';
import 'shared/style/common/preview.scss';
import { Show } from '../utils/Displays';

export interface PreviewContentProps {
  titleLabel?: string;
  titleValue?: string;
  showPublishButton?: boolean;
  previewUrl: string;
  publicationDate?: Date | string;
  onEditClicked: () => void;
  onPublishClicked?: () => void;
  onGoBackClicked: () => void;
}

const PreviewContent = ({
  previewUrl,
  onEditClicked,
  onPublishClicked,
  onGoBackClicked,
  titleLabel = i18n('labels.title'),
  titleValue = '',
  showPublishButton = false,
  publicationDate = new Date(),
}: PreviewContentProps) => (
  <div className="preview">
    <div className="preview-header">
      <div className="preview-prev-btn-container">
        <button type="button" className="preview-prev-btn" onClick={onGoBackClicked}>
          <i className={classNames('icon-arrow', 'preview-prev-btn-icon')} />
          <span className='preview-prev-btn-text'>{i18n('labels.back')}</span>
        </button>
      </div>
      <div className='preview__section'>
        <span className='preview__section-label'>{titleLabel}</span>
        <span className='preview__section-value'>{titleValue}</span>
      </div>
      <div className='preview__section'>
        <span className='preview__section-label'>{i18n('generalSection.publicationDate')}</span>
        <span className='preview__section-value'>{moment(publicationDate).format('DD/MM/YYYY')}</span>
      </div>
      <div className='preview-buttons'>
        <button type="button" className='preview-edit-btn' onClick={onEditClicked}>
          <i className={classNames('icon-edit', 'preview-edit-btn-icon')} />
          &nbsp;
        </button>
        <Show when={showPublishButton}>
          <button type="button" className='preview-publish-btn' onClick={onPublishClicked}>
            <i className={classNames('icon-paper-plane', 'preview-publish-btn-icon')} />
            {i18n('publication.published')}
          </button>
        </Show>
      </div>
    </div>
    <div className='preview-iframe-container'>
      <iframe title="preview" className='preview-iframe' src={decodeURIComponent(previewUrl)} />
    </div>
  </div>
);

export default PreviewContent;
