import { useContext, useState } from 'react';
import { apiErrorToUiNotifier, SIGN_IN_ERROR_MESSAGE } from 'shared/services';
import AuthContext from './AuthContext';

export interface AuthParams {
  email: string;
  password: string;
}

export interface UseSignInReturn {
  singIn: (params: unknown) => void;
  loading: boolean;
}

const useSignIn = (): UseSignInReturn => {
  const authProvider = useContext(AuthContext);
  const [loading, setLoading] = useState<boolean>(false);

  const singIn = (params: unknown) => {
    const { email, password } = params as AuthParams;
    setLoading(true);
    authProvider?.login(email, password).then((user) => {
      setLoading(false);
      authProvider?.setUser(user);
      window.location.reload();
    }).catch(() => {
      apiErrorToUiNotifier(SIGN_IN_ERROR_MESSAGE)();
      setLoading(false);
    });
  };

  return { singIn, loading };
};

export default useSignIn;
