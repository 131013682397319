import { forwardRef } from 'react';
import { FormSection } from 'layout/content';
import { SelectInput }  from 'shared/form/inputs';
import { getSubsectors, Sectors } from 'shared/models';
import { i18n } from 'shared/i18n';
import { CommonFieldProps } from 'shared/form/types';
import { SelectOption } from 'shared/types';
import useArticleSectorInput from './useArticleSectorInput';

export interface ArticleSectionInputProps extends CommonFieldProps {
  onChangeSubsubSector?: (value: SelectOption, subSector?: string) => void;
  onChangeSubSector?: (value: SelectOption) => void;
  onChangeSector?: (value: SelectOption) => void;
  nameSector: string;
  nameSubSector: string;
  nameSubSubSector: string;
  labelSector?: string;
  labelSubsector?: string;
  labelSubsubSector?: string;
}

const ArticleSectionInput = forwardRef<HTMLElement, ArticleSectionInputProps>(({
  id,
  title,
  nameSector,
  nameSubSector,
  nameSubSubSector,
  onChangeSector,
  onChangeSubSector,
  onChangeSubsubSector,
  required = false,
  labelSector = i18n('articleCategorySection.sectorLabel'),
  labelSubsector = i18n('articleCategorySection.labelSubsector'),
  labelSubsubSector = i18n('articleCategorySection.labelSubsubSector'),
}, ref) => {
  const {
    valueSector,
    subsubSectorOpts,
    onChangeSubSubSector,
  } = useArticleSectorInput({
    nameSector,
    nameSubSector,
    nameSubSubSector,
    onChangeSubsubSector,
  });

  return (
    <FormSection ref={ref} title={title} id={id}>
      <div>
        <SelectInput
          required={required}
          label={labelSector}
          name={nameSector}
          onChange={onChangeSector}
          options={Sectors.values}
        />
        <SelectInput
          clearable={false}
          name={nameSubSector}
          required={required}
          label={labelSubsector}
          onChange={onChangeSubSector}
          options={getSubsectors(valueSector || '')}
        />
        {subsubSectorOpts.length > 0 && (
          <SelectInput
            name={nameSubSubSector}
            clearable={false}
            onChange={onChangeSubSubSector}
            label={labelSubsubSector}
            options={subsubSectorOpts}
          />
        )}
      </div>
    </FormSection>
  );
});

export default ArticleSectionInput;
