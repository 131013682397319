import { useCallback, useMemo } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { toNewsHomepageEncodedUrl } from 'shared/utils/preview';
import {
  ActionsCell,
  DataTableRow,
  DateFieldCell,
  PublicationFieldCell,
  TextFieldCell,
} from 'shared/components/dataTableRow';
import { i18n } from 'shared/i18n';
import { HomepageListModel } from 'views/homes/types';
import './homeRow.scss';

export interface HomeRowProps {
  onItemDeleted?: (item: HomepageListModel) => void;
  current: boolean;
  item: HomepageListModel;
}

const HomeRow = ({ current, item, onItemDeleted }: HomeRowProps) => {
  const { slug } = item.scheduledHomepage;
  const isPublished = moment(item.scheduledHomepage.at).isBefore(moment.now());
  const encodedUrl = toNewsHomepageEncodedUrl(slug);
  const newUrl = `/home/preview/${item.scheduledHomepage.slug}/${encodedUrl}`;

  const deleteHomepage = useCallback((event) => {
    event.stopPropagation();
    if (onItemDeleted) {
      onItemDeleted(item);
    }
  }, [item, onItemDeleted]);

  const [statusText, icon] = useMemo(() => {
    if (!item.scheduledHomepage.at) {
      return [i18n('publication.notPublished'), null];
    }
    if (isPublished) {
      return [i18n('publication.published'), 'icon-check-circle'];
    }
    return [i18n('publication.programFor'), 'icon-clock'];
  }, [item, isPublished]);

  return (
    <DataTableRow<HomepageListModel> record={item}>
      <TextFieldCell className='home-row-first-cell' source="scheduledHomepage.name">
        {current ? (
          <div className='home-row-current-container'>
            <i className={classNames('icon-pin', 'home-row-current-icon')} />
          </div>
        ) : null}
      </TextFieldCell>
      <TextFieldCell source="scheduledHomepage.author" />
      <DateFieldCell source="scheduledHomepage.creationDate" />
      <PublicationFieldCell
        published={isPublished}
        source="scheduledHomepage.at"
        statusText={statusText}
        icon={icon}
      />
      <ActionsCell
        deleteAction={deleteHomepage}
        canDelete={!item.current}
        editUrl={`/home/${item.scheduledHomepage.slug}`}
        targetPreviewAction="_blank"
        viewName="home"
        preview={newUrl}
      />
    </DataTableRow>
  );
};

export default HomeRow;
