import { Pagination } from 'shared/types';
import { GetListParams, QueryStateList } from './types';
import useQuery  from './useQuery';

export interface UseGetListReturn<T> {
  data: T[];
  pagination?: Pagination;
  loading: boolean;
  error: unknown;
  refetch: <T>(params: GetListParams) => Promise<T>;
  doRefetch: <T>(params: GetListParams) => Promise<T>;
  onPageChange: (page?: number) => void;
}

const useGetList = <T extends Record<string, unknown>>(
  resource: string,
  options?: GetListParams,
): UseGetListReturn<T> => {
  const { data, pagination, refetch, error, loading, onPageChange, doRefetch } = useQuery<T>(
    resource,
    'getList',
    options,
  ) as QueryStateList<T>;

  return {
    data: data || [],
    pagination,
    loading,
    error,
    refetch,
    doRefetch,
    onPageChange,
  };
};

export default useGetList;
