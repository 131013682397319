import moment from 'moment';
import { createSelectOption } from 'shared/models';
import { isStringNotEmpty } from 'shared/utils/utils';
import { Dossier, QuestionSlugs } from 'views/dossiers/types';
import { InputDossier } from './types';
import { appConfig, REST } from 'shared/config/env';
import { SelectOption } from 'shared/types';

const CARTES_BLANCHES_LABEL = 'CARTES BLANCHES';

const asDossierQuestions = (questions: QuestionSlugs[]) =>
  // 6 = 5 question + carte blanche
  [...new Array(6)].map(
    (_, index) =>
      questions[index] || {
      question: '',
        slugs: [],
      },
  );

const filterNonEmptyQuestions = (questions: QuestionSlugs[]) =>
  questions.filter((questionItem) => isStringNotEmpty(questionItem.question) && questionItem.slugs.length > 0);

export const toDossierOutDto = (formModel: InputDossier) => {
  const questions = filterNonEmptyQuestions([...formModel.questions, formModel.carteBlanche]);
  const subSector = appConfig.queryType === REST ? 'subsector' : 'subSector';

  return {
    title: formModel.title,
    [subSector]: formModel.sector?.value,
    tableRonde: formModel.tableRonde.value,
    questions,
    publicationDate: formModel.publication.date,
    isPublished: formModel.publication.isPublished,
  };
};

const extractCarteBlancheFrom = (questions: QuestionSlugs[]) =>
  questions.find((item) => item.question === CARTES_BLANCHES_LABEL) || {
    question: CARTES_BLANCHES_LABEL,
    slugs: [],
  };

export const dossierSectorsChoices = [
  { value: 'Technologies', label: 'Technologies' },
  { value: 'FinanceLegal', label: 'Finance & Legal' },
  { value: 'RessourcesHumaines', label: 'Ressources Humaines' },
];

export const formattingDossier = (value: Dossier, tableRonde: SelectOption): InputDossier => {
  const allQuestions = asDossierQuestions(value.questions);
  const carteBlanche = extractCarteBlancheFrom(allQuestions);
  const questions = allQuestions.filter((question) => question !== carteBlanche);

  return {
    carteBlanche,
    questions,
    slug: value.slug,
    title: value.title,
    publication: {
      ...value.publication,
      date: moment(value.publication.date).toDate(),
    },
    sector: dossierSectorsChoices.find((item) => item.value === value.subsector || item.value === value.subSector),
    tableRonde: createSelectOption(tableRonde.value, tableRonde.label),
  };
};
