import { CreateParams, useMutation } from 'shared/providers/dataProvider';
import { MutationParams } from 'shared/providers/dataProvider/useMutation';

export interface UseCreateReturn<T> {
  create: (params?: MutationParams<T>) => void;
  data?: T;
  error: unknown;
  loading: boolean;
}

const useCreate = <T extends Record<string, unknown>>(
  resource: string,
  params?: CreateParams,
  messageSuccess?: string,
  messageFailure?: string,
): UseCreateReturn<T> => {
  const { mutate, data, error, loading } = useMutation<T>(
    resource,
    'create',
    params,
    messageSuccess,
    messageFailure,
    );

  return { create: mutate, data, error, loading };
};

export default useCreate;
