import { DocumentNode, gql } from '@apollo/client';
import { GetListParams, GetSearchParams, GetOneParams } from 'shared/providers/dataProvider/types';
import { appConfig } from 'shared/config/env';
import { resources } from 'shared/constants';
import { ArticleSortingCriteriaAlias } from 'shared/services/graphql/aliases';
import { getSort } from 'shared/services/graphql/utils';

const list = (params?: GetListParams): DocumentNode => {
  const offset =
    params?.page !== undefined
      ? params.page === 1
        ? appConfig.offset
        : (params.page - 1) * Number(appConfig.limit)
      : appConfig.offset;

  const [sort, asc] = getSort(ArticleSortingCriteriaAlias, params?.sort);

  const query = params?.titleQuery || '';

  return gql`
    query {
      articles (
        limit: ${appConfig.limit},
        offset: ${offset},
        platformName: ${appConfig.platformName},
        sort: "${sort}",
        asc: ${asc},
        query: "${query}"
      ) {
        ... on ArticlesResponse {
          items {
            slug
            platformName
            woodwingRef
            publication {
              date
              isPublished
              target {
                news
                club
              }
            }
            metadata {
              title
              titleComplement
              creationDate
              updateDate
              categorizations {
                sector
                subSector
                subSubSector {
                  slug
                  name
                  subSector
                }
              }
              author {
                name
              }
            }
            content {
              mainMedia {
                mainImage {
                  filename
                  caption
                  credits
                  focusPoint {
                    x
                    y
                  }
                }
              }
            }
          }
          count
          limit
          offset
        }
        ... on ErrorPayload {
          traceId
          errorMessage
          validationErrors {
            code
            message
          }
        }
        ... on NoResult {
          message
        }
      }
    }
  `;
};

const retrieve = <T>(params?: GetOneParams<T>): DocumentNode =>
  gql`
    query {
      articleWithTitles (
        slug: "${params?.id}"
        platformName: ${appConfig.platformName},
      ) {
        ... on ArticleWithTitles {
          slug
          platformName
          woodwingRef
          metadata {
            title
            titleComplement
            creationDate
            updateDate
            format
            categorizations {
              sector
              subSector
              subSubSector {
                slug
                name
                subSector
              }
            }
            author
            topics
            tags
            linkedContent {
              articles {
                slug
                title
              }
              sponsoredContent {
                slug
                title
              }
              events {
                slug
                title
              }
            }
            audioId
            showAudio
          }
          mainImage {
            filename
            caption
            credits
            focusPoint {
              x
              y
            }
          }
          publication {
            date
            isPublished
            target {
              news
              club
            }
            newsTicker
          }
        }

      ... on ErrorPayload {
        traceId
        errorMessage
        validationErrors {
          code
          message
        }
      }
      
      ... on NoResult {
        message
      }

    }
  }
`;

const search = (params?: GetSearchParams): DocumentNode => {
  const mainQueryResource = params?.additionalResource ? params.additionalResource : resources.ARTICLES;

  let query = `
    query {
      ${mainQueryResource} (
        query: "${params?.q || ''}"
        platformName: ${appConfig.platformName}
        filter: "${params?.filter || ''}"
      ) {
        ... on ArticlesSearchOutput {
          items {
            slug
            title
          }
        }
        ... on ErrorPayload {
          traceId
          errorMessage
          validationErrors {
            code
            message
          }
        }
        ... on NoResult {
          message
        }
      }
    }
  `;

  if (!params?.filter) {
    query = query.replace('filter: ""', '');
  }

  return gql`
    ${query}
  `;
};

const update = (): DocumentNode => gql`
  mutation UpdateArticle($updateData: ArticleMetadataAndPublicationUpdateInput!) {
    updateArticleMetadataAndPublication(updateData: $updateData) {
      ... on ArticleMetadataAndPublicationUpdateOutput {
        slug
        platformName
        titleComplement
        topics
        categorizationsDto {
          sector
          subSector
          subSubSector {
            slug
            name
            subSector
          }
        }
        linkedContent {
          articles
          sponsoredContent
          events
        }
        tags
        publicationDate
        updateDate
        isPublished
        showAudio
        newsTicker
      }
      ... on ErrorPayload {
        traceId
        errorMessage
        validationErrors {
          code
          message
        }
      }
    }
  }
`;

const articlesGraphqlServices = {
  list,
  retrieve,
  search,
  update,
};

export default articlesGraphqlServices;
