import { forwardRef, ReactNode, RefObject } from 'react';
import classNames from 'classnames';
import { FormSection as BaseFormSection } from 'shared/types';
import './FormSection.scss';

export interface FormSectionProps extends BaseFormSection {
  className?: string;
  children: ReactNode;
}

const FormSection = forwardRef<HTMLElement, FormSectionProps>(({
  children,
  className,
  id = '',
  title = '',
}, ref) => (
  <div ref={ref as RefObject<HTMLDivElement>} id={id} className={classNames('section', className)}>
    <h3 className='section-title'>{title}</h3>
    <div className='section-content'>{children}</div>
  </div>
));

export default FormSection;
