import './TimePicker.scss';
import { ChangeEvent } from 'react';

export interface TimeInputProps {
  disabled: boolean;
  onBlur?: () => void;
  value?: string;
  onChange?: (event: ChangeEvent) => void;
}

const TimeInput = ({ disabled, onBlur, ...props }: TimeInputProps) => (
  <input
    {...props}
    disabled={disabled}
    className='time-picker'
    onBlur={onBlur}
  />
);

export default TimeInput;
