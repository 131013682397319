import moment from 'moment';
import 'layout/header/header.scss';
import Header from 'layout/header/Header';
import { i18n } from 'shared/i18n';
import { useWatch } from 'shared/form';
import { appConfig } from 'shared/config/env';

export interface HeaderNewsletterProps {
  isCreating: boolean;
  initialTitle?: string;
  initialPublicationDate: Date | string;
}

const HeaderNewsletter = ({ isCreating, initialPublicationDate, initialTitle }: HeaderNewsletterProps) => {
  const [title, publicationDate] = useWatch(['title', 'publicationDate']) as [string, string | Date];

  return (
    <Header>
      <div className='header__inner'>
        <h1 className='header-title'>
          {isCreating ? i18n('newsletters.view.createTitle') : i18n('newsletters.view.updateTitle')}
        </h1>
        <div className='header__section'>
          <span className='header__section-label'>Titre de la Newsletter</span>
          <span className='header__section-value'>{title || initialTitle}</span>
        </div>
        <div className='header__section'>
          <span className='header__section-label'>Date de publication</span>
          <span className='header__section-value'>{moment(
            publicationDate || initialPublicationDate
          ).format(appConfig.dateFormat)}</span>
        </div>
      </div>
    </Header>
  );
};

export default HeaderNewsletter;
