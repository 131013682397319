import { useInput } from 'shared/form/index';
import { CommonFieldProps, InputProps } from 'shared/form/types';
import 'shared/form/inputs/styles/CheckboxInput.scss';

export type CheckboxInputProps = InputProps<boolean> & CommonFieldProps;

const CheckboxInput = ({ name, label, required, defaultValue = false, }: CheckboxInputProps) => {
  const input = useInput<boolean>({ name, defaultValue });

  return (
    <label className='checkbox-input-label'>
      <input
        className={!!input.value ? 'checkbox-input-checkbox-checked' : 'checkbox-input-checkbox'}
        required={required}
        name={input.name}
        type="checkbox"
        checked={Boolean(input.value)}
        onChange={input.onChange}
      />
      {label}
    </label>
  );
};

export default CheckboxInput;
