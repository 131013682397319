import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Content, FormSection } from 'layout/content';
import useGetSectionRef from 'layout/ScrollspyProvider/useGetSectionRef';
import { TopicSelectInput, DnDArrayInput, ArticleSelectInput } from 'shared/form/inputs';
import { ActionsBar } from 'shared/components';
import { SelectOption } from 'shared/types';
import { Form } from 'shared/form';
import * as constants from './constants';
import './homeDetail.scss';
import Header from './Header';
import {
  BreakingNewsSectionInput,
  GeneralSectionInput,
  FocusAreaSectionInput,
  ALaUneSectionInput,
} from './inputs';
import HomeModal from './HomeModal';
import useInteractionHomepageDetailView from './useInteractionHomepageDetailView';

const HomeDetailView = ({ match }: RouteComponentProps<{ id: string }>) => {
  const sectionRefs = useGetSectionRef();
  const {
    onModalClose,
    onSubmit,
    onPublish,
    openPreview,
    validate,
    homepageData,
    isPublishModalOpen,
    loading,
    aLaUne,
    general,
  } = useInteractionHomepageDetailView(match);

  return (
    <Form onSubmit={onSubmit} validate={validate} initialValues={homepageData}>
      <Header isCreating={match.params.id === 'create'} />
      <Content loading={loading}>
        <div className='home-detail-content'>
          {general && <GeneralSectionInput ref={sectionRefs[constants.HomeGeneralId]} />}
          <BreakingNewsSectionInput ref={sectionRefs[constants.HomeBreakingNewsId]} />
          <TopicSelectInput
            ref={sectionRefs[constants.HomeHotTopicsId]}
            id={constants.HomeHotTopicsId}
            name="hotTopics"
            title={constants.HomeHotTopicsLabel}
          />
          <FocusAreaSectionInput ref={sectionRefs[constants.HomeFocusAreaId]}/>
          {aLaUne && (
            <>
              <ALaUneSectionInput ref={sectionRefs[constants.HomeAlaUneId]} name="aLaUne" />
              <FormSection
                ref={sectionRefs[constants.HomeTopRecommandesId]}
                title={constants.HomeTopRecommandesLabel}
                id={constants.HomeTopRecommandesId}
              >
                <DnDArrayInput<SelectOption>
                  required
                  name="aLaUne.recommandedArticles"
                  label="labels.article"
                  numOfItems={5}
                >
                  <ArticleSelectInput />
                </DnDArrayInput>
              </FormSection>
            </>
          )}
        </div>
        <ActionsBar
          canPreview={match.params.id !== 'create'}
          canPublish={match.params.id === 'create'}
          canUpdate={match.params.id !== 'create'}
          preview={openPreview}
          publish={onPublish}
        />
      </Content>
      <HomeModal onModalClose={onModalClose} isPublishModalOpen={isPublishModalOpen} />
    </Form>
  );
};

export default withRouter(HomeDetailView);
