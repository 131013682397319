import { Auth } from 'aws-amplify';
import { ApolloClient, DocumentNode, FetchResult, InMemoryCache } from '@apollo/client';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { CreateParams, DeleteParams, UpdateParams } from 'shared/providers';
import { ResponseListBody } from 'shared/types';
import { DataProviderType } from 'shared/providers/dataProvider';
import { providerType } from 'shared/constants';
import { apiConfig } from 'shared/config/env';
import { servicesGraphql, uiNotifier } from 'shared/services';
import { GetOneParams, GetListParams, GetSearchParams } from 'shared/providers/dataProvider/types';
import { transformCreateData, transformUpdateData } from './transform';

const makeRequest = async <T>(
  type: string,
  query?: DocumentNode,
  variables?: Record<string, unknown>,
): Promise<FetchResult<T> | ApolloQueryResult<T>> => {
  const session = await Auth.currentSession();
  const client = new ApolloClient({
    uri: apiConfig.endpointGraphqlUrl,
    cache: new InMemoryCache(),
    headers: {
      authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
    },
    credentials: 'same-origin',
  });

  let response;
  if ([providerType.UPDATE, providerType.CREATE, providerType.DELETE_ONE].indexOf(type) !== -1 && query && variables) {
    response = await client.mutate({ mutation: query, variables });
  } else if (query) {
    response = await client.query({ query });
  } else {
    return Promise.reject(new Error(`No service found for type: ${type}`));
  }

  if (response.data && 'errorMessage' in response.data) {
    throw new Error(
      `Error occurred while making request of type ${type} with query ${query}: ${response.data.errorMessage}`,
    );
  }

  return response;
};

const getResult = <T>(
  resource: string,
  params: Record<string, unknown>,
  data?: Record<string, unknown> | null,
): Promise<T> => {
  let mainResource = resource;
  if (params.additionalResource) {
    mainResource = params.additionalResource as string;
  }

  if (data) {
    const mainResourceData = data[mainResource] as Record<string, unknown>;

    if (mainResourceData === null) {
      const error: Error = new Error('No data');
      return Promise.reject(error) as Promise<T>;
    }

    if (mainResourceData['__typename'] === 'NoResult') {
      uiNotifier('info', mainResourceData.message as string);
      return Promise.resolve({} as T);
    }

    if (mainResourceData['__typename'] === 'ErrorPayload') {
      const error: Error = new Error(`${mainResourceData.errorMessage} : ${mainResourceData.traceId}`);
      return Promise.reject(error) as Promise<T>;
    }

    if (mainResourceData.error) {
      return Promise.reject({ detail: mainResourceData.error }) as Promise<T>;
    }

    return mainResourceData as unknown as Promise<T>;
  } else {
    const error: Error = new Error('Invalid data');
    return Promise.reject(error) as Promise<T>;
  }
};

const graphqlProvider: DataProviderType = {
  getList: async <T>(resource: string, params?: GetListParams) => {
    const response = await makeRequest(providerType.GET_LIST, servicesGraphql(providerType.GET_LIST, resource)(params));

    if (response && (response.data as Record<string, unknown>)[resource]) {
      const currentData = (response.data as Record<string, unknown>)[resource] as ResponseListBody<T>;
      if (currentData.__typename === 'ErrorPayload') {
        throw new Error(`${currentData.errorMessage} : ${currentData.traceId}`);
      }
      if (currentData.__typename === 'NoResult') {
        uiNotifier('info', currentData.message as string);
      }

      let lastPage = 1;
      if (currentData.count && currentData.limit && currentData.count > currentData.limit) {
        lastPage = Math.ceil(currentData.count / currentData.limit);
      }

      return {
        items: currentData.items,
        pagination: { page: 1, lastPage },
      };
    }

    return Promise.reject(`No results found while searching resource ${resource} with params ${params}`);
  },
  getOne: async <T = Record<string, unknown>>(resource: string, params?: GetOneParams<T>) => {
    const response = await makeRequest<Record<string, unknown>>(
      providerType.GET_ONE,
      servicesGraphql(providerType.GET_ONE, resource)(params),
    );

    return getResult<T>(resource, params as Record<string, unknown>, response.data);
  },
  getSearch: async <T = Record<string, unknown>[]>(resource: string, params?: GetSearchParams) => {
    const response = await makeRequest<Record<string, unknown>>(
      providerType.GET_SEARCH,
      servicesGraphql(providerType.GET_SEARCH, resource)(params),
    );
    let mainResource = resource;
    if (params && params.additionalResource) {
      mainResource = params.additionalResource;
    }
    if (response.data) {
      if (response.data && Array.isArray(response.data[mainResource])) {
        return response.data[mainResource] as Promise<T>;
      }

      const dataListBody = response.data[mainResource] as ResponseListBody<Record<string, unknown>>;
      if (dataListBody.items) {
        return dataListBody.items as unknown as Promise<T>;
      }
    }
    return Promise.reject();
  },
  create: async <T = Record<string, unknown>>(resource: string, params: CreateParams = {}) => {
    const response = await makeRequest<Record<string, unknown>>(
      providerType.CREATE,
      servicesGraphql(providerType.CREATE, resource)(params),
      transformCreateData(resource, params.payload),
    );

    return getResult<T>(resource, params as Record<string, unknown>, response.data);
  },
  update: async <T = Record<string, unknown>>(resource: string, params: UpdateParams = {}) => {
    const response = await makeRequest<Record<string, unknown>>(
      providerType.UPDATE,
      servicesGraphql(providerType.UPDATE, resource)(params),
      transformUpdateData(resource, params.payload),
    );

    return getResult<T>(resource, params as Record<string, unknown>, response.data);
  },
  deleteOne: async (resource: string, params: DeleteParams) => {
    const response = await makeRequest<Record<string, unknown>>(
      providerType.DELETE_ONE,
      servicesGraphql(providerType.DELETE_ONE, resource)(params),
      params.payload as Record<string, unknown>,
    );

    return getResult(resource, params as Record<string, unknown>, response.data);
  },
};

export default graphqlProvider;
