import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Location } from 'history';
import classNames from 'classnames';
import intercept, { Handler } from 'shared/utils/eventInterceptors';
import './Nav.scss';

export const computeStyle = (selected: boolean) =>
  classNames({
    ['nav-item']: true,
    ['nav-item-selected']: selected,
  });

export interface NavItemProps {
  route: string;
  selected: boolean;
  iconClass: string;
  onChangeRoute: (location: Location) => void;
  children: ReactNode;
}

const NavItem = ({
  route,
  selected,
  iconClass,
  onChangeRoute,
  children,
}: NavItemProps) => {
  const itemStyle = computeStyle(selected);

  return (
    <li className={itemStyle}>
      <Link to={route} className='nav-item-link'>
        <div className='nav-item-link-icon-container'>
          <i className={classNames(iconClass, 'nav-item-link-icon')} />
        </div>
        <div className='nav-item-link-inner' onClick={intercept(onChangeRoute as Handler, { route })}>
          {children}
        </div>
      </Link>
    </li>
  );
};

export default NavItem;
