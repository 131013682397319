import {
  FormEvent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import createSubject from 'shared/form/createSubject';
import { isSame, getProp, setProp, clone } from 'shared/utils';
import FormContext, { FormState } from './FormContext';

type SubmitType = (value: Record<string, unknown>) => void;

export interface FormProviderProps {
  initialValues?: Record<string, unknown>;
  validate?: (value: Record<string, unknown>) => Record<string, unknown>;
  onSubmit: SubmitType;
  onChange?: (value: unknown, name: string) => void;
  children: ReactNode;
}

const FormProvider = ({
  children,
  validate,
  onSubmit,
  onChange: customChange,
  initialValues = {},
}: FormProviderProps) => {
  const [formState, setFormState] = useState<FormState>({
    isValid: false,
    isDirty: false,
  });
  const formValues = useRef<Record<string, unknown>>(initialValues ? clone(initialValues) : {});
  const prevValues = useRef<Record<string, unknown>>(initialValues ? clone(initialValues) : {});
  const subject = createSubject();

  const runValidation = useCallback((values) => {
    let errors;
    if (validate) {
      errors = validate(values);
    }
    const isValid = !(errors && Object.keys(errors).length > 0);

    if (isValid !== formState.isValid) {
      setFormState((prevState) => ({ ...prevState, isValid }));
    }
    return isValid;
  }, [formState, validate]);

  useEffect(() => {
    if (!isSame(prevValues.current, initialValues)) {
      prevValues.current = clone(initialValues);
      runValidation(initialValues);
      formValues.current = clone(initialValues);
      subject.notify(formValues.current);
    }
  }, [runValidation, formValues, initialValues, prevValues, subject]);

  const handleSubmit = useCallback( (event?: FormEvent) => {
    if (event) {
      event.preventDefault && event.preventDefault();
    }

    const isValid = runValidation(formValues.current);
    if (isValid) {
      onSubmit(formValues.current);
    }
    if (formState.isDirty) {
      setFormState((prevState) => ({ ...prevState, isDirty: false }));
    }
  }, [formState, runValidation, onSubmit]);

  const handleChange = useCallback((name: string, value: unknown) => {
    if (customChange) {
      customChange(value, name);
    }

    if (!formState.isDirty) {
      setFormState((prevState) => ({ ...prevState,  isDirty: true }));
    }

    setProp(formValues.current, name, value);
    runValidation(formValues.current);
    subject.notify(formValues.current, name);
  }, [customChange, formState, runValidation, subject]);

  const getValues = useCallback((name?: string) => {
    return getProp(formValues.current, name);
  }, [formValues]);

  const setValue = useCallback((name: string, value: unknown) => {
    const fieldValue = getProp(formValues.current, name);
    if (fieldValue !== value) {
      setProp(formValues.current, name, value);
      subject.notify(formValues.current, name);
      runValidation(formValues.current);
    }
  }, [runValidation, subject]);

  return (
    <FormContext.Provider value={{
      initialValues,
      formState,
      formValues: formValues.current,
      subject,
      setValue,
      handleChange,
      getValues,
    }}>
      <form onSubmit={handleSubmit}>
        {children}
      </form>
    </FormContext.Provider>
  );
};

export default FormProvider;
