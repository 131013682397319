const DELANO_BREAKFAST = 'delanobreakfast';
const DELANO_NOON = 'delanonoon';
const MORNING = 'morning';
const EVENING = 'evening';
const CLUB = 'club';
const CLUB_EN = 'club_en';
const GRAND_DOSSIER = 'granddossier';
const TRENDIN = 'trendin';

const types = {
  DELANO_NOON,
  DELANO_BREAKFAST,
  MORNING,
  EVENING,
  CLUB,
  CLUB_EN,
  GRAND_DOSSIER,
  TRENDIN,
};

export default types;
