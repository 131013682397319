import { types } from 'shared/constants';
import { ImageUpload } from 'shared/types';
import DelanoBreakfastNoonNewsletterForm from 'views/newsletter/categories/DelanoBreakfastNoonNewsletterForm';
import ClubNewsletterForm from './ClubNewsletterForm';
import MorningEveningNewsletterForm from './MorningEveningNewsletterForm';
import TrendinNewsletterForm from './TrendinNewsletterForm';
import GrandDossiersNewsletterForm from './GrandDossiersNewsletterForm';

export type FileSelect = (image: ImageUpload) => void;
export type FileRemove = (name: string) => void;

const CategorizationForm = (type: string, onFileSelect: FileSelect, onRemoveFile: FileRemove) => {
  switch (type) {
    case types.CLUB:
      return <ClubNewsletterForm type={type} />;
    case types.CLUB_EN:
      return <ClubNewsletterForm type={type} />;
    case types.TRENDIN:
      return <TrendinNewsletterForm
        onFileSelect={onFileSelect}
        onRemoveFile={onRemoveFile}
      />;
    case types.GRAND_DOSSIER:
      return <GrandDossiersNewsletterForm
        onFileSelect={onFileSelect}
        onRemoveFile={onRemoveFile}
      />;
    case types.DELANO_BREAKFAST:
    case types.DELANO_NOON:
      return <DelanoBreakfastNoonNewsletterForm
        type={type}
        onFileSelect={onFileSelect}
        onRemoveFile={onRemoveFile}
      />;
    case types.MORNING:
    case types.EVENING:
      return <MorningEveningNewsletterForm
        type={type}
        onFileSelect={onFileSelect}
        onRemoveFile={onRemoveFile}
      />;
    default:
      return null;
  }
};

export default CategorizationForm;
