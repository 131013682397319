import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Content, FormSection } from 'layout/content';
import useGetSectionRef from 'layout/ScrollspyProvider/useGetSectionRef';
import FormHeader from 'shared/components/header/FormHeader';
import { eventFormatsAsSelectOptions, eventThemesAsSelectOptions } from 'shared/models';
import { i18n } from 'shared/i18n';
import { Form } from 'shared/form';
import {
  ArrayInput,
  ArticleSelectInput,
  BiographySelectInput,
  EventSelectInput,
  GeneralInput,
  SelectInput,
  SimpleInput,
  SponsorSelectInput,
  TagSelectInput,
} from 'shared/form/inputs';
import { ActionsBar } from 'shared/components';
import { SelectOption } from 'shared/types';
import * as eventConstants from './constants';
import useInteractionEventView from './useInteractionEventView';
import './eventView.scss';

export interface EventViewProps extends RouteComponentProps<{ slug: string }> {
  isWoodwing?: boolean;
}

const EventView = ({ match, isWoodwing = false }: EventViewProps) => {
  const sectionsRef = useGetSectionRef();
  const { onSubmit, openPreview, validate, fetchError, event, isLoading } = useInteractionEventView({
    match,
    isWoodwing,
  });

  return fetchError && isWoodwing ? (
    <div className="woodwing-error">{i18n('events.messages.syncError')}</div>
  ) : (
    <Form onSubmit={onSubmit} validate={validate} initialValues={event}>
      {!isWoodwing && <FormHeader label={i18n('events.view.editTitle')} titleName="metadata.title" />}
      <Content loading={isLoading}>
        <div className="event-content">
          <div className="event-sections">
            <GeneralInput title={event.metadata.title} nameTitle="metadata.title" isTitleEditable={false} />
            <FormSection
              ref={sectionsRef[eventConstants.eventFormatMenuItem.id]}
              title={eventConstants.eventFormatMenuItem.label}
              id={eventConstants.eventFormatMenuItem.id}
            >
              <SelectInput
                required
                label={i18n('events.view.format')}
                name="metadata.format"
                options={eventFormatsAsSelectOptions}
              />

              <SelectInput
                required
                label={i18n('events.view.theme')}
                name="metadata.theme"
                options={eventThemesAsSelectOptions}
              />
            </FormSection>

            <FormSection
              ref={sectionsRef[eventConstants.eventOratorsMenuItem.id]}
              title={eventConstants.eventOratorsMenuItem.label}
              id={eventConstants.eventOratorsMenuItem.id}
            >
              <ArrayInput<SelectOption> name="content.speakers" label="events.view.speaker">
                <BiographySelectInput filter="isSpeaker:true" />
              </ArrayInput>
            </FormSection>

            <TagSelectInput
              ref={sectionsRef[eventConstants.eventTagsMenuItem.id]}
              title={eventConstants.eventTagsMenuItem.label}
              id={eventConstants.eventTagsMenuItem.id}
              name="metadata.tags"
              label={i18n('events.view.tags')}
            />

            <FormSection
              ref={sectionsRef[eventConstants.eventSponsorsGoldMenuItem.id]}
              title={eventConstants.eventSponsorsGoldMenuItem.label}
              id={eventConstants.eventSponsorsGoldMenuItem.id}
            >
              <ArrayInput<SelectOption> name="content.sponsors.sponsorsGold" label="workshops.view.sponsorsGold">
                <SponsorSelectInput />
              </ArrayInput>
            </FormSection>

            <FormSection
              ref={sectionsRef[eventConstants.eventSponsorsSilverMenuItem.id]}
              title={eventConstants.eventSponsorsSilverMenuItem.label}
              id={eventConstants.eventSponsorsSilverMenuItem.id}
            >
              <ArrayInput<SelectOption> name="content.sponsors.sponsorsSilver" label="workshops.view.sponsorSilver">
                <SponsorSelectInput />
              </ArrayInput>
            </FormSection>

            <FormSection
              ref={sectionsRef[eventConstants.eventArticlesMenuItem.id]}
              title={eventConstants.eventArticlesMenuItem.label}
              id={eventConstants.eventArticlesMenuItem.id}
            >
              <ArrayInput<SelectOption> name="metadata.linkedContent.articles" label="labels.article">
                <ArticleSelectInput platformName="paperjam" />
              </ArrayInput>
            </FormSection>

            <FormSection
              ref={sectionsRef[eventConstants.eventEventsMenuItem.id]}
              title={eventConstants.eventEventsMenuItem.label}
              id={eventConstants.eventEventsMenuItem.id}
            >
              <ArrayInput<SelectOption> name="metadata.linkedContent.events" label="labels.event">
                <EventSelectInput withWorkshop />
              </ArrayInput>
            </FormSection>

            <FormSection
              ref={sectionsRef[eventConstants.eventPhotosMenuItem.id]}
              title={eventConstants.eventPhotosMenuItem.label}
              id={eventConstants.eventPhotosMenuItem.id}
            >
              <ArticleSelectInput platformName="paperjam" label="" name="content.photo" />
            </FormSection>

            <FormSection
              ref={sectionsRef[eventConstants.eventVideoMenuItem.id]}
              title={eventConstants.eventVideoMenuItem.label}
              id={eventConstants.eventVideoMenuItem.id}
            >
              <ArticleSelectInput platformName="paperjam" name="content.video" label="" />
            </FormSection>

            <FormSection
              ref={sectionsRef[eventConstants.eventIdMenuItem.id]}
              title={eventConstants.eventIdMenuItem.label}
              id={eventConstants.eventIdMenuItem.id}
            >
              <SimpleInput name="eventzillaEventId" />
            </FormSection>
          </div>
          <ActionsBar canUpdate canPreview canPublish={false} preview={openPreview} />
        </div>
      </Content>
    </Form>
  );
};

export default withRouter(EventView);
