import { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import './Pagination.scss';

export interface PaginationProps {
  onPageChange: (page: number) => void;
  lastPage: number;
  currentPage: number;
  buttonsRange: number;
}

const Pagination = ({ onPageChange, buttonsRange, lastPage, currentPage }: PaginationProps) => {
  const goTo = useCallback((event) => {
    const index = event.currentTarget.value;
    let nextPage;
    switch (index) {
      case 'first':
        nextPage = 1;
        break;
      case 'last':
        nextPage = lastPage;
        break;
      case 'next':
        nextPage = currentPage <= lastPage ? currentPage + 1 : lastPage;
        break;
      case 'prev':
        nextPage = currentPage >= 1 ? currentPage - 1 : 1;
        break;
      default:
        nextPage = parseInt(index);
        break;
    }
    onPageChange(nextPage);
  }, [currentPage, lastPage, onPageChange]);

  const canGoNextOrLast = useMemo(() => currentPage < lastPage, [currentPage, lastPage]);
  const canGoPrevOrFirst = useMemo(() => currentPage > 1, [currentPage]);
  const indexesToDisplay = useMemo(() =>
    Array.from(Array(lastPage).keys()) // creates an array containing all page numbers
      .map((index) => index + 1) // pages start at one
      .filter((index) => index <= currentPage + buttonsRange && index >= currentPage - buttonsRange),
    [buttonsRange, currentPage, lastPage]
  );

  return (
    <div className='pagination'>
      <button
        type="button"
        hidden={!canGoPrevOrFirst}
        className='pagination-btn'
        value="first"
        onClick={goTo}
      >
        <i className="icon-first" />
      </button>
      <button
        type="button"
        hidden={!canGoPrevOrFirst}
        className='pagination-btn'
        value="prev"
        onClick={goTo}
      >
        <i className="icon-prev" />
      </button>
      {indexesToDisplay.map((index) => (
        <button
          type="button"
          key={index}
          className={classNames('pagination-btn', {
            ['pagination-btn-active']: currentPage === index,
          })}
          value={index}
          onClick={goTo}
        >
          {index}
        </button>
      ))}
      <button
        type="button"
        hidden={!canGoNextOrLast}
        className='pagination-btn'
        value="next"
        onClick={goTo}
      >
        <i className="icon-next" />
      </button>
      <button
        type="button"
        hidden={!canGoNextOrLast}
        className='pagination-btn'
        value="last"
        onClick={goTo}
      >
        <i className="icon-last" />
      </button>
    </div>
  );
};

export default Pagination;
