import { UpdateParams, useMutation } from 'shared/providers/dataProvider';
import { MutationParams } from 'shared/providers/dataProvider/useMutation';

export interface UseUpdateReturn<T> {
  update: (params?: MutationParams<T>) => void;
  data?: T;
  error: unknown;
  loading: boolean;
}

const useUpdate = <T extends Record<string, unknown>> (
  resource: string,
  params?: UpdateParams,
  messageSuccess?: string,
  messageFailure?: string,
): UseUpdateReturn<T> => {
  const { mutate, data, error, loading } = useMutation<T>(
    resource,
    'update',
    params,
    messageSuccess,
    messageFailure,
  );

  return { update: mutate, data, error, loading };
};

export default useUpdate;
