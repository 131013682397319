import { useCallback } from 'react';
import classNames from 'classnames';
import { i18n } from 'shared/i18n';
import { DateTimeInput } from 'shared/form/inputs';
import 'shared/components/modal/Modal.scss';
import { Slug } from 'shared/types';
import { Form } from 'shared/form';
import { useCreate } from 'shared/providers';
import { resources } from 'shared/constants';
import { ClubHomepage } from 'views/clubHome/types';
import './publishClubHomeModal.scss';
import { PublishData } from '../useInteractionClubHomeDetailsView';

export interface PublishClubHomeModalProps {
  onModalClose: () => void;
  setPublicationState: (data: PublishData) => void;
  isLoading:  boolean;
  clubHomepage?: ClubHomepage;
}

const PublishClubHomeModal = ({
  onModalClose,
  setPublicationState,
  isLoading,
  clubHomepage,
}: PublishClubHomeModalProps) => {
  const { create } = useCreate<Slug>(resources.CLUB_HOMEPAGE);

  const onSubmit = useCallback((value) => {
      create({
        payload: clubHomepage,
        onSuccess: (data) => {
          setPublicationState({ at: value.data, slug: data?.slug });
        }
      });
  }, [clubHomepage, create, setPublicationState]);

  return (
    <Form onSubmit={onSubmit}>
      <div className='publish-modal'>
        <div className='modalHeader'>
          <i onClick={onModalClose} className={classNames('icon-delete', 'close-icon')} />
          <span className='modalHeaderTitleLabel'>{i18n('clubHome.confirmPublication')}</span>
        </div>
        <div className='modal-content'>
          <div className='publish-modal-pickers'>
            <DateTimeInput
              required
              labelDate="generalSection.publicationDate"
              labelTime="generalSection.publicationHour"
              name="date"
            />
          </div>
          <button type="submit" className='publish-modal-confirm'>
            {isLoading && <div className='loader' />}
            {i18n('actionBar.confirm')}
          </button>
        </div>
      </div>
    </Form>
  );
};

export default PublishClubHomeModal;
