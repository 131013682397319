import { MouseEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { match } from 'react-router';
import { apiErrorToUiNotifier, LOAD_ERROR_MESSAGE, SAVE_ERROR_MESSAGE } from 'shared/services';
import { FormMode } from 'shared/models';
import { i18n } from 'shared/i18n';
import { isStringNotEmpty } from 'shared/utils/utils';
import { setErrors } from 'shared/form/utils';
import { Dossier } from 'views/dossiers/types';
import { formattingDossier, toDossierOutDto } from './utils';
import { DossierResult, InputDossier } from './types';
import { resources } from 'shared/constants';
import { useCreate, useUpdate, useGetOne } from 'shared/providers';
import { Article } from 'views/article/types';
import { DataType } from 'shared/providers/dataProvider/types';

export interface UseInteractionDossierDetailViewReturn {
  onSubmit: (value: Record<string, unknown>) => void;
  validate: (value: Record<string, unknown>) => Record<string, unknown>;
  dossier: InputDossier;
  mode: string;
  onPreview: () => void;
  onPublish: (event: MouseEvent<HTMLElement>, value?: unknown) => void;
  loading: boolean;
}

const useInteractionDossierDetailView = (match: match<{ slug: string }>): UseInteractionDossierDetailViewReturn => {
  const history = useHistory();
  const { data, loading } = useGetOne<Dossier>(resources.DOSSIERS, {
    id: match.params.slug,
    additionalResource: resources.DOSSIER,
  });

  const [dossier, setDossier] = useState<InputDossier>({
    slug: '',
    title: '',
    publication: {
      date: new Date(),
      isPublished: false,
    },
    sector: { value: '', label: '' },
    tableRonde: { value: '', label: '' },
    questions: new Array(5).fill(0).map(() => ({
      question: '',
      slugs: [],
    })),
    carteBlanche: {
      question: 'CARTES BLANCHES',
      slugs: [],
    },
  });

  const [tableRondeSlug, setTableRondeSlug] = useState<string>('');
  const [articleData, setArticleData] = useState<unknown>({});

  const onSuccess = (resData: DataType<Record<string, unknown>>) => {
    if (resData) {
      setArticleData(resData);
    }
  };

  useGetOne<Article>(resources.ARTICLES, {
    id: tableRondeSlug,
    additionalResource: resources.ARTICLE_WITH_TITLES,
    allowedRequest: !!tableRondeSlug && !!dossier.slug,
    onSuccess,
  });

  const { update } = useUpdate(resources.DOSSIERS, { additionalResource: resources.UPDATE_DOSSIER });
  const { create } = useCreate(resources.DOSSIERS, { additionalResource: resources.CREATE_DOSSIER });

  const mode = match.path === '/dossier/create' ? FormMode.CREATE : FormMode.EDIT;

  const onSubmit = (value: Record<string, unknown>) => {
    const dossierOutDTO = toDossierOutDto(value as InputDossier);
    if (mode === FormMode.EDIT) {
      dossierOutDTO.slug = match.params.slug;
      update({
        payload: dossierOutDTO,
        id: match.params.slug,
      });
    }
  };

  const onPreview = () => {
    history.push(`/dossier/${dossier.slug}/preview`);
  };

  const onPublish = (event: MouseEvent<HTMLElement>, value?: unknown) => {
    if (value) {
      const dossierOutDTO = toDossierOutDto(value as InputDossier);
      create({
        payload: dossierOutDTO,
        onSuccess: (data) => {
          const { createDossier } = data as DossierResult;
          const { slug } = (createDossier as Record<string, string>) || data;
          if (!slug) {
            history.push('/dossiers');
            apiErrorToUiNotifier(LOAD_ERROR_MESSAGE);
          } else {
            history.push(`/dossier/${slug}`);
          }
        },
        onFailure: () => {
          apiErrorToUiNotifier(SAVE_ERROR_MESSAGE);
        },
      });
    }
  };

  const validate = (value: Record<string, unknown>) => {
    const errors = {};
    if (value) {
      const dossierInput = value as InputDossier;
      if (!dossierInput.publication.date) {
        setErrors(errors, 'publication.date', i18n('forms.validation.required'));
      }
      if (!isStringNotEmpty(dossierInput.title)) {
        setErrors(errors, 'title', i18n('forms.validation.required'));
      }
      if (!dossierInput.sector) {
        setErrors(errors, 'sector', i18n('forms.validation.required'));
      }
      if (dossierInput.sector && !isStringNotEmpty(dossierInput.sector.value)) {
        setErrors(errors, 'sector.value', i18n('forms.validation.required'));
      }
      if (!dossierInput.tableRonde) {
        setErrors(errors, 'tableRonde', i18n('forms.validation.required'));
      }
      if (dossierInput.tableRonde && !isStringNotEmpty(dossierInput.tableRonde.value)) {
        setErrors(errors, 'tableRonde.value', i18n('forms.validation.required'));
      }
    }
    return errors;
  };

  useEffect(() => {
    if (data) {
      setTableRondeSlug(data.tableRonde);
    } else {
      apiErrorToUiNotifier(SAVE_ERROR_MESSAGE);
    }
  }, [data]);

  useEffect(() => {
    if (data && tableRondeSlug) {
      if (articleData) {
        const currentData = articleData as Article;
        const tableRonde = {
          value: tableRondeSlug,
          label: currentData?.metadata?.title,
        };
        const formattedDossier = formattingDossier(data, tableRonde);
        setDossier({ ...formattedDossier });
      }
    }
  }, [data, tableRondeSlug, articleData]);

  return {
    onSubmit,
    dossier,
    mode,
    onPreview,
    validate,
    onPublish,
    loading,
  };
};

export default useInteractionDossierDetailView;
