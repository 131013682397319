import { MouseEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { match } from 'react-router';
import { useImages } from 'shared/hooks';
import { i18n } from 'shared/i18n';
import { FormMode } from 'shared/models';
import { isSame } from 'shared/utils';
import { useCreate, useUpdate, useGetOne } from 'shared/providers';
import { resources } from 'shared/constants';
import { appConfig, REST } from 'shared/config/env';
import { INITIAL_STATE } from 'views/workshop/model/WorkshopFormModel';
import { isStringNotEmpty } from 'shared/utils/utils';
import { setErrors } from 'shared/form/utils';
import { ImageUpload } from 'shared/types';
import { Workshop, WorkshopInput, WorkshopInputAsString } from 'views/workshops/types';
import { toWorkshopDto } from 'views/workshop/utils/toWorkshopDto';

export interface UseInteractionWorkshopViewReturn {
  onSubmit: (value: Record<string, unknown>) => void;
  validate: (value: Record<string, unknown>) => Record<string, unknown>;
  workshopView: WorkshopInput;
  loading: boolean;
  mode: string;
  publish: (event: MouseEvent<HTMLElement>, value: unknown) => void;
  openPreview: (value: unknown) => void;
  onFileSelect: (image: ImageUpload) => void;
  onRemoveFile: (name: string) => void;
}

const useInteractionWorkshopView = (match: match<{ slug: string }>): UseInteractionWorkshopViewReturn => {
  const history = useHistory();
  const [workshopView, setWorkshopView] = useState<WorkshopInput>(INITIAL_STATE);
  const [workshopMode, setWorkshopMode] = useState<string>(FormMode.CREATE);
  const { images, onFileSelect, onRemoveFile, onRemoveAllFiles } = useImages();
  const { create } = useCreate(resources.WORKSHOPS);

  const { update } = useUpdate(resources.WORKSHOPS, {
    additionalResource: resources.UPDATE_WORKSHOP,
  });

  const onSuccess = (data: unknown) => {
    setWorkshopView(data as WorkshopInput);
  };

  const { loading } = useGetOne<Workshop>(resources.WORKSHOPS, {
    id: match.params.slug,
    onSuccess,
    additionalResource: resources.WORKSHOP,
    allowedRequest: !(match.path === '/workshop/create' || !isSame(workshopView, INITIAL_STATE)),
  });

  const publish = (event: MouseEvent<HTMLElement>, value: unknown) => {
    let payload: Record<string, unknown> = toWorkshopDto(value as WorkshopInputAsString);
    if (appConfig.queryType !== REST && images.length > 0) {
      payload = { ...payload, images };
    }

    create({
      payload,
      additionalResource: resources.CREATE_WORKSHOP,
      onSuccess: (data) => {
        onRemoveAllFiles();
        if (data && data.slug) {
          history.push(`/workshop/${data.slug}`);
        }
      },
    });
  };

  const onSubmit = (value: Record<string, unknown>) => {
    const workShopInput = toWorkshopDto(value as WorkshopInputAsString);
    let payload: Record<string, unknown> = { slug: value.slug, ...workShopInput };
    if (appConfig.queryType !== REST && images.length > 0) {
      payload = { ...payload, images };
    }

    update({ id: value.slug, payload, onSuccess: onRemoveAllFiles });
  };

  const openPreview = (value: unknown) => {
    const workshopInput = value as WorkshopInput;
    history.push(`/workshop/${workshopInput.slug}/preview`);
  };

  const validate = (value: Record<string, unknown>) => {
    const errors = {};

    const workshopInput = value as WorkshopInput;
    if (workshopInput) {
      if (!workshopInput.publication.date) {
        setErrors(errors, 'publication.date', i18n('forms.validation.required'));
      }
      if (!workshopInput.practicalInfo.beginDate) {
        setErrors(errors, 'practicalInfo.beginDate', i18n('forms.validation.required'));
      }
      if (!isStringNotEmpty(workshopInput.title)) {
        setErrors(errors, 'title', i18n('forms.validation.required'));
      }
      if (workshopInput.description) {
        if (!isStringNotEmpty(workshopInput.description.chapeau)) {
          setErrors(errors, 'description.chapeau', i18n('forms.validation.required'));
        }
        if (!isStringNotEmpty(workshopInput.description.slugline)) {
          setErrors(errors, 'description.slugline', i18n('forms.validation.required'));
        }
      } else {
        setErrors(errors, 'description', i18n('forms.validation.required'));
      }
      if (workshopInput.morning && workshopInput.morning.length === 0) {
        setErrors(errors, 'morning', i18n('forms.validation.required'));
      } else if (workshopInput.morning && workshopInput.morning.length > 0) {
        if (
          workshopInput.morning.some((morningValue) => morningValue === undefined || morningValue.toString() === '')
        ) {
          setErrors(errors, 'morning', i18n('workshop.forms.validation.afternoon'));
        }
      }
      if (workshopInput.afternoon && workshopInput.afternoon.length === 0) {
        setErrors(errors, 'afternoon', i18n('forms.validation.required'));
      } else if (workshopInput.afternoon && workshopInput.afternoon.length > 0) {
        if (
          workshopInput.afternoon.some(
            (afternoonValue) => afternoonValue === undefined || afternoonValue.toString() === '',
          )
        ) {
          setErrors(errors, 'afternoon', i18n('workshop.forms.validation.afternoon'));
        }
      }
      if (
        workshopInput.eventSponsors.sponsorsGold &&
        !workshopInput.eventSponsors?.sponsorsGold?.every((item) => item !== null)
      ) {
        setErrors(errors, 'eventSponsors.sponsorsGold', i18n('workshop.forms.validation.sponsorsGold'));
      }
      if (
        workshopInput.eventSponsors.sponsorsSilver &&
        !workshopInput.eventSponsors.sponsorsSilver?.every((item) => item !== null)
      ) {
        setErrors(errors, 'eventSponsors.sponsorsSilver', i18n('workshop.forms.validation.sponsorsSilver'));
      }
      if (!workshopInput.linkedContent.articles.every((article) => article !== null)) {
        setErrors(errors, 'linkedContent.articles', i18n('workshop.forms.validation.articles'));
      }
      if (!workshopInput.linkedContent.events.every((event) => event !== null)) {
        setErrors(errors, 'linkedContent.events', i18n('workshop.forms.validation.events'));
      }
      if (workshopInput.mainImage.filename === '') {
        setErrors(errors, 'mainImage.filename', i18n('forms.validation.required'));
      }
    }

    return errors;
  };

  useEffect(() => {
    const mode = match.path === '/workshop/create' ? FormMode.CREATE : FormMode.EDIT;
    if (mode === FormMode.EDIT) {
      if (isSame(workshopView, INITIAL_STATE)) {
        setWorkshopMode(mode);
      }
    } else {
      setWorkshopMode(mode);
    }
  }, [match, workshopView]);

  return {
    onSubmit,
    validate,
    workshopView,
    loading,
    mode: workshopMode,
    publish,
    openPreview,
    onFileSelect,
    onRemoveFile,
  };
};

export default useInteractionWorkshopView;
