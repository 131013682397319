import 'layout/header/header.scss';

export interface HeaderSectionProps {
  label: string;
  value?: string;
}

const HeaderSection = ({ label, value }: HeaderSectionProps) => (
  <div className='header__section'>
    <span className='header__section-label'>{label}</span>
    <span className='header__section-value'>{value}</span>
  </div>
);

export default HeaderSection;
