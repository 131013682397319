import { i18n } from 'shared/i18n';
import { ImageUpload } from 'shared/types';
import { useRemoveFile } from 'shared/hooks';
import ToggleInput from './ToggleInput';
import SimpleInput from './SimpleInput';
import FileInput from './FileInput';

export interface AdInputProps {
  togglerId: string;
  toggleInputName: string;
  uploadUrl: string;
  filename: string;
  nameLink: string;
  onFileSelect: (image: ImageUpload) => void;
  onRemoveFile: (name: string) => void;
}

const AdInput = ({
  togglerId,
  uploadUrl,
  nameLink,
  filename,
  toggleInputName,
  onFileSelect,
  onRemoveFile,
}: AdInputProps) => {
  useRemoveFile({ toggleName: toggleInputName, filename, onRemoveFile });

  return (
    <ToggleInput name={toggleInputName} id={togglerId}>
      <SimpleInput
        required
        width="100%"
        label={i18n('labels.link')}
        name={nameLink}
      />
      <FileInput
        required
        onRemoveFile={onRemoveFile}
        onFileSelect={onFileSelect}
        name={filename}
        uploadUrl={uploadUrl}
        label={i18n('labels.image')}
      />
    </ToggleInput>
  );
};

export default AdInput;
