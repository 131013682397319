import { UserSession } from 'shared/types';
import { CognitoUser } from '@aws-amplify/auth';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { filterAndSortRoles } from 'shared/utils/rolesUtils';

export const toUserSessionDto = async (response: CognitoUser): Promise<UserSession> => {
  const cognitoUserSession: CognitoUserSession | null = response.getSignInUserSession();

  const signInUserSession = cognitoUserSession?.getIdToken().payload ?? {};

  const cognitoRoles = signInUserSession['cognito:groups'] || [];
  const sortedRoles = filterAndSortRoles(cognitoRoles);

  const userSession: UserSession = {
    _id: signInUserSession.jti || '',
    createdAt: signInUserSession.auth_time || 0,
    email: signInUserSession.email || '',
    expireAt: signInUserSession.exp || 0,
    firstName: signInUserSession.name || '',
    lastName: signInUserSession.family_name || '',
    profilePictureUrl: '',
    roleNames: sortedRoles,
    sessionKind: '',
    userReference: signInUserSession.event_id || '',
    username: signInUserSession.preferred_username || '',
  };

  return Promise.resolve(userSession);
};
