import { resources } from 'shared/constants';
import { SelectOption } from 'shared/types';
import { useInput } from 'shared/form';
import { SelectInputProps } from 'shared/form/types';
import ArticleSelect from 'shared/components/form/select/ArticleSelect';

const ArticleSelectInput = ({
  label,
  onChange: customChange,
  filter,
  name = '',
  platformName,
  defaultValue,
  required = false,
}: SelectInputProps) => {
  const input = useInput({ name, defaultValue });

  const onChange = (value: SelectOption) => {
    if (customChange) {
      customChange(value);
    }
    input.onChange(value);
  };

  return (
    <ArticleSelect
      clearable
      {...input}
      resource={resources.ARTICLES}
      onChange={onChange}
      platformName={platformName}
      label={label}
      filter={filter}
      required={required}
    />
  );
};

export default ArticleSelectInput;
