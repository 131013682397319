export const ArticleSortingCriteria = {
  Title: 'Title',
  Author: 'Author',
  CreationDate: 'CreationDate',
  PublicationDate: 'PublicationDate',
  RevTitle: '-Title',
  RevAuthor: '-Author',
  RevCreationDate: '-CreationDate',
  RevPublicationDate: '-PublicationDate',
};

export const HomePageSortingCriteria = {
  CreationDate: 'CreationDate',
  RevCreationDate: '-CreationDate',
};

export const NewsletterSortingCriteria = {
  CreationDate: 'CreationDate',
  RevCreationDate: '-CreationDate',
  PublicationDate: 'PublicationDate',
  RevPublicationDate: '-PublicationDate',
};

export const EventSortingCriteria = {
  CreationDate: 'CreationDate',
  RevCreationDate: '-CreationDate',
  PublicationDate: 'PublicationDate',
  RevPublicationDate: '-PublicationDate',
  Title: 'Title',
  RevTitle: '-Title',
};

export const WorkshopSortingCriteria = {
  CreationDate: 'CreationDate',
  RevCreationDate: '-CreationDate',
  PublicationDate: 'PublicationDate',
  RevPublicationDate: '-PublicationDate',
  Title: 'Title',
  RevTitle: '-Title',
};

export const DossierSortingCriteria = {
  CreationDate: 'CreationDate',
  RevCreationDate: '-CreationDate',
  PublicationDate: 'PublicationDate',
  RevPublicationDate: '-PublicationDate',
  Title: 'Title',
  RevTitle: '-Title',
};

export const PodcastShowSortingCriteria = {
  CreationDate: 'CreationDate',
  RevCreationDate: '-CreationDate',
  PublicationDate: 'PublicationDate',
  RevPublicationDate: '-PublicationDate',
  Title: 'Title',
  RevTitle: '-Title',
};
