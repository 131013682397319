import './shared/assets/icons/styles.css';
import 'shared/style/index.scss';
import 'shared/style/core/index.scss';
import 'toast.scss';

import 'pikaday/css/pikaday.css';
import 'react-toastify/dist/ReactToastify.css';

import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Routing, { routeStore } from 'shared/stores/routeStore';
import { DataProvider, AuthProvider } from 'shared/providers';
import { appConfig, REST } from 'shared/config/env';
import { restApiProvider, authProvider, graphqlProvider } from 'shared/services';
import UserSessionProvider from 'shared/stores/user/UserSessionProvider';
import Layout from 'layout/Layout';
import Views from 'views/Views';

const App = () => (
  <BrowserRouter basename="">
    <Routing.Provider value={routeStore}>
      <UserSessionProvider>
        <AuthProvider provided={authProvider}>
          <DataProvider provider={appConfig.queryType === REST ? restApiProvider : graphqlProvider}>
            <Layout>
              <Views routeStore={routeStore} />
              <ToastContainer className='toast-container' />
            </Layout>
          </DataProvider>
        </AuthProvider>
      </UserSessionProvider>
    </Routing.Provider>
  </BrowserRouter>
);

export default App;
