import React from 'react';
import { Link } from 'react-router-dom';
import { SimpleInput } from 'shared/form/inputs';
import '../resetPasswordView.scss';
import { Form } from 'shared/form';
import { i18n } from 'shared/i18n';
import useInteractionHandleResetPasswordInput from './useInteractionHandleResetPasswordInput';

interface ResetEmailComponentProps {
    onConfirmPassword: (code: string, newPassword: string) => void;
    loading: boolean
}

const ResetPasswordComponent: React.FC<ResetEmailComponentProps> = ({ onConfirmPassword, loading }) => {
    const {
        showPassword,
        isDisabled,
        errorMessage,
        strengthStyle,
        handleCodeChange,
        handleNewPasswordChange,
        handlePasswordChange,
        handleFormSubmit,
        togglePasswordVisibility,
    } = useInteractionHandleResetPasswordInput(onConfirmPassword, loading);

    return (
        <Form onSubmit={handleFormSubmit}>
            {errorMessage.length > 0 && <ul className='reset-error'>
                {errorMessage.map((error: string, index: number) => (
                    <li key={index}>{error}</li>
                ))}
            </ul>}
            <label className='reset-label' htmlFor="code">
                Code
            </label>
            <SimpleInput className='reset-code reset-input' type="text" name="code" onChange={handleCodeChange} placeholder="ex: 680813" />

            <label className='reset-label' htmlFor="newPassword">
                {i18n('resetPassword.newPassword')}
            </label>
            <div className='password-input-container'>
                <SimpleInput
                    className='reset-input'
                    type={showPassword ? 'text' : 'password'}
                    name="newPassword"
                    onChange={handleNewPasswordChange}
                    placeholder="........"
                />
                <div className='progress-bar'>
                    <div className={`password-strength-bar ${[strengthStyle]}`} ></div>
                </div>
                <div className='reset-show'>
                    <input type="checkbox" id="showPass" onClick={togglePasswordVisibility} />
                    <label htmlFor="showPass">{i18n('resetPassword.showPassword')}</label>
                </div>
            </div>
            <label className='reset-label' htmlFor="validatePassword">
                {i18n('resetPassword.verifyPassword')}
            </label>

            <SimpleInput className='reset-input' type="password" name="validatePassword" onChange={handlePasswordChange} placeholder="........" />

            {loading ? (
                <div className='reset-submit-loading' />
            ) : (<button className='reset-submit' disabled={isDisabled} type="submit" onClick={handleFormSubmit}>
                {i18n('actionBar.submit')}
            </button>)
            }

            <Link to="./" className='reset-forgotten-pwd'>{i18n('actionBar.cancel')}</Link>
        </Form>
    );
};

export default ResetPasswordComponent;
