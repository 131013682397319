import { useSetValue } from 'shared/form';
import { SelectInput, DateTimeInput } from 'shared/form/inputs';
import { i18n } from 'shared/i18n';
import { SelectOption } from 'shared/types';
import './publishArticleModal.scss';
import { SCHEDULED_MODE } from './usePublishArticleModal';

export interface FormContentProps {
  setPublishMode: (option: SelectOption) => void;
  mode?: SelectOption;
}

const FormContent = ({ setPublishMode, mode }: FormContentProps) => {
  const setValue = useSetValue();

  const onChange = (option: SelectOption) => {
    setPublishMode(option);
    setValue('date', new Date());
  };

  return (
    <>
      <div className='publish-modal-mode-selection'>
        <SelectInput
          searchable={false}
          clearable={false}
          label={i18n('actionBar.publicationMode')}
          name='mode'
          onChange={onChange}
          options={[
            {
              label: i18n('actionBar.instantPublication'),
              value: 'now',
            },
            SCHEDULED_MODE,
          ]}
        />
      </div>
      <div className='publish-modal-pickers'>
        <DateTimeInput
          name="date"
          labelDate="generalSection.publicationDate"
          labelTime="generalSection.publicationHour"
          disabled={mode && mode.value === 'now'}
        />
      </div>
    </>
  );
};

export default FormContent;
