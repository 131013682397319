import { appConfig } from 'shared/config/env';
import { i18n } from 'shared/i18n';
import { DelanoPlatform } from 'shared/models';

const PaperjamNewslettersOptions = [
  {
    label: i18n('newsletters.morningNewsletter'),
    value: 'morning',
  },
  {
    label: i18n('newsletters.eveningNewsletter'),
    value: 'evening',
  },
  {
    label: i18n('newsletters.clubNewsletter'),
    value: 'club',
  },
  {
    label: i18n('newsletters.clubEnNewsletter'),
    value: 'club_en',
  },
  {
    label: i18n('newsletters.trendinNewsletter'),
    value: 'trendin',
  },
  {
    label: i18n('newsletters.granddossierNewsletter'),
    value: 'granddossier',
  },
];

const DelanoNewslettersOptions = [
  {
    label: i18n('newsletters.morningNewsletter'),
    value: 'delanobreakfast',
  },
  {
    label: i18n('newsletters.eveningNewsletter'),
    value: 'delanonoon',
  },
];

export const NewsletterOptions =
  appConfig.platformName === DelanoPlatform ? DelanoNewslettersOptions : PaperjamNewslettersOptions;
