import { getFocusPoint } from 'shared/utils/images';
import { appConfig } from 'shared/config/env';
import { MainImage } from 'shared/types';
import { getProp } from 'shared/utils';
import './ImageCell.scss';

export interface ImageCellProps {
  source: string;
  alt: string;
  viewName: string;
  height?: string;
  sourceMainImage?: string;
  record?: Record<string, unknown>;
}

const ImageFieldCell = ({ source, record, alt, viewName, height='70', sourceMainImage='mainImage' }: ImageCellProps) => {
  const mainImage = getProp(record, sourceMainImage) as MainImage;
  const slug = getProp(record, source, '');
  const focusPoints = getFocusPoint(mainImage?.focusPoint);

  return (
    <td>
      <div className='image-cell-root'>
        <img
          width="100px"
          height={`${height}px`}
          alt={alt}
          src={
            `${appConfig.imagesCdnHost}/images/${viewName}/${slug}/${focusPoints}/100/${height}/${mainImage.filename}`
          }
        />
      </div>
    </td>
  );
};

export default ImageFieldCell;
