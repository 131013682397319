import { forwardRef } from 'react';
import { FormSection } from 'layout/content';
import { SimpleInput, SlugInput, ToggleInput } from 'shared/form/inputs';
import { resources } from 'shared/constants';
import { i18n } from 'shared/i18n';
import { FormSection as FormSectionProps } from 'shared/types';
import { ArticleSelect } from 'shared/components';

const CitationDuJour = forwardRef<HTMLElement, FormSectionProps>(({ id, title }, ref) => (
  <FormSection ref={ref} id={id} title={title}>
    <ToggleInput id="toggler-citationdujour" name="content.citationDuJour.isActive">
      <SimpleInput
        required
        width="100%"
        label="Citation"
        name="content.citationDuJour.quote"
      />
      <SimpleInput
        required
        width="100%"
        label="Auteur"
        name="content.citationDuJour.author"
      />
      <SimpleInput
        required
        width="100%"
        label={i18n('newsletters.view.description')}
        name="content.citationDuJour.description"
      />
      <SlugInput
        name="content.citationDuJour.slug"
        label={i18n('labels.article')}
        resource={resources.ARTICLES}
        options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}
      >
        <ArticleSelect />
      </SlugInput>
    </ToggleInput>
  </FormSection>
));

export default CitationDuJour;
