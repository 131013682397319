import { i18n } from 'shared/i18n';

export const workshopFormatMenuItem = {
  id: 'general-section',
  label: i18n('workshops.nav.general'),
};
export const workshopIntroMenuItem = {
  id: 'workshop-intro',
  label: i18n('workshops.nav.intro'), // 'INTRO',
};
export const workshopProgrammeMenuItem = {
  id: 'workshop-programme',
  label: i18n('workshops.nav.program'), // 'PROGRAMME',
};
export const workshopSponsorsMenuItem = {
  id: 'workshop-sponsors',
  label: i18n('workshops.nav.sponsors'), // 'SPONSORS',
};
export const workshopArticlesMenuItem = {
  id: 'workshop-articles',
  label: i18n('workshops.nav.articles'), // 'ARTICLES ASSOCIÉS',
};
export const workshopEvenementsMenuItem = {
  id: 'workshop-evenements',
  label: i18n('workshops.nav.associatedEvents'), // 'ÉVÉNEMENTS ASSOCIÉS',
};

export const eventSponsorsGoldMenuItem = {
  id: 'workshop-gold-sponsors',
  label: i18n('events.nav.goldSponsors'), // 'Gold sponsors',
};

export const eventSponsorsSilverMenuItem = {
  id: 'workshop-silver-sponsors',
  label: i18n('events.nav.silverSponsor'), // 'Silver Sponsors',
};
