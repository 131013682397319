import { useCallback, useMemo } from 'react';
import AsyncSelectLib from 'react-select/async';
import throttle from 'lodash.throttle';
import { SlugAndTitle, SelectOption, QueryParams } from 'shared/types';
import './Select.scss';

export interface AsyncSelectProps {
  filter?: string;
  getOptions?: (input: string) => Promise<SlugAndTitle[]>;
  loadOptions?: (params: QueryParams) => Promise<SlugAndTitle[]>;
  valueAccessor: (value: SlugAndTitle) => string;
  labelAccessor: (value: SlugAndTitle) => string;
  placeholder?: string;
  label?: string;
  required: boolean;
  disabled?: boolean;
  value?: SelectOption;
  onChange?: (value: SelectOption) => void;
  clearable: boolean;
  searchable: boolean;
  baseParams?: Record<string, unknown>;
}

const AsyncSelect = ({
  filter,
  loadOptions,
  getOptions,
  valueAccessor,
  labelAccessor,
  label,
  required,
  value,
  onChange,
  clearable,
  searchable,
  baseParams,
  disabled = false,
  placeholder = '',
}: AsyncSelectProps) => {
  const asyncSelectLoadOptions = useCallback((input) => {
    if (getOptions) {
      return getOptions(input).then((response) =>
        response.map((responseItem: SlugAndTitle) => ({
          value: valueAccessor(responseItem),
          label: labelAccessor(responseItem),
        })),
      );
    } else if (loadOptions) {
      const mainParams = { ...baseParams, filter };
      const params = input !== '' ? { ...baseParams, q: input } : mainParams;
      return loadOptions(params).then((response) =>
        response.map((responseItem: SlugAndTitle) => ({
          value: valueAccessor(responseItem),
          label: labelAccessor(responseItem),
        })),
      ).catch(e => {
        if (e) {
          console.error('An error occurred while fetching options: ', e);
        }
      });
    }
  }, [baseParams, filter, getOptions, labelAccessor, loadOptions, valueAccessor]);

  const noOptionsMessage = useMemo(() => () => 'Aucun résultat', []);
  const asyncSelectOnChange = useCallback((value) => {
    const active = document.activeElement;
    if (onChange) {
      onChange(value);
    }
    if (active) {
      (active as HTMLElement).blur();
    }
  }, [onChange]);

  return (
    <div className='select'>
      <span className='select-label'>
        {label}
        {required && <span className='select-required'>*</span>}
      </span>
      <AsyncSelectLib
        placeholder={placeholder}
        value={value}
        defaultOptions
        loadOptions={throttle(asyncSelectLoadOptions, 250)}
        noOptionsMessage={noOptionsMessage}
        cacheOptions
        onChange={asyncSelectOnChange}
        isDisabled={disabled}
        isClearable={clearable}
        isSearchable={searchable}
        styles={{
          menu: (styles) => Object.assign(styles, { zIndex: 999999 }),
        }}
      />
    </div>
  );
};

export default AsyncSelect;
