import { forwardRef } from 'react';
import { i18n } from 'shared/i18n';
import { SimpleInput, SlugInput, ToggleInput } from 'shared/form/inputs';
import { resources } from 'shared/constants';
import { ArticleSelect } from 'shared/components';
import { FormSection } from 'layout/content';

export interface SlugAndTitleInputProps {
  id: string;
  title: string;
  name: string;
  toggleId: string;
  slugRequired?: boolean;
}

const SlugAndTitleInput = forwardRef<HTMLElement, SlugAndTitleInputProps>(({
  id,
  title,
  name,
  toggleId,
  slugRequired = false,
}, ref) => (
  <FormSection ref={ref} id={id} title={title}>
    <ToggleInput id={toggleId} name={`${name}.isActive`}>
      <SimpleInput
        required
        width="100%"
        label={i18n('labels.title')}
        name={`${name}.title`}
      />
      <SlugInput
        required={slugRequired}
        name={`${name}.slug`}
        label={i18n('labels.article')}
        resource={resources.ARTICLES}
        options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}
      >
        <ArticleSelect />
      </SlugInput>
    </ToggleInput>
  </FormSection>
));

export default SlugAndTitleInput;
