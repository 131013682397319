import moment from 'moment';
import { appConfig } from 'shared/config/env';
import { AdsContent } from 'views/newsletter/categories/types';

export const adToViewState = (ads?: AdsContent) => ({
  isActive: !!ads,
  imageFilename: ads ? ads.imageFilename : '',
  link: ads ? ads.link : '',
});

export const adFormInitialState = {
  isActive: false,
  imageFilename: '',
  link: '',
};

export const computeNewsletterTitle = (nlKind: string, publicationDate: string | Date) =>
  nlKind === 'club_en'
    ? `${nlKind.charAt(0).toUpperCase() + nlKind.slice(1)}_${
          moment(publicationDate).format(appConfig.dateFormatWithDashDelimiter)
        }`
    : `${nlKind.charAt(0).toUpperCase() + nlKind.slice(1)}_${
          moment(publicationDate).format(appConfig.dateFormatWithDashDelimiter)
        }`;
