import { MouseEvent } from 'react';

export type Handler = (args: unknown) => void;

function doIntercept(
  handler: Handler,
  event: MouseEvent,
  stopPropagation: boolean,
  preventDefault: boolean,
  args: Record<string, unknown>,
) {
  if (event) {
    if (stopPropagation) event.stopPropagation();
    if (preventDefault) event.preventDefault();
  }
  handler(args);
}

const intercept = (
  handler: Handler,
  args: Record<string, unknown>
) => (event: MouseEvent) => {
  doIntercept(handler, event, false, false, args);
};

export default intercept;
