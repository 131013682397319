import { selectSearchHitLabel, selectSearchHitValue } from 'shared/models/response/search';
import { Refetch, SelectOption, SlugAndTitle } from 'shared/types';
import { useSearch } from 'shared/providers';
import { resources } from 'shared/constants';
import AsyncSelect from 'shared/components/form/select/AsyncSelect';

export interface EventSelectProps {
  withWorkshop?: boolean;
  label?: string;
  onChange?: (value: SelectOption) => void;
  value?: SelectOption;
  filter?: string;
  required?: boolean;
  clearable?: boolean;
}

const EventSelect = ({
  label,
  onChange,
  filter,
  value,
  required = false,
  withWorkshop = false,
  clearable = true,
}: EventSelectProps) => {
  const { refetch: refetchEventAndWorkshop } = useSearch<SlugAndTitle[]>(resources.SEARCH_EVENT_AND_WORKSHOP);
  const { refetch: refetchEvent } = useSearch<SlugAndTitle[]>(resources.EVENTS_SEARCH);

  return (
    <AsyncSelect
      searchable
      clearable={clearable}
      onChange={onChange}
      label={label}
      value={value}
      baseParams={{additionalResource: withWorkshop ? resources.SEARCH_EVENT_AND_WORKSHOP : resources.EVENTS_SEARCH }}
      loadOptions={withWorkshop ? (refetchEventAndWorkshop as Refetch) : (refetchEvent as Refetch)}
      valueAccessor={selectSearchHitValue}
      labelAccessor={selectSearchHitLabel}
      filter={filter}
      required={required}
    />
  );
};

export default EventSelect;
