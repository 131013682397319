import { MouseEvent, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import {
  uiNotifier,
  INCOMPLETE_FORM_ERROR_MESSAGE,
  apiErrorToUiNotifier,
  DUPLICATE_ENTRIES_ERROR_MESSAGE,
} from 'shared/services';
import { useGetOne, useUpdate } from 'shared/providers';
import { resources } from 'shared/constants';
import { i18n } from 'shared/i18n';
import { useRunOnce } from 'shared/hooks';
import { toNewsHomepageEncodedUrl } from 'shared/utils/preview';
import { setErrors } from 'shared/form/utils';
import {
  CENTER_MEDIUM_ARTICLE,
  HEADLINE,
  LEFT_MEDIUM_ARTICLE,
  LIST_ARTICLES,
  RIGHT_MEDIUM_ARTICLE,
  TOP_MEDIUM_ARTICLE,
} from 'views/home/constants';
import { formState2ScheduledHomepageDto, homepage2FormState, inputValueListNotEmpty } from 'views/home/translators';
// Services
import { EmptyFocusArea } from './translators';
import { General, Homepage, HomepageInput } from './types';
import { SelectOption } from 'shared/types';
import { searchDuplicates } from 'shared/utils/utils';

export interface HomeDetailState {
  isPublishModalOpen: boolean;
}

const useInteractionHomepageDetailView = (match: RouteComponentProps<{ id: string }>['match']) => {
  const history = useHistory();
  const [homepageDetailState, setHomepageDetailState] = useState<HomeDetailState>({
    isPublishModalOpen: false,
  });

  const [homepageData, setHomepageData] = useState<Partial<HomepageInput>>({});

  const { update } = useUpdate(resources.NEWS_HOMEPAGE, {}, i18n('homes.messages.updateSuccess'));

  const onSuccess = (data?: unknown) => {
    if (data && Object.keys(data).length > 0) {
      const formState = homepage2FormState(data as Homepage);
      setHomepageData({
        ...formState,
        breakingNews: formState.breakingNews && {
          ...formState.breakingNews,
          isActive: !!formState.breakingNews.title,
        },
        focusArea: formState.focusArea && {
          ...formState.focusArea,
          isActive: formState.focusArea !== EmptyFocusArea,
        },
      });
    }
  };

  const { loading } = useGetOne<Homepage>(resources.NEWS_HOMEPAGE, {
    id: match.params.id,
    onSuccess,
    allowedRequest: !(match.params.id === 'create' || Object.keys(homepageData).length > 0),
  });

  useRunOnce(() => {
    if (match.params.id === 'create') {
      setHomepageData({
        aLaUne: {
          alaUneSections: [],
          recommandedArticles: [],
        },
        general: {
          date: new Date(),
        },
        breakingNews: {},
        hotTopics: [],
      });
    }
  });

  const openPreview = useCallback(() => {
    const homepageId = match.params.id;
    if (homepageId.length > 0) {
      const encodedUrl = toNewsHomepageEncodedUrl(homepageId);
      const newUrl = `/home/preview/${homepageId}/${encodedUrl}`;
      history.push(newUrl);
    } else {
      uiNotifier('warning', i18n('homes.messages.previewFailure'));
    }
  }, [history, match]);

  const updateHomepage = useCallback(
    (value, inputHomepage) => {
      if (value) {
        update({
          id: match.params.id,
          additionalResource: resources.UPDATE_NEWS_HOMEPAGE,
          payload: { ...value, slug: match.params.id },
          onSuccess: () => {
            setHomepageData(inputHomepage);
          },
        });
      } else {
        uiNotifier('error', INCOMPLETE_FORM_ERROR_MESSAGE);
      }
    },
    [match, update],
  );

  const onSubmit = useCallback(
    (value) => {
      const duplicateSections = checkDuplicates(value);
      if (duplicateSections.length > 0) {
        const duplicateErrorMsg = `${DUPLICATE_ENTRIES_ERROR_MESSAGE}:\n - ${duplicateSections.join('\n - ')}`;
        apiErrorToUiNotifier(duplicateErrorMsg)();
      } else {
        const inputHomepage = inputValueListNotEmpty(value);
        const homepageDto = formState2ScheduledHomepageDto(value);
        updateHomepage(homepageDto, inputHomepage);
      }
    },
    [updateHomepage],
  );

  const validate = (value: Record<string, unknown>) => {
    const { aLaUne, general, focusArea, breakingNews } = value as HomepageInput;
    const errors: Record<string, unknown> = {};
    const hasFocusArea = focusArea !== undefined && focusArea !== EmptyFocusArea;
    if (general && !(general as General).name) {
      setErrors(errors, 'general.name', i18n('forms.validation.required'));
    }

    if (breakingNews?.isActive && !breakingNews.title) {
      setErrors(errors, 'breakingNews.title', i18n('forms.validation.required'));
    }

    if (aLaUne) {
      const articleList = aLaUne.alaUneSections?.slice(LIST_ARTICLES).filter((article) => article !== null);
      const recommendArticles = aLaUne.recommandedArticles?.filter((recommendArticle) => recommendArticle !== null);

      if (!articleList) {
        setErrors(errors, 'aLaUne.articlesList', i18n('forms.validation.required'));
      }
      if (articleList && articleList.length < 6) {
        setErrors(errors, 'aLaUne.articlesList', 'max length 6');
      }
      if (!aLaUne.alaUneSections[HEADLINE]) {
        setErrors(errors, 'aLaUne.headline', i18n('forms.validation.required'));
      }
      if (!aLaUne.alaUneSections[LEFT_MEDIUM_ARTICLE]) {
        setErrors(errors, 'aLaUne.leftMediumArticle', i18n('forms.validation.required'));
      }
      if (!aLaUne.alaUneSections[CENTER_MEDIUM_ARTICLE]) {
        setErrors(errors, 'aLaUne.centerMediumArticle', i18n('forms.validation.required'));
      }
      if (!aLaUne.alaUneSections[RIGHT_MEDIUM_ARTICLE]) {
        setErrors(errors, 'aLaUne.rightMediumArticle', i18n('forms.validation.required'));
      }
      if (!aLaUne.alaUneSections[TOP_MEDIUM_ARTICLE]) {
        setErrors(errors, 'aLaUne.topMediumArticle', i18n('forms.validation.required'));
      }
      if (!recommendArticles) {
        setErrors(errors, 'aLaUne.recommandedArticles', i18n('forms.validation.required'));
      }
      if (recommendArticles && recommendArticles.length !== 5) {
        setErrors(errors, 'aLaUne.recommandedArticles', 'length top recommendations must be 5');
      }
    } else {
      setErrors(errors, 'aLaUne', i18n('forms.validation.required'));
    }
    if (hasFocusArea && focusArea) {
      if (focusArea.isActive && (!focusArea.articles || (focusArea.articles && focusArea.articles.length < 2))) {
        setErrors(errors, 'focusArea.articles', 'There must be two or more articles');
      }
    }
    return errors;
  };

  const onPublish = (evt: MouseEvent<HTMLElement>) => {
    evt.preventDefault();
    evt.stopPropagation();
    setHomepageDetailState((prevState) => ({
      ...prevState,
      isPublishModalOpen: true,
    }));
  };

  const onModalClose = () => {
    setHomepageDetailState((prevState) => ({
      ...prevState,
      isPublishModalOpen: false,
    }));
  };

  const checkDuplicates = (value: Record<string, unknown>) => {
    const labels = {
      focusAreaLabel: i18n('homes.nav.focusArea'),
      alaUneLabel: i18n('homes.nav.aLaUne'),
      topRecommandesLabel: i18n('homes.nav.topRecommendations'),
    };

    const base = value as HomepageInput;
    const duplicateSections = [] as string[];

    const searchAndPushDuplicates = (label: string, arr?: SelectOption[]) => {
      if (arr && searchDuplicates(arr)) {
        duplicateSections.push(label);
      }
    };

    searchAndPushDuplicates(labels.alaUneLabel, base.aLaUne.alaUneSections);
    searchAndPushDuplicates(labels.topRecommandesLabel, base.aLaUne.recommandedArticles);
    searchAndPushDuplicates(labels.focusAreaLabel, base.focusArea?.articles);

    return duplicateSections;
  };

  return {
    onModalClose,
    onSubmit,
    onPublish,
    openPreview,
    validate,
    homepageData,
    isPublishModalOpen: homepageDetailState.isPublishModalOpen,
    loading,
    aLaUne: homepageData.aLaUne,
    general: homepageData.general,
  };
};

export default useInteractionHomepageDetailView;
