import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { HomePageSortingCriteria } from 'shared/models';
import { i18n } from 'shared/i18n';
import { useDelete, useGetList } from 'shared/providers';
import { resources } from 'shared/constants';
import { HomepageListModel, ScheduledHomepage } from './types';

export interface HomeListState {
  sortPredicate: string;
}

const useInteractionHomepageListView = () => {
  const history = useHistory();
  const [homepageState, setHomepageState] = useState<HomeListState>({
    sortPredicate: HomePageSortingCriteria.RevCreationDate,
  });

  const { data, loading, doRefetch } = useGetList<HomepageListModel>(
    resources.NEWS_HOMEPAGES,
    {
      sort: homepageState.sortPredicate,
    },
  );

  const { deleteOne } = useDelete(
    resources.NEWS_HOMEPAGE,
    {},
    i18n('homes.messages.deleteSuccess'),
    i18n('homes.messages.deleteFailure'),
  );

  const deleteHomepage = (homepage: HomepageListModel) => {
    const scheduledHomepage: ScheduledHomepage = homepage.scheduledHomepage;
    deleteOne({
      additionalResource: resources.DELETE_NEWS_HOMEPAGE,
      payload: {
        slug: scheduledHomepage.slug,
      },
      onSuccess: () => {
        doRefetch({throwOnError: true});
        setHomepageState((prevState) => ({
          ...prevState,
        }));
      },
    });
  };

  const openCreateHomepage = () => {
    history.push('/home/create');
  };

  const openDetailHomepage = (homepage: HomepageListModel) => {
    history.push(`/home/${homepage.scheduledHomepage.slug}`);
  };

  return {
    data,
    isLoading: loading,
    sortPredicate: homepageState.sortPredicate,
    deleteHomepage,
    openDetailHomepage,
    openCreateHomepage,
  };
};

export default useInteractionHomepageListView;
