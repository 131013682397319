import { SimpleInput } from 'shared/form/inputs';
import { i18n } from 'shared/i18n';
import { ImageUpload } from 'shared/types';
import FileInput from 'shared/form/inputs/FileInput';
import TwoColumnsRow from 'views/newsletter/components/TwoColumnsRow';
import './JobsSection.scss';

export interface JobSubFormProps {
  index?: number;
  name?: string;
  onFileSelect: (image: ImageUpload) => void;
  onRemoveFile: (name: string) => void;
}

const JobFormset = ({ name, onFileSelect, onRemoveFile, index = 0 }: JobSubFormProps) => (
  <div className='jobItem'>
    <SimpleInput
      required
      name={`${name}.title`}
      label={`${i18n('newsletters.view.offerTitle')} ${index + 1}`}
    />
    <SimpleInput
      required
      name={`${name}.link`}
      label={`${i18n('newsletters.view.offerLink')} ${index + 1}`}
    />
    <TwoColumnsRow>
      {[
        <SimpleInput
          required
          name={`${name}.societe`}
          key={`jobs-societe-${index + 1}`}
          label={`${i18n('newsletters.view.society')} ${index + 1}`}
        />,
        <FileInput
          required
          onRemoveFile={onRemoveFile}
          onFileSelect={onFileSelect}
          name={`${name}.logoFilename`}
          key={`jobs-logo-${index + 1}`}
          label={`${i18n('newsletters.view.logo')} ${index + 1}`}
          uploadUrl="/api/newsletter/image"
        />,
      ]}
    </TwoColumnsRow>
    <TwoColumnsRow>
      {[
        <SimpleInput
          required
          name={`${name}.typeOffre`}
          key={`jobs-typeOffre-${index + 1}`}
          label={`${i18n('newsletters.view.offerType')} ${index + 1}`}
        />,
        <SimpleInput
          required
          name={`${name}.lieu`}
          key={`jobs-lieu-${index + 1}`}
          label={`${i18n('labels.location')} ${index + 1}`}
        />,
      ]}
    </TwoColumnsRow>
  </div>
);

export default JobFormset;
