import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { i18n } from 'shared/i18n';
import { ArticleSortingCriteria } from 'shared/models';
import { DataTable, Pagination, Searchbar } from 'shared/components';
import { RowRenderData } from 'shared/components/datatable/DataTable';
import { Content } from 'layout/content';
import Header from 'layout/header/Header';
import 'layout/header/header.scss';
import { ArticleListModel } from './types';
import ArticleRow from './row/ArticleRow';
import useInteractionArticleListView from './useInteractionArticleListView';

const ArticleListView = () => {
  const {
    pagination,
    query,
    loading,
    data,
    sortPredicate,
    openArticle,
    onPredicateChange,
    onPageChange,
    onSearchbarChange,
    onSearch,
  } = useInteractionArticleListView();

  const rowRenderer = ({ key, item }: RowRenderData<ArticleListModel>) => {
    return <ArticleRow key={key} item={item} />;
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        verticalAlign: 'center',
        textAlign: 'center',
      }}
    >
      <Header>
        <h1 className={classNames('header-title', 'header-title-dark')}>
          <i className={classNames('header-title-icon', 'icon-big', 'icon-articles')} />
          <div className={'header-title-text'}>{i18n('articles.pageTitle')}</div>
        </h1>
      </Header>
      <Content loading={false}>
        <Searchbar
          value={query}
          onChange={onSearchbarChange}
          onSearch={onSearch}
          placeholder={i18n('articles.searchArticles')}
        />
        <DataTable<ArticleListModel>
          sortable
          isLoading={loading}
          columns={[
            {
              label: '',
            },
            {
              label: i18n('table.columns.title'),
              predicate: ArticleSortingCriteria.Title,
            },
            {
              label: i18n('table.columns.author'),
              predicate: ArticleSortingCriteria.Author,
            },
            {
              label: i18n('table.columns.sector'),
            },
            {
              label: i18n('table.columns.creationDate'),
              predicate: ArticleSortingCriteria.CreationDate,
            },
            {
              label: i18n('table.columns.publicationDate'),
              predicate: ArticleSortingCriteria.PublicationDate,
            },
            {
              label: '',
            },
          ]}
          data={data}
          rowRenderer={rowRenderer}
          selectItem={openArticle}
          sortByColumn={onPredicateChange}
          sortPredicate={sortPredicate}
        />
        {pagination && !loading && (
          <Pagination
            buttonsRange={3}
            onPageChange={onPageChange}
            currentPage={pagination.page}
            lastPage={pagination.lastPage}
          />
        )}
      </Content>
    </div>
  );
};

export default withRouter(ArticleListView);
