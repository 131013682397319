import * as Http from 'shared/services/httpService';
import { PaperjamPlatform } from 'shared/models';
import { Params, ResponseListBody, Slug, SlugParams } from 'shared/types';
import { Workshop } from 'views/workshops/types';
import { CreateParams, UpdateParams } from '../providers';

const ENDPOINT_URI = '/api/workshop';

export const create = (params: CreateParams) =>
  Http.post<Slug>(ENDPOINT_URI, params.payload, {
    platformName: PaperjamPlatform,
  });

export const save = ({ payload, id }: UpdateParams) =>
  Http.put(`${ENDPOINT_URI}/${id}`, payload, {
    platformName: PaperjamPlatform,
  });

export const getAll = (params: Params) =>
  Http.get<ResponseListBody<Workshop>>(`${ENDPOINT_URI}s`, {
    params,
    platformName: PaperjamPlatform,
  });

export const getOne = (params: SlugParams) =>
  Http.get<Workshop>(`${ENDPOINT_URI}/${params.id }`, { 
    platformName: PaperjamPlatform,
  });

const WorkshopService = {
  create,
  save,
  getAll,
  getOne,
};

export default WorkshopService;
