import { useMemo } from 'react';
import classNames from 'classnames';
import { Sectors } from 'shared/models';
import { woodwingLink } from 'shared/stores/woodwingStore';
import { toArticleEncodedUrl } from 'shared/utils/preview';
import {
  ActionsCell,
  DataTableRow,
  DateFieldCell,
  ImageFieldCell,
  PublicationFieldCell,
  TextFieldCell,
} from 'shared/components/dataTableRow';
import { i18n } from 'shared/i18n';
import { ArticleListModel } from 'views/articles/types';
import './articleRow.scss';

export interface ArticleRowProps {
  item: ArticleListModel;
}

const ArticleRow = ({ item }: ArticleRowProps) => {
  const { slug } = item;
  const encodedUrl = toArticleEncodedUrl(slug);
  const previewUrl = `/article/preview/${slug}/${encodedUrl}`;

  const [statusText, icon] = useMemo(() => {
    if (item.publication.isPublished) {
      return [i18n('publication.published'), 'icon-check-circle'];
    }

    if (item.publication.date && item.publication.isPublished) {
      return [i18n('publication.programFor'), 'icon-clock'];
    }

    return [i18n('publication.notPublished'), null];
  }, [item]);

  return (
    <DataTableRow<ArticleListModel> record={item}>
      <ImageFieldCell
        source="slug"
        alt="magic"
        height="74"
        viewName="articles"
        sourceMainImage="content.mainMedia.mainImage"
      />
      <TextFieldCell source="metadata.title" />
      <TextFieldCell source="metadata.author.name" />
      <TextFieldCell source="metadata.categorizations[0].sector" format={Sectors.display} />
      <DateFieldCell source="metadata.creationDate" />
      <PublicationFieldCell
        statusText={statusText}
        icon={icon}
        source="publication.date"
        published={item.publication.isPublished}
      />
      <ActionsCell viewName="article" preview={previewUrl}>
        <a
          hidden={!item.woodwingRef}
          className='article-row-action'
          target="_blank"
          rel="noreferrer"
          href={woodwingLink(item.woodwingRef)}
        >
          <i className={classNames('icon-woodwing', 'article-row-icon', 'article-row-icon-woodwing')} />
        </a>
      </ActionsCell>
    </DataTableRow>
  );
};

export default ArticleRow;
