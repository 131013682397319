import { useState } from 'react';
import { Image, ImageUpload } from '../types';

export interface UseImagesReturn {
  images: Image[];
  onFileSelect: (image: ImageUpload) => void;
  onRemoveFile: (name: string) => void;
  onRemoveAllFiles: () => void;
}

const useImages = (): UseImagesReturn => {
  const [images, setImages] = useState<ImageUpload>({});

  const onFileSelect = (image: ImageUpload) => {
    setImages((prevState) => ({ ...(prevState || {}), ...image }));
  };

  const onRemoveFile = (nameToRemove: string) => {
    if (images && images[nameToRemove]) {
      setImages(
        (prevState) => Object.keys(prevState)
          .filter((name) => name !== nameToRemove)
          .reduce((images, name) => ({...images, [name]: prevState[name]}), {})
      );
    }
  };

  const onRemoveAllFiles = () => {
    setImages({});
  };

  return { images: Object.values(images), onFileSelect, onRemoveFile, onRemoveAllFiles };
};

export default useImages;
