import moment from 'moment';
import { i18n } from 'shared/i18n';
import 'layout/header/header.scss';
import Header from 'layout/header/Header';
import HeaderSection from './HeaderSection';

export interface HeaderClubHomeProps {
  date?: Date | string;
}

const HeaderClubHome = ({ date }: HeaderClubHomeProps) => (
  <Header>
    <div className='header__inner'>
      <h1 className='header-title'>{i18n('clubHome.pageTitle')}</h1>
      <HeaderSection label="Date de publication" value={date && moment(date).format('DD/MM/YYYY')} />
    </div>
  </Header>
);

export default HeaderClubHome;
