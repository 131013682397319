import { Scrollspy } from '@makotot/ghostui';
import classNames from 'classnames';
import useGetSectionRef from 'layout/ScrollspyProvider/useGetSectionRef';
import { MenuItem } from './menus';
import '../Nav/Nav.scss';

export interface NavScrollMenuProps {
  items: MenuItem[];
}

const NavScrollMenu = ({ items }: NavScrollMenuProps) => {
  const sectionRefs = useGetSectionRef();
  return (
    <div>
      {items.length > 0 ? (
        <Scrollspy sectionRefs={Object.values(sectionRefs)}>
          {({ currentElementIndexInViewport }) => (
            <div style={{ listStyle: 'none' }}>
              {items.map((item, index) => (
                <div
                  key={item.elementId}
                  className={currentElementIndexInViewport === index
                    ? 'nav-scrollMenu-selected'
                    : undefined
                  }
                >
                  <li className={classNames('nav-item', 'nav-item-small')}>
                    <a
                      className={classNames('nav-item-link-inner', 'nav-scrollMenu-item')}
                      href={`#${item.elementId}`}
                    >
                      {item.label}
                    </a>
                  </li>
                </div>
              ))}
            </div>
          )}
        </Scrollspy>
      ) : null}
    </div>
  );
};

export default NavScrollMenu;
