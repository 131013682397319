import { selectSearchHitLabel, selectSearchHitValue } from 'shared/models/response/search';
import { SlugAndTitle } from 'shared/types';
import { useInput } from 'shared/form';
import { SelectInputProps } from 'shared/form/types';
import AsyncSelect from 'shared/components/form/select/AsyncSelect';
import { useSearch } from 'shared/providers';
import { resources } from 'shared/constants';
import { appConfig } from 'shared/config/env';

const PODCAST_FILTER_FORMAT = 'format:Podcast';

const PodcastSelectInput = ({
  label,
  onChange,
  filter,
  defaultValue,
  name = '',
  value,
  required = false,
}: SelectInputProps) => {
  const input = useInput({ name, defaultValue });
  if(!!value){
    input.value = value;
  }

  const { refetch } = useSearch<SlugAndTitle[]>(resources.PODCAST_SHOWS);

  const getOptions = (inputValue: string) => {
    const baseParams = { filter: PODCAST_FILTER_FORMAT };
    const params = inputValue !== '' ? { ...baseParams, q: inputValue } : baseParams;
    return refetch<SlugAndTitle[]>(
      { ...params, platformName: appConfig.platformName, additionalResource: resources.ARTICLE_SEARCH }
    );
  };

  return (
    <AsyncSelect
      searchable
      clearable
      {...input}
      onChange={onChange}
      label={label}
      getOptions={getOptions}
      valueAccessor={selectSearchHitValue}
      labelAccessor={selectSearchHitLabel}
      filter={filter}
      required={required}
    />
  );
};

export default PodcastSelectInput;
