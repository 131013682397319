import { uiNotifier } from 'shared/services';
import { copyToClipboard } from 'shared/utils/utils';
import { i18n } from 'shared/i18n';
import './CopyField.scss';

export interface CopyFieldProps {
  value: string;
  btnLabel: string;
}

const CopyField = ({ value, btnLabel }: CopyFieldProps) => {
  const copyClipboard = () => {
    copyToClipboard(value);
    uiNotifier('info', i18n('forms.copyField.infoMessage'));
  };

  return (
    <div>
      <input className='copy-field-input' readOnly value={value} />
      <button type="button" onClick={copyClipboard} className='copy-field-btn'>
        {btnLabel}
      </button>
    </div>
  );
};

export default CopyField;
