const isSame = (values: unknown, newValues: unknown): boolean => {
  if (values === null || newValues === null || values === undefined || newValues === undefined) {
    return values === newValues;
  }

  if (
    !Array.isArray(values)
    && !Array.isArray(newValues)
    && !(values instanceof Date)
    && !(newValues instanceof Date)
    && typeof values === 'object'
    && typeof newValues === 'object'
  ) {
    const objectNewValues = newValues as Record<string, unknown>;
    const objectValues = values as Record<string, unknown>;

    return Object.keys(objectNewValues).every((key) => isSame(objectNewValues[key], objectValues[key]));
  }

  if (Array.isArray(values) && Array.isArray(newValues)) {
    return values.length == newValues.length
      && values.every((valueItem, index) => isSame(valueItem, newValues[index]));
  }

  if (values instanceof Date && newValues instanceof Date) {
    return values.getTime() === newValues.getTime();
  }

  return values === newValues;
};

export default isSame;
