import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import classNames from 'classnames';
import dndIcon from 'shared/assets/img/dndIcon.png';
import { i18n } from 'shared/i18n';
import useArrayInput from 'shared/form/useArrayInput';
import { ArrayInputProps } from 'shared/form/inputs/ArrayInput';
import ArrayItemInput from './ArrayItemInput';
import './DndArray.scss';
import './ListForms.scss';

export interface DragArrayInputProps<T> extends ArrayInputProps<T> {
  onDragEnd?: (result: DropResult) => void;
  getLabel?: (index: number) => string;
  translateLabel?: boolean;
  movedValueItems?: boolean;
}

const DnDArrayInput = <T, >({
  children,
  onDragEnd: customDragEnd,
  numOfItems,
  name,
  label,
  getLabel,
  getEmptyValue,
  maxItems,
  translateLabel = true,
  required = false,
  movedValueItems = false,
}: DragArrayInputProps<T>) => {
  const { items, move, moveValueItems, append, deleteItem } = useArrayInput<T>({
    name,
    numOfItems,
    movedValueItems,
  });

  const onDragEnd = (result: DropResult) => {
    if (customDragEnd) {
      customDragEnd(result);
    } else {
      if (!result.destination) {
        return;
      }

      move(result.destination.index, result.source.index);
    }
  };

  const appendItem = () => {
    const emptyValue = getEmptyValue ? getEmptyValue() : undefined;
    append(emptyValue);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={name}>
        {(provided) => (
          <>
            <div
              className='dnd-list'
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {items.map((item, index) => (
                <Draggable {...item} draggableId={`${item.key}`} index={index}>
                  {(provided) => (
                    <div
                      {...provided?.draggableProps}
                      {...item}
                      className={classNames('dnd-list-item', provided.draggableProps.style)}
                      ref={provided.innerRef}
                    >
                      <ArrayItemInput
                        translateLabel={translateLabel}
                        label={getLabel?.(index) || label}
                        staticArray={numOfItems !== undefined}
                        index={index}
                        required={required}
                        deleteItem={deleteItem}
                        moveValueItems={moveValueItems}
                        name={`${name}[${index}]`}
                      >
                        {children}
                      </ArrayItemInput>
                      <img
                        alt="dnd"
                        className='dnd-list-action'
                        src={dndIcon}
                        {...provided.dragHandleProps}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
            {numOfItems === undefined &&
              <button
              type='button'
              hidden={!!maxItems && maxItems <= items.length}
                className='list-form-add-button'
              onClick={appendItem}
              >
                {i18n('labels.addElement')}
              </button>
            }
          </>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DnDArrayInput;
