import { ForwardedRef, forwardRef } from 'react';
import { i18n } from 'shared/i18n';
import 'views/home/homeDetail.scss';
import * as constants from 'views/home/constants';
import FormSection from 'layout/content/formSection/FormSection';
import { DateTimeInput, SimpleInput } from 'shared/form/inputs';

const GeneralSectionInput = forwardRef((_, ref: ForwardedRef<HTMLElement>) => (
  <FormSection ref={ref} title={constants.HomeGeneralLabel} id={constants.HomeGeneralId}>
    <div className='home-detail-general'>
      <SimpleInput
        required
        name="general.name"
        width="240px"
        label={i18n('homes.view.homepageName')}
      />
      <DateTimeInput
        required
        defaultValue={new Date()}
        name="general.date"
        labelDate="generalSection.publicationDate"
        labelTime="generalSection.publicationHour"
      />
    </div>
  </FormSection>
));

export default GeneralSectionInput;

