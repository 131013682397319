import { useState } from 'react';
import { validateEmail } from 'shared/utils/utils';

interface UseInteractionResetEmailProps {
    email: string;
    isValidEmail: boolean;
    isError: boolean;
    handleEmailChange: (value: unknown) => void;
    handleFormSubmit: () => void;
}

const useInteractionHandleResetEmailInput = (onValidEmail: (email: string) => void): UseInteractionResetEmailProps => {

    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [isError, setIsError] = useState(false);

    const handleEmailChange = (value: unknown) => {
        const currentEmail = value as string;
        setEmail(currentEmail);
        const { status, isDomain } = validateEmail(currentEmail);
        setIsError(isDomain);
        setIsValidEmail(status);
    };

    const handleFormSubmit = () => {
        if (email) {
            onValidEmail(email);
        }
    };

    return {
        email,
        isValidEmail,
        isError,
        handleEmailChange,
        handleFormSubmit,
    };
};

export default useInteractionHandleResetEmailInput;