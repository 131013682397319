import { DocumentNode, gql } from '@apollo/client';
import { appConfig } from 'shared/config/env';
import { GetListParams, GetOneParams } from 'shared/providers/dataProvider/types';
import { getSort } from 'shared/services/graphql/utils';
import { PodcastSortingCriteriaAlias } from 'shared/services/graphql/aliases';

const list = (params?: GetListParams): DocumentNode => {
  const offset =
    params?.page !== undefined
      ? params.page === 1
        ? appConfig.offset
        : (params.page - 1) * Number(appConfig.limit)
      : appConfig.offset;

  const [sort, asc] = getSort(PodcastSortingCriteriaAlias, params?.sortPredicate);

  return gql`
      query {
        podcastShows (
          limit: ${appConfig.limit},
          offset: ${offset},
          sort: "${sort}",
          asc: ${asc},
          platformName: ${appConfig.platformName},
        ) {
          ... on PodcastShowsResponse {
            items {
              slug
              title
              isSponsored
              sector
              subSector
              publication {
                date
                isPublished
                target {
                  news
                  club
                }
                newsTicker
              }
              subscribeUrl
              podcastEpisodes
              mainImage {
                filename
                caption
                credits
                focusPoint {
                  x
                  y
                }
              }
              creationDate
              updateDate
              platformName
              updatedBy
            }
            count
            limit
            offset
          }

          ... on ErrorPayload {
            traceId
            errorMessage
            validationErrors {
              code
              message
            }
          }

          ... on NoResult {
            message
          }
        }
      }
    `;
};

const retrieve = <T>(params?: GetOneParams<T>): DocumentNode => {
  return gql`
    query {
      podcastShow( slug: "${params?.id}", platformName:${appConfig.platformName}) {
        ... on PodcastShow {
          slug
          title
          isSponsored
          sector
          subSector
          publication {
            date
            isPublished
            target {
              news
              club
            }
          }
          subscribeUrl
          podcastEpisodes
          mainImage {
            filename
          }
          creationDate
        }
        
        ... on ErrorPayload {
          traceId
          errorMessage
          validationErrors {
            code
            message
          }
        }

        ... on NoResult {
            message
        }
      }
    }`;
};

const update = (): DocumentNode => gql`
  mutation updatePodcastShow($updateData: PodcastShowUpdateInput!) {
    updatePodcastShow(updateData: $updateData) {
      __typename
      ... on PodcastShow {
        slug
        title
        isSponsored
        sector
        subSector
        publication {
          date
          isPublished
          target {
            news
            club
          }
        }
        subscribeUrl
        podcastEpisodes
        mainImage {
          filename
        }
        creationDate
      }
      ... on ErrorPayload {
        traceId
        errorMessage
        validationErrors {
          code
          message
        }
      }
    }
  }
`;

const create = (): DocumentNode => gql`
  mutation createPodcastShow($createData: PodcastShowCreateInput!) {
    createPodcastShow(createData: $createData) {
      __typename
      ... on PodcastShow {
        slug
        title
        isSponsored
        sector
        subSector
        publication {
          date
          isPublished
          target {
            news
            club
          }
        }
        subscribeUrl
        podcastEpisodes
        mainImage {
          filename
        }
        creationDate
      }
      ... on ErrorPayload {
        traceId
        errorMessage
        validationErrors {
          code
          message
        }
      }
    }
  }
`;

const podcastGraphqlServices = {
  list,
  retrieve,
  update,
  create,
};

export default podcastGraphqlServices;
