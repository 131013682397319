import { useContext } from 'react';
import FormContext from 'shared/form/FormContext';

const useForm = () => {
  const formContext = useContext(FormContext);
  if (!formContext) {
    throw new Error('must be used inside Form component');
  }

  return formContext;
};

export default useForm;
