import { useEffect, useRef, useState } from 'react';
import { useQuery as useQueryMain } from 'react-query';
import { apiErrorToUiNotifier, DEFAULT_ERROR_MESSAGE, FETCH_ERROR_MESSAGE } from 'shared/services';
import { Pagination, ResponseListBody } from 'shared/types';
import isSame from 'shared/utils/isSame';
import { DataProviderType, useDataProvider } from 'shared/providers/dataProvider';
import { QueryDataProviderType, QueryParams, QueryStateResult, RecordQuery } from './types';
import { ApolloError } from '@apollo/client';

const useQuery = <T extends RecordQuery>(
  resource: string,
  type: QueryDataProviderType,
  params?: QueryParams<T>,
  allowedRequest = true,
): QueryStateResult<T> => {
  const requestParamsRef = useRef<QueryParams<T>>({});
  const dataProvider = useDataProvider();
  const [pagination, setPagination] = useState<Pagination>({ page: 1, lastPage: 1 });

  const localRefetch = (refetchParams?: QueryParams<T>) =>
    (dataProvider as DataProviderType)[type]<T>(resource, { ...refetchParams, page: pagination.page });

  const onFailure = (error: ApolloError | Error) => {
    let errorMessage = FETCH_ERROR_MESSAGE;
    if (error instanceof ApolloError && error.networkError) {
      apiErrorToUiNotifier(errorMessage)(error.networkError);
      return;
    }
    if (error instanceof Error) {
      errorMessage = DEFAULT_ERROR_MESSAGE;
    }
    apiErrorToUiNotifier(errorMessage)(error);
  };

  const onSuccess = (data: ResponseListBody<T> | T | undefined) => {
    if (params && params.onSuccess) {
      params.onSuccess(data);
    }
  };

  const { data, error, isLoading, refetch } = useQueryMain<ResponseListBody<T> | T | undefined>(
    [resource, params, pagination.page],
    () => {
      const mainParams: Record<string, unknown> = { ...params, page: pagination.page };
      if (params && !params.search && allowedRequest) {
        requestParamsRef.current = mainParams;
        return (dataProvider as DataProviderType)[type]<T>(resource, { ...mainParams, page: pagination.page });
      }
    },
    {
      onSuccess,
      onError: (error) => onFailure(error as Error | ApolloError),
      enabled: !isSame(requestParamsRef.current, { ...params, page: pagination.page }) && allowedRequest,
    },
  );

  useEffect(() => {
    if (type === 'getList' && data && !isLoading) {
      const currentData = data as ResponseListBody<T>;
      if (currentData.pagination) {
        setPagination((prevState) => ({
          ...prevState,
          lastPage: currentData.pagination?.lastPage ?? prevState.lastPage,
        }));
      }
    }
  }, [data, isLoading, type]);

  const onPageChange = (page = 1) => {
    setPagination((prevState) => ({ ...prevState, page }));
  };

  return {
    data: type === 'getList' && data ? (data as ResponseListBody<T>).items : data,
    pagination,
    loading: isLoading,
    error,
    refetch: localRefetch,
    doRefetch: refetch,
    onPageChange: type === 'getList' ? onPageChange : undefined,
  } as QueryStateResult<T>;
};

export default useQuery;
