import { SimpleInput } from 'shared/form/inputs';
import { ImageUpload } from 'shared/types';
import FileInput from 'shared/form/inputs/FileInput';
import { i18n } from 'shared/i18n';
import './ParusChez.scss';

export interface ParusChezMaisonModerneInputProps {
  name?: string;
  onFileSelect: (image: ImageUpload) => void;
  onRemoveFile: (name: string) => void;
}

const ParusChezMaisonModerneInput = ({ name, onFileSelect, onRemoveFile }: ParusChezMaisonModerneInputProps) =>
  <div className='parusChez-root'>
    <SimpleInput
      required
      width="100%"
      name={`${name}.title`}
      label={i18n('labels.title')}
    />
    <SimpleInput
      required
      width="100%"
      label={i18n('newsletters.view.description')}
      name={`${name}.description`}
    />
    <SimpleInput
      required
      width="100%"
      label={i18n('labels.link')}
      name={`${name}.link`}
    />
    <FileInput
      required
      onRemoveFile={onRemoveFile}
      onFileSelect={onFileSelect}
      label={i18n('labels.image')}
      name={`${name}.imageFilename`}
      uploadUrl="/api/newsletter/image"
    />
  </div>
;

export default ParusChezMaisonModerneInput;
