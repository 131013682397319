import { routes } from 'shared/stores/constants';
import { scrollMenus } from 'layout/aside/nav/scrollMenu';

const toScrollMenuForRoute = (currentRoute: string) => {
  const key = currentRoute.toString().replace('/', '');
  switch (key) {
    case routes.ARTICLE:
      return scrollMenus.article;
    case routes.HOME:
      return scrollMenus.home;
    case routes.WORKSHOP:
      return scrollMenus.workshop;
    case routes.EVENT:
      return scrollMenus.event;
    case routes.NEWSLETTER.CLUB:
      return scrollMenus.newsletter.club;
    case routes.NEWSLETTER.DELANOBREAKFAST:
    case routes.NEWSLETTER.DELANONOON:
      return scrollMenus.newsletter.delanoBreakfastNoon;
    case routes.NEWSLETTER.MORNING:
    case routes.NEWSLETTER.EVENING:
      return scrollMenus.newsletter.morningEvening;
    case routes.NEWSLETTER.TRENDIN:
      return scrollMenus.newsletter.trendin;
    case routes.NEWSLETTER.GRANDDOSSIER:
      return scrollMenus.newsletter.grandDossiers;
    case routes.DOSSIER:
      return scrollMenus.dossier;
    case routes.PODCASTSHOW:
      return scrollMenus.podcastShow;
    default:
      return [];
  }
};

export default toScrollMenuForRoute;
