import { DocumentNode, gql } from '@apollo/client';
import { appConfig } from 'shared/config/env';
import { GetSearchParams } from 'shared/providers/dataProvider/types';

const search = (params?: GetSearchParams): DocumentNode => {
  let query = `
    query {
      topicsSearch (
        query: "${params?.q || ''}"
        platformName: ${appConfig.platformName}
      ) {
        ... on TopicSearchResponse {
          items {
            name
          }
        }
        ... on ErrorPayload {
          traceId
          errorMessage
          validationErrors {
            code
            message
          }
        }
        ... on NoResult {
          message
        }
      }
    }`;

  if (!params?.q) {
    query = query.replace('query: ""', '');
  }

  return gql`
    ${query}
  `;
};

const topicsGraphqlServices = {
  search,
};

export default topicsGraphqlServices;
