import { useMemo } from 'react';
import paperjamLogo from 'shared/assets/img/logo.svg';
import delanoLogo from 'shared/assets/img/logo-delano.svg';
import { appConfig } from 'shared/config/env';
import { DelanoPlatform } from 'shared/models';
import './Logo.scss';

export type TextAlign = 'start' | 'end' | 'left' | 'right' | 'center' | 'justify';

export interface LogoProps {
  subTitleAlign?: TextAlign;
}

const Logo = ({ subTitleAlign }: LogoProps) => {
  const logoUrl = useMemo(() =>
    appConfig.platformName === DelanoPlatform ? delanoLogo : paperjamLogo,
    []
  );

  return (
    <div className='logo-wrapper'>
      <img width="175" height="23" src={logoUrl} alt={appConfig.platformName} />
      <div style={{ textAlign: subTitleAlign }} className='logo-sub-logo'>
        <span>BackOffice</span>
      </div>
    </div>
  );
};

export default Logo;
