import moment from 'moment';
import { setErrors } from 'shared/form/utils';
import { i18n } from 'shared/i18n';
import { appConfig } from 'shared/config/env';
import {
  BillboardsInput,
  GrandDossier,
  GrandDossierInput,
} from 'views/newsletter/categories/GrandDossiersNewsletterForm/types';
import { NewsletterDetail } from 'views/newsletter/types';
import { computeNewsletterTitle } from 'views/newsletter/state/common';
import { NewsletterGrandDossierContent } from 'shared/types';
import { types } from 'shared/constants';

const blankBillboard = {
  imageFilename: '',
  link: '',
};

function processBillboards(currentBillboard: BillboardsInput[]): BillboardsInput[] {
  const billboards = [...new Array(6)].map(() => ({
    ...blankBillboard,
    billboardsIsOpen: false,
  }));
  if (currentBillboard && currentBillboard.length > 0) {
    currentBillboard.forEach((billboard: BillboardsInput, index: number) => {
      if (billboard != null && billboard.link?.length > 0) {
        billboards[index] = {
          ...billboard,
          billboardsIsOpen: true,
        };
      }
    });
  }
  return billboards;
}

export const createInitialStateGrandDossier = (value: GrandDossierInput) => {
  const title = value.title || computeNewsletterTitle(types.GRAND_DOSSIER, new Date());
  const publicationDate = value.publicationDate || new Date();
  const magazine = value.content.magazine || {
    isMagazinePresent: true,
  };
  const billboards = processBillboards(value.content.billboards);
  return {
    title,
    publicationDate,
    content: {
      slugline: '',
      billboards,
      magazine,
    },
  };
};

export const initialStateGrandDossier = (value: NewsletterDetail) => {
  const content = value.content as NewsletterGrandDossierContent;
  const title = value.title || computeNewsletterTitle(types.GRAND_DOSSIER, new Date());
  const slugline = value.content.slugline || '';
  const billboards = processBillboards(content.billboards);
  return {
    title,
    publicationDate: value.publicationDate ?? new Date(),
    content: {
      slugline,
      dossier: content.dossier && {
        value: content.dossier.slug,
        label: content.dossier.title,
      },
      billboards,
      magazine: {
        isMagazinePresent: !!content.magazine,
        ...content.magazine,
      },
    },
  };
};

export const grandDossierData = (value: GrandDossierInput) => ({
  ...value,
  publicationDate: moment(value.publicationDate).format(appConfig.yearBasedDateFormat),
  content: {
    ...value.content,
    dossier: value.content.dossier.value || '',
    billboards: value.content.billboards.map((billboard) =>
      billboard.billboardsIsOpen
        ? {
            link: billboard.link,
            imageFilename: billboard.imageFilename,
          }
        : null,
    ),
    magazine:
      value.content.magazine &&
      value.content.magazine.isMagazinePresent &&
      (value.content.magazine.date ||
        value.content.magazine.description ||
        value.content.magazine.imageFilename ||
        value.content.magazine.link ||
        value.content.magazine.titre)
        ? {
            date: value.content.magazine.date || null,
            description: value.content.magazine.description || null,
            imageFilename: value.content.magazine.imageFilename || null,
            link: value.content.magazine.link || null,
            titre: value.content.magazine.titre || null,
          }
        : null,
  },
});

export const grandDossierDataCopy = (value: GrandDossier) => {
  return {
    ...value,
    publicationDate: moment(value.publicationDate).format(appConfig.yearBasedDateFormat),
    content: {
      ...value.content,
      dossier: value.content.dossier.slug || '',
    },
  };
};

const isBillboardValid = (billboard: BillboardsInput) =>
  (billboard.billboardsIsOpen && !!billboard.imageFilename && !!billboard.link) || !billboard.billboardsIsOpen;

export const grandDossierValidate = (value: GrandDossierInput) => {
  const errors = {};
  if (value) {
    if (value.content.billboards) {
      if (!value.content.billboards.every((billboard) => isBillboardValid(billboard))) {
        setErrors(errors, 'content.billboards', i18n('forms.validation.invalid'));
      }
    } else {
      setErrors(errors, 'content.billboards', i18n('forms.validation.required'));
    }
    if (!value.publicationDate) {
      setErrors(errors, 'publicationDate', i18n('forms.validation.required'));
    }
    if (!value.content.slugline) {
      setErrors(errors, 'content.slugline', i18n('forms.validation.required'));
    }
    if (!value.content.dossier) {
      setErrors(errors, 'content.dossier', i18n('forms.validation.required'));
    }
    if (value.content.magazine?.isMagazinePresent) {
      if (!value.content.magazine.date) {
        setErrors(errors, 'content.magazine.date', i18n('forms.validation.required'));
      }
      if (!value.content.magazine.description) {
        setErrors(errors, 'content.magazine.description', i18n('forms.validation.required'));
      }
      if (!value.content.magazine.imageFilename) {
        setErrors(errors, 'content.magazine.imageFilename', i18n('forms.validation.required'));
      }
      if (!value.content.magazine.link) {
        setErrors(errors, 'content.magazine.link', i18n('forms.validation.required'));
      }
      if (!value.content.magazine.titre) {
        setErrors(errors, 'content.magazine.titre', i18n('forms.validation.required'));
      }
    }
  }
  return errors;
};
