import { forwardRef } from 'react';
import { FormSection } from 'layout/content';
import { SimpleInput, ToggleInput } from 'shared/form/inputs';
import FileInput from 'shared/form/inputs/FileInput';
import { i18n } from 'shared/i18n';
import { FormSection as FormSectionProps, ImageUpload } from 'shared/types';
import { useRemoveFile } from 'shared/hooks';

export interface EnCeMomentDansLeMagazineInputProps extends FormSectionProps {
  onFileSelect: (image: ImageUpload) => void;
  onRemoveFile: (name: string) => void;
}

const EnCeMomentDansLeMagazineInput = forwardRef<HTMLElement, EnCeMomentDansLeMagazineInputProps>(({
  id,
  title,
  onFileSelect,
  onRemoveFile,
}, ref) => {
  const toggleName = 'content.enCeMomentDansLeMagazine.isActive';
  const filename = 'content.enCeMomentDansLeMagazine.imageFilename';
  
  useRemoveFile({ toggleName, filename, onRemoveFile });

  return (
    <FormSection ref={ref} id={id} title={title}>
      <ToggleInput id="magazine-id" name={toggleName}>
        <SimpleInput
          required
          width="100%"
          label={i18n('labels.date')}
          name="content.enCeMomentDansLeMagazine.date"
        />
        <SimpleInput
          required
          width="100%"
          label={i18n('labels.title')}
          name="content.enCeMomentDansLeMagazine.title"
        />
        <SimpleInput
          required
          width="100%"
          label={i18n('newsletters.view.description')}
          name="content.enCeMomentDansLeMagazine.description"
        />
        <SimpleInput
          required
          width="100%"
          label={i18n('labels.link')}
          name="content.enCeMomentDansLeMagazine.link"
        />
        <FileInput
          required
          onRemoveFile={onRemoveFile}
          onFileSelect={onFileSelect}
          label={i18n('labels.image')}
          name={filename}
          uploadUrl="/api/newsletter/image"
        />
      </ToggleInput>
    </FormSection>
  );
});

export default EnCeMomentDansLeMagazineInput;
