const ARTICLES = 'articles';
const ARTICLE_PUBLISHED = 'articlePublished';
const ARTICLE_BY_PLATFORM = 'articleByPlatform';
const TOPICS = 'topics';
const EVENTS_WITH_WORKSHOP = 'eventsWithWorkshop';
const SEARCH_EVENT_AND_WORKSHOP = 'searchEventAndWorkshops';
const EVENTS_SEARCH = 'eventsSearch';
const SEARCH_TOPICS = 'topicsSearch';
const ARTICLE_WITH_TITLES = 'articleWithTitles';
const ARTICLE_SEARCH = 'articlesSearch';
const UPDATE_ARTICLE_METADATA_PUBLICATION = 'updateArticleMetadataAndPublication';
const EVENTS = 'events';
const EVENT = 'event';
const UPDATE_EVENT_CONTENT_METADATA_PUBLICATION = 'updateEventContentMetadataPublication';
const BIOGRAPHIES_SEARCH = 'biograhiesSearch';
const SPONSORS_SEARCH = 'sponsorsSearch';
const CLUB_HOMEPAGE = 'scheduledClubHomepage';
const NEWSLETTERS = 'newsletters';
const CREATE_NEWSLETTER_CLUB = 'createNewsletterClub';
const CREATE_NEWSLETTER_DELANO_DAILY = 'createNewsletterDelanoDaily';
const CREATE_NEWSLETTER_DAILY = 'createNewsletterDaily';
const CREATE_NEWSLETTER_TRENDIN = 'createNewsletterTrendin';
const CREATE_NEWSLETTER_DOSSIER = 'createNewsletterDossier';
const NEWS_HOMEPAGES = 'newsHomepages';
const NEWS_HOMEPAGE = 'newsHomepage';
const UPDATE_SCHEDULED_CLUB_HOMEPAGE = 'updateScheduledClubHomepage';
const UPDATE_NEWSLETTER_DELANO_DAILY = 'updateNewsletterDelanoDaily';
const UPDATE_NEWSLETTER_CLUB = 'updateNewsletterClub';
const UPDATE_NEWSLETTER_DAILY = 'updateNewsletterDaily';
const UPDATE_NEWSLETTER_TRENDIN = 'updateNewsletterTrendin';
const UPDATE_NEWSLETTER_DOSSIER = 'updateNewsletterDossier';
const CREATE_NEWS_HOMEPAGE = 'createNewsHomepage';
const UPDATE_NEWS_HOMEPAGE = 'updateNewsHomepage';
const DELETE_NEWS_HOMEPAGE = 'deleteNewsHomepage';
const WORKSHOPS = 'workshops';
const WORKSHOP = 'workshop';
const CREATE_WORKSHOP = 'createWorkshop';
const UPDATE_WORKSHOP = 'updateWorkshop';
const DOSSIER = 'dossier';
const DOSSIERS = 'dossiers';
const CREATE_DOSSIER = 'createDossier';
const UPDATE_DOSSIER = 'updateDossier';
const SEARCH_DOSSIER = 'dossiersSearch';
const SPONSOR = 'sponsor';
const ORGANISATION_BY_SLUG = 'organisationBySlug';
const PODCAST_SHOWS = 'podcastShows';
const UPDATE_PODCAST = 'updatePodcastShow';
const CREATE_PODCAST_SHOW = 'createPodcastShow';
const MAGAZINES = 'magazines';
const UPDATE_MAGAZINE = 'saveMagazine';
const MAGAZINE = 'magazine';
const PODCAST_SHOW = 'podcastShow';
const GUIDES = 'guides';
const GUIDE_FEATURED_PROFILES = 'featuredProfiles';
const GUIDE_UPDATE_FEATURED_PROFILES = 'saveFeaturedProfiles';

const resources = {
  ARTICLES,
  ARTICLE_PUBLISHED,
  ARTICLE_BY_PLATFORM,
  TOPICS,
  EVENTS_WITH_WORKSHOP,
  SEARCH_EVENT_AND_WORKSHOP,
  SEARCH_TOPICS,
  ARTICLE_WITH_TITLES,
  ARTICLE_SEARCH,
  UPDATE_ARTICLE_METADATA_PUBLICATION,
  EVENTS,
  EVENTS_SEARCH,
  EVENT,
  UPDATE_EVENT_CONTENT_METADATA_PUBLICATION,
  BIOGRAPHIES_SEARCH,
  SPONSORS_SEARCH,
  CLUB_HOMEPAGE,
  NEWSLETTERS,
  CREATE_NEWSLETTER_CLUB,
  CREATE_NEWSLETTER_DAILY,
  CREATE_NEWSLETTER_DELANO_DAILY,
  CREATE_NEWSLETTER_TRENDIN,
  CREATE_NEWSLETTER_DOSSIER,
  NEWS_HOMEPAGES,
  NEWS_HOMEPAGE,
  UPDATE_SCHEDULED_CLUB_HOMEPAGE,
  UPDATE_NEWSLETTER_DELANO_DAILY,
  UPDATE_NEWSLETTER_CLUB,
  UPDATE_NEWSLETTER_DAILY,
  UPDATE_NEWSLETTER_TRENDIN,
  UPDATE_NEWSLETTER_DOSSIER,
  CREATE_NEWS_HOMEPAGE,
  UPDATE_NEWS_HOMEPAGE,
  DELETE_NEWS_HOMEPAGE,
  WORKSHOPS,
  WORKSHOP,
  CREATE_WORKSHOP,
  UPDATE_WORKSHOP,
  DOSSIER,
  DOSSIERS,
  CREATE_DOSSIER,
  UPDATE_DOSSIER,
  SEARCH_DOSSIER,
  SPONSOR,
  ORGANISATION_BY_SLUG,
  PODCAST_SHOWS,
  UPDATE_PODCAST,
  CREATE_PODCAST_SHOW,
  MAGAZINES,
  UPDATE_MAGAZINE,
  MAGAZINE,
  PODCAST_SHOW,
  GUIDES,
  GUIDE_FEATURED_PROFILES,
  GUIDE_UPDATE_FEATURED_PROFILES
};

export default resources;
