import { DocumentNode } from '@apollo/client';
import { providerType, resources } from 'shared/constants';
import articlesGraphqlServices from './articles';
import topicsGraphqlServices from './topics';
import eventsGraphqlServices from './events';
import sponsorsGraphqlServices from './sponsors';
import biographiesGraphqlServices from './biographies';
import clubHomeGraphqlServices from './clubHome';
import newslettersGraphqlServices from './newsletter';
import homepageGraphqlServices from './homepageService';
import workshopGraphqlServices from './workshop';
import dossiersGraphqlServices from './dossier';
import podcastGraphqlServices from './podcast';
import magazinesGraphqlServices from './magazine';
import guideGraphqlServices from './guide';

export type ServiceFn = (args?: Record<string, unknown>) => DocumentNode | undefined;

const servicesGraphql = (type: string, resource: string): ServiceFn => {
  switch (resource) {
    case resources.ARTICLES:
    case resources.ARTICLE_PUBLISHED:
    case resources.ARTICLE_BY_PLATFORM:
      return {
        [providerType.GET_LIST]: articlesGraphqlServices.list,
        [providerType.GET_ONE]: articlesGraphqlServices.retrieve,
        [providerType.GET_SEARCH]: articlesGraphqlServices.search,
        [providerType.UPDATE]: articlesGraphqlServices.update,
      }[type];
    case resources.TOPICS:
      return {
        [providerType.GET_SEARCH]: topicsGraphqlServices.search,
      }[type];
    case resources.EVENTS_WITH_WORKSHOP:
    case resources.SEARCH_EVENT_AND_WORKSHOP:
      return {
        [providerType.GET_SEARCH]: eventsGraphqlServices.searchEventsAndWorkshopsDay,
      }[type];
    case resources.EVENTS:
      return {
        [providerType.GET_LIST]: eventsGraphqlServices.list,
        [providerType.GET_ONE]: eventsGraphqlServices.retrieve,
        [providerType.GET_SEARCH]: eventsGraphqlServices.eventsSearch,
        [providerType.UPDATE]: eventsGraphqlServices.update,
      }[type];
    case resources.EVENTS_SEARCH:
      return {
        [providerType.GET_SEARCH]: eventsGraphqlServices.eventsSearch,
      }[type];
    case resources.SPONSORS_SEARCH:
    case resources.SPONSOR:
      return {
        [providerType.GET_SEARCH]: sponsorsGraphqlServices.search,
        [providerType.GET_ONE]: sponsorsGraphqlServices.retrieve,
      }[type];
    case resources.BIOGRAPHIES_SEARCH:
      return {
        [providerType.GET_SEARCH]: biographiesGraphqlServices.search,
      }[type];
    case resources.CLUB_HOMEPAGE:
      return {
        [providerType.GET_ONE]: clubHomeGraphqlServices.retrieve,
        [providerType.UPDATE]: clubHomeGraphqlServices.update,
        [providerType.CREATE]: clubHomeGraphqlServices.create,
      }[type];
    case resources.NEWSLETTERS:
      return {
        [providerType.GET_ONE]: newslettersGraphqlServices.retrieve,
        [providerType.GET_LIST]: newslettersGraphqlServices.list,
        [providerType.UPDATE]: newslettersGraphqlServices.update,
        [providerType.CREATE]: newslettersGraphqlServices.create,
      }[type];
    case resources.NEWS_HOMEPAGES:
      return {
        [providerType.GET_LIST]: homepageGraphqlServices.list,
      }[type];
    case resources.NEWS_HOMEPAGE:
      return {
        [providerType.GET_ONE]: homepageGraphqlServices.retrieve,
        [providerType.CREATE]: homepageGraphqlServices.create,
        [providerType.UPDATE]: homepageGraphqlServices.update,
        [providerType.DELETE_ONE]: homepageGraphqlServices.remove,
      }[type];
    case resources.WORKSHOPS:
      return {
        [providerType.GET_LIST]: workshopGraphqlServices.list,
        [providerType.GET_ONE]: workshopGraphqlServices.retrieve,
        [providerType.CREATE]: workshopGraphqlServices.create,
        [providerType.UPDATE]: workshopGraphqlServices.update,
      }[type];
    case resources.DOSSIERS:
      return {
        [providerType.CREATE]: dossiersGraphqlServices.create,
        [providerType.UPDATE]: dossiersGraphqlServices.update,
        [providerType.GET_ONE]: dossiersGraphqlServices.retrieve,
        [providerType.GET_LIST]: dossiersGraphqlServices.list,
      }[type];
      case resources.SEARCH_DOSSIER:
        return {
          [providerType.GET_SEARCH]: dossiersGraphqlServices.search,
        }[type];
    case resources.PODCAST_SHOWS:
        return {
          [providerType.GET_LIST]: podcastGraphqlServices.list,
          [providerType.GET_ONE]: podcastGraphqlServices.retrieve,
          [providerType.GET_SEARCH]: articlesGraphqlServices.search,
          [providerType.CREATE]: podcastGraphqlServices.create,
          [providerType.UPDATE]: podcastGraphqlServices.update,
        }[type];
    case resources.CREATE_PODCAST_SHOW:
      return {
        [providerType.GET_LIST]: podcastGraphqlServices.list,
      }[type];
    case resources.MAGAZINES:
      return {
        [providerType.GET_ONE]: magazinesGraphqlServices.retrieve,
        [providerType.UPDATE]: magazinesGraphqlServices.update,
      }[type];
    case resources.GUIDES:
      return {
        [providerType.GET_ONE]: guideGraphqlServices.retrieve,
        [providerType.UPDATE]: guideGraphqlServices.update,
      }[type];
  }
  return (args) => undefined;
};

export default servicesGraphql;
