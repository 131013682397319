import { DocumentNode, gql } from '@apollo/client';
import { GetListParams, GetOneParams } from 'shared/providers/dataProvider/types';
import { appConfig } from 'shared/config/env';
import { getSort } from 'shared/services/graphql/utils';
import { WorkshopSortingCriteriaAlias } from 'shared/services/graphql/aliases';

const list = (params?: GetListParams): DocumentNode => {
  const offset = params?.page !== undefined
    ? (params.page === 1 ? appConfig.offset : (params.page - 1) * Number(appConfig.limit))
    : appConfig.offset;
  
  const [sort, asc] = getSort(WorkshopSortingCriteriaAlias, params?.sort);
  
  return gql`
    query {
      workshops (
        limit: ${appConfig.limit},
        offset: ${offset},
        sort: "${sort}",
        asc: ${asc},
      ) {
        ... on WorkshopsResponse {
          items {
            slug
            title
            description {
              chapeau
              slugline
            }
            mainImage {
              filename
              caption
              credits
              focusPoint {
                x
                y
              }
            }
            morning
            afternoon
            eventSponsors {
              sponsorsGold
              sponsorsSilver
            }
            linkedContent {
              articles
              sponsoredContent
              events
            }
            practicalInfo {
              beginDate
              endDate
              languages
              location {
                name
                street
                zipCode
                city
                country
              }
            }
            creationDate
            updateDate
            publication {
              date
              isPublished
              target {
                news
                club
              }
              newsTicker
            }
          }
          count
          limit
          offset
        }

        ... on ErrorPayload {
          traceId
          errorMessage
          validationErrors {
            code
            message
          }
        }

        ... on NoResult {
          message
        }
      }
    }
  `;
};

const retrieve = <T>(params?: GetOneParams<T>): DocumentNode =>
  gql`
    query {
      workshop (
        slug: "${params?.id}"
      ) {
        ... on Workshop {
          slug
          title
          description {
            chapeau
            slugline
          }
           mainImage {
             filename
             caption
             credits
             focusPoint {
               x
               y
             }
           }
          morning
          afternoon
          eventSponsors {
            sponsorsGold
            sponsorsSilver
          }
          linkedContent {
            articles
            sponsoredContent
            events
          }
          practicalInfo {
            beginDate
            endDate
            languages
            location {
              name
              street
              zipCode
              city
              country
            }
          }
          creationDate
          updateDate
          publication {
            date
            isPublished
            target {
              news
              club
            }
            newsTicker
          }
        }
        ... on ErrorPayload {
          validationErrors {
            code
            message
          }
          errorMessage
          traceId
        }
        ... on NoResult {
          message
        }
      }
    }
`;

const create = (): DocumentNode =>
  gql`
     mutation CreateWorkshop (
      $createData: WorkshopCreateInput!
     ) {
      createWorkshop(createData: $createData) {
        ... on Workshop {
          slug
          title
          description {
            chapeau
            slugline
          }
          mainImage {
            filename
            caption
            credits
            focusPoint {
              x
              y
            }
          }
          morning
          afternoon
          practicalInfo {
            beginDate
            endDate
            languages
            location {
              name
              street
              zipCode
              city
              country
            }
          }
          eventSponsors {
            sponsorsGold
            sponsorsSilver
          }
          linkedContent {
            articles
            sponsoredContent
            events
          }
          publication {
            date
            isPublished
            target {
              news
              club
            }
            newsTicker
          }
        }
        ... on ErrorPayload {
        traceId
        errorMessage
        validationErrors {
            code
            message
          }
        }
      }
    }
  `;

const update = () => gql`
  mutation UpdateWorkshop(
    $updateData:  WorkshopUpdateInput!
  ) {
    updateWorkshop(updateData: $updateData) {
      ... on Workshop {
        slug
        title
        description {
          chapeau
          slugline
        }
        mainImage {
          filename
          caption
          credits
          focusPoint {
            x
            y
          }
        }
        morning
        afternoon
        practicalInfo {
          beginDate
          endDate
          languages
          location {
            name
            street
            zipCode
            city
            country
          }
        }
        eventSponsors {
          sponsorsGold
          sponsorsSilver
        }
        linkedContent {
          articles
          sponsoredContent
          events
        }
        publication {
          date
          isPublished
          target {
            news
            club
          }
          newsTicker
        }
      }
      ... on ErrorPayload {
      traceId
      errorMessage
      validationErrors {
          code
          message
        }
      }
    }
  }
 `;

const workshopGraphqlServices = {
  list,
  retrieve,
  create,
  update,
};

export default workshopGraphqlServices;
