import { ReactNode } from 'react';

export interface ShowHideProps {
  when: boolean;
  children: ReactNode;
}

const Show = ({ when, children }: ShowHideProps) => <>{when && children}</>;
const Hide = ({ when, children }: ShowHideProps) => <Show when={!when}>{children}</Show>;

export { Show, Hide };
