import { i18n } from 'shared/i18n';

export const HomeGeneralId = 'home-general';
export const HomeBreakingNewsId = 'home-breakingnews';
export const HomeHotTopicsId = 'home-hot-topics';
export const HomeFocusTopicId = 'home-focustopic';
export const HomeFocusAreaId = 'home-focus-area';
export const HomeAlaUneId = 'home-alaune';
export const HomeTopRecommandesId = 'home-toprecommandes';
export const HomePersoMomentId = 'home-persodumoment';
export const HomeMagazineId = 'home-magazine';

export const HomeGeneralLabel = i18n('homes.nav.general');
export const HomeBreakingNewsLabel = i18n('homes.nav.breakingNews');
export const HomeHotTopicsLabel = i18n('homes.nav.hotTopics');
export const HomeFocusAreaLabel = i18n('homes.nav.focusArea');
export const HomeFocusTopicLabel = i18n('homes.nav.focusTopic');
export const HomeAlaUneLabel = i18n('homes.nav.aLaUne');
export const HomeTopRecommandesLabel = i18n('homes.nav.topRecommendations');
export const HomePersoMomentLabel = i18n('homes.nav.personOfTheMoment');
export const HomeMagazineLabel = i18n('homes.nav.magazine');

export const HEADLINE = 0;
export const TOP_MEDIUM_ARTICLE = 1;
export const LEFT_MEDIUM_ARTICLE = 2;
export const CENTER_MEDIUM_ARTICLE = 3;
export const RIGHT_MEDIUM_ARTICLE = 4;
export const LIST_ARTICLES = 5;

export interface AlaUneSection {
  name: string;
  label: string;
}

export const alaUneSection: AlaUneSection[] = [
  {
    name: 'headline',
    label: i18n('homes.view.headlineArticle'),
  },
  {
    name: 'topMediumArticle',
    label: i18n('homes.view.articleHigh'), // 'Article Medium Haut',
  },
  {
    name: 'leftMediumArticle',
    label: i18n('homes.view.articleLeft'), // 'Article Medium Bas Gauche',
  },
  {
    name: 'centerMediumArticle',
    label: i18n('homes.view.articleCenter'), // 'Article Medium Bas Centre',
  },
  {
    name: 'rightMediumArticle',
    label: i18n('homes.view.articleRight'), // 'Article Medium Bas Droite',
  },
  ...[...new Array(9)].map((_, index) => ({
    name: 'articlesList',
    label: `${i18n('homes.view.smallArticle')} ${index + 1}`,
  })),
];
