import { RouteComponentProps } from 'react-router';
import { Content, FormSection } from 'layout/content';
import useGetSectionRef from 'layout/ScrollspyProvider/useGetSectionRef';
import { FormMode } from 'shared/models';
import FormHeader from 'shared/components/header/FormHeader';
import { i18n } from 'shared/i18n';
import { ArrayInput, ArticleSelectInput, GeneralInput, SelectInput } from 'shared/form/inputs';
import { Form } from 'shared/form';
import { ActionsBar } from 'shared/components';
import { QuestionSlugs } from 'views/dossiers/types';
import { dossierSectorsChoices } from './utils';
import DossierQuestionInput from './inputs/DossierQuestionInput';
import useInteractionDossierDetailView from './useInteractionDossierDetailView';
import './dossierView.scss';
import * as constants from './constants';

const DossierView = ({ match }: RouteComponentProps<{ slug: string }>) => {
  const sectionRefs = useGetSectionRef();

  const { onPreview, onSubmit, validate, mode, dossier, onPublish, loading } = useInteractionDossierDetailView(match);

  return (
    <Form onSubmit={onSubmit} initialValues={dossier} validate={validate}>
      <FormHeader
        label={match.path === '/dossier/create' ? i18n('dossiers.createTitle') : i18n('dossiers.updateTitle')}
      />
      <Content loading={loading}>
        <div className="dossier-content">
          <div className="dossier-sections">
            <GeneralInput
              ref={sectionRefs['general-section']}
              nameTitle="title"
              title={dossier.title}
              label={i18n('dossiers.view.title')}
            />
            <FormSection
              ref={sectionRefs[constants.dossierSecteurMenuItem.id]}
              title={i18n('dossiers.nav.sector')}
              id={constants.dossierSecteurMenuItem.id}
            >
              <SelectInput name="sector" label={i18n('dossiers.view.sectorLabel')} options={dossierSectorsChoices} />
            </FormSection>
            <FormSection
              ref={sectionRefs[constants.dossierTableRondeMenuItem.id]}
              title={i18n('dossiers.nav.table')}
              id={constants.dossierTableRondeMenuItem.id}
            >
              <ArticleSelectInput label={i18n('labels.question')} name="tableRonde" />
            </FormSection>

            <ArrayInput<QuestionSlugs>
              innerRef={sectionRefs[constants.dossierQuestionsMenuItem.id]}
              numOfItems={5}
              label="labels.article"
              name="questions"
            >
              <DossierQuestionInput id={constants.dossierQuestionsMenuItem.id} />
            </ArrayInput>

            <DossierQuestionInput
              ref={sectionRefs[constants.dossierCarteBlancheMenuItem.id]}
              id={constants.dossierCarteBlancheMenuItem.id}
              label={i18n('dossiers.nav.cart')}
              showLabelInput={false}
              name="carteBlanche"
            />
          </div>
          <ActionsBar
            canUpdate={mode === FormMode.EDIT}
            canPublish={mode === FormMode.CREATE}
            canPreview={mode === FormMode.EDIT}
            customPublishLabel={i18n('actionBar.save')}
            preview={onPreview}
            publish={onPublish}
          />
        </div>
      </Content>
    </Form>
  );
};

export default DossierView;
