import moment from 'moment';
import { appConfig } from 'shared/config/env';
import { i18n } from 'shared/i18n';
import { useWatch } from 'shared/form';
import Header from 'layout/header/Header';
import 'layout/header/header.scss';
import HeaderSection from 'views/home/Header/HeaderSection';

export interface HeaderHomepageProps {
  isCreating: boolean;
}

const HeaderHomepage =  ({ isCreating }: HeaderHomepageProps) => {
  const [name, date] = useWatch(
    ['general.name', 'general.date'],
  ) as [string, string | Date];

  return (
    <Header>
      <div className='header__inner'>
        <h1 className='header-title'>
          {isCreating ? i18n('homes.view.createTitle') : i18n('homes.view.updateTitle')}
        </h1>
        <HeaderSection label={i18n('homes.view.titleLabel')} value={name || ''} />
        <HeaderSection
          label={i18n('generalSection.publicationDate')}
          value={date && moment(date).format(appConfig.dateFormat)}
        />
        <HeaderSection
          label={i18n('generalSection.publicationHour')}
          value={date && moment(date).format(appConfig.timeFormat)}
        />
      </div>
    </Header>
  );
};

export default HeaderHomepage;
