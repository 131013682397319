import { useContext, useMemo } from 'react';
import UserSessionContext, { ROLE_IT_DIGITAL } from 'shared/stores/user/store';
import { Sections, SubSection } from 'views/Views';

const useNavMenuItems = () => {
  const userSession = useContext(UserSessionContext);
  const navItems: SubSection[] = Object.values(Sections);
  const filteredNavMenuItemsByRole = useMemo(() => {
    if (userSession && userSession.roleNames) {
      const userRoles = Array.isArray(userSession.roleNames) ? userSession.roleNames : [];
      return navItems.filter(
        (navItem) => navItem.roles?.some((role) => userRoles.includes(role) || userRoles.includes(ROLE_IT_DIGITAL)),
      );
    }
    return [];
  }, [userSession, navItems]);

  return filteredNavMenuItemsByRole;
};

export default useNavMenuItems;
