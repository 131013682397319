import { GetSearchParams } from 'shared/providers/dataProvider/types';
import { useQuery } from 'shared/providers';

const useSearch = <T extends Record<string, unknown>[]>(
  resource: string,
  params?: GetSearchParams,
) => {
  const { refetch } = useQuery<T>(resource, 'getSearch', params);

  return { refetch };
};

export default useSearch;