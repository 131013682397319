import { FormSection } from 'layout/content';
import useGetSectionRef from 'layout/ScrollspyProvider/useGetSectionRef';
import { resources } from 'shared/constants';
import { appConfig } from 'shared/config/env';
import {
  ArrayInput,
  BiographySelectInput,
  DnDArrayInput,
  EventSelectInput,
  SimpleInput,
  SlugInput,
  ToggleInput,
} from 'shared/form/inputs';
import { i18n } from 'shared/i18n';
import AdInput from 'shared/form/inputs/AdInput';
import { ImageUpload, SelectOption } from 'shared/types';
import { DelanoBreakfastNoon } from 'views/newsletter/constants';
import { ArticleSelect } from 'shared/components';
import JobsSectionInput from '../daily/JobsSectionInput';
import AdsBillboardInput from '../inputs/AdsBillboardInput';
import ParusChezMaisonModerneInput from '../inputs/ParusChezMaisonModerneInput';
import GeneralInput from '../inputs/GeneralInput';
import PhotoDuJour from '../inputs/PhotoDuJour';
import ClubMemberNewsInput from '../inputs/ClubMemberNewsInput';
import EnCeMomentDansLeMagazineInput from '../inputs/EnCeMomentDansLeMagazineInput';
import CitationDuJour from '../inputs/CitationDuJour';
import ChiffreDuJour from '../inputs/ChiffreDuJour';
import SlugAndTitleInput from '../inputs/SlugAndTitleInput';
import TweetDuJourInput from '../inputs/TweetDuJourInput';
import ExternalApiDefinitionSection from '../inputs/ExternalApiDefinitionSection';
import { ParusChezMaisonModerne } from '../types';

export interface DelanoBreakfastNoonNewsletterFormProps {
  type: string;
  onFileSelect: (image: ImageUpload) => void;
  onRemoveFile: (name: string) => void;
}

const DelanoBreakfastNoonNewsletterForm = ({
  type,
  onFileSelect,
  onRemoveFile,
}: DelanoBreakfastNoonNewsletterFormProps) => {
  const sectionRefs = useGetSectionRef();

  return (
    <>
      <FormSection
        ref={sectionRefs[DelanoBreakfastNoon.General.id]}
        id={DelanoBreakfastNoon.General.id}
        title={DelanoBreakfastNoon.General.label}
      >
        <GeneralInput
          type={type}
          labelTitle="newsletters.view.referenceNewsletter"
          labelSlugLine="newsletters.view.preHeaderMail"
        />
      </FormSection>
      <FormSection
        ref={sectionRefs[DelanoBreakfastNoon.AdsBillboard1.id]}
        id={DelanoBreakfastNoon.AdsBillboard1.id}
        title={DelanoBreakfastNoon.AdsBillboard1.label}
      >
        <AdsBillboardInput onRemoveFile={onRemoveFile} onFileSelect={onFileSelect} index={1} />
      </FormSection>
      <SlugAndTitleInput
        ref={sectionRefs[DelanoBreakfastNoon.BreakingNews.id]}
        id={DelanoBreakfastNoon.BreakingNews.id}
        title={DelanoBreakfastNoon.BreakingNews.label}
        name="content.breakingNews"
        toggleId="toggler-breakingnews"
      />
      <FormSection
        ref={sectionRefs[DelanoBreakfastNoon.ASuivre.id]}
        id={DelanoBreakfastNoon.ASuivre.id}
        title={DelanoBreakfastNoon.ASuivre.label}
      >
        <ArrayInput<string> name="content.aSuivre" label="newsletters.view.toBeContinued">
          <SimpleInput required width="100%" />
        </ArrayInput>
      </FormSection>
      <FormSection
        ref={sectionRefs[DelanoBreakfastNoon.ALaUne.id]}
        id={DelanoBreakfastNoon.ALaUne.id}
        title={DelanoBreakfastNoon.ALaUne.label}
      >
        <SlugInput
          required
          name="content.aLaUne"
          label={i18n('labels.article')}
          resource={resources.ARTICLES}
          options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}
        >
          <ArticleSelect />
        </SlugInput>
      </FormSection>
      <FormSection
        ref={sectionRefs[DelanoBreakfastNoon.AdsImu.id]}
        id={DelanoBreakfastNoon.AdsImu.id}
        title={DelanoBreakfastNoon.AdsImu.label}
      >
        <AdInput
          onRemoveFile={onRemoveFile}
          onFileSelect={onFileSelect}
          togglerId="adsimu-id"
          toggleInputName="content.adsImu.isActive"
          nameLink="content.adsImu.link"
          filename="content.adsImu.imageFilename"
          uploadUrl="/api/newsletter/image"
        />
      </FormSection>
      <FormSection
        ref={sectionRefs[DelanoBreakfastNoon.PolitiqueInstitutions.id]}
        id={DelanoBreakfastNoon.PolitiqueInstitutions.id}
        title={DelanoBreakfastNoon.PolitiqueInstitutions.label}
      >
        <DnDArrayInput<string> name="content.sectors.politiqueInstitutions" label="labels.article">
          <SlugInput resource={resources.ARTICLES} options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}>
            <ArticleSelect filter="sector:politiqueinstitutions" />
          </SlugInput>
        </DnDArrayInput>
      </FormSection>
      <FormSection
        ref={sectionRefs[DelanoBreakfastNoon.AdsBillboard2.id]}
        id={DelanoBreakfastNoon.AdsBillboard2.id}
        title={DelanoBreakfastNoon.AdsBillboard2.label}
      >
        <AdsBillboardInput onRemoveFile={onRemoveFile} onFileSelect={onFileSelect} index={2} />
      </FormSection>
      <FormSection
        ref={sectionRefs[DelanoBreakfastNoon.EntreprisesStratégies.id]}
        id={DelanoBreakfastNoon.EntreprisesStratégies.id}
        title={DelanoBreakfastNoon.EntreprisesStratégies.label}
      >
        <DnDArrayInput<string> name="content.sectors.entreprisesEtStrategies" label="labels.article">
          <SlugInput resource={resources.ARTICLES} options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}>
            <ArticleSelect filter="sector:entreprisesstrategies" />
          </SlugInput>
        </DnDArrayInput>
      </FormSection>
      <FormSection
        ref={sectionRefs[DelanoBreakfastNoon.AdsBillboard3.id]}
        id={DelanoBreakfastNoon.AdsBillboard3.id}
        title={DelanoBreakfastNoon.AdsBillboard3.label}
      >
        <AdsBillboardInput onRemoveFile={onRemoveFile} onFileSelect={onFileSelect} index={3} />
      </FormSection>
      <FormSection
        ref={sectionRefs[DelanoBreakfastNoon.PlaceFinancière.id]}
        id={DelanoBreakfastNoon.PlaceFinancière.id}
        title={DelanoBreakfastNoon.PlaceFinancière.label}
      >
        <DnDArrayInput<string> name="content.sectors.placeFinanciereEtMarches" label="labels.article">
          <SlugInput resource={resources.ARTICLES} options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}>
            <ArticleSelect filter="sector:placefinancieremarches" />
          </SlugInput>
        </DnDArrayInput>
      </FormSection>
      <FormSection
        ref={sectionRefs[DelanoBreakfastNoon.AdsBillboard4.id]}
        id={DelanoBreakfastNoon.AdsBillboard4.id}
        title={DelanoBreakfastNoon.AdsBillboard4.label}
      >
        <AdsBillboardInput onRemoveFile={onRemoveFile} onFileSelect={onFileSelect} index={4} />
      </FormSection>
      <FormSection
        ref={sectionRefs[DelanoBreakfastNoon.LifestyleViePratique.id]}
        id={DelanoBreakfastNoon.LifestyleViePratique.id}
        title={DelanoBreakfastNoon.LifestyleViePratique.label}
      >
        <DnDArrayInput<string> name="content.sectors.lifestyleEtViePrivee" label="labels.article">
          <SlugInput resource={resources.ARTICLES} options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}>
            <ArticleSelect filter="sector:lifestyleviepratique" />
          </SlugInput>
        </DnDArrayInput>
      </FormSection>
      <FormSection
        ref={sectionRefs[DelanoBreakfastNoon.AdsBillboard5.id]}
        id={DelanoBreakfastNoon.AdsBillboard5.id}
        title={DelanoBreakfastNoon.AdsBillboard5.label}
      >
        <AdsBillboardInput onRemoveFile={onRemoveFile} onFileSelect={onFileSelect} index={5} />
      </FormSection>

      <PhotoDuJour
        onRemoveFile={onRemoveFile}
        onFileSelect={onFileSelect}
        ref={sectionRefs[DelanoBreakfastNoon.PhotoDuJour.id]}
        id={DelanoBreakfastNoon.PhotoDuJour.id}
        title={DelanoBreakfastNoon.PhotoDuJour.label}
      />

      <TweetDuJourInput
        ref={sectionRefs[DelanoBreakfastNoon.TweetDuJour.id]}
        onRemoveFile={onRemoveFile}
        onFileSelect={onFileSelect}
        id={DelanoBreakfastNoon.TweetDuJour.id}
        title={DelanoBreakfastNoon.TweetDuJour.label}
      />

      <ChiffreDuJour
        onRemoveFile={onRemoveFile}
        onFileSelect={onFileSelect}
        ref={sectionRefs[DelanoBreakfastNoon.ChiffreDuJour.id]}
        id={DelanoBreakfastNoon.ChiffreDuJour.id}
        title={DelanoBreakfastNoon.ChiffreDuJour.label}
      />

      <CitationDuJour
        ref={sectionRefs[DelanoBreakfastNoon.CitationDuJour.id]}
        id={DelanoBreakfastNoon.CitationDuJour.id}
        title={DelanoBreakfastNoon.CitationDuJour.label}
      />
      {appConfig.platformName === 'delano' ? (
        <FormSection
          ref={sectionRefs[DelanoBreakfastNoon.Knowledge.id]}
          id={DelanoBreakfastNoon.Knowledge.id}
          title={DelanoBreakfastNoon.Knowledge.label}
        >
          <ArrayInput<string> name="content.expertises" label="labels.article">
            <SlugInput resource={resources.ARTICLES} options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}>
              <ArticleSelect filter="subSector:knowledge" />
            </SlugInput>
          </ArrayInput>
        </FormSection>
      ) : (
        <FormSection
          ref={sectionRefs[DelanoBreakfastNoon.Expertises.id]}
          id={DelanoBreakfastNoon.Expertises.id}
          title={DelanoBreakfastNoon.Expertises.label}
        >
          <ArrayInput<string> name="content.expertises" label="labels.article">
            <SlugInput resource={resources.ARTICLES} options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}>
              <ArticleSelect filter="subSector:expertises" />
            </SlugInput>
          </ArrayInput>
        </FormSection>
      )}

      <FormSection
        ref={sectionRefs[DelanoBreakfastNoon.InvestLongTerm.id]}
        id={DelanoBreakfastNoon.InvestLongTerm.id}
        title={DelanoBreakfastNoon.InvestLongTerm.label}
      >
        <ToggleInput id="toggler-investLongTerm" name="content.externalApi.investLongTerm.isActive">
          <SlugInput
            required
            name="content.externalApi.investLongTerm.slug"
            label={i18n('labels.article')}
            resource={resources.ARTICLES}
            options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}
          >
            <ArticleSelect />
          </SlugInput>
        </ToggleInput>
      </FormSection>
      <FormSection
        ref={sectionRefs[DelanoBreakfastNoon.AdsBillboard6.id]}
        id={DelanoBreakfastNoon.AdsBillboard6.id}
        title={DelanoBreakfastNoon.AdsBillboard6.label}
      >
        <AdsBillboardInput onRemoveFile={onRemoveFile} onFileSelect={onFileSelect} index={6} />
      </FormSection>
      <FormSection
        ref={sectionRefs[DelanoBreakfastNoon.Agenda.id]}
        id={DelanoBreakfastNoon.Agenda.id}
        title={DelanoBreakfastNoon.Agenda.label}
      >
        <ArrayInput<SelectOption> name="content.clubPart.agenda" label="labels.event">
          <EventSelectInput />
        </ArrayInput>
      </FormSection>

      <ExternalApiDefinitionSection
        onRemoveFile={onRemoveFile}
        onFileSelect={onFileSelect}
        ref={sectionRefs[DelanoBreakfastNoon.Explorator.id]}
        toggleId="toggler-explorator"
        name="vousMangezOuCeMidi"
        id={DelanoBreakfastNoon.Explorator.id}
        title={DelanoBreakfastNoon.Explorator.label}
      />

      <FormSection
        ref={sectionRefs[DelanoBreakfastNoon.Anniversaires.id]}
        id={DelanoBreakfastNoon.Anniversaires.id}
        title={DelanoBreakfastNoon.Anniversaires.label}
      >
        <ArrayInput<SelectOption> name="content.anniversaires" label="newsletters.view.anniversary">
          <BiographySelectInput />
        </ArrayInput>
      </FormSection>
      <FormSection
        ref={sectionRefs[DelanoBreakfastNoon.Carrieres.id]}
        id={DelanoBreakfastNoon.Carrieres.id}
        title={DelanoBreakfastNoon.Carrieres.label}
      >
        <ArrayInput<string> name="content.carrieres" label="newsletters.view.career">
          <SlugInput resource={resources.ARTICLES} options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}>
            <ArticleSelect filter="subSector:mouvements" />
          </SlugInput>
        </ArrayInput>
      </FormSection>

      <JobsSectionInput
        onRemoveFile={onRemoveFile}
        onFileSelect={onFileSelect}
        ref={sectionRefs[DelanoBreakfastNoon.Delano.id]}
        label={DelanoBreakfastNoon.Delano.label}
        id={DelanoBreakfastNoon.Delano.id}
      />

      <SlugAndTitleInput
        slugRequired
        ref={sectionRefs[DelanoBreakfastNoon.Club.id]}
        id={DelanoBreakfastNoon.Club.id}
        title={DelanoBreakfastNoon.Club.label}
        name="content.clubPart.paperjamClub"
        toggleId="toggler-Club"
      />

      <ClubMemberNewsInput
        ref={sectionRefs[DelanoBreakfastNoon.ClubMembers.id]}
        id={DelanoBreakfastNoon.ClubMembers.id}
        title={DelanoBreakfastNoon.ClubMembers.label}
      />

      <FormSection
        ref={sectionRefs[DelanoBreakfastNoon.ClubAgenda.id]}
        id={DelanoBreakfastNoon.ClubAgenda.id}
        title={DelanoBreakfastNoon.ClubAgenda.label}
      >
        <ArrayInput<SelectOption> name="content.clubPart.clubAgenda" label="labels.event">
          <EventSelectInput withWorkshop />
        </ArrayInput>
      </FormSection>

      <EnCeMomentDansLeMagazineInput
        onFileSelect={onFileSelect}
        onRemoveFile={onRemoveFile}
        ref={sectionRefs[DelanoBreakfastNoon.Magazine.id]}
        id={DelanoBreakfastNoon.Magazine.id}
        title={DelanoBreakfastNoon.Magazine.label}
      />

      <FormSection
        ref={sectionRefs[DelanoBreakfastNoon.ParusChez.id]}
        id={DelanoBreakfastNoon.ParusChez.id}
        title={DelanoBreakfastNoon.ParusChez.label}
      >
        <ArrayInput<ParusChezMaisonModerne> name="content.parusChezMaisonModerne">
          <ParusChezMaisonModerneInput onRemoveFile={onRemoveFile} onFileSelect={onFileSelect} />
        </ArrayInput>
      </FormSection>
      <FormSection
        ref={sectionRefs[DelanoBreakfastNoon.AdsBillboard7.id]}
        id={DelanoBreakfastNoon.AdsBillboard7.id}
        title={DelanoBreakfastNoon.AdsBillboard7.label}
      >
        <AdsBillboardInput onRemoveFile={onRemoveFile} onFileSelect={onFileSelect} index={7} />
      </FormSection>
    </>
  );
};

export default DelanoBreakfastNoonNewsletterForm;
