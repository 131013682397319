import moment from 'moment';
import { getProp } from 'shared/utils';
import { appConfig } from 'shared/config/env';

export interface TextFieldCellProps {
  className?: string;
  record?: Record<string, unknown>;
  source: string;
  showTime?: boolean;
}

const DateFieldCell = ({
  className,
  source,
  record,
  showTime = false,
}: TextFieldCellProps) => {
  const date = getProp(record, source, '') as string;

  return <td className={className}>
    {moment(date).format(showTime ? appConfig.dateTimeFormat : appConfig.dateFormat)}
  </td>;
};

export default DateFieldCell;
