import { USER_ROLES } from 'shared/stores/constants';
import { Role } from 'shared/types';

/**
 * Filters and sorts a given array of roles based on a predefined order.
 * 
 * @param roles - The array of roles to filter and sort.
 * @returns The filtered and sorted array of roles.
 */
export const filterAndSortRoles = (roles: Role[]): Role[] => {
  return roles
    .filter((role) => USER_ROLES.includes(role)) // Filter to only include valid roles
    .sort((a, b) => USER_ROLES.indexOf(a) - USER_ROLES.indexOf(b)); // Sort based on the priority order
};
