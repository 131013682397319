import { GetOneParams, QueryStateOne } from './types';
import useQuery  from './useQuery';

export interface UseGetOneReturn<T> {
  data?: T;
  error: unknown;
  loading: boolean;
  refetch: <T>(params: GetOneParams<T>) => Promise<T>;
}

const useGetOne = <T extends Record<string, unknown>>(
  resource: string,
  options: GetOneParams<T>,
): UseGetOneReturn<T> => {
  const { data, error, loading, refetch } = useQuery<T>(
    resource,
    'getOne',
    options,
    options.allowedRequest
  ) as QueryStateOne<T>;

  return {
    data,
    error,
    loading,
    refetch,
  };
};

export default useGetOne;
