import AdInput from 'shared/form/inputs/AdInput';
import { ImageUpload } from 'shared/types';

export interface AdsBillboardInputProps {
  index: number;
  onFileSelect: (image: ImageUpload) => void;
  onRemoveFile: (name: string) => void;
}

const AdsBillboardInput = ({ index, onFileSelect, onRemoveFile }: AdsBillboardInputProps) =>
  <AdInput
    onRemoveFile={onRemoveFile}
    onFileSelect={onFileSelect}
    togglerId={`AdsBillboard${index}-id`}
    nameLink={`content.billboards.adsBillboard${index}.link`}
    toggleInputName={`content.billboards.adsBillboard${index}.isActive`}
    filename={`content.billboards.adsBillboard${index}.imageFilename`}
    uploadUrl="/api/newsletter/image"
  />;

export default AdsBillboardInput;
