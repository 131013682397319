import { i18n } from 'shared/i18n';

export const NewsletterKind = {
  Morning: i18n('newsletters.kind.Morning'),
  Evening: i18n('newsletters.kind.Evening'),
  DelanoBreakfast: i18n('newsletters.kind.Breakfast'),
  DelanoNoon: i18n('newsletters.kind.Noon'),
  Club: i18n('newsletters.kind.Club'),
  Trendin: i18n('newsletters.kind.Trendin'),
  GrandDossier: i18n('newsletters.kind.GrandDossier'),
};

export type Kind = 'DelanoBreakfast' | 'DelanoNoon' | 'Morning' | 'Evening' | 'Trendin' | 'Club' | 'GrandDossier';

const newsletterKindFromString = (kind: Kind) => {
  switch (kind) {
    case 'DelanoBreakfast':
      return NewsletterKind.DelanoBreakfast;
    case 'DelanoNoon':
      return NewsletterKind.DelanoNoon;
    case 'Morning':
      return NewsletterKind.Morning;
    case 'Evening':
      return NewsletterKind.Evening;
    case 'Trendin':
      return NewsletterKind.Trendin;
    case 'Club':
      return NewsletterKind.Club;
    case 'GrandDossier':
      return NewsletterKind.GrandDossier;
    default:
      return '';
  }
};

export default newsletterKindFromString;
