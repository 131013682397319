/* tslint:disable */
import moment from 'moment';
import { createSelectOption } from 'shared/models';
import { SlugAndTitle, SelectOption, BreakingNews } from 'shared/types';
import { Homepage } from 'views/home/types';
import {
  FocusAreaState,
  HomepageInput,
} from 'views/home/types';
import {
  CENTER_MEDIUM_ARTICLE,
  HEADLINE,
  LEFT_MEDIUM_ARTICLE, LIST_ARTICLES,
  RIGHT_MEDIUM_ARTICLE,
  TOP_MEDIUM_ARTICLE,
} from 'views/home/constants';

export const homepage2FormState = ({ articles, name, scheduledTime }: Homepage) => {
  const { hotTopics, primaryZone, breakingNews, focusArea } = articles;
  const article2SelectOption = (articleOption: SlugAndTitle) => ({
    value: articleOption.slug,
    label: articleOption.title,
  });

  return {
    aLaUne: {
      alaUneSections: [
        article2SelectOption(primaryZone.headline),
        article2SelectOption(primaryZone.topMediumArticle),
        article2SelectOption(primaryZone.bottomMediumArticles.left),
        article2SelectOption(primaryZone.bottomMediumArticles.center),
        article2SelectOption(primaryZone.bottomMediumArticles.right),
        ...primaryZone.articlesList.map(article2SelectOption),
      ],
      recommandedArticles: primaryZone.recommandedArticles.map(article2SelectOption),
    },
    general: {
      name,
      date: moment(scheduledTime).toDate(),
    },
    hotTopics: hotTopics.map((hotTopic: string) => createSelectOption(hotTopic, hotTopic)),
    isPublishModalOpen: false,
    loading: false,
    breakingNews: breakingNews === null ? undefined : breakingNews,
    focusArea: focusArea === null ? undefined : focusArea && {
      title: focusArea.title,
      articles: focusArea.articles.map(article2SelectOption),
      lirePlus: focusArea.lirePlus.map((lirePlus: string) => createSelectOption(lirePlus, lirePlus)),
    },
  };
};

export const EmptyFocusArea = {
  title: '',
  articles: [],
  lirePlus: [],
};

const toFocusAreaDto = (focusArea?: FocusAreaState) => {
  if (focusArea !== null && focusArea !== undefined && focusArea.isActive && focusArea !== EmptyFocusArea) {
    return {
      title: focusArea.title,
      articles: focusArea.articles &&
        focusArea.articles.filter(
          (article: SelectOption) => Boolean(article)
        ).map((article: SelectOption) => article.value),
      lirePlus: focusArea.lirePlus && focusArea.lirePlus.map((lirePlus: SelectOption) => lirePlus.value),
    };
  }
};

const toBreakingNews = (breakingNews?: Partial<BreakingNews>) => {
  if (breakingNews && breakingNews.isActive && breakingNews.title) {
    return {
      slug: breakingNews.slug,
      title: breakingNews.title,
    };
  }
};

export const inputValueListNotEmpty = (value: HomepageInput): Partial<HomepageInput> => ({
  ...value,
  aLaUne: {
    alaUneSections: [
      ...value.aLaUne.alaUneSections?.slice(0, LIST_ARTICLES),
      ...value.aLaUne.alaUneSections?.slice(LIST_ARTICLES).filter(
        (article?: SelectOption | null) => !!article
      ),
    ],
    recommandedArticles: value.aLaUne.recommandedArticles?.filter(
      (article?: SelectOption | null) => !!article
    ),
  },
  focusArea: value.focusArea && {
    ...value.focusArea,
    articles: value.focusArea?.articles?.filter(
      (article?: SelectOption | null) => !!article
    ),
  },
});

export const formState2ScheduledHomepageDto = ({
  aLaUne,
  general,
  hotTopics,
  breakingNews,
  focusArea,
}: Partial<HomepageInput>) => {
  if (!!general && !!general.name && !!general.date && !!aLaUne && !!hotTopics) {
    return {
      name: general.name,
      scheduledTime: general.date,
      articles: {
        hotTopics: hotTopics.map((hotTopic: Partial<SelectOption>) => hotTopic.value) as string[],
        breakingNews: toBreakingNews(breakingNews),
        primaryZone: {
          headline: aLaUne.alaUneSections[HEADLINE]
            ? aLaUne.alaUneSections[HEADLINE].value
            : undefined,
          topMediumArticle: aLaUne.alaUneSections[TOP_MEDIUM_ARTICLE]
            ? aLaUne.alaUneSections[TOP_MEDIUM_ARTICLE].value
            : undefined,
          leftMediumArticle: aLaUne.alaUneSections[LEFT_MEDIUM_ARTICLE]
            ? aLaUne.alaUneSections[LEFT_MEDIUM_ARTICLE].value
            : undefined,
          centerMediumArticle: aLaUne.alaUneSections[CENTER_MEDIUM_ARTICLE]
            ? aLaUne.alaUneSections[CENTER_MEDIUM_ARTICLE].value
            : undefined,
          rightMediumArticle: aLaUne.alaUneSections[RIGHT_MEDIUM_ARTICLE]
            ? aLaUne.alaUneSections[RIGHT_MEDIUM_ARTICLE].value
            : undefined,
          articlesList: aLaUne.alaUneSections?.slice(LIST_ARTICLES).filter(
            (article?: SelectOption | null) => !!article
          ).map(
            (article: SelectOption) => article.value
          ),
          recommandedArticles: aLaUne.recommandedArticles?.filter(
            (article?: SelectOption | null) => !!article
          ).map((article) => article.value),
        },
        focusArea: toFocusAreaDto(focusArea),
      },
    };
  }
};
