import { MouseEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { match } from 'react-router';
import { SAVE_IMAGE_ERROR_MESSAGE } from 'shared/services';
import { uiNotifier, apiErrorToUiNotifier, LOAD_ERROR_MESSAGE, SAVE_ERROR_MESSAGE } from 'shared/services';
import { i18n } from 'shared/i18n';
import { FormMode } from 'shared/models';
import { isStringNotEmpty, isValidUrl } from 'shared/utils/utils';
import { setErrors } from 'shared/form/utils';
import { PodcastShowInput } from 'views/show/PodcastShowView';
import { useGetOne } from 'shared/providers';
import { PodcastShow } from 'views/shows/PodcastsShowsListView';
import { toFormState, INITIAL_STATE, toPostCastShowDto, toPostCastShowUpdateDto } from 'views/show/models';
import { useCreate, useUpdate } from 'shared/providers';
import { resources } from 'shared/constants';
import { useImages } from 'shared/hooks';
import { ImageUpload } from 'shared/types';

export interface PodcastShowState {
  mode: string;
}

export interface UseInteractionPodcastShowViewReturn extends PodcastShowState {
  onSubmit: (value: Record<string, unknown>) => void;
  validate: (value: Record<string, unknown>) => Record<string, unknown>;
  publish: (event: MouseEvent<HTMLElement>, value?: unknown) => void;
  openPreview: (value?: unknown) => void;
  podcastShowDetail: PodcastShowInput;
  onFileSelect: (image: ImageUpload) => void;
  onRemoveFile: (name: string) => void;
  loading: boolean;
}

const useInteractionPodcastShowView = (match: match<{ slug: string }>): UseInteractionPodcastShowViewReturn => {
  const history = useHistory();
  const mode = match.path === '/podcast-show/create' ? FormMode.CREATE : FormMode.EDIT;
  const [podcastShowDetail, setPodcastShowDetail] = useState<PodcastShowInput>(INITIAL_STATE);
  const [podcastShowState, setPodcastShowState] = useState<PodcastShowState>({
    mode: FormMode.CREATE,
  });

  const {
    data: initialData,
    error,
    loading,
  } = useGetOne<PodcastShow>(resources.PODCAST_SHOWS, {
    id: match.params.slug,
    additionalResource: resources.PODCAST_SHOW,
    allowedRequest: mode === FormMode.EDIT,
  });

  useEffect(() => {
    if (error) {
      apiErrorToUiNotifier(LOAD_ERROR_MESSAGE);
      setPodcastShowState((prevState) => ({ ...prevState, loading: false }));
    }
  }, [error]);

  useEffect(() => {
    setPodcastShowState({ mode });
    if (initialData) {
      const podcast = { ...initialData } as PodcastShow;
      const formContent = toFormState(podcast);
      setPodcastShowDetail(formContent as unknown as PodcastShowInput);
    }
  }, [initialData, mode]);

  const { create } = useCreate(resources.PODCAST_SHOWS);
  const { images, onFileSelect, onRemoveFile, onRemoveAllFiles } = useImages();
  const { update } = useUpdate(resources.PODCAST_SHOWS);

  const publish = (event: MouseEvent<HTMLElement>, value?: unknown) => {
    const podcastData = toPostCastShowDto(value as PodcastShowInput, images);

    create({
      additionalResource: resources.CREATE_PODCAST_SHOW,
      payload: { ...podcastData },
      onSuccess: (data) => {
        onRemoveAllFiles();
        const id = (data as Record<string, string>).slug || (data as Record<string, unknown>).id;
        history.push(`/podcast-show/${id}`);
      },
      onFailure: (error) => {
        apiErrorToUiNotifier(SAVE_ERROR_MESSAGE)(error);
      },
    });
  };

  const onSubmit = (value: Record<string, unknown>) => {
    const podcastShowInput = value as PodcastShowInput;
    const podcastShowData = toPostCastShowUpdateDto(podcastShowInput, images);

    update({
      additionalResource: resources.UPDATE_PODCAST,
      id: podcastShowData.slug,
      payload: podcastShowData,
      onSuccess: (response) => {
        if (response && !!response.traceId) {
          uiNotifier('error', SAVE_IMAGE_ERROR_MESSAGE);
        }
        onRemoveAllFiles();
      },
      onFailure: (error) => {
        apiErrorToUiNotifier(SAVE_ERROR_MESSAGE)(error);
      },
    });
  };

  const openPreview = (value: unknown) => {
    history.push(`/podcast-show/${(value as PodcastShowInput).slug}/preview`);
  };

  const validate = (value: Record<string, unknown>) => {
    const errors = {};
    const podcastShowInput = value as PodcastShowInput;

    if (podcastShowInput) {
      if (!isStringNotEmpty(podcastShowInput.title)) {
        setErrors(errors, 'title', i18n('forms.validation.required'));
      }
      if (podcastShowInput.mainImage && !podcastShowInput.mainImage.filename) {
        setErrors(errors, 'mainImage.filename', i18n('forms.validation.required'));
      }
      if (!podcastShowInput.sector) {
        setErrors(errors, 'sector', i18n('forms.validation.required'));
      }
      if (!podcastShowInput.subSector) {
        setErrors(errors, 'subSector', i18n('forms.validation.required'));
      }
      if (isStringNotEmpty(podcastShowInput.subscribeUrl)) {
        if (!isValidUrl(podcastShowInput.subscribeUrl)) {
          setErrors(errors, 'subscribeUrl', i18n('podcastShows.form.validation.subscribe'));
        }
      }
      if (podcastShowInput.podcastEpisodes?.some((value) => value === null || value === undefined)) {
        setErrors(errors, 'podcastEpisodes', i18n('forms.validation.required'));
      }
    }

    return errors;
  };

  return {
    onSubmit,
    validate,
    publish,
    openPreview,
    podcastShowDetail,
    loading,
    mode: podcastShowState.mode,
    onRemoveFile,
    onFileSelect,
  };
};

export default useInteractionPodcastShowView;
