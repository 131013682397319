import moment from 'moment';
import { types } from 'shared/constants';
import { NewsletterDetail } from 'views/newsletter/types';
import { computeNewsletterTitle } from 'views/newsletter/state/common';

const transform = (data: NewsletterDetail, type: string) => {
  switch (type) {
    case types.CLUB:
    case types.CLUB_EN:
      return clubTransformValue(data);
    case types.TRENDIN:
      return trendinTransformValue(data);
    case types.GRAND_DOSSIER:
      return grandDossierTransformValue(data);
    case types.DELANO_NOON:
    case types.DELANO_BREAKFAST:
      return delanoBreakfastNoonTransformValue(data);
    case types.MORNING:
    case types.EVENING:
      return morningEveningTransformValue(data);
    default:
      return {
        content: {},
        title: computeNewsletterTitle(type.toLowerCase(), new Date()),
        publicationDate: moment(new Date()).toDate(),
      };
  }
};

const clubTransformValue = (data: NewsletterDetail) => {
  const { clubALaUne, ...content } = data.content;

  return {
    ...data,
    content: {
      ...content,
      aLaUne: clubALaUne,
    }
  };
};

const trendinTransformValue = (data: NewsletterDetail) => {
  const { trendinExpertises, ...content } = data.content;

  return {
    ...data,
    content: {
      ...content,
      expertises: trendinExpertises,
    }
  };
};

const grandDossierTransformValue = (data: NewsletterDetail) => {
  const { dossierBillboards, ...content } = data.content;

  return {
    ...data,
    content: {
      ...content,
      billboards: dossierBillboards,
    }
  };
};

const delanoBreakfastNoonTransformValue = (data: NewsletterDetail) => {
  const {
    delanoBillboards,
    delanoALaUne,
    delanoExpertises,
    delanoParusChezMaisonModerne,
    ...content
  } = data.content;

  return {
    ...data,
    content: {
      ...content,
      billboards: delanoBillboards,
      aLaUne: delanoALaUne,
      expertises: delanoExpertises,
      parusChezMaisonModerne: delanoParusChezMaisonModerne,
    }
  };
};

const morningEveningTransformValue = (data: NewsletterDetail) => {
  const {
    dailyBillboards,
    dailyALaUne,
    dailyExpertises,
    dailyParusChezMaisonModerne,
    ...content
  } = data.content;
  
  return {
    ...data,
    content: {
      ...content,
      billboards: dailyBillboards,
      aLaUne: dailyALaUne,
      expertises: dailyExpertises,
      parusChezMaisonModerne: dailyParusChezMaisonModerne,
    }
  };
};

export default transform;
