import { ChangeEvent } from 'react';
import TimeField from 'react-simple-timefield';
import classNames from 'classnames';
import './TimePicker.scss';
import TimeInput from './TimeInput';

export interface TimePickerProps {
  onBlur?: () => void;
  disabled?: boolean;
  label?: string;
  required?: boolean;
  value?: string;
  onChange: (value: string) => void;
}

const TimePicker = ({
  label,
  value,
  onBlur,
  onChange,
  disabled = false,
  required = false,
}: TimePickerProps) => {
  const onChangeInput = (event: ChangeEvent, value: string) => {
    onChange(value);
  };
  
  return (
    <div className='time-picker-container'>
      {label && (
        <span className='time-picker-label'>
          {label}
          {required && <span className='time-picker-required'>*</span>}
        </span>
      )}
      <i className={classNames('icon-clock', 'time-picker-icon')} />
      <TimeField
        value={value}
        onChange={onChangeInput}
        input={
          <TimeInput disabled={disabled} onBlur={onBlur} />
        }
      />
    </div>
  );
};

export default TimePicker;
