import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { EventSortingCriteria } from 'shared/models';
import { Pagination, Params } from 'shared/types';
import { useGetList } from 'shared/providers';
import { resources } from 'shared/constants';
import { Event } from './types';

export interface UseInteractionEventListViewReturn {
  events: Event[];
  pagination?: Pagination;
  isLoading: boolean;
  query: string;
  sortPredicate: string;
  handlePageChange: (index: number) => void;
  onPredicateChange: (predicate: string) => void;
  handleSearch: (query: string) => void;
  onChangeSearchBar: (query: string) => void;
  openDetailEvent: (item: Event) => void;
}

export interface EventListModel extends Params {
  page: number;
}

const useInteractionEventListView = (): UseInteractionEventListViewReturn => {
  const history = useHistory();
  const [eventParams, setEventParams] = useState<EventListModel>({
    titleQuery: '',
    page: 1,
    nbElementPerPage: 8,
    sort: EventSortingCriteria.RevCreationDate,
  });
  
  const [query, setQuery] = useState<string>('');
  
  const { titleQuery, ...params } = eventParams;

  const {
    data,
    pagination,
    loading: isLoading,
    onPageChange,
  } = useGetList<Event>(resources.EVENTS, titleQuery ? { ...eventParams } : { ...params });

  const handleSearch = (titleQuery: string) => {
    setEventParams((prevParams) => ({ ...prevParams, titleQuery, page: 1 }));
  };

  const onPredicateChange = (predicate: string) => {
    setEventParams((prevParams) => ({ ...prevParams, sort: predicate, page: 1 }));
  };

  const onChangeSearchBar = (titleQuery: string) => {
    setQuery(titleQuery);
    if (!titleQuery) {
      setEventParams((prevState) => ({ ...prevState, titleQuery: '' }));
    }
  };

  const openDetailEvent = (item: Event) => {
    history.push(`/event/${item.slug}`);
  };

  return {
    pagination,
    isLoading,
    events: data,
    query,
    sortPredicate: params.sort,
    handlePageChange: onPageChange,
    onPredicateChange,
    handleSearch,
    onChangeSearchBar,
    openDetailEvent,
  };
};

export default useInteractionEventListView;
