import {  ReactElement, useEffect, useMemo, useState } from 'react';
import { createSelectOption } from 'shared/models';
import { SelectOption } from 'shared/types';
import { useGetOne } from 'shared/providers';
import { DataType } from 'shared/providers/dataProvider/types';

const DEFAULT_STATE = {
  value: '',
  label: '',
};

export interface RenderProps {
  value: SelectOption;
  label?: string;
  onChange?: (value: SelectOption) => void;
}

export interface SlugAndTitleProps {
  render: (props: RenderProps) => ReactElement;
  slug?: string;
  value?: string | SelectOption;
  label?: string;
  onChange?: (value: SelectOption) => void;
  resource: string;
  options?: Record<string, unknown>;
}

export interface FullSlugProps {
  hash: string;
  slugifiedName: string;
  slug?: string;
  title?: string;
}
export interface SelectDataProps {
  slug?: string;
  title?: string;
  name?: string;
  fullSlug?: FullSlugProps;
  metadata:  {
    title?: string;
  };
}

const WithSlugAndTitle = ({
  render,
  slug,
  value,
  resource,
  label,
  onChange,
  options = {},
}: SlugAndTitleProps) => {
  const [slugState, setSlugState] = useState<SelectOption>(DEFAULT_STATE);
  const slugValue = useMemo(() =>
    slug || value && (typeof value === 'string' ? value : value.value),
    [slug, value]
  );

  const onSuccess = (data?: DataType<Record<string, unknown>>) => {
    const selectData = data as unknown as SelectDataProps;

    if (selectData && Object.keys(selectData).length) {
      let slug = '';
      let name = '';

      if (selectData.slug) {
        slug = `${selectData.slug}`;
        name = selectData?.name ?? selectData?.metadata?.title as string;
      } else {
        const fullSlug = selectData.fullSlug as FullSlugProps || {};
        const { hash, slugifiedName } = fullSlug;
        slug = `${hash}/${slugifiedName}`;
        name = selectData.name ?? '';
      }

      setSlugState(createSelectOption(slug, name));
    }
  };

  useGetOne<Record<string, unknown>>(resource, {
    id: slugValue,
    allowedRequest: !!(slugValue && slugState.value !== slugValue),
    onSuccess,
    ...options,
  });

  useEffect(() => {
    if (!slugValue) {
      setSlugState(DEFAULT_STATE);
    }
  }, [slugValue, slugState]);

  return render({ value: slugState, label, onChange });
};

export default WithSlugAndTitle;

