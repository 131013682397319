import { createRef, useEffect, useRef } from 'react';
import Pikaday from 'pikaday';
import classNames from 'classnames';
import i18nConfig from 'shared/components/form/date/pikadayi18n';
import 'shared/components/form/date/DatePicker.scss';

type OnChange = (newDate: Date) => void;

export interface DatePickerProps {
  onChange: (newDate: Date) => void;
  value: Date;
  disabled?: boolean;
  required?: boolean;
  label?: string;
}

const DatePicker = ({ onChange, value, label, disabled = false, required = false }: DatePickerProps) => {
  const inputRef = createRef<HTMLInputElement>();
  const prevOnChange = useRef<OnChange>();
  const pickday = useRef<Pikaday>();

  useEffect(() => {
    if (inputRef.current && !pickday.current) {
      pickday.current = new Pikaday({
        field: inputRef.current,
        i18n: i18nConfig,
        firstDay: 1,
        defaultDate: value || new Date(),
        setDefaultDate: true,
        format: 'DD/MM/YYYY',
        onSelect: onChange,
      });
    }

    if (pickday.current && prevOnChange.current != onChange) {
      prevOnChange.current = onChange;
      pickday.current.config({
        field: inputRef.current,
        onSelect: onChange,
      });
    }

    if (pickday.current && pickday.current.getDate() !== value) {
      pickday.current.setDate(value, true);
    }
  }, [onChange, inputRef, pickday, value]);

  return (
    <div className='date-picker-container'>
      {label !== undefined && (
        <span className='date-picker-label'>
          {label}
          {required && <span className='date-picker-required'>*</span>}
        </span>
      )}
      <i className={classNames('icon-calendar', 'date-picker-icon')} />
      <input className='date-picker' disabled={disabled} type="text" ref={inputRef} />
    </div>
  );
};

export default DatePicker;
