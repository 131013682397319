import { i18n } from 'shared/i18n';

export const DelanoBreakfastNoon = {
  General: {
    id: 'morningEvening-General',
    label: i18n('newsletters.nav.morningEvening.general'),
  },
  BreakingNews: {
    id: 'morningEvening-BreakingNews',
    label: i18n('newsletters.nav.morningEvening.breakingNews'), // 'Breaking News',
  },
  AdsBillboard1: {
    id: 'morningEvening-AdsBillboard1',
    label: i18n('newsletters.nav.morningEvening.adsBillboard1'), // 'Ads billboard 1',
  },
  ASuivre: {
    id: 'morningEvening-ASuivre',
    label: i18n('newsletters.nav.morningEvening.toBeContinued'), // 'À Suivre',
  },
  ALaUne: {
    id: 'morningEvening-ALaUne',
    label: i18n('newsletters.nav.morningEvening.aLaUne'), // 'À la une',
  },
  AdsImu: {
    id: 'morningEvening-AdsImu',
    label: i18n('newsletters.nav.morningEvening.adsImu'), //  'Ads imu',
  },
  PolitiqueInstitutions: {
    id: 'morningEvening-PolitiqueInstitutions',
    label: i18n('newsletters.nav.morningEvening.politiqueInstitutions'), // 'Politique & Institutions',
  },
  AdsBillboard2: {
    id: 'morningEvening-AdsBillboard2',
    label: i18n('newsletters.nav.morningEvening.adsBillboard2'), // 'Ads Billboard 2',
  },
  MyLifeLu: {
    id: 'morningEvening-MyLifeLu',
    label: i18n('newsletters.nav.morningEvening.myLifeLu'), // '(mylife.lu)',
  },
  EntreprisesStratégies: {
    id: 'morningEvening-EntreprisesStratégies',
    label: i18n('newsletters.nav.morningEvening.industry'), // 'Entreprises & stratégies',
  },
  AdsBillboard3: {
    id: 'morningEvening-AdsBillboard3',
    label: i18n('newsletters.nav.morningEvening.adsBillboard3'), // 'Ads Billboard 3',
  },
  Enjoy: {
    id: 'morningEvening-Enjoy',
    label: i18n('newsletters.nav.morningEvening.enjoy'), // '(Enjoy)',
  },
  InspiringWorkplace: {
    id: 'morningEvening-InspiringWorkplace',
    label: i18n('newsletters.nav.morningEvening.inspiringWorkplace'), // 'Inspiring Workplace'
  },
  ObservatoireImmobilier: {
    id: 'morningEvening-ObservatoireImmobilier',
    label: i18n('newsletters.nav.morningEvening.observatoireImmobilier'), // 'Observatoire immobilier',
  },
  PlaceFinancière: {
    id: 'morningEvening-PlaceFinancière',
    label: i18n('newsletters.nav.morningEvening.finance'), // 'Place financière',
  },
  AdsBillboard4: {
    id: 'morningEvening-AdsBillboard4',
    label: i18n('newsletters.nav.morningEvening.adsBillboard4'), // 'Ads Billboard 4',
  },
  Delano: {
    id: 'morningEvening-Delano',
    label: i18n('newsletters.nav.morningEvening.delano'), // '(Delano)',
  },
  PhotoDuJour: {
    id: 'morningEvening-PhotoDuJour',
    label: i18n('newsletters.nav.morningEvening.photoOfTheDay'), // 'Photo du jour',
  },
  TweetDuJour: {
    id: 'morningEvening-TweetDuJour',
    label: i18n('newsletters.nav.morningEvening.tweetOfTheDay'), // 'Tweet du jour',
  },
  ChiffreDuJour: {
    id: 'morningEvening-ChiffreDuJour',
    label: i18n('newsletters.nav.morningEvening.numberOfTheDay'), // 'Chiffre du jour',
  },
  CitationDuJour: {
    id: 'morningEvening-CitationDuJour',
    label: i18n('newsletters.nav.morningEvening.citationOfTheDay'), // 'Citation du jour',
  },
  Expertises: {
    id: 'morningEvening-Expertises',
    label: i18n('newsletters.nav.morningEvening.expertise'), // 'Expertises',
  },
  Knowledge: {
    id: 'morningEvening-Knowledge',
    label: i18n('newsletters.nav.morningEvening.knowledge') //Knowledge
  },
  InvestLongTerm: {
    id: 'morningEvening-InvestLongTerm',
    label: i18n('newsletters.nav.morningEvening.investLongTerm'), // 'InvestLongTerm',
  },
  AdsBillboard5: {
    id: 'morningEvening-AdsBillboard5',
    label: i18n('newsletters.nav.morningEvening.adsBillboard5'), // 'Ads Billboard 5',
  },
  LifestyleViePratique: {
    id: 'morningEvening-LifestyleViePratique',
    label: i18n('newsletters.nav.morningEvening.lifestyle'), // 'Lifestyle & Vie pratique',
  },
  AdsBillboard6: {
    id: 'morningEvening-AdsBillboard6',
    label: i18n('newsletters.nav.morningEvening.adsBillboard6'), // 'Ads billboard 6',
  },
  AdsBillboard7: {
    id: 'morningEvening-AdsBillboard7',
    label: i18n('newsletters.nav.morningEvening.adsBillboard7'), // 'Ads billboard 7',
  },
  Explorator: {
    id: 'morningEvening-Explorator',
    label: i18n('newsletters.nav.morningEvening.explorator'), // 'Explorator',
  },
  Carrieres: {
    id: 'morningEvening-Carrieres',
    label: i18n('newsletters.nav.morningEvening.careers'), // 'Carrières',
  },
  Faillites: {
    id: 'morningEvening-Faillites',
    label: i18n('newsletters.nav.morningEvening.bankruptcies'), // 'Faillites',
  },
  Anniversaires: {
    id: 'morningEvening-Anniversaires',
    label: i18n('newsletters.nav.morningEvening.birthdays'), // 'Anniversaires',
  },
  BonnesNouvelles: {
    id: 'morningEvening-BonnesNouvelles',
    label: i18n('newsletters.nav.morningEvening.goodNews'), // 'Bonnes nouvelles',
  },
  Jobs: {
    id: 'morningEvening-Jobs',
    label: i18n('newsletters.nav.morningEvening.jobs'), // 'Paperjam Jobs',
  },
  Immo: {
    id: 'morningEvening-Immo',
    label: i18n('newsletters.nav.morningEvening.immo'), // 'Paperjam Immo',
  },
  Agenda: {
    id: 'morningEvening-Agenda',
    label: i18n('newsletters.nav.morningEvening.agenda'), // 'Agenda',
  },
  ClubAgenda: {
    id: 'morningEvening-ClubAgenda',
    label: i18n('newsletters.nav.morningEvening.clubAgenda'), // 'ClubAgenda',
  },
  Club: {
    id: 'morningEvening-Club',
    label: i18n('newsletters.nav.morningEvening.club'), // 'Paperjam Club',
  },
  ClubMembers: {
    id: 'morningEvening-ClubMembers',
    label: i18n('newsletters.nav.morningEvening.clubMembers'), // 'Club Members',
  },
  Magazine: {
    id: 'morningEvening-Magazine',
    label: i18n('newsletters.nav.morningEvening.magazine'), // 'Dans le Magazine',
  },
  ParusChez: {
    id: 'morningEvening-ParusChez',
    label: i18n('newsletters.nav.morningEvening.publishedAt'), // 'Parus chez maison moderne',
  },
};

export const MorningEvening = {
  General: {
    id: 'morningEvening-General',
    label: i18n('newsletters.nav.morningEvening.general'),
  },
  BreakingNews: {
    id: 'morningEvening-BreakingNews',
    label: i18n('newsletters.nav.morningEvening.breakingNews'), // 'Breaking News',
  },
  AdsBillboard1: {
    id: 'morningEvening-AdsBillboard1',
    label: i18n('newsletters.nav.morningEvening.adsBillboard1'), // 'Ads billboard 1',
  },
  ASuivre: {
    id: 'morningEvening-ASuivre',
    label: i18n('newsletters.nav.morningEvening.toBeContinued'), // 'À Suivre',
  },
  ALaUne: {
    id: 'morningEvening-ALaUne',
    label: i18n('newsletters.nav.morningEvening.aLaUne'), // 'À la une',
  },
  AdsImu: {
    id: 'morningEvening-AdsImu',
    label: i18n('newsletters.nav.morningEvening.adsImu'), //  'Ads imu',
  },
  PolitiqueInstitutions: {
    id: 'morningEvening-PolitiqueInstitutions',
    label: i18n('newsletters.nav.morningEvening.politiqueInstitutions'), // 'Politique & Institutions',
  },
  AdsBillboard2: {
    id: 'morningEvening-AdsBillboard2',
    label: i18n('newsletters.nav.morningEvening.adsBillboard2'), // 'Ads Billboard 2',
  },
  MyLifeLu: {
    id: 'morningEvening-MyLifeLu',
    label: i18n('newsletters.nav.morningEvening.myLifeLu'), // '(mylife.lu)',
  },
  EntreprisesStratégies: {
    id: 'morningEvening-EntreprisesStratégies',
    label: i18n('newsletters.nav.morningEvening.industry'), // 'Entreprises & stratégies',
  },
  AdsBillboard3: {
    id: 'morningEvening-AdsBillboard3',
    label: i18n('newsletters.nav.morningEvening.adsBillboard3'), // 'Ads Billboard 3',
  },
  Enjoy: {
    id: 'morningEvening-Enjoy',
    label: i18n('newsletters.nav.morningEvening.enjoy'), // '(Enjoy)',
  },
  InspiringWorkplace: {
    id: 'morningEvening-InspiringWorkplace',
    label: i18n('newsletters.nav.morningEvening.inspiringWorkplace'), // 'Inspiring Workplace'
  },
  ObservatoireImmobilier: {
    id: 'morningEvening-ObservatoireImmobilier',
    label: i18n('newsletters.nav.morningEvening.observatoireImmobilier'), // 'Observatoire immobilier',
  },
  PlaceFinancière: {
    id: 'morningEvening-PlaceFinancière',
    label: i18n('newsletters.nav.morningEvening.finance'), // 'Place financière',
  },
  AdsBillboard4: {
    id: 'morningEvening-AdsBillboard4',
    label: i18n('newsletters.nav.morningEvening.adsBillboard4'), // 'Ads Billboard 4',
  },
  Delano: {
    id: 'morningEvening-Delano',
    label: i18n('newsletters.nav.morningEvening.delano'), // '(Delano)',
  },
  PhotoDuJour: {
    id: 'morningEvening-PhotoDuJour',
    label: i18n('newsletters.nav.morningEvening.photoOfTheDay'), // 'Photo du jour',
  },
  TweetDuJour: {
    id: 'morningEvening-TweetDuJour',
    label: i18n('newsletters.nav.morningEvening.tweetOfTheDay'), // 'Tweet du jour',
  },
  ChiffreDuJour: {
    id: 'morningEvening-ChiffreDuJour',
    label: i18n('newsletters.nav.morningEvening.numberOfTheDay'), // 'Chiffre du jour',
  },
  CitationDuJour: {
    id: 'morningEvening-CitationDuJour',
    label: i18n('newsletters.nav.morningEvening.citationOfTheDay'), // 'Citation du jour',
  },
  Expertises: {
    id: 'morningEvening-Expertises',
    label: i18n('newsletters.nav.morningEvening.expertise'), // 'Expertises',
  },
  Knowledge: {
    id: 'morningEvening-Knowledge',
    label: i18n('newsletters.nav.morningEvening.knowledge') //Knowledge
  },
  InvestLongTerm: {
    id: 'morningEvening-InvestLongTerm',
    label: i18n('newsletters.nav.morningEvening.investLongTerm'), // 'InvestLongTerm',
  },
  AdsBillboard5: {
    id: 'morningEvening-AdsBillboard5',
    label: i18n('newsletters.nav.morningEvening.adsBillboard5'), // 'Ads Billboard 5',
  },
  LifestyleViePratique: {
    id: 'morningEvening-LifestyleViePratique',
    label: i18n('newsletters.nav.morningEvening.lifestyle'), // 'Lifestyle & Vie pratique',
  },
  AdsBillboard6: {
    id: 'morningEvening-AdsBillboard6',
    label: i18n('newsletters.nav.morningEvening.adsBillboard6'), // 'Ads billboard 6',
  },
  AdsBillboard7: {
    id: 'morningEvening-AdsBillboard7',
    label: i18n('newsletters.nav.morningEvening.adsBillboard7'), // 'Ads billboard 7',
  },
  Explorator: {
    id: 'morningEvening-Explorator',
    label: i18n('newsletters.nav.morningEvening.explorator'), // 'Explorator',
  },
  Carrieres: {
    id: 'morningEvening-Carrieres',
    label: i18n('newsletters.nav.morningEvening.careers'), // 'Carrières',
  },
  Faillites: {
    id: 'morningEvening-Faillites',
    label: i18n('newsletters.nav.morningEvening.bankruptcies'), // 'Faillites',
  },
  Anniversaires: {
    id: 'morningEvening-Anniversaires',
    label: i18n('newsletters.nav.morningEvening.birthdays'), // 'Anniversaires',
  },
  BonnesNouvelles: {
    id: 'morningEvening-BonnesNouvelles',
    label: i18n('newsletters.nav.morningEvening.goodNews'), // 'Bonnes nouvelles',
  },
  Jobs: {
    id: 'morningEvening-Jobs',
    label: i18n('newsletters.nav.morningEvening.jobs'), // 'Paperjam Jobs',
  },
  Immo: {
    id: 'morningEvening-Immo',
    label: i18n('newsletters.nav.morningEvening.immo'), // 'Paperjam Immo',
  },
  Agenda: {
    id: 'morningEvening-Agenda',
    label: i18n('newsletters.nav.morningEvening.agenda'), // 'Agenda',
  },
  Club: {
    id: 'morningEvening-Club',
    label: i18n('newsletters.nav.morningEvening.club'), // 'Paperjam Club',
  },
  ClubMembers: {
    id: 'morningEvening-ClubMembers',
    label: i18n('newsletters.nav.morningEvening.clubMembers'), // 'Club Members',
  },
  Magazine: {
    id: 'morningEvening-Magazine',
    label: i18n('newsletters.nav.morningEvening.magazine'), // 'Dans le Magazine',
  },
  ParusChez: {
    id: 'morningEvening-ParusChez',
    label: i18n('newsletters.nav.morningEvening.publishedAt'), // 'Parus chez maison moderne',
  },
};

export const Club = {
  Header: {
    id: 'club-header',
    label: i18n('newsletters.nav.club.header'), // Header
  },
  ALaUne: {
    id: 'club-alaune',
    label: i18n('newsletters.nav.club.aLaUne'), // À La Une
  },
  Prochainement: {
    id: 'club-prochainement',
    label: i18n('newsletters.nav.club.comingSoon'), // Prochainement
  },
};

export const GrandsDossiers = {
  BillboardsQuantity: 6,
  GrandsDossiers: {
    id: 'grandDossiers-grandDossiers',
    label: i18n('newsletters.nav.grandDossiers.grandsDossiers'), // 'Grands dossiers',
  },
  AdsBillboard: {
    id: 'grandDossiers-adsbillboard',
    label: i18n('newsletters.nav.grandDossiers.adsBillboard'), // 'Ads Billboard',
  },
  Magazine: {
    id: 'grandDossiers-magazine',
    label: i18n('newsletters.nav.grandDossiers.magazine'), // 'Magazine ',
  },
};

export const Trendin = {
  Header: {
    id: 'trendin-Header',
    label: i18n('newsletters.nav.trendin.header'),
  },
  AdsBillboard1: {
    id: 'trendin-AdsBillboard1',
    label: i18n('newsletters.nav.trendin.adsBillboard1'), // 'Ads billboard 1',
  },
  Decodeurs: {
    id: 'trendin-Décodeur',
    label: i18n('newsletters.nav.trendin.decoder'), // 'Décodeur',
  },
  TroisQuestionsA: {
    id: 'trendin-3Questions',
    label: i18n('newsletters.nav.trendin.threeQuestions'), // '3 Questions',
  },
  Focus: {
    id: 'trendin-Focus',
    label: i18n('newsletters.nav.trendin.focus'), // 'Focus',
  },
  Expertises: {
    id: 'trendin-Expertises',
    label: i18n('newsletters.nav.trendin.expertises'), // 'Expertises',
  },
  LaMinuteLuxInnovation: {
    id: 'trendin-Luxinnovation',
    label: i18n('newsletters.nav.trendin.innovationMinute'), //  'La minute de Luxinnovation',
  },
  LInstantT: {
    id: 'trendin-LInstantT',
    label: i18n('newsletters.nav.trendin.moment'), // "L'Instant T",
  },
  Enjeux: {
    id: 'trendin-Enjeux',
    label: i18n('newsletters.nav.trendin.enjoy'), // 'Enjeux',
  },
  AdsBillboard2: {
    id: 'trendin-AdsBillboard2',
    label: i18n('newsletters.nav.trendin.adsBillboard2'), // 'Ads billboard 2',
  },
  Startuppers: {
    id: 'trendin-Startupper',
    label: i18n('newsletters.nav.trendin.startuppers'), // 'Startupper',
  },
  BrandVoice: {
    id: 'trendin-Brandvoice',
    label: i18n('newsletters.nav.trendin.brandVoice'), // 'Brandvoice',
  },
  EnBref: {
    id: 'trendin-EnBref',
    label: i18n('newsletters.nav.trendin.inBrief'), // 'En bref',
  },
  Agenda: {
    id: 'trendin-Agenda',
    label: i18n('newsletters.nav.trendin.agenda'), // 'Agenda',
  },
  AdsBillboard3: {
    id: 'trendin-AdsBillboard3',
    label: i18n('newsletters.nav.trendin.adsBillboard3'), // 'Ads billboard 3',
  },
  LaPresseEnParle: {
    id: 'trendin-LaPresseEnParle',
    label: i18n('newsletters.nav.trendin.pressSpeaks'), // 'La presse en parle',
  },
};
