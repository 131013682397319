import { i18n } from 'shared/i18n';

export const ArticleCategoriePrincipaleId = 'article-categorieprincipale';
export const ArticleCategorieSecondaireId = 'article-categoriesecondaire';
export const ArticleTopicsId = 'article-topics';
export const ArticleArticleAssociesId = 'article-articlesassocies';
export const ArticleContenusSponsoId = 'article-contenussponso';
export const ArticleEvenementsAssociesId = 'article-evenementsassocies';
export const ArticleTagsId = 'article-tags';
export const ArticleAudioId = 'article-speech-kit';

export const ArticleCategoriePrincipaleLabel = i18n('articles.nav.primaryCategory'); // 'Catégorie Principale';
export const ArticleCategorieSecondaireLabel = i18n('articles.nav.secondaryCategory'); // 'Catégorie Secondaire';
export const ArticleTopicsLabel = i18n('articles.nav.topics'); // 'Topics';
export const ArticleArticleAssociesLabel = i18n('articles.nav.associatedArticles'); //  'Articles Associés';
export const ArticleContenusSponsoLabel = i18n('articles.nav.sponsoredContent'); //  'Contenus sponsorisés';
export const ArticleEvenementsAssociesLabel = i18n('articles.nav.associatedEvents'); //  'Événements associés';
export const ArticleTagsLabel = i18n('articles.nav.tags'); //  'Tags';
export const ArticleAudio = i18n('articles.nav.speechKit'); //  "L'audio";
