import { RouteComponentProps } from 'react-router';
import { Content, FormSection } from 'layout/content';
import useGetSectionRef from 'layout/ScrollspyProvider/useGetSectionRef';
import { ActionsBar } from 'shared/components';
import {
  TopicSelectInput,
  SimpleInput,
  ToggleInput,
  GeneralInput,
  ArrayInput,
  ArticleSelectInput,
  TagSelectInput,
  EventSelectInput,
} from 'shared/form/inputs';
import { Form } from 'shared/form';
import { i18n } from 'shared/i18n';
import { SelectOption } from 'shared/types';
import * as constants from 'views/article/constants';
import ArticleDetailHeader from 'views/article/sections/ArticleDetailHeader';
import { ArticleCategoryInput } from './inputs';
import ArticleModal from './modal';
import useInteractionArticleView from './useInteractionArticleView';
import './ArticleDetail.scss';
import { withRouter } from 'react-router-dom';

export interface ArticleDetailFormProps extends RouteComponentProps<{ slug: string }> {
  isWoodwing?: boolean;
}

const ArticleDetailView = ({ isWoodwing, match }: ArticleDetailFormProps) => {
  const sectionRefs = useGetSectionRef();

  const {
    audioId,
    fetchError,
    hasUnsavedData,
    isPublishModalOpen,
    showAudio,
    article,
    onPublicationChange,
    putArticleMetadata,
    onPublish,
    onSubmit,
    onModalClose,
    validate,
    openPreview,
    loading,
  } = useInteractionArticleView({ isWoodwing, match });

  return fetchError && isWoodwing ? (
    <div className="woodwing-error">{i18n('articles.view.notSyncError')}</div>
  ) : (
    <Form onSubmit={onSubmit} validate={validate} initialValues={article}>
      {!isWoodwing && <ArticleDetailHeader title={article && article.metadata.title} />}
      <Content loading={loading}>
        <div className="article-detail-content">
          {article && (
            <>
              <GeneralInput
                nameTitle="metadata.title"
                onPublicationChange={onPublicationChange}
                title={article.metadata.title}
                isTitleEditable={false}
                nameCheckbox="publication.newsTicker"
                labelCheckbox={i18n('articles.newsTicker')}
              />
              <ArticleCategoryInput
                required
                index={0}
                ref={sectionRefs[constants.ArticleCategoriePrincipaleId]}
                id={constants.ArticleCategoriePrincipaleId}
                title={constants.ArticleCategoriePrincipaleLabel}
              />
              <ArticleCategoryInput
                index={1}
                ref={sectionRefs[constants.ArticleCategorieSecondaireId]}
                id={constants.ArticleCategorieSecondaireId}
                title={constants.ArticleCategorieSecondaireLabel}
              />
            </>
          )}
          <TopicSelectInput
            creatable
            ref={sectionRefs[constants.ArticleTopicsId]}
            optionsNumberLimit={2}
            title={constants.ArticleTopicsLabel}
            id={constants.ArticleTopicsId}
            name="metadata.topics"
          />
          <FormSection
            ref={sectionRefs[constants.ArticleArticleAssociesId]}
            title={constants.ArticleArticleAssociesLabel}
            id={constants.ArticleArticleAssociesId}
          >
            <ArrayInput<SelectOption>
              movedValueItems
              numOfItems={3}
              name="metadata.linkedContent.articles"
              label="labels.article"
            >
              <ArticleSelectInput />
            </ArrayInput>
          </FormSection>
          {/*Todo: exactly do not need to pass the options argument?*/}
          <TagSelectInput
            ref={sectionRefs[constants.ArticleTagsId]}
            title={constants.ArticleTagsLabel}
            id={constants.ArticleTagsId}
            label={i18n('articles.view.tags')}
            name="metadata.tags"
          />
          <FormSection
            ref={sectionRefs[constants.ArticleContenusSponsoId]}
            title={constants.ArticleContenusSponsoLabel}
            id={constants.ArticleContenusSponsoId}
          >
            <ArrayInput<SelectOption>
              movedValueItems
              numOfItems={3}
              name="metadata.linkedContent.sponsoredContent"
              label="labels.sponsored"
            >
              <ArticleSelectInput />
            </ArrayInput>
          </FormSection>

          <FormSection
            ref={sectionRefs[constants.ArticleEvenementsAssociesId]}
            title={constants.ArticleEvenementsAssociesLabel}
            id={constants.ArticleEvenementsAssociesId}
          >
            <ArrayInput<SelectOption>
              movedValueItems
              name="metadata.linkedContent.events"
              label="labels.event"
              numOfItems={3}
            >
              <EventSelectInput withWorkshop />
            </ArrayInput>
          </FormSection>

          {article && (
            <FormSection title={i18n('articles.view.sponsorArticle')} id="magazine-config">
              <SimpleInput
                width="100%"
                onChange={onPublicationChange}
                label={i18n('articles.view.additionalTitle')}
                name="metadata.titleComplement"
              />
            </FormSection>
          )}

          <FormSection
            ref={sectionRefs[constants.ArticleAudioId]}
            title={constants.ArticleAudio}
            id={constants.ArticleAudioId}
          >
            <ToggleInput id="toggler-audio-version" name="metadata.showAudio">
              {Boolean(audioId) && (
                <div>
                  <iframe
                    title="article-preview"
                    allowFullScreen={false}
                    data-src={`https://spkt.io/a/${audioId}`}
                    frameBorder="0"
                    id="speechkit-io-iframe"
                    scrolling="no"
                    style={{ display: 'none' }}
                  />
                </div>
              )}
            </ToggleInput>
          </FormSection>
        </div>
        {article && (
          <ActionsBar
            canUpdate
            formStateDependent
            canPreview={!isWoodwing && !hasUnsavedData}
            canPublish={false}
            preview={openPreview}
            publish={onPublish}
          />
        )}
      </Content>
      <ArticleModal
        onModalClose={onModalClose}
        isPublishModalOpen={isPublishModalOpen}
        showAudio={showAudio}
        putArticleMetadata={putArticleMetadata}
      />
    </Form>
  );
};

export default withRouter(ArticleDetailView);
