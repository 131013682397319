import { Fragment } from 'react';
import './resetPasswordView.scss';
import ResetEmailComponent from './inputs/ResetEmailComponent';
import ResetPasswordComponent from './inputs/ResetPasswordComponent';
import { i18n } from 'shared/i18n';
import useInteractionResetPasswordView from './useInteractionResetPasswordView';

export interface ResetPasswordViewProps {
  loadingUser: boolean;
}

const ResetPasswordView = ({ loadingUser }: ResetPasswordViewProps) => {
  const { logoUrl,
    loading,
    isEmailValid,
    handleForgotPassword,
    handleConfirmNewPassword
  } = useInteractionResetPasswordView();

  return (
    <div className="reset">
      <div className='reset-wrapper'>
        {loadingUser ? (
          <div className='reset-loading' />
        ) : (
          <Fragment>
              <div className='reset-logo-wrapper'>
                <img width="175" height="23" src={logoUrl} alt="BackOffice" />
                <div className='reset-logo-sub-logo'>
                <span>BackOffice</span>
              </div>
            </div>
              <div className='reset-box'>
                <h1 className='reset-title'>{i18n('resetPassword.title')}</h1>
              {isEmailValid ? (
                <ResetPasswordComponent onConfirmPassword={handleConfirmNewPassword} loading={loading} />
              ) : (
                <ResetEmailComponent onValidEmail={handleForgotPassword} loading={loading} />
              )}
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default ResetPasswordView;
