import { RouteStore } from 'shared/stores/routeStore';
import { appConfig } from 'shared/config/env';
import NavItem from './NavItem';
import useNavMenuItems from './useNavMenuItems';

export interface GlobalNavProps {
  routeStore: RouteStore;
}

const GlobalNav = ({ routeStore }: GlobalNavProps) => {
  const { currentRouteIs, storeRoute } = routeStore;
  const navItems = useNavMenuItems();

  return (
    <ul>
      {navItems
        .filter((section) => section.platforms.includes(appConfig.platformName))
        .map((item) => (
          <NavItem
            key={item.route}
            route={item.route}
            selected={currentRouteIs(item.route)}
            onChangeRoute={storeRoute}
            iconClass={item.iconClass}
          >
            <span>{item.label}</span>
          </NavItem>
        ))}
    </ul>
  );
};

export default GlobalNav;
