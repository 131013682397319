import moment from 'moment';
import classNames from 'classnames';
import { i18n } from 'shared/i18n';
import { appConfig } from 'shared/config/env';
import { getProp } from 'shared/utils';
import './PublicationCell.scss';

export interface PublicationCellProps {
  source: string;
  format?: string;
  label?: string;
  record?: Record<string, unknown>;
  published: boolean;
  statusText?: string;
  icon?: string | null;
}

const PublicationFieldCell = ({
  source,
  record,
  statusText: customStatusText,
  icon: customIcon,
  label='',
  published = false,
  format = appConfig.dateTimeFormat,
}: PublicationCellProps) => {
  const publication = getProp(record, source) as string | Date;
  const publicationDate = moment(publication).toDate();
  const isPublished = moment(publicationDate).isBefore(moment.now());
  const statusText = customStatusText ? customStatusText : isPublished
    ? i18n('publication.published')
    : i18n('publication.programFor');

  const icon = customIcon !== undefined ? customIcon : isPublished ? 'icon-check-circle' : 'icon-clock';
  
  const iconClasses = classNames(icon, 'publication-cell-pub', {
    ['icon-green']: isPublished,
  });

  return (
    <td>
      {published ? (
        <div>
          {icon && <i className={iconClasses} />}
          <span>{statusText}</span>
          <span className='publication-cell-published-on'>
            {`${label} ${moment(publicationDate).format(format)}`}
          </span>
        </div>
        ) : <div>{i18n('publication.notPublished')}</div>
      }
    </td>
  );
};

export default PublicationFieldCell;
