import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import classNames from 'classnames';
import moment from 'moment';
import { i18n } from 'shared/i18n';
import { apiErrorToUiNotifier, FETCH_ERROR_MESSAGE } from 'shared/services';
import 'shared/style/common/preview.scss';
import { Article } from '../types';
import { useGetOne } from 'shared/providers';
import { resources } from 'shared/constants';
import { articleNormalizer } from 'shared/services/articleService';

export interface ArticlePreviewState {
  isPublishModalOpen: boolean;
  article?: Article;
}

const ArticlePreview = ({ history, match }: RouteComponentProps<{ slug: string; previewUrl: string }>) => {
  const [articlePreviewState, setArticlePreview] = useState<ArticlePreviewState>({
    isPublishModalOpen: false,
  });
  const previewUrl = decodeURIComponent(match.params.previewUrl);

  const { data, error } = useGetOne<Article>(resources.ARTICLES, {
    id: match.params.slug,
    normalize: articleNormalizer,
    additionalResource: resources.ARTICLE_WITH_TITLES,
  });
  useEffect(() => {
    if(!!data?.slug){
      setArticlePreview((prevState) => ({ ...prevState, article: data }));
    }else {
      if(error){
        apiErrorToUiNotifier(FETCH_ERROR_MESSAGE);
      }
    }
  }, [data, error]);

  const openDetailArticle = () => {
    history.push(`/article/${match.params.slug}`);
  };

  return (
    <div className='preview'>
      <div className='preview-header'>
        <div className='preview-prev-btn-container'>
          <button
            type="button"
            className='preview-prev-btn'
            onClick={history.goBack}
          >
            <i className={classNames('icon-arrow', 'preview-prev-btn_icon')} />
            <span className='preview-prev-btn-text'>{i18n('labels.back')}</span>
          </button>
        </div>
        <div className='preview__section'>
          <span className='preview__section-label'>{i18n('labels.title')}</span>
          <span className='preview__section-value'>
            {articlePreviewState.article ? articlePreviewState.article.metadata.title : ''}
          </span>
        </div>
        <div className='preview__section'>
          <span className='preview__section-label'>{i18n('generalSection.publicationDate')}</span>
          <span className='preview__section-value'>
            {articlePreviewState.article
              ? moment(articlePreviewState.article.publication.date).format('DD/MM/YYYY')
              : ''
            }
          </span>
        </div>
        <div className='preview-buttons'>
          <button type="button" className='preview-edit-btn' onClick={openDetailArticle}>
            <i className={classNames('icon-edit', 'preview-edit-btn-icon')} />
            &nbsp;
          </button>
        </div>
      </div>
      <div className='preview-iframe-container'>
        <iframe title="articlePreview" className='preview-iframe' src={previewUrl} />
      </div>
    </div>
  );
};

export default withRouter(ArticlePreview);
