import { forwardRef } from 'react';
import { FormSection } from 'layout/content';
import { useInput } from 'shared/form';
import { TopicSelect } from 'shared/components/form/select/TopicSelect';
import { SelectOption } from 'shared/types';
import { CommonFieldProps, InputProps } from '../types';

export interface TopicSelectInputProps extends InputProps<SelectOption[]>, CommonFieldProps {
  creatable?: boolean;
}

const TopicSelectInput = forwardRef<HTMLElement, TopicSelectInputProps>(({
  name,
  label,
  title,
  id,
  defaultValue = [],
  ...props
}, ref) => {
  const input = useInput({ name, defaultValue });

  return label
    ? (<TopicSelect {...input} label={label} values={input.value || []} {...props} />)
    : (
        <FormSection ref={ref} title={title} id={id}>
          <TopicSelect {...input} values={input.value || []} {...props} />
        </FormSection>
    );
});

export default TopicSelectInput;
