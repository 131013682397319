import { useCallback, useMemo, useRef, useState } from 'react';
import { i18n } from 'shared/i18n';
import { useUpdate } from 'shared/providers';
import { useSetValue } from 'shared/form';
import { useTimeout } from 'shared/hooks';
import { resources } from 'shared/constants';
import { Article } from 'views/article/types';
import { InputArticle, viewStateToArticle } from 'views/article/utils';
import { SelectOption } from 'shared/types';

export interface PublishArticleState {
  changed: boolean;
  isLoading: boolean;
}

export interface PublishData extends Record<string, unknown> {
  date?: string | Date;
  mode: SelectOption;
}

export interface UseInteractionPublishModalProps {
  showAudio: boolean;
  articleData: InputArticle;
  publicationChange?: boolean;
  onModalClose: () => void;
  onSubmit?: (value: InputArticle) => void;
}

export const SCHEDULED_MODE: SelectOption = {
  label: i18n('actionBar.plannedPublication'),
  value: 'planned',
};

export interface UsePublishArticleModalReturn {
  initialValues: PublishData;
  publishArticleState: PublishArticleState;
  mode: SelectOption;
  onPublish: (value: Record<string, unknown>) => void;
  setPublishMode: (mode: SelectOption) => void;
}

const usePublishArticleModal = ({
  showAudio,
  onModalClose,
  articleData,
  publicationChange,
}: UseInteractionPublishModalProps): UsePublishArticleModalReturn => {
  const { update } = useUpdate(resources.ARTICLE_PUBLISHED, {
    additionalResource: resources.UPDATE_ARTICLE_METADATA_PUBLICATION,
  });
  const value = useRef<PublishData>({
    date: articleData.publication.date,
    mode: SCHEDULED_MODE,
  });

  const setParentFormValue = useSetValue();
  const [mode, setMode] = useState<SelectOption>(SCHEDULED_MODE);

  const article: Article = useMemo(() => viewStateToArticle(articleData, showAudio), [articleData, showAudio]);

  const [publishArticleState, setPublishArticleState] = useState<PublishArticleState>({
    changed: false,
    isLoading: false,
  });

  const { run: updateLoading } = useTimeout({
    callback: () => {
      setPublishArticleState((prevState) => ({ ...prevState, isLoading: false }));
    },
    ms: 100,
  });

  const { run: successPublication } = useTimeout({
    callback: () => {
      onModalClose();
    },
    ms: 250,
  });

  const onPublish = useCallback(
    (value) => {
      if (publicationChange) {
        setParentFormValue('publication.date', value.date);
        setParentFormValue('metadata.updateDate', value.date);
      }
      // todo feedback and close on complete
      setPublishArticleState((prevState) => ({ ...prevState, isLoading: true }));
      update({
        payload: article,
        id: article.slug,
        onSuccess: () => {
          updateLoading();
          successPublication();
        },
      });
    },
    [article, publicationChange, setParentFormValue, successPublication, update, updateLoading],
  );

  const setPublishMode = useCallback(
    (option) => {
      if (!option || !option.value) {
        return;
      }
      if (option.value === 'now') {
        const publicationDate = new Date();
        setParentFormValue('publication.date', publicationDate);
        setParentFormValue('metadata.updateDate', publicationDate);
      }

      setPublishArticleState((prevState) => ({
        ...prevState,
        changed: true,
      }));
      setMode(option);
    },
    [setParentFormValue],
  );

  return {
    initialValues: value.current,
    publishArticleState,
    mode,
    onPublish,
    setPublishMode,
  };
};

export default usePublishArticleModal;
