const stringToPath = (path: string): string[] =>
  path.replace(/["'|\]]/g, '').split(/[.\[]/);

const setProp = (stateValues: Record<string, unknown>, name: string, value: unknown) => {
  let index = -1;
  const primitiveFieldRegular = /^\\w*$/;
  const path = primitiveFieldRegular.test(name) ? [name] : stringToPath(name);
  const lastIndex = path.length - 1;
  while (++index < path.length) {
    const key = path[index];
    let fieldValue = value;
    if (index !== lastIndex) {
      const field = stateValues[key];
      fieldValue = (typeof field === 'object' && !Array.isArray(field)) || Array.isArray(field)
        ? field
        :!isNaN(+path[index + 1])
          ? []
          : {};
    }

    stateValues[key] = fieldValue as unknown;
    stateValues = stateValues[key] as Record<string, unknown>;
  }
};

export default setProp;
