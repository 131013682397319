import moment from 'moment';
import {
  Location,
  MainImage,
  Publication,
} from 'shared/types';
import { Description, LinkedContent } from 'views/workshops/types';

export const INITIAL_STATE = {
  slug: '',
  title: '',
  description: {
    chapeau: '',
    slugline: '',
  },
  mainImage: {
    _type: 'lu.paperjam.common.models.ModernImageContentType',
    filename: '',
    caption: undefined,
    credits: undefined,
    focusPoint: undefined,
  },
  morning: [],
  afternoon: [],
  eventSponsors: {
    sponsorsGold: [],
    sponsorsSilver: [],
  },
  linkedContent: {
    articles: [],
    sponsoredContent: [],
    events: [],
  },
  practicalInfo: {
    beginDate: moment().toISOString(),
    endDate: undefined,
    location: {
      name: '',
      street: '',
      zipCode: '',
      city: '',
      country: '',
    },
    registrationEmbed: undefined,
  },
  creationDate: moment().toISOString(),
  updateDate: undefined,
  publication: {
    date: moment().toISOString(),
    isPublished: true,
    target: {
      news: true,
      club: true,
    },
  },
  languages: ['fr', 'en'],
};

export interface WorkshopFormatted extends Record<string, unknown> {
  title: string;
  description: Description;
  mainImage: MainImage;
  instantDebut: string | Date;
  instantFin: string | Date;
  location: Location;
  afternoon: string[];
  linkedContent: LinkedContent;
  morning: string[];
  sponsorsGold: string[];
  sponsorsSilver: string[];
  publication: Publication;
}
