import { useCallback, useMemo } from 'react';
import moment from 'moment';
import { useInput } from 'shared/form';
import { CommonFieldProps, InputProps } from 'shared/form/types';
import { DatePicker, TimePicker } from 'shared/components/form';
import { i18n } from 'shared/i18n';

export interface DateTimeInputProps extends InputProps<Date>, CommonFieldProps {
  labelDate: string;
  labelTime: string;
  isoFormat?: boolean;
  onChange?: () => void;
}

const DateTimeInput = ({
  labelDate,
  labelTime,
  name,
  defaultValue,
  onChange,
  isoFormat = false,
  required = false,
  disabled = false,
}: DateTimeInputProps) => {
  const input = useInput({ name, defaultValue });

  const time = useMemo(() =>
    moment(input.value || new Date()).format('HH:mm'),
    [input]
  );

  const onDateTimeChange = useCallback((newDate, newTime) => {
    const date = moment(`${moment(newDate).format('YYYY-MM-DD')}T${newTime}`).toDate();

    if (onChange) {
      onChange();
    }

    input.onChange(isoFormat ? moment(date).toISOString() : date);

  }, [input, isoFormat, onChange]);

  const onDateChange = useCallback((newDate) => {
    onDateTimeChange(newDate, time);
  }, [onDateTimeChange, time]);

  const onTimeChange = useCallback((time) => {
    onDateTimeChange(input.value, time);
  }, [input, onDateTimeChange]);

  return (
    <>
      <DatePicker
        disabled={disabled}
        required={required}
        label={i18n(labelDate)}
        value={input.value || new Date()}
        onChange={onDateChange}
      />
      <TimePicker
        disabled={disabled}
        required={required}
        label={i18n(labelTime)}
        value={time}
        onChange={onTimeChange}
      />
    </>
  );
};

export default DateTimeInput;
