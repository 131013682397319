import { DelanoPlatform, PaperjamPlatform } from 'shared/models';
import { appConfig } from '../config/env';

export const isDelanoPlatform = () => appConfig.platformName === DelanoPlatform;

export interface Config {
  params?: Record<string, unknown>;
  platformName?: string;
}

export const getPlatform = (config: Config) => {
  // target platform explicitly specified in config
  if ('platformName' in config) {
    const { platformName } = config;
    if (platformName === PaperjamPlatform) {
      return undefined;
    }
    return platformName;
  }
  // target platform is taken from the environment
  if (isDelanoPlatform()) {
    return DelanoPlatform;
  }
  // return undefined which is equal to paperjam platform
  return undefined;
};

export const withPlatformName = (config: Config = {}) => {
  const platformName = getPlatform(config);
  return {
    ...config,
    params: {
      ...(config ? config.params : {}),
      platformName,
    },
  };
};

const PlatformNameService = {
  withPlatformName,
  isDelanoPlatform,
  getPlatform,
};

export default PlatformNameService;
