import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { match } from 'react-router';
import { uiNotifier } from 'shared/services';
import { appConfig, apiConfig, REST } from 'shared/config/env';
import { i18n } from 'shared/i18n';
import { DelanoPlatform } from 'shared/models';
import { useCreate, useGetOne, useUpdate } from 'shared/providers';
import { resources } from 'shared/constants';
import { ImageUpload } from 'shared/types';
import { useImages } from 'shared/hooks';
import { getNewsletterType, resourceNewsletters } from 'shared/utils';
import { DataType } from 'shared/providers/dataProvider/types';
import { computeNewsletterTitle } from './state/common';
import { reformattingValue, validate } from './state';
import { NewsletterDetail } from './types';
import transform from './transform';

export interface UseInteractionNewsletterDetailViewReturn {
  onSubmit: (value: Record<string, unknown>) => void;
  validation: (value: Record<string, unknown>) => Record<string, unknown>;
  newsletterDetail: NewsletterDetail;
  type: string;
  openPreview: () => void;
  closeModal: () => void;
  modalOpen: boolean;
  onFileSelect: (image: ImageUpload) => void;
  onRemoveFile: (name: string) => void;
  newsletterUrl: string;
  isNewNewsletterEndpoint: boolean;
  publish: () => void;
  previewNewNewsletter: () => void;
  publishNewNewsletterUrl: () => void;
  loading: boolean;
}

const useInteractionNewsletterDetailView = (
  match: match<{ type: string; id: string }>,
): UseInteractionNewsletterDetailViewReturn => {
  const history = useHistory();
  const newsletterUrl = useRef<string>('');
  const [isNewNewsletterEndpoint, setNewNewsletterEndpoint] = useState(false);
  const typeWithoutClubEn = match.params.type === 'club_en' ? 'club' : match.params.type;
  const { images, onFileSelect, onRemoveFile, onRemoveAllFiles } = useImages();
  const type = match.params.type;
  const newsletterDetail = useRef<NewsletterDetail>({
    content: {},
    title: computeNewsletterTitle(type.toLowerCase(), new Date()),
    publicationDate: moment(new Date()).toDate(),
  });

  const onSuccess = (data?: DataType<NewsletterDetail>) => {
    const newsletter = data as NewsletterDetail;
    if (Object.keys(newsletter).length > 0) {
      if (newsletter._owner && !newsletter._mine) {
        const oname = `${newsletter._owner.firstName} ${newsletter._owner.lastName}`;
        uiNotifier(
          'warning',
          `${i18n('newsletters.messages.editInProgress')} ${newsletter._owner.username} (${oname}).`,
        );
      }
      newsletterDetail.current = {
        ...(appConfig.queryType === REST ? newsletter : transform(newsletter, type)),
        kind: type.toLowerCase(),
      };
    }
  };
  const { update } = useUpdate(resources.NEWSLETTERS);
  const { create } = useCreate(resources.NEWSLETTERS);

  const { loading } = useGetOne<NewsletterDetail>(resources.NEWSLETTERS, {
    id: match.params.id,
    kind: typeWithoutClubEn,
    additionalResource: 'newsletter',
    onSuccess,
    allowedRequest: !(match.params.id === 'create' || Object.values(newsletterDetail.current.content).length > 0),
  });

  const [modalOpen, setModalOpen] = useState(false);

  const onSubmit = (value: Record<string, unknown>) => {
    const payload = reformattingValue(value, type);
    if (match.params.id === 'create' && payload) {
      let createData: Record<string, unknown> = payload;
      if (appConfig.queryType !== REST && images.length > 0) {
        createData = { ...payload, images };
      }
      const createNewsletterResource = resourceNewsletters.getCreateNewsletterResource(typeWithoutClubEn);
      create({
        kind: typeWithoutClubEn,
        additionalResource: createNewsletterResource,
        payload: { ...createData, kind: typeWithoutClubEn },
        onSuccess: (data) => {
          const id = (data as Record<string, string>).slug || (data as Record<string, unknown>).id;
          history.push(`/newsletter/${type}/${id}`);
        },
      });
    } else if (payload) {
      let updateData: Record<string, unknown> = payload;
      if (appConfig.queryType !== REST && images.length > 0) {
        updateData = { ...payload, images };
      }
      const updateNewsletterResource = resourceNewsletters.getUpdateNewsletterResource(typeWithoutClubEn);
      update({
        kind: typeWithoutClubEn,
        additionalResource: updateNewsletterResource,
        id: match.params.id,
        payload: { ...updateData, id: match.params.id, kind: typeWithoutClubEn },
        onSuccess: onRemoveAllFiles,
      });
    }
  };

  const validation = (value: Record<string, unknown>) => validate(type, value);
  const lang = type === 'club_en' ? 'en/' : '';

  const openPreview = () => {
    const { id } = match.params;
    const encodedUrl = encodeURIComponent(
      `${apiConfig.endpointUrl}/newsletter/${lang}${typeWithoutClubEn}/${id}/${new Date().getTime()}${
        appConfig.platformName === DelanoPlatform ? '?platformName=delano' : ''
      }`,
    );
    history.push(`/newsletter/preview/${type}/${id}/${encodedUrl}`);
  };

  const showModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const publish = () => {
    if (isNewNewsletterEndpoint) {
      setNewNewsletterEndpoint(false);
    }
    newsletterUrl.current = `${appConfig.assetsCdnHost}/newsletter/${lang}${typeWithoutClubEn}/${match.params.id}`;

    showModal();
  };

  const publishNewNewsletterUrl = () => {
    const type = getNewsletterType(typeWithoutClubEn);

    if (!isNewNewsletterEndpoint) {
      setNewNewsletterEndpoint(true);
    }

    newsletterUrl.current = `${apiConfig.newsletterEndpointUrl}/api/${type}/${
      match.params.id
    }?ts=${new Date().getTime()}`;

    showModal();
  };

  const previewNewNewsletter = () => {
    const newsletterType = getNewsletterType(typeWithoutClubEn);

    const encodedUrl = encodeURIComponent(
      `${apiConfig.newsletterEndpointUrl}/api/${newsletterType}/${match.params.id}?ts=${new Date().getTime()}`,
    );

    history.push(`/newsletter/preview/${type}/${match.params.id}/${encodedUrl}`);
  };

  return {
    onSubmit,
    validation,
    newsletterDetail: newsletterDetail.current,
    type,
    isNewNewsletterEndpoint,
    newsletterUrl: newsletterUrl.current,
    openPreview,
    closeModal,
    modalOpen,
    onFileSelect,
    onRemoveFile,
    previewNewNewsletter,
    publish,
    publishNewNewsletterUrl,
    loading,
  };
};

export default useInteractionNewsletterDetailView;
