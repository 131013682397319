import { Children, cloneElement, ReactElement } from 'react';

export interface DataTableCells<T extends Record<string, unknown>> {
  record: T;
  children: ReactElement[];
}

const DataTableRow = <T extends Record<string, unknown>>({ record, children }: DataTableCells<T>) => (
  <>
    {Children.map(children, (element) =>
      cloneElement(element, {
        ...element.props,
        record,
      })
    )}
  </>
);

export default DataTableRow;
