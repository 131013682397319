import { ReactElement, RefObject } from 'react';
import { i18n } from 'shared/i18n';
import { CommonFieldPropsWithoutRequired, InputName } from 'shared/form/types';
import useArrayInput from 'shared/form/useArrayInput';
import './ListForms.scss';
import ArrayItemInput from './ArrayItemInput';

export interface ArrayInputProps<T> extends CommonFieldPropsWithoutRequired, InputName {
  children: ReactElement;
  innerRef?: RefObject<HTMLElement>;
  getEmptyValue?: () => T;
  maxItems?: number;
  numOfItems?: number;
  staticArray?: boolean;
  movedValueItems?: boolean;
  required?: ((index: number) => void) | boolean;
  onRemoveNameItem?: (name: string) => void;
}

const ArrayInput = <T, >({
  children,
  name,
  maxItems,
  label,
  getEmptyValue,
  numOfItems,
  innerRef,
  onRemoveNameItem,
  staticArray = false,
  required = false,
  movedValueItems = false,
}: ArrayInputProps<T>) => {
  const { items, deleteItem, append, moveValueItems } = useArrayInput<T>({
    name,
    numOfItems,
    movedValueItems,
  });

  const appendItem = () => {
    const emptyValue = getEmptyValue ? getEmptyValue() : undefined;
    append(emptyValue);
  };

  const removeItem = (index: number) => {
    deleteItem(index);
    if (onRemoveNameItem) {
      onRemoveNameItem(`${name}[${index}]`);
    }
  };

  return (
    <>
      <ul ref={innerRef as RefObject<HTMLUListElement>} className='list-form'>
        {items.map((field, index) =>
          <ArrayItemInput
            {...field}
            required={required}
            staticArray={numOfItems !== undefined || staticArray}
            deleteItem={removeItem}
            index={index}
            name={`${name}[${index}]`}
            label={label}
            moveValueItems={moveValueItems}
          >
            {children}
          </ArrayItemInput>
        )}
      </ul>
      {staticArray || numOfItems === undefined &&
        <button
          type='button'
          hidden={!!maxItems && maxItems <= items.length}
          className='list-form-add-button'
          onClick={appendItem}
        >
          {i18n('labels.addElement')}
        </button>
      }
    </>
  );
};

export default ArrayInput;
