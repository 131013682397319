import { ChangeEvent } from 'react';
import { i18n } from 'shared/i18n';
import { useInput } from 'shared/form';
import { RadioInput } from 'shared/form/inputs';
import { CommonFieldProps, InputProps } from 'shared/form/types';
import 'shared/form/inputs/styles/RadioInput.scss';

export interface PublishInputProps extends InputProps<boolean>, CommonFieldProps {
  className?: string;
}

const PublishInput = ({ label, name, className, defaultValue = false }: PublishInputProps) => {
  const input = useInput({ name, defaultValue });

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    input.onChange(event.target.value === 'true');
  };

  return (
    <div className={className ? `radio-input ${className}` : 'radio-input'}>
      <span className='radio-input_description'>{label}</span>
      <RadioInput
        value="true"
        label={i18n('labels.yes')}
        onChange={onChange}
        isActive={!!input.value}
      />
      <RadioInput
        notChecked={!!input.value}
        value="false"
        label={i18n('labels.not')}
        onChange={onChange}
        isActive={!input.value}
      />
    </div>
  );
};

export default PublishInput;
