import { forwardRef } from 'react';
import { FormSection } from 'layout/content';
import { ArticleSelectInput, DnDArrayInput } from 'shared/form/inputs';
import { SelectOption } from 'shared/types';
import { InputName } from 'shared/form/types';
import * as constants from 'views/home/constants';

const ALaUneSectionInput = forwardRef<HTMLElement, InputName>(({
  name,
}, ref) => {
  const getLabel = (index: number) => constants.alaUneSection[index].label;

  return (
    <FormSection ref={ref} title={constants.HomeAlaUneLabel} id={constants.HomeAlaUneId}>
      <DnDArrayInput<SelectOption>
        required
        translateLabel={false}
        getLabel={getLabel}
        numOfItems={constants.alaUneSection.length}
        name={`${name}.alaUneSections`}
      >
        <ArticleSelectInput />
      </DnDArrayInput>
    </FormSection>
  );
});

export default ALaUneSectionInput;
