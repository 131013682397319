import { useEffect, useState } from 'react';
import { useGetValues, useSetValue, useWatch } from 'shared/form';
import { getSubsectors, getSubsubSectors } from 'shared/models';
import { SelectOption } from 'shared/types';

export interface UseArticleCategoryInputReturn {
  valueSector?: string;
  subsubSectorOpts: SelectOption[];
  onChangeSubSubSector: (value: SelectOption) => void;
}

export interface UseArticleSectorInputProps {
  nameSector: string;
  nameSubSector: string;
  nameSubSubSector: string;
  onChangeSubsubSector?: (value: SelectOption, subsector?: string) => void;
}

const useArticleSectorInput = ({
  nameSector,
  nameSubSector,
  nameSubSubSector,
  onChangeSubsubSector,
}: UseArticleSectorInputProps): UseArticleCategoryInputReturn => {
  const sector = useGetValues(nameSector);
  const setValue = useSetValue();
  const [subSectorValue, subsubSectorDtoValue] = useWatch(
    [nameSubSector, nameSubSubSector]
  ) as SelectOption[];
  const [subSector, setSubSector] = useState<string>();

  const valueSubSector = subSectorValue && (
    typeof subSectorValue === 'object'
      ? subSectorValue.value
      : subSectorValue
  );

  const subsubSectorOpts = valueSubSector ? getSubsubSectors(valueSubSector) : [];

  const valueSector = sector && (
    typeof sector === 'object' ? (sector as SelectOption).value : sector
  );

  const valueSubsubSectorDto =
    subsubSectorDtoValue && (
      typeof subsubSectorDtoValue === 'object'
        ? (subsubSectorDtoValue as SelectOption).value
        : subsubSectorDtoValue
    );

  useEffect(() => {
    if (valueSector && !valueSubSector) {
      setValue(nameSubSector,
        valueSector ? getSubsectors(valueSector as string)[0] : undefined
      );
    }
  }, [nameSubSector, setValue, valueSector, valueSubSector]);

  useEffect(() => {
    if (subSector !== valueSubSector) {
      setSubSector(valueSubSector);
      setValue(nameSubSubSector,
        valueSubSector && getSubsubSectors(valueSubSector).length
          ? getSubsubSectors(valueSubSector)[0]
          : undefined
      );
    }
  }, [nameSubSubSector, setValue, subSector, valueSubSector, valueSubsubSectorDto]);

  const onChangeSubSubSector = (value: SelectOption) => {
    if (onChangeSubsubSector) {
      onChangeSubsubSector(value, valueSubSector);
    }
  };

  return {
    valueSector: valueSector as string | undefined,
    subsubSectorOpts,
    onChangeSubSubSector,
  };
};

export default useArticleSectorInput;
