const GET_LIST = 'get_list';
const GET_ONE = 'get_one';
const GET_SEARCH = 'get_search';
const CREATE = 'create';
const UPDATE = 'update';
const DELETE_ONE = 'deleteOne';

const providerType = {
  GET_LIST,
  GET_ONE,
  GET_SEARCH,
  CREATE,
  UPDATE,
  DELETE_ONE,
};

export default providerType;
