import { getProp } from 'shared/utils';

export interface Observer<T> {
  name: string | string[];
  update: (value: T, name?: string) => void;
}

export interface Subscription {
  unsubscribe: () => void;
}

export interface Subject<T> {
  readonly observers: Observer<T>[];
  subscribe: (observer: Observer<T>) => Subscription;
  unsubscribe: () => void;
  notify: (formValues: T, name?: string) => void;
}

const createSubject = <T>(): Subject<T> => {
  let observers: Observer<T>[] = [];

  const subscribe = (observer: Observer<T>) => {
    observers.push(observer);

    return {
      unsubscribe: () => {
        observers = observers.filter((obs) => obs !== observer);
      }
    };
  };

  const unsubscribe = () => {
    observers = [];
  };

  const notify = (values: T, name?: string) => {
    if (name) {
      const value = getProp(values, name) as T;
      for (const observer of observers) {
        if (Array.isArray(observer.name)) {
          if (observer.name.indexOf(name) !== -1) {
            observer.update(values);
          }
        } else if (observer.name === name) {
          observer.update(value, name);
        }
      }
    } else {
      for (const observer of observers) {
        observer.update(values);
      }
    }
  };

  return {
    get observers() {
      return observers;
    },
    notify,
    unsubscribe,
    subscribe,
  };
};

export default createSubject;
