import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Pagination } from 'shared/types';
import { PodcastShowSortingCriteria } from 'shared/models';
import { PodcastShow } from './PodcastsShowsListView';
import { useGetList } from 'shared/providers';
import { resources } from 'shared/constants';

export interface UseInteractionPodcastShowsListViewReturn {
  list: PodcastShow[];
  pagination?: Pagination;
  isLoading: boolean;
  sortPredicate: string;
  goToCreate: () => void;
  onPredicateChange: (predicate: string) => void;
  openPodcastShowDetail: (item: PodcastShow) => void;
  handlePageChange: (index: number) => void;
}
const useInteractionPodcastShowsListView = (): UseInteractionPodcastShowsListViewReturn => {
  const history = useHistory();
  const [podcastSort, setPodcastSort] = useState<string>(PodcastShowSortingCriteria.RevCreationDate);
  const { data, pagination, loading, onPageChange } = useGetList<PodcastShow>(resources.PODCAST_SHOWS, {
    nbElementPerPage: 8,
    sortPredicate: podcastSort
  });

  const goToCreate = () => {
    history.push('/podcast-show/create');
  };

  const onPredicateChange = (predicate: string) => {
    setPodcastSort(predicate);
  };

  const openPodcastShowDetail = (item: PodcastShow) => {
    history.push(`/podcast-show/${item.slug}`);
  };

  return {
    list: data,
    pagination: pagination,
    isLoading: loading,
    sortPredicate: podcastSort,
    goToCreate,
    onPredicateChange,
    openPodcastShowDetail,
    handlePageChange: onPageChange,
  };
};

export default useInteractionPodcastShowsListView;
