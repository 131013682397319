import moment from 'moment';
import { setErrors } from 'shared/form/utils';
import { i18n } from 'shared/i18n';
import { NewsletterClubContent } from 'shared/types';
import { appConfig } from 'shared/config/env';
import { NewsletterDetail } from 'views/newsletter/types';
import { computeNewsletterTitle } from 'views/newsletter/state/common';
import { ClubData, ClubInput } from '../categories/ClubNewsletterForm/types';

export const createInitialStateClubData = (type: string) => ({
  publicationDate: new Date(),
  title: computeNewsletterTitle(type, new Date()),
  content: {
    slugline: '',
    aLaUne: [null],
    prochainement: [null],
  },
});

export const initialStateClubData = (value: NewsletterDetail) => {
  const content = value.content as NewsletterClubContent;
  return {
    publicationDate: new Date(value.publicationDate),
    title: value.title,
    content: {
      slugline: value.content.slugline,
      aLaUne: content.aLaUne?.map((aLaUne) => ({
        value: aLaUne.slug,
        label: aLaUne.title,
      })),
      prochainement: content.prochainement?.map((prochainement) => ({
        value: prochainement.slug,
        label: prochainement.title,
      })),
    },
  };
};

export const clubData = (value: ClubInput) => ({
    ...value,
    publicationDate: moment(value.publicationDate).format(appConfig.yearBasedDateFormat),
    content: {
      ...value.content,
      aLaUne: value.content.aLaUne?.filter(
        (aLaUne) => aLaUne !== null
      ).map((aLaUne) => aLaUne.value) || [],
      prochainement: value.content.prochainement?.filter(
        (prochainement) => prochainement !== null
      ).map((prochainement) => prochainement.value) || [],
    },
  });

export const clubDataValidate = (value: ClubInput) => {
  const errors = {};
  if (value) {
    if (!value.title){
      setErrors(errors, 'title', i18n('forms.validation.required'));
    }
    if (!value.publicationDate) {
      setErrors(errors, 'publicationDate', i18n('forms.validation.required'));
    }
    if (value.content && !value.content.slugline) {
      setErrors(errors, 'content.slugline', i18n('forms.validation.required'));
    }
  }

  return errors;
};

export const clubDataCopy = (value: ClubData) => ({
  ...value,
  publicationDate: moment(value.publicationDate).format(appConfig.yearBasedDateFormat),
  content: {
    ...value.content,
    aLaUne: value.content.aLaUne?.filter(
      (aLaUne) => aLaUne !== null
    ).map((aLaUne) => aLaUne.value) || [],
    prochainement: value.content.prochainement?.filter(
      (prochainement) => prochainement !== null
    ).map((prochainement) => prochainement.value) || [],
  },
});
