import { DeleteParams, useMutation } from 'shared/providers/dataProvider';
import { MutationParams } from 'shared/providers/dataProvider/useMutation';

export interface UseDeleteReturn {
  deleteOne: (params?: MutationParams<Record<string, unknown>>) => void;
}

const useDelete = (
  resource: string,
  params?: DeleteParams,
  messageSuccess?: string,
  messageFailure?: string,
): UseDeleteReturn => {
  const { mutate } = useMutation(
    resource,
    'deleteOne',
    params,
    messageSuccess,
    messageFailure,
  );

  return { deleteOne: mutate };
};

export default useDelete;
