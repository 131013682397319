import { ResponseListBody } from 'shared/types';
import { providerType } from 'shared/constants';
import {
  CreateParams,
  DataProviderType,
  DeleteParams,
  UpdateParams,
} from 'shared/providers/dataProvider';
import { GetListParams, GetOneParams, GetSearchParams } from 'shared/providers/dataProvider/types';
import services from 'shared/services/services';

const restApiProvider: DataProviderType = {
  getList: <T = Record<string, unknown>>(
    resource: string,
    params?: GetListParams,
  ) => services(providerType.GET_LIST, resource)?.(params as never) as Promise<ResponseListBody<T>>,
  getOne: <T = Record<string, unknown>>(
    resource: string,
    params: GetOneParams<T>,
  ) => {return services(providerType.GET_ONE, resource)?.(params as never) as Promise<T>;},
  getSearch: <T = Record<string, unknown>[]>(
    resource: string,
    params: GetSearchParams,
  ) => {
    if (params?.additionalResource) {
      delete params.additionalResource;
    }
    return services(providerType.GET_SEARCH, resource)?.(params as never) as Promise<T>;
  },
  create: <T = Record<string, unknown>>(
    resource: string,
    params: CreateParams,
  ) => services(providerType.CREATE, resource)?.(params as never) as Promise<T>,
  update: <T = Record<string, unknown>>(
    resource: string,
    params: UpdateParams,
  ) => services(providerType.UPDATE, resource)?.(params as never) as Promise<T>,
  deleteOne: <T = Record<string, unknown>>(resource: string, params: DeleteParams) =>
    services(providerType.DELETE_ONE, resource)?.(params as never) as Promise<T>,
};

export default restApiProvider;
