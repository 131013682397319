import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { Content } from 'layout/content';
import Header from 'layout/header/Header';
import 'layout/header/header.scss';
import { i18n } from 'shared/i18n';
import { DataTable, Pagination } from 'shared/components';
import { WorkshopSortingCriteria } from 'shared/models';
import { RowRenderData } from 'shared/components/datatable/DataTable';
import WorkshopRow from 'views/workshops/row/WorkshopRow';
import { Workshop } from './types';
import './workshopListView.scss';
import useInteractionWorkshopListView  from './useInteractionWorkshopListView';

const WorkshopListView = () => {
  const {
    workshops,
    pagination,
    isLoading,
    sortPredicate,
    goToCreate,
    handlePageChange,
    openWorkshopDetail,
    onPredicateChange,
  } = useInteractionWorkshopListView();

  const rowRenderer = ({item}: RowRenderData<Workshop>) => <WorkshopRow item={item} key={item.slug} />;

  return (
    <div className='workshop-list-root'>
      <Header>
        <h1 className={classNames('header-title', 'header-title-dark')}>
          <i className={classNames('header-title-icon', 'icon-big', 'icon-articles')} />
          <div className='header-title-text'>{i18n('workshops.pageTitle')}</div>
        </h1>
      </Header>
      <Content loading={isLoading}>
        <div className='workshop-list-create-btn-container'>
          <button type="button" className='workshop-list-create-btn' onClick={goToCreate}>
            {i18n('workshops.createNew')}
          </button>
        </div>
        <DataTable<Workshop>
          sortable
          sortByColumn={onPredicateChange}
          sortPredicate={sortPredicate}
          columns={[
            { label: '' },
            {
              label: i18n('table.columns.title'),
              predicate: WorkshopSortingCriteria.Title,
            },
            {
              label: i18n('table.columns.creationDate'),
              predicate: WorkshopSortingCriteria.CreationDate,
            },
            {
              label: i18n('table.columns.publicationDate'),
              predicate: WorkshopSortingCriteria.PublicationDate,
            },
            {
              label: '',
            },
          ]}
          data={workshops}
          isLoading={isLoading}
          selectItem={openWorkshopDetail}
          rowRenderer={rowRenderer}
        />
        {pagination && (
          <Pagination
            buttonsRange={3}
            onPageChange={handlePageChange}
            currentPage={pagination.page}
            lastPage={pagination.lastPage}
          />
        )}
      </Content>
    </div>
  );
};

export default withRouter(WorkshopListView);
