import { DocumentNode, gql } from '@apollo/client';
import { GetListParams, GetOneParams } from 'shared/providers/dataProvider/types';
import { appConfig } from 'shared/config/env';
import { getSort } from 'shared/services/graphql/utils';
import { HomepageSortingCriteriaAlias } from 'shared/services/graphql/aliases';

const list = (params?: GetListParams): DocumentNode => {
  const offset = params?.page !== undefined
    ? (params.page === 1 ? appConfig.offset : (params.page - 1) * Number(appConfig.limit))
    : appConfig.offset;
  
  const [sort, asc] = getSort(HomepageSortingCriteriaAlias, params?.sort);
  const type = params?.type || 'CURRENT_FUTURE';

  return gql`
    query {
      newsHomepages(
        type: ${type}
        limit: ${appConfig.limit},
        offset: ${offset},
        sort: "${sort}",
        asc: ${asc},
        platformName: ${appConfig.platformName},
      ) {
        ... on NewsHomepagesResponse {
          items {
            current
            scheduledHomepage {
              slug
              name
              at
              articles {
                hotTopics
                focusArea {
                  title
                  articles
                  lirePlus
                }
                breakingNews {
                  title
                  slug
                }
                primaryZone {
                  headline
                  topMediumArticle
                  bottomMediumArticles {
                    left
                    center
                    right
                  }
                  articlesList
                  recommandedArticles
                }
                personnalitesDuMoment
                nouveauxChallenges
              }
              creationDate
              author
              platformName
            }
          }
          count
          limit
          offset
        }

        ... on ErrorPayload {
          traceId
          errorMessage
          validationErrors {
            code
            message
          }
        }

        ... on NoResult {
          message
        }
      }
    }
  `;
};

const retrieve =<T>(params?: GetOneParams<T>): DocumentNode =>
  gql`
    query {
      newsHomepage (
        slug: "${params?.id}"
      ) {
         ... on NewsHomepageOut {
            scheduledTime
            name
            articles {
              hotTopics
              primaryZone {
                headline {
                  title
                  slug
                }
                topMediumArticle {
                  title
                  slug
                }
                bottomMediumArticles {
                  left {
                    title
                    slug
                  }
                  center {
                    title
                    slug
                  }
                  right {
                    title
                    slug
                  }
                }
                articlesList {
                  title
                  slug
                }
                recommandedArticles {
                  title
                  slug
                }
              }
              breakingNews {
                title
                slug
              }
              focusArea {
                title
                articles {
                  title
                  slug
                }
                lirePlus
              }
            }
          }
         ... on NoResult {
           message
         }
         ... on ErrorPayload {
          traceId
          errorMessage
          validationErrors {
            code
            message
          }
        }
      }
    }
  `;

const create = (): DocumentNode =>
  gql`
     mutation CreateNewsHomepage(
      $createData: NewsHomepageCreateInput!
     ) {
      createNewsHomepage(createData: $createData) {
        ... on NewsHomepageOut {
            slug
            scheduledTime
            name
            articles {
              hotTopics
              primaryZone {
                headline {
                  title
                  slug
                }
                topMediumArticle {
                  title
                  slug
                }
                bottomMediumArticles {
                  left {
                    title
                    slug
                  }
                  center {
                    title
                    slug
                  }
                  right {
                    title
                    slug
                  }
                }
                articlesList {
                  title
                  slug
                }
                recommandedArticles {
                  title
                  slug
                }
              }
              breakingNews {
                title
                slug
              }
              focusArea {
                title
                articles {
                  title
                  slug
                }
                lirePlus
              }
            }
          }
          ... on ErrorPayload {
          traceId
          errorMessage
          validationErrors {
            code
            message
          }
         }
        }
      }
  `;

const update = () => gql`
  mutation UpdateNewsHomepage(
    $updateData: NewsHomepageInput!
  ) {
    updateNewsHomepage(updateData: $updateData) {
      ... on NewsHomepageOut {
        slug
        scheduledTime
        name
        articles {
          hotTopics
          primaryZone {
            headline {
              title
              slug
            }
            topMediumArticle {
              title
              slug
            }
            bottomMediumArticles {
              left {
                title
                slug
              }
              center {
                title
                slug
              }
              right {
                title
                slug
              }
            }
            articlesList {
              title
              slug
            }
            recommandedArticles {
              title
              slug
            }
          }
          breakingNews {
            title
            slug
          }
          focusArea {
            title
            articles {
              title
              slug
            }
            lirePlus
          }
        }
      }
      ... on ErrorPayload {
          traceId
          errorMessage
          validationErrors {
            code
            message
          }
      }
    }
  }
 `;

const remove = () =>
  gql`
    mutation DeleteNewsHomepage($slug: String!) {
     deleteNewsHomepage (slug: $slug) {
       ... on DeleteNewsHomepageOut{
         deleted
       }
       ... on ErrorPayload {
          traceId
          errorMessage
          validationErrors {
            code
            message
          }
      }
     }
   }
  `;

const homepageGraphqlServices = {
  list,
  retrieve,
  create,
  update,
  remove,
};

export default homepageGraphqlServices;
