import { createContext } from 'react';
import { AuthProviderType } from './auth';

export interface AuthContextProps extends AuthProviderType {
  user: unknown;
  setUser: (user: unknown) => void;
}

const AuthContext = createContext<AuthContextProps | null>(null);

export default AuthContext;
