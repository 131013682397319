import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { Content } from 'layout/content';
import Header from 'layout/header/Header';
import 'layout/header/header.scss';
import './newsletterList.scss';
import { NewsletterSortingCriteria } from 'shared/models';
import { DataTable, Pagination, Modal } from 'shared/components';
import { i18n } from 'shared/i18n';
import { RowRenderData } from 'shared/components/datatable/DataTable';
import CreateNewsletterModal from 'views/newsletters/modal/CreateNewsletterModal';
import NewsletterRow from './row/NewsletterRow';
import useInteractionNewsletterList from './useInteractionNewsletterList';

export interface Newsletter extends Record<string, unknown> {
  title: string;
  creationDate: string | Date;
  id: string;
  kind: string;
  platformName: string;
  publicationDate: string;
}

const NewsletterListView = () => {
  const {
    newsletters,
    pagination,
    isLoading,
    sortPredicate,
    isCreateModalOpen,
    onPredicateChange,
    openModal,
    openNewsletterDetail,
    onItemDeleted,
    onPageChange,
    closeModal,
    copySuccess,
  } = useInteractionNewsletterList();

  const rowRenderer = ({ key, item }: RowRenderData<Newsletter>) =>
    <NewsletterRow
      key={key}
      item={item}
      onCopySuccess={copySuccess}
      onItemDeleted={onItemDeleted}
    />;

  return (
    <div className='newsletter-list-root'>
      <Header>
        <h1 className={classNames('header-title', 'header-title-dark')}>
          <i className={classNames('header-title-icon', 'icon-big', 'icon-email')} />
          <div className='header-title-text'>{i18n('newsletters.pageTitle')}</div>
        </h1>
      </Header>
      <Content>
        <button type="button" onClick={openModal} className='nl-create'>
          {i18n('newsletters.createNew')}
        </button>
        <DataTable<Newsletter>
          sortable
          isLoading={isLoading}
          columns={[
            {
              label: i18n('table.columns.title'),
            },
            {
              label: i18n('table.columns.publicationDate'),
              predicate: NewsletterSortingCriteria.PublicationDate,
            },
            {
              label: i18n('table.columns.type'),
            },
            {
              label: i18n('table.columns.creationDate'),
              predicate: NewsletterSortingCriteria.CreationDate,
            },
            {
              label: i18n('table.columns.actions'),
            },
          ]}
          data={newsletters}
          rowRenderer={rowRenderer}
          selectItem={openNewsletterDetail}
          sortByColumn={onPredicateChange}
          sortPredicate={sortPredicate}
        />
        {pagination && (
          <Pagination
            buttonsRange={3}
            onPageChange={onPageChange}
            currentPage={pagination.page}
            lastPage={pagination.lastPage}
          />
        )}
      </Content>
      <Modal onModalClose={closeModal} isOpen={isCreateModalOpen}>
        <CreateNewsletterModal onModalClose={closeModal} />
      </Modal>
    </div>
  );
};

export default withRouter(NewsletterListView);
