import { useCallback, useState } from 'react';
import useSubscribe from 'shared/form/useSubscribe';
import { getProp, isSame } from 'shared/utils';

const useWatch = <T>(name: string | string[]) => {
  const [value, setValues] = useState<unknown>(
    Array.isArray(name) ? name.map(() => null) : undefined
  );

  const update = useCallback((fieldValues) => {
    if (Array.isArray(name) && Array.isArray(value)) {
      const values = name.map((nameObserver) => {
        return getProp(fieldValues, nameObserver);
      });

      if (!isSame(value, values)) {
        setValues(values);
      }
    } else if (fieldValues !== value) {
      setValues(fieldValues);
    }
  }, [name, value]);

  useSubscribe<T>({
    name,
    update,
  });

  return value;
};

export default useWatch;
