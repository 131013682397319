import * as Http from 'shared/services/httpService';
import { SlugAndTitle } from 'shared/types';

interface FeaturedProfilesReturn {
  monthlyProfiles: SlugAndTitle[]
}

export function featuredProfiles() {
  return Http.get<FeaturedProfilesReturn>('/api/guide/featuredProfiles');
}

interface GuideProfilesOutDto {
  payload: string[];
}

export function updatefeaturedProfiles(guideProfilesOutDto: GuideProfilesOutDto) {
  return Http.put('/api/guide/featuredProfiles', guideProfilesOutDto.payload);
}

const GuideHomepageService = {
  featuredProfiles,
  updatefeaturedProfiles,
};

export default GuideHomepageService;
