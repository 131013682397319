import React from 'react';
import { Link } from 'react-router-dom';
import '../resetPasswordView.scss';
import { Form } from 'shared/form';
import { i18n } from 'shared/i18n';
import { RESET_ERROR_CHECK_EMAIL_MESSAGE } from 'shared/services';
import useInteractionHandleResetEmailInput from './useInteractionHandleResetEmailInput';
import { SimpleInput } from 'shared/form/inputs';
import { commonConstants } from 'shared/constants';

interface ResetEmailComponentProps {
    onValidEmail: (email: string) => void;
    loading: boolean
}

const ResetEmailComponent: React.FC<ResetEmailComponentProps> = ({ onValidEmail, loading }) => {
    const {
        isValidEmail,
        isError,
        handleEmailChange,
        handleFormSubmit,
    } = useInteractionHandleResetEmailInput(onValidEmail);

    return (
        <Form onSubmit={handleFormSubmit}>
            {isError && <div className='reset-error'>
                {RESET_ERROR_CHECK_EMAIL_MESSAGE}
            </div>
            }
            <label className='reset-label' htmlFor="email">
                E-Mail
            </label>
            <SimpleInput className='reset-input' name="email"
                placeholder={`@${commonConstants.DEFAULT_DOMAIN}`} onChange={handleEmailChange} />
            {loading ? (
                <div className='reset-submit-loading' />
            ) : (<button className='reset-submit' type="submit" disabled={!isValidEmail}>
                {i18n('actionBar.submit')}
            </button>)}
            <p className='reset-forgotten-pwd'>
                <Link to="./articles">{i18n('actionBar.cancel')}</Link>
            </p>
        </Form>
    );
};

export default ResetEmailComponent;