import { useCallback } from 'react';
import classNames from 'classnames';
import './Searchbar.scss';

export interface SearchbarProps {
  placeholder: string;
  onSearch: (value: string) => void;
  value?: string;
  onChange: (value: string) => void;
}

const Searchbar = ({ placeholder, onSearch, onChange, value = '' }: SearchbarProps) => {
  const onKeyDown = useCallback((event) => {
    if (event.keyCode === 13) {
      onSearch(value);
    }
  }, [onSearch, value]);

  const onInputChange = useCallback((event) => {
    const inputValue = event.currentTarget.value;
    onChange(inputValue);
    if (!inputValue) {
      // if we cleared the field using the keyboard, reset search predicate
      onSearch('');
    }
  }, [onChange, onSearch]);

  const onClear = useCallback(() => {
    onChange('');
    onSearch('');
  }, [onChange, onSearch]);

  const search = useCallback(() => {
    onSearch(value);
  }, [onSearch, value]);

  return (
    <div className='searchbar'>
      <div className='searchbar-search'>
        <i className={classNames('icon-search', 'searchbar-clear-ico')} />
      </div>
      <div className='searchbar-input-container'>
        <input
          value={value}
          onChange={onInputChange}
          onKeyDown={onKeyDown}
          className='searchbar-input'
          placeholder={placeholder}
        />
        {value && (
          <button type="button" className='searchbar-clear' onClick={onClear}>
            <i className={classNames('icon-delete', 'searchbar-clear-ico')} />
          </button>
        )}
      </div>
      <button
        type="button"
        disabled={!value}
        onClick={search}
        className='searchbar-btn'
      >
        Rechercher
      </button>
    </div>
  );
};

export default Searchbar;
