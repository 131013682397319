import { createContext, RefObject } from 'react';

export interface SectionRefs {
  [key: string]: RefObject<HTMLElement>;
}

export interface ScrollspyContextProps {
  sectionRefs: SectionRefs;
}

const ScrollspyContext = createContext<ScrollspyContextProps>({
  sectionRefs: {},
});

export default ScrollspyContext;
